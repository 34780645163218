import styled, { css } from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

const colors = {
  border: SECOND_COLOR,
  error: "#f67070",
  success: PRIMARY_COLOR,
};

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "100%")};
  position: relative;
  align-content: center;
  align-items: center;
  z-index: 90;

  div {
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

export const Label = styled.label`
  width: 60%;
  display: block;
  font-weight: 600;
  cursor: pointer;
  color: #5c5757;
  font-size: 1.6rem;
  text-align: left;
  margin-right: 10px;

  ${(props) =>
    props.valid === "false" &&
    css`
      color: ${colors.error};
    `}
`;

export const Input = styled.input`
  width: 40%;
  background: #f1f1f1;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  padding: 10px 40px 10px 20px;
  margin: 10px 0;
  transition: 0.3s ease all;
  border: none;
  /* border-bottom: 1px solid #2e2e2e; */
  display: flex;
  font-size: 1.5rem;

  &.little {
    transform: scale(0.5);
    margin-top: 0;
  }

  &:focus {
    border: 3px solid ${colors.border};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }

  ${(props) =>
    props.valid === "true" &&
    css`
      border: 3px solid transparent;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${colors.error} !important;
    `}
`;

export const LegendError = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
  color: ${colors.error};
  display: none;

  ${(props) =>
    props.valid === "true" &&
    css`
      display: none;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      display: block;
    `}
`;
