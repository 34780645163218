import Grid from "@mui/material/Grid";
import Map, {
  NavigationControl,
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
} from "react-map-gl";
import FloatingSetting from "components/Float/FloatingSettingMap";
import FloatingTable from "components/Float/FloatingTableOfAllMap";

import { useState, useCallback, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { MapCard, NavInfo } from "./UserRoutesElements";
import DrawControl from "./DrawControl";
import MapDrawer from "./MapDrawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { grey } from "@mui/material/colors";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import DialogInformation from "components/Dialogs/DialogInformation.jsx";
// import fogStyle from "utils/fogStyle";
import {
  CustomSelect,
  ButtonInformation,
  NueronTools,
} from "./userRoutes";

import { SetUsers, SetShowUsers, SetShowAllUsers } from "redux/actions/admin";

import HandlerDrawerControl from "./HandlerDrawerControl";
import OperationDialog from "components/Dialogs/OperationDialog";
// Clusters
import CSVCluster from "../Clusters/CSV";
import ObjectsCluster from "../Clusters/Objects";
import OperationsCluster from "../Clusters/Operations";
import ComponentClustere from "../Clusters/Components";

//GeoControl
import GeocoderControl from "./geocoder-control";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import {
  ContentInformation,
} from "../ContentDialogs/ContentDialogs";
import { isAfter, parse } from "date-fns";
import { isBefore, parseISO } from "date-fns/esm";
// Services
import { findUsersByAdminCompanyId } from "services/findUsersByAdminCompanyId";
// Hooks
import useSWR from "swr";
import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import newWorkerClass from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import PolygonTable from "components/Drawers/DrawerPolygonTable";
import Lines from "./Lines";
import Trackings from "./Trackings";
import Regions from "./Regions";
import ExtendView from "./ExtendView";
import setDispatchFilterNewComponentsByType from "helpers/filters/setDispatchFilterComponents";
import { setShowDataComponents } from "redux/actions/digitalTwin";
import { PRIMARY_COLOR } from "utils/const";
import BaseMapButton from "components/Buttons/BaseMapButton";
mapboxgl.workerClass = newWorkerClass;

const CustomizedChevronLeftIcon = styled(ChevronLeftIcon)`
  background-color: #0e4d45;
  border-radius: 23px;
`;

const CustomizedChevronRightIcon = styled(ChevronRightIcon)`
  background-color: #0e4d45;
  border-radius: 23px;
`;

const MapDigitalTwin = ({
  objects,
  operations,
  anemometers,
  trackings,
  events,
  components,
}) => {
  const [baseMap, setBaseMap] = useState("streets-v11");
  const [open, setOpen] = useState(true);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);
  const [haveObjects, setHaveObjects] = useState(false);
  const [information, setInfoObjects] = useState(false);
  const [section, setSection] = useState("puntos");
  const [filterObjects, setFilterObjects] = useState([]);
  const [filterOperations, setFilterOperations] = useState([...operations]);
  const [filterEvents, setFilterEvents] = useState([...events]);
  const [filterComponents, setFilterComponents] = useState([...components]);
  const [seePolygonTable, setSeePolygonTable] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [currentDataEvent, setCurrentDataEvent] = useState({});
  const [numberTables, setNumberTables] = useState(false);

  window.addEventListener("storage", (event) => {
    if (event.key === "currentId") {
      setCurrentId(JSON.parse(localStorage.getItem("currentId")));
    } else if (event.key === "typeEvent") {
      setCurrentDataEvent(JSON.parse(localStorage.getItem("typeEvent")));
    } else if (event.key === "tablesOpen") {
      setNumberTables(!numberTables);
    }
  });

  //Data Admin
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { onCreate, onSelect, onUpdate, onDelete } = HandlerDrawerControl();

  const dispatch = useDispatch();

  const locationsJSON = useSelector(
    (state) => state.locationCSVReducer.locationsJSON
  );

  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const showLines = useSelector((state) => state.digitalTwinReducer.showLines);
  const showTrackings = useSelector(
    (state) => state.digitalTwinReducer.showTrackings
  );

  const neuron = useSelector(
    (state) => state.digitalTwinReducer.setNeuronSelectTool
  );

  const showAllEvents = useSelector((state) => state.adminReducer.showEvents);
  const showMarkerByElementWithMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );

  const [viewState, setViewState] = useState({
    latitude: 4.81500179,
    longitude: -75.725484,
    width: "100vw",
    height: "100vh",
    zoom: 5,
  });

  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const users = useSelector((state) => state.adminReducer.SetUsers);
  const showUsers = useSelector((state) => state.adminReducer.SetShowUsers);

  const showAllUsers = useSelector(
    (state) => state.adminReducer.SetShowAllUsers
  );

  const mapRef = useRef();

  const dateFilterObject = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );

  //Get all regions from api-green-dragon-mg by adminCompanyId
  const { data: regionsData, error: errorRegions } = useSWR(
    `${process.env.REACT_APP_URL_MG_API}region?admin_company_id=${adminCompanyId}`
  );

  const dataRegions = regionsData && !errorRegions ? regionsData : [];

  const filterObjectsByDateAndUser = useCallback(() => {
    const { endDate: endDateString, startDate: startDateString } =
      dateFilterObject;
    const endDate = parse(endDateString, "yyyy-MM-dd", new Date());
    const startDate = parse(startDateString, "yyyy-MM-dd", new Date());
    if (objects.length > 0) {
      const newObjects = objects.filter((object) => {
        const userId = object.user.id;
        const { date: dateObjectString } = object;
        const dateObject = parseISO(dateObjectString, "yyyy-MM-dd", new Date());
        const isInRange =
          !isBefore(dateObject, startDate) && !isAfter(dateObject, endDate);
        const isShowUSer = users && users[userId]?.isShow;
        const isShow = isInRange && isShowUSer;
        return isShow;
      });
      setFilterObjects(() => [...newObjects]);
    }
  }, [dateFilterObject, objects, users]);

  const filterOperationsByDateAndUser = useCallback(() => {
    const { endDate: endDateString, startDate: startDateString } =
      dateFilterObject;
    const endDate = parse(endDateString, "yyyy-MM-dd", new Date());
    const startDate = parse(startDateString, "yyyy-MM-dd", new Date());
    if (operations.length > 0) {
      const newOperations = operations.filter((operation) => {
        const { userId } = operation;
        const { date: dateOperationString } = operation;
        const dateOperation = parseISO(
          dateOperationString,
          "yyyy-MM-dd",
          new Date()
        );
        const isInRange =
          !isBefore(dateOperation, startDate) &&
          !isAfter(dateOperation, endDate);
        const isShowUSer = users && users[userId]?.isShow;
        const isShow = isInRange && isShowUSer;
        return isShow;
      });
      setFilterOperations(() => [...newOperations]);
    }
  }, [dateFilterObject, operations, users]);

  const filterEventsByDateAndUser = useCallback(() => {
    const { endDate: endDateString, startDate: startDateString } =
      dateFilterObject;
    const endDate = parse(endDateString, "yyyy-MM-dd", new Date());
    const startDate = parse(startDateString, "yyyy-MM-dd", new Date());
    if (events.length > 0) {
      const newEvents = events.filter((event) => {
        const { userId } = event;
        const { date: dateEventString } = event;
        const dateEvent = parseISO(dateEventString, "yyyy-MM-dd", new Date());
        const isInRange =
          !isBefore(dateEvent, startDate) && !isAfter(dateEvent, endDate);
        const isShowUSer = users && users[userId]?.isShow;
        const isShow = isInRange && isShowUSer;
        return isShow;
      });
      setFilterEvents(() => [...newEvents]);
    }
  }, [events, users, dateFilterObject]);

  const filterComponentByDateAndUser = useCallback(() => {
    const { endDate: endDateString, startDate: startDateString } =
      dateFilterObject;
    const endDate = parse(endDateString, "yyyy-MM-dd", new Date());
    const startDate = parse(startDateString, "yyyy-MM-dd", new Date());
    if (components.length > 0) {
      const newComponents = components.filter((component) => {
        const { userId } = component;
        const { date: dateComponentString } = component;
        const dateComponent = parseISO(
          dateComponentString,
          "yyyy-MM-dd",
          new Date()
        );
        const isInRange =
          !isBefore(dateComponent, startDate) &&
          !isAfter(dateComponent, endDate);
        const isShowUSer = users && users[userId]?.isShow;
        const isShow = isInRange && isShowUSer;
        return isShow;
      });
      setFilterComponents(() => [...newComponents]);
    }
  }, [components, users, dateFilterObject]);

  useEffect(() => {
    if (showUsers) dispatch(SetShowUsers(!showUsers));
    filterObjectsByDateAndUser();
    filterOperationsByDateAndUser();
    filterEventsByDateAndUser();
    filterComponentByDateAndUser();
  }, [
    filterObjectsByDateAndUser,
    filterOperationsByDateAndUser,
    filterEventsByDateAndUser,
    filterComponentByDateAndUser,
    dispatch,
    showUsers,
    currentId,
    showAllEvents,
    showMarkerByElementWithMedia,
    objects,
  ]);

  useEffect(() => {
    setDispatchFilterNewComponentsByType(components, dispatch);
    dispatch(setShowDataComponents({ state: true, color: `${PRIMARY_COLOR}` }));
  }, [components]);

  useEffect(() => {
    findUsersByAdminCompanyId({ adminCompanyId }).then((usersAdmin) => {
      const users = {};
      usersAdmin.forEach((user) => {
        users[user.id] = {
          operator: user.name,
          isShow: true,
        };
      });
      dispatch(SetUsers(users));
    });
  }, [dispatch, adminCompanyId]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (drawState) => {
    setOpen(false);
  };

  const seeAllMarkerOfUser = () => {
    for (const key in users) {
      users[key].isShow = true;
      setHaveObjects(false);
    }
    setFilterObjects(objects);
  };

  if (showAllUsers) {
    dispatch(SetShowAllUsers(!showAllUsers));
    seeAllMarkerOfUser();
  }

  const [popUpLines, setPopUpLines] = useState({});
  const [popUpregions, setPopUpRegions] = useState({});

  const handlePopUpRegion = (lngLat, features, type) => {
    const [feature] = features;
    const { layer } = feature;
    const { id } = layer;
    const { lat, lng } = lngLat;
    setPopUpRegions((current) => ({
      popUp: {
        latitude: lat,
        longitude: lng,
        id,
        type,
      },
      region: {
        ...current.region,
        ...feature,
      },
    }));
  };

  const handlePopUpLine = (lngLat, features) => {
    const { lat, lng } = lngLat;
    const { object } = features[0].properties;
    const line = JSON.parse(object);
    const { id, properties } = line;
    setPopUpLines((current) => ({
      popUp: {
        id,
        latitude: lat,
        longitude: lng,
      },
      line: {
        ...current.line,
        ...properties,
        id,
      },
    }));
  };

  const handleMapClick = (e) => {
    const { lngLat, features } = e;
    if (features && features.length > 0) {
      const [feature] = features;
      const { layer } = feature;
      if (layer.type === "line") handlePopUpLine(lngLat, features);
      if (layer.type === "fill") handlePopUpRegion(lngLat, features, "click");
    }
  };

  const handleRightClickMap = (e) => {
    const { lngLat, features } = e;
    if (features && features.length > 0) {
      const [feature] = features;
      const { layer } = feature;
      if (layer.type === "fill")
        handlePopUpRegion(lngLat, features, "rightClick");
    }
  };

  const handleTabInformation = (event) => {
    setSection(event.currentTarget.value);
  };

  // Modal that show the objects
  const handleInformationObjects = () => {
    setInfoObjects(true);
  };

  const onSelectPoint = useCallback((longitude, latitude) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 2000,
      zoom: 16,
    });
  }, []);

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const coordinatesGeocoder = function (query) {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    if (!matches) {
      return null;
    }

    function coordinateFeature(lng, lat) {
      return {
        center: [lng, lat],
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        place_name: "Lat: " + lat + " Lng: " + lng,
        place_type: ["coordinate"],
        properties: {},
        type: "Feature",
      };
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
      // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
  };

  useEffect(() => {
    if (geometryPolygon?.type === "Polygon") setSeePolygonTable(() => true);
  }, [geometryPolygon]);

  return (
    <>
      <Grid container>
        {/* Dialogs */}
        <OpenDialog
          openDialog={haveObjects}
          setOpenDialog={setHaveObjects}
          content={contentDialog}
        />
        {/* Dialog infotmation */}
        <DialogInformation
          openDialog={information}
          setOpenDialog={setInfoObjects}
          content={ContentInformation(section, handleTabInformation)}
        />
        <Grid item xs={12} sm={6} md={9}>
          <MapCard>
            <CustomSelect>
              <NavInfo tipo={open}>
                <MapDrawer drawState={open} dataObjects={objects} />
                {open === false ? (
                  <IconButton
                    color="error"
                    onClick={handleDrawerOpen}
                    fontSize="medium"
                    sx={{ color: grey[50] }}
                  >
                    <CustomizedChevronRightIcon sx={{ color: grey[100] }} />
                  </IconButton>
                ) : (
                  <IconButton
                    color="error"
                    onClick={handleDrawerClose}
                    fontSize="large"
                    sx={{ color: grey[50] }}
                  >
                    <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
                  </IconButton>
                )}
              </NavInfo>
            </CustomSelect>
            {seePolygonTable && (
              <PolygonTable
                setSeePolygonTable={setSeePolygonTable}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
              />
            )}
            <FloatingTable setSeePolygonTable={setSeePolygonTable} />
            <FloatingSetting />
            <NueronTools display={neuron.show} top={neuron.top} />
            {/* Button information */}
            {objects && (
              <ButtonInformation onClick={handleInformationObjects}>
                <InfoIcon
                  style={{
                    color: "#282828",
                    fontSize: "1.6rem",
                  }}
                />
                <div className="container-tooltip">
                  <span className="tooltip">Information</span>
                </div>
              </ButtonInformation>
            )}

            {/* Button Extend view */}
            <ExtendView map={mapRef} />

            {/* Button layer map */}
            {objects && (
              <BaseMapButton
                setHaveObjects={setHaveObjects}
                setContentDialog={setContentDialog}
                setBaseMap={setBaseMap}
                position={357}
              />
            )}

            <Map
              {...viewState}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              style={{ width: "100%", height: "100vh", margin: "auto" }}
              mapStyle={`mapbox://styles/mapbox/${baseMap}`}
              onClick={handleMapClick}
              onContextMenu={handleRightClickMap}
              interactiveLayerIds={interactiveLayerIds}
              onMove={(e) => setViewState(e.viewState)}
              ref={mapRef}
              attributionControl={false}
              //projection={"globe"}
              //fog={fogStyle}
            >
              <GeocoderControl
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                position="top-right"
                placeholder={"Try: Longitude, Latitude"}
                localGeocoder={coordinatesGeocoder}
              />
              <NavigationControl />
              {locationsJSON.length !== 0 && (
                <CSVCluster
                  locationsJSON={locationsJSON}
                  onSelectPoint={onSelectPoint}
                  bounds={bounds}
                  viewState={viewState}
                  setViewState={setViewState}
                />
              )}

              <DrawControl
                position="bottom-right"
                displayControlsDefault={false}
                userProperties={true}
                // styles={StyleDrawControl}
                controls={{
                  polygon: true,
                  line_string: true,
                  point: true,
                  trash: true,
                }}
                onSelect={onSelect}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onDelete={onDelete}
              />

              {dataRegions && (
                <Regions
                  layerIds={interactiveLayerIds}
                  setLayerIds={setInteractiveLayerIds}
                  dataRegions={dataRegions}
                  popUpregions={popUpregions}
                />
              )}

              {showLines && (
                <Lines
                  popUpLines={popUpLines}
                  setPopUpLines={setPopUpLines}
                  layerIds={interactiveLayerIds}
                  setLayerIds={setInteractiveLayerIds}
                />
              )}
              {showTrackings && trackings && (
                <Trackings trackings={trackings} />
              )}

              <AttributionControl customAttribution="© Decimetrix® 2023" />
              <FullscreenControl />
              <GeolocateControl
                ref={useCallback((ref) => {
                  if (ref) {
                    ref.trigger();
                  }
                }, [])}
                trackUserLocation={true}
              />
              <ScaleControl position="bottom-right" />   
              
              {filterObjects && (
                <ObjectsCluster
                  objects={filterObjects}
                  onSelectPoint={onSelectPoint}
                  bounds={bounds}
                  viewState={viewState}
                  setViewState={setViewState}
                />
              )}
              <OperationsCluster
                anemometers={anemometers}
                operations={filterOperations}
                events={filterEvents}
                onSelectPoint={onSelectPoint}
                bounds={bounds}
                viewState={viewState}
                setViewState={setViewState}
              />
              <ComponentClustere
                components={filterComponents}
                onSelectPoint={onSelectPoint}
                bounds={bounds}
                viewState={viewState}
                setViewState={setViewState}
              />
              <OperationDialog
                style={{
                  margin: 0,
                  padding: 0,
                  width: {
                    xs: "100%",
                    sm: "95%",
                    md: "93%",
                    lg: "90%",
                  },
                  height: {
                    xs: "100%",
                    sm: "95%",
                  },
                  maxWidth: "none",
                }}
                minimized={true}
                currentId={currentId}
                handle={setCurrentId}
                dataEvent={currentDataEvent}
                handleEvent={setCurrentDataEvent}
              />
            </Map>
          </MapCard>
        </Grid>
      </Grid>
    </>
  );
};

export default MapDigitalTwin;
