const InformationCollect = () => {
  return (
    <>
      <p className="text">
        1.1 Personal Information: When you register an account or use the
        Platform, we may collect personal information that can identify you,
        such as your name, email address, contact information, and company
        details.
      </p>
      <p className="text">
        1.2 Usage Data: We may automatically collect certain information about
        your interactions with the Platform, including your IP address, device
        information, browser type, and operating system. This information is
        used for analytical purposes and to improve the Platform's performance
        and user experience.
      </p>
      <p className="text">
        1.3 Cookies and Tracking Technologies: We use cookies and similar
        tracking technologies to enhance your experience on the Platform. These
        technologies collect information about your usage patterns and
        preferences.
      </p>
    </>
  );
};
const UseInformation = () => {
  return (
    <>
      <p className="text">
        2.1 We use the collected information for the following purposes:
      </p>
      <br></br>
      <p className="text">- To provide, operate, and maintain the Platform;</p>
      <p className="text">
        - To personalize and improve your experience on the Platform;
      </p>
      <p className="text">
        - To communicate with you and respond to your inquiries;
      </p>
      <p className="text">
        - To send you technical notices, updates, and administrative messages;
      </p>
      <p className="text">
        - To analyze usage patterns and trends and perform data analytics;
      </p>
      <p className="text">
        - To enforce our terms, policies, and legal obligations;
      </p>
      <p className="text">
        - To protect against fraud, unauthorized access, or other potential
        risks.
      </p>
      <br></br>
      <p className="text">
        2.2 We may use anonymized and aggregated data for statistical, research,
        and analytical purposes.
      </p>
    </>
  );
};
const DataSharing = () => {
  return (
    <>
      <p className="text">
        3.1 We do not sell, trade, or rent your personal information to third
        parties for marketing purposes. However, we may share your information
        in the following circumstances:
      </p>
      <br></br>
      <p className="text">
        - With service providers who assist us in providing and improving the
        Platform (e.g., hosting, analytics, customer support);
      </p>
      <p className="text">
        - With your consent or at your direction, such as when you authorize the
        integration of third-party services with the Platform;
      </p>
      <p className="text">
        - With the consent of the company paying for your license accessfor
        usage optimization, such as when the providing employerwantsto optimize
        the access to the Platform;
      </p>
      <p className="text">
        - When required by law or to comply with legal process, enforceable
        governmental request, or applicable regulations;
      </p>
      <p className="text">
        - To protect our rights, property, or safety, and that of our users and
        the public.
      </p>
      <br></br>
      <p className="text">
        3.2 We may disclose aggregated and anonymized information that does not
        identify individual users for marketing, advertising, research, or other
        purposes.
      </p>
    </>
  );
};
const DataRetention = () => {
  return (
    <>
      <p className="text">
        {" "}
        4.1 We retain your personal information for as long as necessary to
        fulfill the purposes outlined in this Policy unless a longer retention
        period is required or permitted by law.
      </p>
      <p className="text">
        {" "}
        4.2 We will securely delete or anonymize your personal information
        within a reasonable time after it is no longer needed for the purposes
        stated in this Policy or as required by applicable law.
      </p>
    </>
  );
};
const DataSecurity = () => {
  return (
    <>
      <p className="text">
        {" "}
        5.1 We implement appropriate technical and organizational measures to
        protect your personal information against unauthorized access, loss, or
        alteration. However, please note that no data transmission or storage
        system is completely secure, and we cannot guarantee the absolute
        security of your information.{" "}
      </p>
    </>
  );
};
const LinksServices = () => {
  return (
    <>
      <p className="text">
        {" "}
        6.1 The Platform may contain links to third-party websites,
        applications, or services. This Policy does not apply to such
        third-party platforms, and we are not responsible for their privacy
        practices. We encourage you to review the privacy policies of those
        third parties before providing any personal information.
      </p>
    </>
  );
};
const ChildrenPrivacy = () => {
  return (
    <>
      <p className="text">
        {" "}
        7.1 The Platform is not intended for use by individuals under the age of
        16. We do not knowingly collect personal information from children. If
        we become aware that we have inadvertently collected personal
        information from a child under 16, we will take steps to delete the
        information as soon as possible.
      </p>
    </>
  );
};
const PrivacyPolicy = () => {
  return (
    <>
      <p className="text">
        {" "}
        8.1 We may update or modify this Policy from time to time. If we make
        any material changes, we will notify you by posting the revised Policy
        on the Platform or by other means. Your continued use of the Platform
        after the effective date of therevised Policy constitutes your
        acceptance of the changes.
      </p>
    </>
  );
};
const ContactUs = () => {
  return (
    <>
      <p className="text">
        {" "}
        9.1 If you have any questions or concerns regarding this Policy or our
        privacy practices, please contact us at business@decimetrix.com.{" "}
      </p>
    </>
  );
};
const Acknowledgment = () => {
  return (
    <>
      <p className="text">
        {" "}
        By downloading, accessing, or using the Platform, you acknowledge that
        you have read, understood, and agree to be bound by this Agreement. If
        you do not agree with any part of this Agreement, you should not
        download, access, or use the Platform.
      </p>
    </>
  );
};

export const dataCloudPol = {
  title:
    "Decimetrix® Green Dragon cloud enterprise-level web platform Privacy Policy",
  mainText:
    'This  Privacy  Policy  ("Policy")  describes  how  Decimetrix,  Incand  Decimetrix  SAS ("Decimetrix,"  "we,"  or "us")  collects,  uses,  and  protects  the  personal  information  and  data of  users  ("User,"  "you,"  or "your") who  access  or  use  the  Decimetrix  Green  Dragon  cloud  enterprise-level  web  platform  ("Platform").  By accessing  or  using  the  Platform,  you  consent  to  the  collection,  storage,  processing,  and  use  of  your personal information as described in this Policy.',
  info: [
    {
      subtitle: "Information We Collect",
      text: <InformationCollect />,
    },
    {
      subtitle: "Use of Information",
      text: <UseInformation />,
    },
    {
      subtitle: "Data Sharing and Disclosure",
      text: <DataSharing />,
    },
    {
      subtitle: "Data Retention",
      text: <DataRetention />,
    },
    {
      subtitle: "Data Security",
      text: <DataSecurity />,
    },
    {
      subtitle: "Third-Party Links and Services",
      text: <LinksServices />,
    },
    {
      subtitle: "Children's Privacy",
      text: <ChildrenPrivacy />,
    },
    {
      subtitle: "Changes to this Privacy Policy",
      text: <PrivacyPolicy />,
    },
    {
      subtitle: "Contact Us",
      text: <ContactUs />,
    },
    {
      subtitle: "Acknowledgment",
      text: <Acknowledgment />,
    },
  ],
};
