import axios from "axios";

const findTypeEventsTypeElements = async (queries) => {
  const { typeElementId, pointTypeEventId } = queries;
  const token = localStorage.getItem("token");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-events-type-elements?`;
  if (adminCompanyId) {
    url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-events-type-elements?adminCompanyId=${adminCompanyId}`;
  }
  let existQuery = false;

  if (typeElementId) {
    url = url + `&typeElementId=${typeElementId}`;
    existQuery = true;
  }

  if (pointTypeEventId) {
    if (existQuery) url = url + `&pointTypeEventId=${pointTypeEventId}`;
    else url = url + `&pointTypeEventId=${pointTypeEventId}`;
  }

  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });                         

  const data = await result.data;
  return data;
};

export default findTypeEventsTypeElements;
