import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const Container = styled.div`
  position: fixed;
  top: 10rem;
  left: 2rem;
  font-size: 2rem;
  box-shadow: ${(props) =>
    props.background ? "rgba(149, 157, 165, 0.2) 0px 8px 24px" : "none"};
  background: ${(props) => (props.background ? "#fff" : "transparent")};
  border-radius: ${(props) => (props.background ? "10px" : "50%")};

  .close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: translate(10px, -10px);
  }

  .dates {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
  }

  .buttonFilter {
    background: ${PRIMARY_COLOR};
    color: #fff;
    padding: 1rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
