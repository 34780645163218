// React
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
// Redux
import { setFeaturesDetailsDT } from "redux/actions/admin";
import { setObjectsUnderDrawnPoint } from "redux/actions/index";
import { useDispatch } from "react-redux";
// Components
import TextInput from "../GenericInput";
import SelectInput from "../SelectInput";
// Custom hooks
import useGet from "hooks/useFetch";
// Style
import { Form } from "./DynamicFieldsStyle";
import { StepSection } from "../Events/DynamicEventStyles";
// Services
import saveObject from "services/saveObject";
import bodyObjectWithFiles from "services/createBodyObject";
import useSwr from "swr";
// Config
import { config } from "config.js";
// Hooks
import operationsExp from "hooks/useOperations.jsx";

const findHalf = (fields) => {
  return {
    fields,
    half: parseInt(fields?.length / 2),
    length: fields?.length,
  };
};

function Formulario({ genericData }) {
  // States
  const [typeElemetFields, setTypeElementFields] = useState();
  // Custom hooks
  const [typeElement, errorTypeElement] = useGet(
    `api/v1/type-elements?id=${genericData.point.typeElementId}`
  );
  // Swr
  const { mutate } = useSWRConfig();
  // Redux
  const dispatch = useDispatch();
  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // Local storage
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  // Effects
  useEffect(() => {
    typeElement &&
      !errorTypeElement &&
      setTypeElementFields(findHalf(typeElement[0]?.fields));
  }, [typeElement, errorTypeElement]);

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`
  );
  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];
  const [form, setForm] = useState({});

  // Method
  const onSubmit = async (data) => {
    //get all numeric fields from the form
    const numericFields = await typeElemetFields.fields
      ?.map((field) => {
        if (field.type === "number") {
          const elmt = document.getElementById(field.name);
          if (elmt) {
            field.value = elmt.value;
            return field;
          }
        }
        return null;
      })
      .filter((it) => it !== null);

    const expFields = await typeElemetFields.fields?.filter(
      (field) => field.type === "operation"
    );

    const expFieldsProcessed = operationsExp(expFields, numericFields);

    if (expFieldsProcessed.length > 0)
      expFieldsProcessed.forEach((fld) => {
        data[fld.name] = JSON.stringify(fld);
      });
    if (numericFields.length > 0)
      numericFields.forEach((fld, index) => {
        data[fld.name] = JSON.stringify(fld);
      });

    const fileFields = typeElemetFields.fields.filter(
      (field) => field.type === "file"
    );
    const bodyObject = await bodyObjectWithFiles(fileFields, data, genericData);

    // add alias to every field, if exists
    let tmp = {};
    bodyObject?.attributes.map((item, index) => {
      tmp = typeElemetFields?.fields.find((it) => it.name === item.name);
      if (tmp) bodyObject.attributes[index].alias = tmp?.alias || "";
      return item;
    });

    const res = await saveObject(
      {
        point: bodyObject,
        regionIds: genericData?.regionIds,
      },
      "objects-region"
    );
    if (res) {
      mutate(
        `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`
      );
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
      dispatch(setObjectsUnderDrawnPoint([]));
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {typeElemetFields && (
        <div>
          <div>
            {typeElemetFields.fields?.map((field, index) => {
              if (field.type === "operation") {
                <TextInput
                  key={index}
                  field={field}
                  register={register}
                  errors={errors}
                />;
              }
              if (field.type === "select") {
                return (
                  <SelectInput key={index} field={field} register={register} />
                );
              }
              if (field.type === "currency") {
                return (
                  <div>
                    <TextInput
                      key={index}
                      field={field}
                      register={register}
                      errors={errors}
                    />
                    <SelectInput
                      key={index}
                      field={field}
                      register={register}
                    />
                  </div>
                );
              }
              if (field.type === "range") {
                const step =
                  field.options.length !== 0 ? parseInt(field.options[0]) : 1;
                if (Object.keys(form).length === 0 || form[field.name] === "") {
                  const middleValue =
                    Math.ceil((field.max - field.min) / 2) + field.min;
                  form[field.name] = middleValue;
                }
                return (
                  <div key={index} onChange={(e) => handleChange(e)}>
                    <TextInput
                      key={index}
                      field={{
                        name: field.name,
                        required: field.required,
                        type: field.type,
                        step: step,
                        max: field.max,
                        min: field.min,
                      }}
                      register={register}
                      errors={errors}
                    />
                    <StepSection>
                      <b>Step: {step}</b>
                      <b>
                        {form[field.name] ? form[field.name] : field.min + step}
                      </b>
                    </StepSection>{" "}
                  </div>
                );
              }
              if (field.type === "delegate") {
                if (
                  form[field.name] === "" &&
                  operatorsAdmin &&
                  !errorOperators
                ) {
                  form[
                    field.name
                  ] = `${allOperators.operators[0].firstName} ${allOperators.operators[0].firstLastName}`;
                }
                return (
                  <div onChange={(e) => handleChange(e)}>
                    <SelectInput
                      key={index}
                      field={{
                        name: field.name,
                        options: allOperators.operators?.map((operator) => {
                          return `${operator?.firstName} ${operator?.firstLastName}`;
                        }),
                        required: field.required,
                        type: field.type,
                      }}
                      register={register}
                    />
                  </div>
                );
              }
              if (field.type === "file") {
                return (
                  <div onChange={(e) => handleChange(e)}>
                    <TextInput
                      key={index}
                      field={{
                        name: field.name,
                        required: field.required,
                        type: field.type,
                        possible: field.possible,
                      }}
                      register={register}
                      errors={errors}
                    />
                  </div>
                );
              }
              if (field.type === "captureMedia") return null;
              return (
                <TextInput
                  key={index}
                  field={field}
                  register={register}
                  errors={errors}
                />
              );
            })}
          </div>
        </div>
      )}
      <button type="submit">SAVE</button>
    </Form>
  );
}

export default Formulario;
