import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .types-base-map {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .type-base-map {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .select-base-map {
        outline: none;
        border: none;
        background-color: ${PRIMARY_COLOR};
        color: white;
        padding: 5px 8px;
        font-size: 1.5rem;
        cursor: pointer;
        border-radius: 5px;
        width: 150px;
      }

      img {
        height: 20rem;
        width: 20rem;
        object-fit: cover;
      }
    }
  }
`;

export const ButtonBaseMap = styled("button")`
  outline: none;
  position: absolute;
  top: ${(props) => props.top}px;
  right: 8px;
  z-index: 8;
  background-color: #f9f9f9;
  color: #b9b9b9;
  display: inline-block;
  cursor: pointer;
  padding: 5px 7px 2px 7px;
  border-radius: 5px;
  border: solid 2.3px #c9c5c5;


  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: #000;
      color: #fff;
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;
