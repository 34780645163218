import {
  SHOW_DRAWER_DIGITAL_TWIN,
  SHOW_CARD_MAKER_DIGITAL_TWIN,
  SET_DATA_OBJECT_DT,
  SET_PROFILE_IMAGE,
  SET_DATA_OBJECT_DT_IMAGES,
  SHOW_DRAWER_OBJECT_DT,
  SHOW_MARKERS_DIGITAL_TWIN,
  SET_MARKER_BY_TYPE_ELEMENT_ID,
  SHOW_MARKERS_BY_ACTIVITIES,
  SHOW_MARKER_BY_ELEMENT_WITH_MEDIA,
  SHOW_OPERATION_DETAILS,
  OPERATION_DETAILS,
  SET_FILTER_OBJECTS_BY_DATA,
  SET_DRAW_ANY_THING,
  SET_DRAWER_CONTROL,
  SET_UPDATE_REGION,
  SET_MAP,
  SET_OBJECTS_UNDER_DRAWN_POINT,
  SHOW_EVENTS_BY_INSPECTION_TYPE,
  SET_DATA_OPERATIONS,
  SHOW_LABELS,
  SET_OPERATION_CLUSTERS,
  SET_OBJECT_CLUSTERS,
  SET_COMPONENT_CLUSTERS,
  SHOW_OPERATIONS_BY_TYPE,
  SET_SHOW_LINES,
  SET_FLY_TO,
  SET_OBJECTS_DIGITAL_TWIN,
  SET_FILTERED_OBJECTS_DIGITAL_TWIN,
  SET_OPERATIONS,
  SET_FILTERED_OPERATIONS,
  SET_SHOW_TRACKINGS,
  SET_DATA_EVENTS,
  SET_TABLE_DETAILS_FORWARD,
  SET_NEURON_SELECT_TOOL,
  DYNAMIC_ROW_NUM,
  GET_TABLE_ROWS_FILTER,
  GET_TABLE_ROWS_FULL,
  OPEN_SELECT_COLUMNS,
  DOWNLOAD_OPTION,
  DATA_NEW_TABLE_WINDOW,
  STATE_OBJECT_FILTER_TABLE,
  STATE_EVENT_FILTER_TABLE,
  STATE_COMPONENT_FILTER_TABLE,
  SET_DATA_COMPONENTS,
  SHOW_DATA_COMPONENTS_LIBRARY,
  SHOW_DATA_COMPONENTS_BY_TYPE,
  SHOW_DATA_COMPONENTS,
  SET_FILTER_COMPONENT,
  SET_FILTER_EVENTS,
  SHOW_EVENTS,
  SHOW_EVENTS_BY_LIBRARY,
  SHOW_EVENTS_BY_TYPE,
  STATE_FILTER_BUTTON_TABLE,
} from "../actions/types";

const initialState = {
  stateFilterButtonTable: false,
  stateFilterEvents: false,
  showEvents: {},
  showEventsByLibrary: [],
  showEventsByType: [],
  stateFilterComponent: false,
  stateObjectFilterTable: false,
  stateComponentFilterTable: false,
  stateEventFilterTable: false,
  dataNewTableWindow: [],
  optionDownload: 0,
  openSelectColumns: false,
  filterTableRows: {},
  fullTableRows: {},
  rowNum: 0,
  showDrawerDigitalTwin: false,
  showDrawerObjectDt: false,
  cardMarkerDigitalTwin: false,
  objectDataDT: {},
  profileImage: [],
  objectDataDTImages: [],
  showMarkersDigitalTwin: true,
  showMarkerByTypeElementId: {},
  showMarkersByActivities: {},
  showDataComponents: {},
  showDataComponentsLibrary: [],
  showDataComponentsByType: [],
  showMarkerByElementWithMedia: false,
  showOperationDetails: false,
  operationDetails: {},
  setFilterObjectsByData: {},
  objects: [],
  filteredObjects: [],
  operations: [],
  filteredOperations: [],
  drawAnyThing: {
    type: "",
    isDraw: false,
  },
  drawerControl: null,
  updateRegion: {},
  map: null,
  objectsUnderDrawnPoint: [],
  showEventsByInspectionType: {},
  setDataOperations: [],
  operationClusters: JSON.parse(localStorage.getItem("operationCluster")) || {
    radius: 100,
    maxZoom: 19,
  },
  objectClusters: JSON.parse(localStorage.getItem("objectClusters")) || {
    radius: 100,
    maxZoom: 19,
  },
  componentClusters: JSON.parse(localStorage.getItem("componentClusters")) || {
    radius: 100,
    maxZoom: 19,
  },
  showLabels: false,
  showOperationsByType: {},
  setDataEvents: [],
  setDataComponents: [],
  showLines: false,
  showTrackings: false,
  flyTo: {
    object: {
      id: "",
      latitude: "",
      longitude: "",
    },
    operation: {
      id: "",
      latitude: "",
      longitude: "",
    },
  },
  setTableDetailsForward: 1000,
  setNeuronSelectTool: {
    show: false,
    top: null,
  },
};

const ShowDrawerDigitalTwinReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATE_FILTER_BUTTON_TABLE:
      return {
        ...state,
        stateFilterButtonTable: action.payload,
      };
    case SET_FILTER_EVENTS:
      return {
        ...state,
        stateFilterEvents: action.payload,
      };
    case SHOW_EVENTS:
      return {
        ...state,
        showEvents: action.payload,
      };

    case SHOW_EVENTS_BY_LIBRARY:
      return {
        ...state,
        showEventsByLibrary: action.payload,
      };

    case SHOW_EVENTS_BY_TYPE:
      return {
        ...state,
        showEventsByType: action.payload,
      };
    case SET_FILTER_COMPONENT:
      return {
        ...state,
        stateFilterComponent: action.payload,
      };
    case SHOW_DATA_COMPONENTS:
      return {
        ...state,
        showDataComponents: action.payload,
      };
    case SHOW_DATA_COMPONENTS_BY_TYPE:
      return {
        ...state,
        showDataComponentsByType: action.payload,
      };
    case SHOW_DATA_COMPONENTS_LIBRARY:
      return {
        ...state,
        showDataComponentsLibrary: action.payload,
      };
    case SET_DATA_COMPONENTS:
      return {
        ...state,
        setDataComponents: action.payload,
      };
    case STATE_OBJECT_FILTER_TABLE:
      return {
        ...state,
        stateObjectFilterTable: action.payload,
      };
    case STATE_EVENT_FILTER_TABLE:
      return {
        ...state,
        stateEventFilterTable: action.payload,
      };
    case STATE_COMPONENT_FILTER_TABLE:
      return {
        ...state,
        stateComponentFilterTable: action.payload,
      };
    case DATA_NEW_TABLE_WINDOW:
      return {
        ...state,
        dataNewTableWindow: action.payload,
      };
    case DOWNLOAD_OPTION:
      return {
        ...state,
        optionDownload: action.payload,
      };
    case OPEN_SELECT_COLUMNS:
      return {
        ...state,
        openSelectColumns: action.payload,
      };
    case GET_TABLE_ROWS_FILTER:
      return {
        ...state,
        filterTableRows: action.payload,
      };
    case GET_TABLE_ROWS_FULL:
      return {
        ...state,
        fullTableRows: action.payload,
      };
    case DYNAMIC_ROW_NUM:
      return {
        ...state,
        rowNum: action.payload,
      };
    case SET_OBJECTS_DIGITAL_TWIN:
      return {
        ...state,
        objects: action.payload,
      };
    case SET_FILTERED_OBJECTS_DIGITAL_TWIN:
      return {
        ...state,
        filteredObjects: action.payload,
      };
    case SET_SHOW_TRACKINGS:
      return {
        ...state,
        showTrackings: action.payload,
      };
    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.payload,
      };
    case SET_FILTERED_OPERATIONS:
      return {
        ...state,
        filteredOperations: action.payload,
      };
    case SET_SHOW_LINES:
      return {
        ...state,
        showLines: action.payload,
      };
    case SET_FLY_TO:
      return {
        ...state,
        flyTo: action.payload,
      };
    case SET_OPERATION_CLUSTERS:
      return {
        ...state,
        operationClusters: action.payload,
      };
    case SET_OBJECT_CLUSTERS:
      return {
        ...state,
        objectClusters: action.payload,
      };
    case SET_COMPONENT_CLUSTERS:
      return {
        ...state,
        componentClusters: action.payload,
      };
    case SET_OBJECTS_UNDER_DRAWN_POINT:
      return {
        ...state,
        objectsUnderDrawnPoint: [...action.payload],
      };
    case SET_MAP:
      return {
        ...state,
        map: action.payload,
      };
    case SET_UPDATE_REGION:
      return {
        ...state,
        updateRegion: { ...action.payload },
      };
    case SET_DRAW_ANY_THING:
      return {
        ...state,
        drawAnyThing: action.payload,
      };
    case SET_DRAWER_CONTROL:
      return {
        ...state,
        drawerControl: action.payload,
      };
    case SET_FILTER_OBJECTS_BY_DATA:
      return {
        ...state,
        setFilterObjectsByData: action.payload,
      };
    case OPERATION_DETAILS:
      return {
        ...state,
        operationDetails: action.payload,
      };
    case SHOW_OPERATION_DETAILS:
      return {
        ...state,
        showOperationDetails: action.payload,
      };
    case SHOW_MARKER_BY_ELEMENT_WITH_MEDIA:
      return {
        ...state,
        showMarkerByElementWithMedia: action.payload,
      };
    case SHOW_DRAWER_DIGITAL_TWIN:
      return {
        ...state,
        showDrawerDigitalTwin: !state.showDrawerDigitalTwin,
      };
    case SHOW_DRAWER_OBJECT_DT:
      return {
        ...state,
        showDrawerObjectDt: action.payload,
      };
    case SHOW_CARD_MAKER_DIGITAL_TWIN:
      return {
        ...state,
        cardMarkerDigitalTwin: action.payload,
      };
    case SET_DATA_OBJECT_DT:
      return {
        ...state,
        objectDataDT: action.payload,
      };
    case SET_DATA_OBJECT_DT_IMAGES:
      return {
        ...state,
        objectDataDTImages: action.payload,
      };
    case SET_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.payload,
      };
    case SHOW_MARKERS_DIGITAL_TWIN:
      return {
        ...state,
        showMarkersDigitalTwin: !state.showMarkersDigitalTwin,
      };

    // reducer all markers
    case SET_MARKER_BY_TYPE_ELEMENT_ID:
      return {
        ...state,
        showMarkerByTypeElementId: action.payload,
      };
    case SHOW_MARKERS_BY_ACTIVITIES:
      return {
        ...state,
        showMarkersByActivities: action.payload,
      };

    case SHOW_EVENTS_BY_INSPECTION_TYPE:
      return {
        ...state,
        showEventsByInspectionType: action.payload,
      };

    case SET_DATA_OPERATIONS:
      return {
        ...state,
        setDataOperations: action.payload,
      };

    case SHOW_LABELS:
      return {
        ...state,
        showLabels: action.payload,
      };

    case SHOW_OPERATIONS_BY_TYPE:
      return {
        ...state,
        showOperationsByType: action.payload,
      };

    case SET_DATA_EVENTS:
      return {
        ...state,
        setDataEvents: action.payload,
      };

    case SET_TABLE_DETAILS_FORWARD:
      return {
        ...state,
        setTableDetailsForward: action.payload,
      };

    case SET_NEURON_SELECT_TOOL:
      return {
        ...state,
        setNeuronSelectTool: action.payload,
      };
    default:
      return state;
  }
};

export default ShowDrawerDigitalTwinReducer;
