import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  text-align: center;
  width: 0 auto;

  img {
    width: 40%;
  }

  .logo-decimetrix {
    margin-bottom: 20px;
  }

  @media (max-width: 1000px) {
    img {
      width: 80%;
    }
  }
`;
