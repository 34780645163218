import { disabled } from "glamor";
import { ContainerInput } from "./style";

import { ButtonInfo } from "../../ImageGallery/ObjectDetails/ObjectDetailsElements";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import OpenDialog from "components/Dialogs/OpenDialog";
import { useState } from "react";

function TextInput({ field, register, errors }) {
  const paramsRegister = () => {
    let params = {};
    if (field.type !== "operation" && field.required)
      params.required = "this is required";
    // regular expression to strigs
    if (field.type === "string")
      params.pattern = {
        value:
          /^[a-zA-Z0-9\-\_\.\,\@\#\$\%\^\&\*\(\)\[\]\{\}\|\:\;\<\>\?\/\!\+\s\ñ]+$/,
        message: "carácteres inválidos",
      };

    if (field.type === "range") {
      params.min = {
        value: field.min,
        message: `numero mayor o igual a ${field.min}`,
      };
      params.max = {
        value: field.max,
        message: `numero menor o igual a ${field.max}`,
      };
    }
    // regular expression to email
    if (field.type === "email")
      params.pattern = {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "debe ser un email",
      };

    // regular expression to email
    if (field.type === "url") {
      params.pattern = {
        value:
          /^((http(s)?:\/\/)?(www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}((\/[a-zA-Z0-9\-_\.]+)*\/?)(\?[a-zA-Z0-9_]+\=[a-zA-Z0-9_]+)?)$/,
        message: "debe ser una url",
      };
    }

    // regular expression to email
    if (field.type === "coords") {
      params.pattern = {
        value: /^-?\d+(\.\d+)$/,
        message: "debe ser una coordenada",
      };
    }

    if (field.type === "operation") {
      params.operation = {
        value: field.operation,
        message: "debe ser una expresion válida",
      };
    }

    return params;
  };

  // If field.type === file
  let extensionsAccept = null;
  if (field.type === "file") {
    const extensions = field.possible.map((ext) => "." + ext + ",");
    extensionsAccept = extensions.reduce(
      (current, prev) => current + " " + prev
    );
  }

  if (field.type === "range") {
    if (field.step === "par") {
      field.step = "2";
      if (field.min % 2 === 1) {
        field.min = field.min - 1;
      }
    }
    if (field.step === "impar") {
      field.step = "2";
      if (field.min % 2 === 0) {
        field.min = field.min + 1;
      }
    }
  }

  return (
    <ContainerInput>
      <div className="label" htmlFor={field.name}>
        {field.alias ? field.alias : field.name}
        {field.required ? <div className="required">*</div> : null}
        {field.type === "operation" && <OperationInfo content={field} />}
      </div>

      {field.type === "operation" ? (
        <div className="divformathfield">
          <math-field
            read-only
            className="math-field"
            id={field.name}
            style={{ display: "inline-block" }}
            value={field.operation}
          />
        </div>
      ) : (
        <input
          className="input"
          id={field.name}
          type={
            field.type === "range"
              ? "range"
              : field.type === "currency" || field.type === "operation"
              ? "number"
              : field.type === "check"
              ? "checkbox"
              : field.type
          }
          accept={extensionsAccept}
          {...register(field.name, {
            ...paramsRegister(),
          })}
          disabled={field.type === "operation"}
          placeholder={field.type === "operation" ? `${field.operation}` : ""}
          step={field.type === "number" ? "any" : field.step}
          min={field.type === "range" ? field.min : ""}
          max={field.type === "range" ? field.max : ""}
          defaultValue={field.type === "range" ? field.min + field.step : null}
        />
      )}
      {errors[field.name]?.message && (
        <p className="message">{errors[field.name]?.message}</p>
      )}
    </ContainerInput>
  );
}
export default TextInput;

const OperationInfo = ({ content }) => {
  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      agree: "Close",
    },
  });

  const handleShow = () => {
    setDialog((current) => ({
      ...current,
      open: true,
    }));
  };
  const handleShowOff = () => {
    setDialog((current) => ({
      ...current,
      open: false,
    }));
  };
  return (
    <ButtonInfo>
      {
        <ContactSupportIcon
          sx={{
            fontSize: "1.8rem",
            color: "#3f51b5",
          }}
          onClick={handleShow}
        />
      }
      <OpenDialog
        openDialog={dialog?.open}
        setOpenDialog={setDialog}
        execute={handleShowOff}
        content={{
          title: "Operation detail",
          description: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <div>
                <span>
                  The result of this field will be obtained from the following
                  expression:
                </span>
              </div>
              <div>
                <math-field
                  read-only
                  className="math-field"
                  id={content?.name}
                  style={{
                    display: "inline-block",
                    minWidth: "200px",
                    width: "100%",
                    minHeight: "60px",
                    fontSize: "2.2rem",
                    border: "none",
                  }}
                  value={content?.operation}
                />
              </div>
            </div>
          ),
          ...dialog.actions,
        }}
      />
    </ButtonInfo>
  );
};
