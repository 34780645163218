import { useRef } from "react";
import { useCallback, useState, useEffect } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Consts
import fogStyle from "utils/fogStyle";
import { EVENT_CLUSTER_COLOR, COMPONENT_CLUSTER_COLOR } from "utils/const";
// Styles
import Container from "@mui/material/Container";
// Components
import { Map, Marker, Source, Layer, AttributionControl } from "react-map-gl";
import ObjectDetails from "components/ImageGallery/ObjectDetails/ObjectDetails";
import DrawControl from "./DrawControl";
import OpenDialog from "components/Dialogs/OpenDialog";
import CreateEvent from "./CreateEvent";
import OperationDetails from "components/ImageGallery/OperationDetails/OperationDetails";
import EventDetails from "components/ImageGallery/EventDetails/EventDetails";
// Services
import findObjects from "services/findObjects";
// Hooks
import FetchTypeEventsTypeComponents from "hooks/FetchTypeEventsTypeComponents";
import FetchEventsByComponentId from "hooks/fetchEventsByComponentId";
// Helpers
import { createLineString } from "helpers/createLineString";
import { ShowEventMarkers } from "helpers/ShowEventsMarker";
import ShowLinesJoin from "helpers/showLines";
import BaseMapButton from "components/Buttons/BaseMapButton";

export default function ComponentMap({ component }) {
  const [viewState, setViewState] = useState({
    latitude: component.locationComponent.latitude,
    longitude: component.locationComponent.longitude,
    width: "100%",
    height: "100%",
    zoom: 20,
  });

  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });
  const [baseMap, setBaseMap] = useState("streets-v11");

  const [haveObjects, setHaveObjects] = useState(false);

  const [feature, setFeatures] = useState({});

  const [eventLines, setEventLines] = useState([]);

  const mapRef = useRef();

  const { data: events, error: errorEvents } = FetchEventsByComponentId(
    component.id
  );

  // Know if is draw any thing in the map
  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );

  // Dispatch to redux
  const dispatch = useDispatch();

  const [objectMarker, setObjectMarker] = useState([]);

  const { data: typeEventsTypeComponents } = FetchTypeEventsTypeComponents(
    component.pointTypeComponentId
  );

  const handleClickDynamicEvent = (eventId) => {
    const event = {
      content: (
        <EventDetails
          eventId={eventId}
          fromObject={true}
          fromComponent={true}
        />
      ),
      title: "Event Details",
    };
    dispatch(setOperationDetails(event));
  };

  const handleClickMarker = (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} fromObject={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
  };

  // Get object the current component
  useEffect(() => {
    const getObject = async () => {
      const objectId = component?.objectId;
      const data = await findObjects(objectId);

      const newMarker = {
        id: data.id,
        latitude: data?.location?.latitude,
        longitude: data?.location?.longitude,
        urlIcon: data?.typeElement?.urlIconColor,
      };
      setObjectMarker((current) => {
        return current.concat(newMarker);
      });
    };
    component && getObject();
  }, [component]);

  useEffect(() => {
    if (!feature.geometry) return;
    setContentDialog({
      title: "Assign Event",
      description: (
        <CreateEvent
          feature={feature}
          pointComponentId={component.id}
          closeModal={setHaveObjects}
          typeEventsTypeComponents={typeEventsTypeComponents}
        />
      ),
    });
    setHaveObjects(true);
  }, [feature, typeEventsTypeComponents, component.id]);

  useEffect(() => {
    const createLineFeature = () => {
      const eventLines = events.map((event) => {
        const firstLocation = [
          component.locationComponent.longitude,
          component.locationComponent.latitude,
        ];
        const secondLocation = [
          event.locationOperation.longitude,
          event.locationOperation.latitude,
        ];
        const lineString = createLineString(
          [firstLocation, secondLocation],
          EVENT_CLUSTER_COLOR
        );
        return lineString;
      });
      setEventLines(eventLines);
    };

    events && createLineFeature();
  }, [events, component]);

  // To click in marker of object
  const handleClickMarkerObject = async (objectId) => {
    const object = {
      content: (
        <ObjectDetails
          objectId={objectId}
          currentRelation={component}
          fromRelation={true}
          isComponent={true}
        />
      ),
      title: "Objects Details",
    };
    if (!drawAnyThing) {
      dispatch(setOperationDetails(object));
      dispatch(setShowOperationDetails(true));
    }
  };

  const showObjectMarkers = () => {
    return objectMarker.map((marker, index) => {
      const firstLocation = [
        component.locationComponent.longitude,
        component.locationComponent.latitude,
      ];
      const secondLocation = [marker.longitude, marker.latitude];
      const lineString = createLineString(
        [firstLocation, secondLocation],
        COMPONENT_CLUSTER_COLOR
      );

      return (
        <>
          <div key={index}>
            <Source type="geojson" data={lineString} key={index}>
              <Layer
                key={index}
                id={index}
                type="line"
                paint={{
                  "line-color": lineString.properties.color,
                  "line-width": 3,
                  "line-dasharray": [2, 2],
                }}
              />
            </Source>
          </div>
          <Marker
            latitude={Number(marker.latitude)}
            longitude={Number(marker.longitude)}
            onClick={() => handleClickMarkerObject(marker.id)}
            style={{
              cursor: "pointer",
            }}
          >
            <div className="marker">
              <img src={marker.urlIcon} alt="marker" width={30} height={30} />
            </div>
          </Marker>
        </>
      );
    });
  };

  return (
    <Container
      maxWidth="l"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
        position: "relative",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        disagree={() => {}}
        content={contentDialog}
        maxWidth={contentDialog.title === "Base Map" ? null : "500px"}
      />
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        style={{
          width: "100%",
          height: "593px",
          margin: "auto",
          zIndex: "10000",
        }}
        mapStyle={`mapbox://styles/mapbox/${baseMap}`}
        attributionControl={false}
        ref={mapRef}
        initialViewState={{
          latitude: viewState.latitude,
          longitude: viewState.longitude,
          zoom: viewState.zoom,
        }}
        onMove={useCallback((e) => {
          setViewState(e.viewState);
        }, [])}
        projection={"globe"}
        fog={fogStyle}
      >
        <DrawControl handleCreateComponent={setFeatures} />
        <BaseMapButton
          setHaveObjects={setHaveObjects}
          setContentDialog={setContentDialog}
          setBaseMap={setBaseMap}
          position={75}
        />
        <Marker
          key={component.id}
          longitude={component.locationComponent.longitude}
          latitude={component.locationComponent.latitude}
        >
          <div className="marker">
            <img
              src={component.pointTypeComponent.icon}
              alt="marker"
              width={30}
              height={30}
            />
          </div>
        </Marker>
        {eventLines && <ShowLinesJoin features={eventLines} />}
        {objectMarker && showObjectMarkers()}
        {events &&
          !errorEvents &&
          ShowEventMarkers(events, handleClickDynamicEvent, handleClickMarker)}
        <AttributionControl customAttribution="© Decimetrix® 2023"/>
      </Map>
    </Container>
  );
}
