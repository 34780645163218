import axios from "axios"

const saveLineApiMg = async (regionGeoJson) => {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_MG_API}line`,
    regionGeoJson
  );
  const data = await res.data;
  return {data, res}
}

export default saveLineApiMg