import axios from "axios";

export const createTypeEventsTypeComponents = async (body) => {
  const token = localStorage.getItem("token");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-events-type-components`;
  const result = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

/**
 * Finds type events type components based on queries.
 *
 * @param {Object} queries - An object containing the queries to search for type events type components.
 * @param {string} queries.typeElementId - The ID of the type element to search for type events type components.
 * @param {string} queries.pointTypeEventId - The ID of the point type event to search for type events type components.
 * @return {Promise<Object>} Returns a promise that resolves to an object containing the type events type components found.
 */
export const findTypeEventsTypeComponents = async (queries) => {
  const { typeElementId, pointTypeEventId } = queries;
  const token = localStorage.getItem("token");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-events-type-components?`;
  if (adminCompanyId) {
    url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-events-type-components?adminCompanyId=${adminCompanyId}`;
  }
  let existQuery = false;

  if (typeElementId) {
    url = url + `&typeElementId=${typeElementId}`;
    existQuery = true;
  }

  if (pointTypeEventId) {
    if (existQuery) url = url + `&pointTypeEventId=${pointTypeEventId}`;
    else url = url + `&pointTypeEventId=${pointTypeEventId}`;
  }

  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  const data = await result.data;
  return data;
};

/**
 * Deletes a type event type component with the given ID.
 *
 * @param {number} id - The ID of the type event type component to be deleted.
 * @return {Promise<Object>} Returns a promise that resolves with the deleted data object.
 */
export const deleteTypeEventsTypeComponents = async (id) => {
  const token = localStorage.getItem("token");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-events-type-components/${id}`;
  const result = await axios.delete(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};
