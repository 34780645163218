const MASA_MOLAR_CH4 = 16; // g/mol
const LVHCH4 = 50.009; // MJ/kg

export function CalculateEfficiency(
  CH4,
  MasaMolarGAS,
  VelocidadViento,
  CaudalGas,
  DiametreoTea
) {
  // Se calcula el LVH del GAS
  const LVH_GAS = (CH4 * MASA_MOLAR_CH4 * LVHCH4) / (100 * MasaMolarGAS);

  // Se calcula la velocidad del GAS
  const VelocidadGas =
    (0.32774128 * CaudalGas) /
    (Math.PI * Math.pow((0.0254 * DiametreoTea) / 2, 2));

  // Se calcula el valor de X1
  const X1 =
    VelocidadViento /
    2.237 /
    Math.pow(VelocidadGas * 9.807 * 0.0254 * DiametreoTea, 1 / 3);

  // Se calcula la eficiencia de la TEA
  let flareEfficiency =
    100 * (1 - 0.0016 * Math.exp(0.387 * X1) * (LVHCH4 / LVH_GAS));

  // Redondear a dos dígitos
  flareEfficiency = Math.round(flareEfficiency * 100) / 100;
  return flareEfficiency;
}
