import Layout from "components/Layout/AdminDecimetrix";
import FlareEffiency from "components/FlareEfficiency";
import { Container } from "./FlareEfficiencyStyle";

const index = () => {
  return (
    <Layout>
      <Container>
        <section style={{ marginBottom: "20px" }} className="logo-decimetrix">
          <img
            src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/img_footer.png"
            alt=""
          />
        </section>
        <FlareEffiency />
      </Container>
    </Layout>
  );
};

export default index;
