function timeZoneOffset() {
  const ahora = new Date();
  const minutesDifference = ahora.getTimezoneOffset();
  const hoursDifference = (minutesDifference / 60) * -1;
  const timeZoneOffset = `${hoursDifference}:00`;

  const [hours, minutes] = timeZoneOffset.split(":");
  const formattedHours = hours.startsWith("-")
    ? `-${hours.slice(1).padStart(2, "0")}`
    : `+${hours.padStart(2, "0")}`;
  const formattedMinutes = minutes.padEnd(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
}

export default timeZoneOffset;
