import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const SummaryFeature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0px 0;
  margin-top: 50px;
  h3 {
    display: flex;
    padding: 1%;

    font-size: 1.6rem;
  }

  p {
    margin: 4px 0px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1.6rem;
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    font-size: 1.5rem;
  }
  textarea {
    height: 60px;
  }
`;
