import { useCallback, useEffect, useMemo, useState } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import useGet from "hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeometryLine,
  setGeometryPolygon,
  setFeaturesDetailsDT,
} from "redux/actions/admin";

// import { PRIMARY_COLOR } from "utils/const";
import { PopUpContainer } from "./LineStyles";
import useSWR from "swr";
import deleteLine from "services/deleteLine";
import OpenDialog from "components/Dialogs/OpenDialog";

export default function Lines({
  popUpLines,
  setPopUpLines,
  setLayerIds,
  setShowLinesPopUp,
}) {
  // Constants
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // States
  const [linesFeatures, setLinesFeatures] = useState([]);
  const [popUp, setPopUp] = useState({});
  const [line, setLine] = useState({});
  const [state, setState] = useState({});

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  // Fetch data
  //Get all Lines from api-green-dragon-mg
  const { data: dataLines, error: errorLines } = useSWR(
    `${process.env.REACT_APP_URL_MG_API}line?admin_company_id=${adminCompanyId}`
  );

  let lines = useMemo(() => {
    return dataLines && !errorLines ? dataLines : [];
  }, [dataLines, errorLines]);

  // const [libraries, errorLibraries] = useGet("api/v1/line-libraries");

  const [typesLine] = useGet(`api/v1/type-lines`);

  const dispatch = useDispatch();
  const drawerControl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );

  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );

  useEffect(() => {
    if (!lines || lines.length <= 0) return null;
    setLayerIds((current) => [...current, "line-id"]);
  }, [lines, setLayerIds]);

  useEffect(() => {
    if (geometryLine) return null;
    setLine({});
    setState(() => ({ type: "normal" }));
  }, [geometryLine]);

  const findLine = useCallback(
    (id) => {
      const line = lines?.find((line) => line.feature[0].id === id);
      return line;
    },
    [lines]
  );

  const handleDeleteLine = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);

  const deletedLine = useCallback(async () => {
    const { id } = line;
    const completeLine = findLine(id);
    await deleteLine(completeLine._id);
    setPopUp(() => {});
    setState(() => ({ type: "normal" }));
    dispatch(setGeometryLine({}));
    dispatch(setGeometryPolygon({}));
    dispatch(
      setFeaturesDetailsDT({
        geometry: {
          type: null,
        },
      })
    );
    setPopUpLines({});
    setLinesFeatures((current) =>
      current.filter((ln) => ln?.properties?.object?.id !== id)
    );
    drawerControl.set({
      type: "FeatureCollection",
      features: [],
    });
  }, [line, dispatch, drawerControl, findLine, setPopUpLines]);

  const handleUpdateLine = () => {
    const { id } = line;
    const completeLine = findLine(id);
    const { feature: features } = completeLine;
    const feature = features[0];
    setState(() => ({ type: "update" }));
    dispatch(
      setGeometryLine({
        ...feature,
        properties: {
          ...line,
          idMongo: completeLine._id,
        },
      })
    );
    drawerControl.set({
      type: "FeatureCollection",
      features: [
        {
          ...feature,
          properties: {
            ...line,
            idMongo: completeLine._id,
          },
        },
      ],
    });
  };

  useEffect(() => {
    setPopUp({ ...popUpLines?.popUp });
  }, [popUpLines?.popUp]);

  useEffect(() => {
    setLine((current) => ({
      ...current,
      ...popUpLines.line,
    }));
  }, [popUpLines?.line]);

  useEffect(() => {
    if (!line?.lineTypeId) return null;
    const lineType = typesLine?.find((type) => type.id === line?.lineTypeId);
    const { type } = {
      type: lineType?.type,
      lineLibrary: lineType?.lineLibrary,
    };
    const { id, name } = { id: lineType?.id, name: lineType?.name };
    setLine((current) => ({
      ...current,
      lineType: type,
      lineLibrary: name,
      lineLibraryId: id,
    }));
  }, [line.lineTypeId, typesLine]);

  useEffect(() => {
    const stop =
      !line.id || state.type !== "update" || linesFeatures.length <= 0;
    if (stop) return null;
    const existLineFeature = linesFeatures.findIndex(
      (lineFeature) => lineFeature.properties.object.id === line.id
    );
    if (!existLineFeature || existLineFeature < 0) return null;
    const newLinesFeatures = linesFeatures
      .slice(0, existLineFeature)
      .concat(linesFeatures.slice(existLineFeature + 1, linesFeatures.length));
    setLinesFeatures(newLinesFeatures);
    setPopUp(() => {});
    setState(() => ({ type: "normal" }));
  }, [line.id, state.type, linesFeatures]);

  useEffect(() => {
    if (!lines || lines.length <= 0) return;
    lines.map((object) => {
      const features = object?.feature;
      const featuresFilter = features.filter(
        (feature) => feature.geometry.type === "LineString"
      );

      const newsLinesFeatures = featuresFilter.map((feature) => {
        const type = typesLine?.filter(
          (type) => feature?.properties?.lineTypeId === type.id
        )[0];

        return {
          type: "Feature",
          properties: {
            cluster: false,
            object: feature,
            ...type,
          },
          geometry: {
            type: "LineString",
            coordinates: feature.geometry.coordinates,
          },
        };
      });

      const existLineFeature = linesFeatures.find(
        (lineFeature) =>
          lineFeature.properties.object.id === featuresFilter[0].id
      );
      const addFeature = existLineFeature || line.id === featuresFilter[0].id;
      if (addFeature) return null;

      setLinesFeatures((current) => [...current, ...newsLinesFeatures]);

      return null;
    });
  }, [lines, line.id, linesFeatures, state.type, typesLine]);

  return (
    <div>
      <Source
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: linesFeatures,
        }}
      >
        <Layer
          key="line-id"
          id="line-id"
          type={"line"}
          paint={{
            "line-color": ["get", "color"],
            "line-width": ["get", "dasharrayWidth"],
          }}
        />
      </Source>
      {popUp?.latitude && popUp?.longitude && (
        <Popup
          longitude={popUp?.longitude}
          latitude={popUp?.latitude}
          anchor="bottom"
          onClose={() => setPopUp({})}
          closeButton={false}
        >
          <PopUpContainer>
            <h3 className="title">Details Of {line?.name}</h3>
            <div className="row">
              <label>Comments: </label>
              <span>{line?.comments}</span>
            </div>

            <div className="row">
              <label>Line Library: </label>
              <span>{line?.lineLibrary}</span>
            </div>

            <div className="row">
              <label>Type Line: </label>
              <span>{line?.lineType}</span>
            </div>

            <div className="row">
              <label>Name: </label>
              <span>{line?.name}</span>
            </div>

            <div className="actions">
              <button className="button-actions" onClick={handleDeleteLine}>
                Delete
              </button>
              <button className="button-actions" onClick={handleUpdateLine}>
                Update
              </button>
            </div>
            <OpenDialog
              openDialog={deleteConfirmation}
              setOpenDialog={setDeleteConfirmation}
              execute={deletedLine}
              content={{
                title: "Delete line",
                description: "Do you want to delete selected line?",
                agree: "delete",
                disagree: "cancel",
              }}
            />
          </PopUpContainer>
        </Popup>
      )}
    </div>
  );
}
