import styled from "styled-components";
import { PRIMARY_COLOR, BLACK_GRAY_COLOR } from "utils/const";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;

  .title {
    font-size: 3.7rem;
    color: ${PRIMARY_COLOR};
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
  }

  .list-number {
    font-size: 2rem;
    color: ${PRIMARY_COLOR};
  }

  .subtitle {
    font-size: 2rem;
    color: ${PRIMARY_COLOR};
    font-weight: 600;
    margin: 20px 0;
  }

  .text {
    margin: 5px 0;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${BLACK_GRAY_COLOR};
  }

  .last-update {
    margin-top: 50px;
    font-weight: 500;
  }
`;
