import useGet from "hooks/useFetch";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import {
  setDateCollection,
  setOperationDetails,
  setShowOperationDetails,
} from "redux/actions";

import ObjectDialog from "./../Dialogs/OperationDialog";
import ObjectDetails from "./ObjectDetails/ObjectDetails";

import {
  ImagesContainer,
  NavGallery,
  GalleryTitle,
  ImagesWrapper,
  Img,
  ContentImg,
} from "./ImageGalleryElements";

const ImageGalleryComponent = ({ date }) => {
  const [objects, error] = useGet(`api/v1/objects/date/${date}`);

  const dispatch = useDispatch();

  const handlerClick = (event) => {
    dispatch(setDateCollection({ date: date }));
    const object = {
      content: <ObjectDetails objectId={event.target.id} />,
      title: "User Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };

  const iteratorImages = () => {
    return objects.map((object, index) => {
      return (
        object.objectPhotos.length > 0 && (
          <ContentImg key={index}>
            <Img
              id={object.id}
              src={object.objectPhotos[0].url}
              onClick={handlerClick}
            />
          </ContentImg>
        )
      );
    });
  };

  return (
    <Container
      style={{
        paddingTop: 80,
      }}
      maxWidth="xl"
    >
      <ImagesContainer>
        {error ? (
          <span>Error get data</span>
        ) : objects && objects.length > 0 ? (
          <>
            <NavGallery>
              <GalleryTitle>{objects.length} collected objects</GalleryTitle>
            </NavGallery>
            <ImagesWrapper>{iteratorImages()}</ImagesWrapper>
          </>
        ) : (
          <h2>No images</h2>
        )}
      </ImagesContainer>
      <ObjectDialog
        style={{
          margin: 0,
          padding: 0,
          width: "70%",
          height: "100%",
          maxWidth: "none",
        }}
      />
    </Container>
  );
};

export default ImageGalleryComponent;
