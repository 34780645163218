import {
  ContainerForm,
  VideoContent,
  WrapperVideos,
} from "./dragAndDropStyles";
import { useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AiFillCloseCircle } from "react-icons/ai";
import DialogContent from "@mui/material/DialogContent";
import CustomButton from "components/Buttons/CustomButton";
import uploadFileToS3 from "services/uploadFileToS3sdk";
import updateUrlFlirVideo from "services/updateUrlFlirVideo";
import uploadFieldVideo from "services/uploadFieldVideo";
import { useSWRConfig } from "swr";
// import LinearProgress from "@mui/material/LinearProgress";
// import Typography from "@mui/material/Typography";
import TextFileSVG from "images/text-files.svg";

function videosIsMatch(videosDetails, videosWithOutEvent) {
  return videosDetails.map((item) => {
    const res = videosWithOutEvent.find((item2) => item2.name === item.name);
    if (res) return { ...item, isMatch: false };
    return { ...item, isMatch: true };
  });
}

// function LinearProgressWithLabel(props, { value: number }) {
//   return (
//     <Box sx={{ display: "flex", alignItems: "center" }}>
//       <Box sx={{ width: "100%", mr: 1 }}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box sx={{ minWidth: 35 }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(
//           props.value
//         )}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }

function videosIsLoad(videosDetails, videoName) {
  return videosDetails.map((videoDetails) => {
    if (videoDetails.name === videoName) {
      return {
        ...videoDetails,
        videoIsLoad: true,
      };
    }
    return videoDetails;
  });
}

const DragAndDropContent = ({ eventWithoutVideo, userId }) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [videosDetails, setVideosDetails] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [uploadAllVideos, setUploadAllVideos] = useState(false);

  // const [uploadProgress, setUploadProgress] = useState(0);
  const [isSyncVideos, setIsSyncVideos] = useState(false);
  const { mutate } = useSWRConfig();

  const inputRef = useRef(null);

  // triggers when file is dropped
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSyncVideos(false);
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = e.dataTransfer.files;

      Object.values(files).forEach((video, i) => {
        if (video.name.includes(".seq")) {
          setVideoFiles((current) => current.concat(video));
          const reader = new FileReader();
          reader.readAsDataURL(video);
          reader.onload = () => {
            setVideosDetails((prev) => {
              const result = reader.result;
              return prev.concat([
                {
                  data: result,
                  name: video.name,
                  size: video.size,
                  type: video.type,
                  updatedDate: video.lastModifiedDate,
                  videoIsLoad: null,
                },
              ]);
            });
          };
        }
      });
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is selected with click
  const handleChange = async function (e) {
    e.preventDefault();
    setIsSyncVideos(false);
    if (e.target.files && e.target.files[0]) {
      const files = e.target.files;

      Object.values(files).forEach((video) => {
        setVideoFiles((current) => current.concat(video));
        const reader = new FileReader();
        reader.readAsDataURL(video);
        reader.onload = () => {
          setVideosDetails((prev) => {
            return prev.concat([
              {
                data: reader.result,
                name: video.name,
                size: video.size,
                type: video.type,
                updatedDate: video.lastModifiedDate,
                videoIsLoad: null,
              },
            ]);
          });
        };
      });
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const deleteUploadVideo = async (index, videos) => {
    videos.splice(index, 1);
    setVideosDetails([...videos]);
    videoFiles.splice(index, 1);
    setVideoFiles((current) => current);
    setIsSyncVideos(false);
  };

  const clearVideos = (e) => {
    setVideosDetails([]);
    setVideoFiles([]);
    setIsSyncVideos(false);
    // setUploadProgress(0);
  };

  const syncVideoWithEvent = async (videos, eventWithoutVideo, upload) => {
    // const totalVideos = videos.length;
    // let progressUpload = 0;

    const videosWithoutEvent = [...videos];

    Promise.all(
      eventWithoutVideo.map(async (flirEvent) => {
        Promise.all(
          videos.map(async (video) => {
            const { flirVideos } = flirEvent.inspectionFlir;
            const name = flirVideos[0]?.name;
            const flirVideoId = flirVideos[0]?.id;
            if (video.name === name) {
              const index = videosWithoutEvent.indexOf(video);
              videosWithoutEvent.splice(index, 1);
              if (upload) {
                const urlFile = await uploadFileToS3(video, "5");
                if (urlFile === null) alert("Couldn't upload'");
                await updateUrlFlirVideo(flirVideoId, urlFile);
                // const res = await updateUrlFlirVideo(flirVideoId, urlFile);
                // if (res) {
                //   progressUpload += 1;
                //   setUploadProgress((progressUpload / totalVideos) * 100);
                // }
                setVideosDetails((current) => videosIsLoad(current, name));
              } else {
                setIsSyncVideos(true);
              }
              mutate(
                `${process.env.REACT_APP_URL_BACKEND}api/v1/operation/user/${userId}`
              );
            }
          })
        );
      })
    );
    Promise.all(
      videosWithoutEvent.map(async (video) => {
        if (upload) {
          const urlFile = await uploadFileToS3(video, "5");
          if (urlFile === null) alert("Couldn't upload'");
          // const res = await uploadFieldVideo({
          await uploadFieldVideo({
            userId,
            name: video.name,
            url: urlFile,
            date: new Date(),
            size: parseFloat((video.size / 1000000).toFixed(3)),
            type: video.type,
            updatedDate: video.updatedDate,
          });
          setVideosDetails((current) => videosIsLoad(current, video.name));

          // if (res) {
          //   progressUpload += 1;
          //   setUploadProgress((progressUpload / totalVideos) * 100);
          // }
        } else {
          setIsSyncVideos(true);
        }
        mutate(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/field-video/userId/${userId}`
        );
      })
    );
    const videosMatch = videosIsMatch(videosDetails, videosWithoutEvent);
    setVideosDetails(videosMatch);
  };

  const syncVideos = async () => {
    await syncVideoWithEvent(videoFiles, eventWithoutVideo, false);
  };

  const uploadVideos = async () => {
    // setUploadProgress(1);
    setUploadAllVideos(true);
    await syncVideoWithEvent(videoFiles, eventWithoutVideo, true);
  };

  return (
    <div>
      {" "}
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <ContainerForm>
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                  accept="*"
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <div className="drag-info">
                    <p>Drag and drop your file here or</p>
                    <button className="upload-button" onClick={onButtonClick}>
                      <p>Upload a file</p>
                    </button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </ContainerForm>
          </Grid>
        </Grid>
        {videosDetails && (
          <>
            {videosDetails.length > 0 && (
              <Grid
                container
                style={{
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <CustomButton
                      text="Clear Videos"
                      onClick={clearVideos}
                    ></CustomButton>
                    <CustomButton
                      text="Sync Videos"
                      onClick={syncVideos}
                    ></CustomButton>
                    <CustomButton
                      text="Upload Videos"
                      onClick={uploadVideos}
                    ></CustomButton>
                  </Box>
                  {/* {uploadProgress === 0 ? null : (
                    <LinearProgressWithLabel value={uploadProgress} />
                  )} */}
                </Grid>
              </Grid>
            )}
            <WrapperVideos>
              {videosDetails.map((video, index) => (
                <VideoContent>
                  <div>
                    {uploadAllVideos && (
                      <h1 className="video-load">
                        {video.videoIsLoad ? (
                          "Uploaded ✅"
                        ) : (
                          <>
                            <CircularProgress
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#067444",
                                marginRight: "5px",
                              }}
                            />
                          </>
                        )}
                      </h1>
                    )}
                    <h1> </h1>
                    <Button
                      className="delete-button"
                      onClick={() => deleteUploadVideo(index, videosDetails)}
                    >
                      <AiFillCloseCircle
                        style={{ color: "#0E4D45", fontSize: 21 }}
                      />
                    </Button>{" "}
                  </div>
                  <div className="video-container">
                    <img width="100px" src={TextFileSVG} alt="text-file" />
                  </div>
                  <div className="video-details">
                    <p>
                      <b>Name: </b> {video.name}
                    </p>
                    <p>
                      <b>Size: </b>
                      {(video.size / 1000000).toFixed(3)} MB
                    </p>
                    <p>
                      <b>type: </b>
                      {video.type}
                    </p>

                    {isSyncVideos && (
                      <p>
                        <b>Is match:</b>{" "}
                        <span>{video.isMatch ? "Yes ✅" : "No ❌"}</span>
                      </p>
                    )}
                  </div>

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  ></Box>
                </VideoContent>
              ))}
            </WrapperVideos>
          </>
        )}
      </DialogContent>
    </div>
  );
};
export default DragAndDropContent;
