import { SET_LOCATIONS_CSV, SET_LOCATIONS_JSON, RELOAD_CSV, RELOAD_DETAILS } from "../actions/types";

const initialState = {
  locationsCSV: {},
  locationsJSON: [],
  reloadCSV: false,
  reloadDetails: false,
};

const locationCSVReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATIONS_CSV:
      return {
        ...state,
        locationsCSV: action.payload,
      };
    case SET_LOCATIONS_JSON:
      return {
        ...state,
        locationsJSON: action.payload,
      };
    case RELOAD_CSV:
      return {
        ...state,
        reloadCSV: action.payload,
      };
    case RELOAD_DETAILS:
      return {
        ...state,
        reloadDetails: action.payload,
      };
    default:
      return state;
  }
};

export default locationCSVReducer;
