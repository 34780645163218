import styled from "styled-components";
// import { PRIMARY_COLOR } from "utils/const";

export const Loader = styled.div`
  display: grid;
  place-content: center;
  width: 200px;
  height: 200px;
  background-image: fff;
  /* border: 16px solid #ffffff; */
  /* border-top: 16px solid rgba(14, 77, 69, 0.571); */
  /* border-bottom: 16px solid rgba(14, 77, 69, 0.571); */
  border-radius: 50%;
  animation: spin 2s linear infinite;

  .logo {
    width: 100px;
    height: 100px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingContainer = styled.div`
  display: grid;
  place-content: center;
  height: 70vh;
  text-align: center;

  p {
    margin: 20px 0;
  }
`;
