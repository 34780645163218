import ImageObjects from "images/undraw_product_iteration_kjok.svg";
import ImageEvents from "images/undraw_our_solution_re_8yk6.svg";
import ImageLibraries from "images/undraw_filing_system_re_56h6.svg";
import ImageComponents from "images/components.svg";

const contentCards = [
  {
    id: 1,
    title: "Objects",
    image: ImageObjects,
    description: "Add or edit your objects",
    toNavigate: "/object-configurations",
  },
  {
    id: 2,
    title: "Components",
    image: ImageComponents,
    description: "Add or edit your components",
    toNavigate: "/component-configurations",
  },
  {
    id: 3,
    title: "Events",
    image: ImageEvents,
    description: "Add or edit your events",
    toNavigate: "/config-actions-events",
  },
  {
    id: 4,
    title: "Libraries",
    image: ImageLibraries,
    description: "Add or edit your libraries",
    toNavigate: "/library-configurations",
  },
];

export default contentCards;
