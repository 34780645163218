const deleteDuplicateObjects = (components) => {
  return components
    .filter(
      (object, index, self) =>
        index ===
        self.findIndex((t) => t.typeElementId === object.typeElementId)
    )
    .map((item) => item.typeElement);
};

export default deleteDuplicateObjects;
