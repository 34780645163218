// check layer
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// custom style
import {
  WrapperTypeObjectTitle,
  WrapperTypeElementTitle,
  useStyles,
  CustomizedAccordion,
} from "./AccordionStyles";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  showMarkerseByTypeObjectLoad,
  showMarkerseByTypeElementLoad,
} from "redux/actions/admin";
import { useEffect, useMemo } from "react";

const deleteDuplicateObjects = (objects) => {
  return objects.filter(
    (object, index, self) =>
      index === self.findIndex((t) => t.name === object.name)
  );
};

const getTypeElementIds = (dataObjects) => {
  const typeElementIds = dataObjects.map((object) => object.typeElementId);
  return [...new Set(typeElementIds)];
};

const AccordionLoadCsv = ({ dataObjects }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const libraryIds = dataObjects.map((object) => object.libraryId);

  // filter duplicate libraryIds
  const uniqueLibraryIds = useMemo(
    () => [...new Set(libraryIds)],
    [libraryIds]
  );

  useEffect(() => {
    const showTypeObject = {};
    uniqueLibraryIds.map((libraryId) => (showTypeObject[libraryId] = true));
    dispatch(showMarkerseByTypeObjectLoad({ ...showTypeObject }));

    const showTypeElement = {};
    const typeElementsIds = getTypeElementIds(dataObjects);
    typeElementsIds.map(
      (typeElementId) => (showTypeElement[typeElementId] = true)
    );
    dispatch(showMarkerseByTypeElementLoad({ ...showTypeElement }));
  }, [dataObjects, dispatch]);

  const showTypeObject = useSelector(
    (state) => state.adminReducer.markerByTypeObjectLoad
  );

  const handlerClickFilterTypeObject = (libraryId) => {
    showTypeObject[libraryId] = !showTypeObject[libraryId];
    dispatch(showMarkerseByTypeObjectLoad({ ...showTypeObject }));
  };

  const showTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElementLoad
  );

  const handlerClickFilterTypeElement = (typeElementName) => {
    showTypeElement[typeElementName] = !showTypeElement[typeElementName];
    dispatch(showMarkerseByTypeElementLoad({ ...showTypeElement }));
  };

  const getObjectAndIcon = (objects) => {
    const typesElementNames = objects.map((object) => {
      return {
        typeElementId: object.typeElementId,
        name: object.name,
        icon: object.icon,
      };
    });
    const uniqueTypesElementNames = deleteDuplicateObjects(typesElementNames);
    return uniqueTypesElementNames;
  };

  const accordionDetails = uniqueLibraryIds.map((libraryId, index) => {
    let typeElements = null;
    // filter typeElement by libraryId
    const objects = dataObjects.filter(
      (object) => object.libraryId === libraryId
    );

    typeElements = getObjectAndIcon(objects);

    return (
      <div key={index}>
        <Accordion
          sx={{
            paddingLeft: "5px",
            border: "none",
            boxShadow: "none",
          }}
        >
          <WrapperTypeObjectTitle>
            <div className="icon-title">
              {showTypeObject[libraryId] ? (
                <VisibilityIcon
                  sx={{
                    color: "#575757",
                    margin: "0px 10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlerClickFilterTypeObject(libraryId)}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{
                    color: "#272727",
                    margin: "0px 10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlerClickFilterTypeObject(libraryId)}
                />
              )}

              <p>Objects {libraryId}</p>
            </div>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            ></AccordionSummary>
          </WrapperTypeObjectTitle>
          <AccordionDetails>
            {typeElements.map((element, index) => {
              return (
                <FormGroup key={index}>
                  <WrapperTypeElementTitle>
                    <div className="icon-title">
                      {showTypeElement[element.typeElementId] ? (
                        <VisibilityIcon
                          sx={{
                            color: "#575757",
                            margin: "0px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handlerClickFilterTypeElement(element.typeElementId)
                          }
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{
                            color: "#575757",
                            margin: "0px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handlerClickFilterTypeElement(element.typeElementId)
                          }
                        />
                      )}
                      <p>{element.name.toLowerCase()}</p>
                    </div>
                    <img
                      src={element.icon}
                      width="30px"
                      height="30px"
                      alt="ico"
                    />
                  </WrapperTypeElementTitle>
                </FormGroup>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });

  return (
    <>
      <CustomizedAccordion
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
      >
        {accordionDetails}
      </CustomizedAccordion>
    </>
  );
};

export default AccordionLoadCsv;
