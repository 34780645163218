// React
import { useState } from "react";
// Components
import CustomButton from "components/Buttons/CustomButton";
// Style
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { HeadModal } from "./DialogsStyles";
import { AiFillCloseCircle } from "react-icons/ai";

const OpenDialog = (props) => {
  const { openDialog, setOpenDialog, execute, content, maxWidth, minWidth } =
    props;
  const open = openDialog;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoadDisagree, setIsLoadDisagree] = useState(false);
  const [isLoadAgree, setIsLoadAgree] = useState(false);

  const handleClose = () => {
    setIsLoadDisagree(true);
    setOpenDialog(false);
    setIsLoadDisagree(true);
  };

  const handleExecute = async () => {
    setIsLoadAgree(true);
    await execute();
    setIsLoadAgree(false);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: maxWidth || "1900px",
              minWidth: minWidth || "1000px",
            },
          },
        }}
      >
        <HeadModal>
          <div className="title">
            <img
              src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
              alt=""
              className="neurona"
            />
            <span className="titleHeadModal">{content.title}</span>
          </div>
          <Button onClick={handleClose}>
            <AiFillCloseCircle
              style={{ color: "#FFF", fontSize: 21, marginBottom: -4 }}
            />
          </Button>
        </HeadModal>
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            marginTop: "50px",
          }}
        ></DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              justifyItems: "center",
              fontSize: "1.5rem",
            }}
          >
            {content.description}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {content.disagree && (
            <CustomButton
              isLoad={isLoadDisagree}
              text={content.disagree}
              onClick={handleClose}
            />
          )}
          {content.agree && (
            <CustomButton
              isLoad={isLoadAgree}
              text={content.agree}
              onClick={handleExecute}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OpenDialog;
