import { useState } from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import InfoSection from "../components/InfoSection";
import {
  homeObjectOne,
  homeObjectThree,
  homeObjectTwo,
} from "../components/InfoSection/Data";
import Services from "../components/Services";
import Footer from "../components/Footer";

const Home = () => {
  const configNavbar = {
    sidebar: true,
    typeLinks: "scroll",
    linkLogo: "about",
    menuElements: {
      greenDragon: "Green Dragon",
      discover: "Discover",
      value: "Value",
      productAndServices: "Product & Services",
      connect: "Connect",
    },
    button: {
      link: "signIn",
      text: "Log In",
    },
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} configNavbar={configNavbar} />
      {/* <div style={{ marginBottom: "40px" }} /> */}
      <InfoSection {...homeObjectOne} />
      <InfoSection {...homeObjectTwo} />
      <InfoSection {...homeObjectThree} />
      <Services/>
      <Footer />
    </>
  );
};

export default Home;
