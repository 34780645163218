import { TbDeviceAnalytics } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { GoCircuitBoard } from "react-icons/go";
import { BiVideoRecording, BiCollection } from "react-icons/bi";
import PeopleIcon from "@mui/icons-material/People";

const userId = localStorage.getItem("userId");

export const iconStyle = {
  color: "#f9f9f9",
  fontSize: "4rem",
};

export const dashboard = (url, name) => ({
  icon: <TbDeviceAnalytics style={iconStyle} data-tip data-for={name} />,
  url,
});

export const addUser = (url, name) => ({
  icon: (
    <PeopleIcon style={iconStyle} sx={iconStyle} data-tip data-for={name} />
  ),
  url,
});

export const digitalTwin = (url, name) => ({
  icon: <GoCircuitBoard style={iconStyle} data-tip data-for={name} />,
  url,
});

export const settings = (url, name) => ({
  icon: <IoSettingsOutline style={iconStyle} data-tip data-for={name} />,
  url,
});

export const video = (url, name) => ({
  icon: <BiVideoRecording style={iconStyle} data-tip data-for={name} />,
  url,
});

export const Collection = (url, name) => ({
  icon: <BiCollection style={iconStyle} data-tip data-for={name} />,
  url,
});

export const SideBarItems = {
  "Administrador de decimetrix": {
    Dashboard: dashboard("/dashboard-admin-decimetrix", "Dashboard"),
    "Company managers": addUser("/admin-companies", "Company managers"),
    Settings: settings("/config-admin", "Settings"),
  },
  "Administrador de compañía": {
    Dashboard: dashboard("/dashboard-admin", "Dashboard"),
    "Digital twin": digitalTwin("/digital-twin-admin", "Digital twin"),
    Operators: addUser("/operators", "Operators"),
  },
  Operario: {
    Dashboard: dashboard("/dashboardUser", "Dashboard"),
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Video manager": video(`/upload-videos/${userId}`, "Video manager"),
  },
};
