// React
import { useEffect, useState } from "react";

// React - Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setDataObjectDTImages,
  setOperationDetails,
  setShowOperationDetails,
} from "redux/actions";
import { setFeaturesDetailsDT } from "redux/actions/admin";

// Components
import GenericTable from "components/Tables/GenericTable";
import CustomButton from "components/Buttons/CustomButton";
import GenericDialog from "components/Dialogs/GenericDialog";
import EditObject from "components/Forms/EditObject";
import ContentDialogConvertObject from "components/ImageGallery/contentDialogs/ContentDialogConvertObject";
import UploadMediaObject from "components/ImageGallery/ObjectDetails/UploadMediaObject";
import EventDetails from "../EventDetails/EventDetails";
import ComponentDetails from "../ComponentDetails/Index";
import TableObject from "./TableObject";
import ListEventsTable from "../ListEventsTable";
import OpenDialog from "components/Dialogs/OpenDialog";
import PhotoGallery from "./../PhotoGalley/PhotoGallery";
import MapView from "components/MapView";

// Config
import { config } from "config.js";

// Hooks
import FetchComponentsByObjectId from "hooks/fetchComponentsByObjectId";
import useCreateEventsObjets from "hooks/useCreateEventsObject";
import useCountEventsToOneObject from "hooks/useCountEventsToOneObject";
import useCountComponentsToOneObject from "hooks/useCountComponentsToOneObject";

// Services
import findPointLibraries from "services/findPointLibrary";
import {
  sendPolygonGeometry,
  iteratorRegionLocationId,
} from "services/sendPolygonGeometry";
import updateObjectPg from "services/updateObjectPg";

// Dependencies
import { circle } from "@turf/turf";
import { useSWRConfig } from "swr";
import axios from "axios";
import jwt_decode from "jwt-decode";
import useSwr from "swr";

// Style
import { IconButton } from "@mui/material";
import { CustomizedChevronLeftIcon } from "../EventDetails/EventDetailsElements";
import { grey } from "@mui/material/colors";
import { ContainerOperations } from "../OperationDetails/OperationDetailsElements";
import Grid from "@material-ui/core/Grid";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ActionsObject,
  ContentButtonGallery,
  LibToolTip,
} from "./ObjectDetailsElements";
import {
  WraperTitleCardMarker,
  InfoTitle,
  Wrapperbtn,
  Label,
} from "../ImageDetailsElements";

// Conts

//Event History Table
const columns = [
  "Id",
  "Mark Event",
  "Name",
  "Date",
  "Longitude",
  "Latitude",
  "Created At",
];

const idColumns = [
  "id",
  "markPoint",
  "name",
  "date",
  "longitude",
  "latitude",
  "createdAt",
];

const ObjectDetails = ({
  objectId,
  fromRelation,
  currentRelation,
  isComponent,
  isEvent,
}) => {
  const dispatch = useDispatch();

  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );

  // Get state from redux
  const [status, setStatus] = useState("normal");
  const [equipmentPhoto, setEquipmentPhoto] = useState(null);
  const [namePlatePhoto, setNamePlatePhoto] = useState(null);
  const [equipmentPhotos, setEquipmentPhotos] = useState();
  const [namePlatePhotos, setNamePlatePhotos] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [seeEventsTable, setSeeEventsTable] = useState(false);
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");

  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this element?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const [photosFile, setPhotosFile] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(true);
  const token = localStorage.getItem("token");
  const [openDialogChange, setOpenDialogChange] = useState(false);
  const [typeElementId, setTypeElementId] = useState("1");
  const [equipmentName, setEquipmentName] = useState("");
  const [pointLibrary, setPointLibrary] = useState(null);
  const [initialLocation, setInitialLocation] = useState({});
  const [polygon, setPolygon] = useState({});
  const [objectRadiusIds, setObjectRadiusIds] = useState([]);
  const [currentObjectId, setCurrentObjectId] = useState(objectId);

  const { amountEvents } = useCountEventsToOneObject({
    objectId,
    setShowDeleteButton,
  });

  const { amountComponents } = useCountComponentsToOneObject({
    objectId,
    setShowDeleteButton,
  });

  const {
    eventRows,
    dataDynamicEvents,
    seePointEventsTable,
    markedEvent,
    setMarkedEvent,
  } = useCreateEventsObjets({ objectId });

  const handleConvertObject = () => {
    setOpenDialogChange(true);
  };
  const { mutate } = useSWRConfig();

  const setTypeElement = (e) => {
    setTypeElementId(e.target.value);
  };

  const setTypeEquipmentName = (e) => {
    setEquipmentName(e.target.value);
  };

  // Components Data
  const { data: dataComponents } = FetchComponentsByObjectId(objectId);

  const urlData = `${config.URL_BACKEND_PG}api/v1/objects/${currentObjectId}`;
  const { data: object, error: errorObject } = useSwr(urlData);

  const decodeToken = jwt_decode(token);

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setDataObjectDTImages([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setOpenModal(true);
  };

  const uploadImages = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/objects/${currentObjectId}`);
  };

  useEffect(() => {
    if (dataDynamicEvents?.length > 0) setShowDeleteButton(false);
    if (dataComponents?.length > 0) setShowDeleteButton(false);
    const equipementPhotosArray = [];
    const nameplatePhotosArray = [];
    object &&
      !errorObject &&
      object.objectFiles.forEach((objectImage) => {
        if (objectImage.typeObjectFileId === 1) {
          setEquipmentPhoto(objectImage);
          equipementPhotosArray.push(objectImage);
        }

        if (objectImage.typeObjectFileId === 2) {
          setNamePlatePhoto(objectImage);
          nameplatePhotosArray.push(objectImage);
        }
      });
    setEquipmentPhotos(equipementPhotosArray);
    setNamePlatePhotos(nameplatePhotosArray);
    const fetchDataPointLibrary = async () => {
      if (object?.regionsObjects?.length > 0) setShowDeleteButton(false);
      const { data, res } = await findPointLibraries(
        object.typeElement.pointLibraryId
      );
      if (res.status === 200) setPointLibrary(data[0]);
    };
    object && fetchDataPointLibrary();
  }, [object, errorObject, dataDynamicEvents, dataComponents]);

  const handleDelete = () => {
    setOpenDialog(true);
  };

  const deleteElement = async () => {
    setContentDialog({ ...contentDialog, agree: <CircularProgress /> });
    const result = await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/objects/${object.id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    if (result) {
      mutate(
        `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`
      );
    }
    setContentDialog({ ...contentDialog, agree: "Accept" });
    setOpenDialog(false);
    dispatch(setShowOperationDetails(false));
  };

  const handleEdit = () => {
    setStatus(() => "edit");
  };

  const saveObject = async (body) => {
    try {
      await updateObjectPg(body, objectId);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`
      );
      setContentDialog({ ...contentDialog, agree: "yes" });
    } catch (error) {
      console.error(error);
    }
  };

  const convertElement = async () => {
    let body = {
      typeElementId: Number(typeElementId),
      fieldEquipmentName: equipmentName,
      lastUpdate: new Date(),
      userUpdateId: userId,
    };

    await saveObject(body);
    dispatch(
      setFeaturesDetailsDT({
        geometry: {
          type: null,
        },
      })
    );
    setOpenDialogChange(false);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/object-ghg/count/object/${currentObjectId}`
    );
  };

  const handleCurrentObjectId = (index) => {
    const nextId = objectRadiusIds[index];
    setCurrentObjectId(() => nextId);
  };

  useEffect(() => {
    if (object && !initialLocation.latitude) {
      setInitialLocation(object.location);
    }
  }, [object, initialLocation]);

  useEffect(() => {
    if (initialLocation.latitude && initialLocation.longitude) {
      const center = [initialLocation.longitude, initialLocation.latitude];
      const radius = 0.02;
      const options = {
        steps: 10,
        units: "kilometers",
        properties: { foo: "bar" },
      };
      const polygon = circle(center, radius, options);
      setPolygon(() => ({ ...polygon.geometry }));
    }
  }, [initialLocation]);

  useEffect(() => {
    const getData = async () => {
      const { response: ids } = await sendPolygonGeometry(polygon);
      const objects = await iteratorRegionLocationId(ids);
      const objectsIds = objects.map((object) => object.id);
      setObjectRadiusIds([...objectsIds]);
    };
    if (!polygon.type) return;
    getData();
  }, [polygon]);

  const returnToEvent = () => {
    if (isEvent) {
      const event = {
        content: <EventDetails eventId={currentRelation.id} />,
        title: "Event Details",
      };
      dispatch(setOperationDetails(event));
    }
    if (isComponent) {
      const component = {
        content: <ComponentDetails componentId={currentRelation.id} />,
        title: "Component Details",
      };
      dispatch(setOperationDetails(component));
    }
    dispatch(setShowOperationDetails(true));
  };

  return (
    <>
      {fromRelation && (
        <IconButton
          onClick={returnToEvent}
          style={{
            fontSize: 30,
            cursor: "pointer",
            position: "sticky",
            left: "0px",
            top: "0px",
          }}
        >
          <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
        </IconButton>
      )}
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={deleteElement}
        content={contentDialog}
      />
      <GenericDialog
        openDialog={openDialogChange}
        setOpenDialog={setOpenDialogChange}
        execute={convertElement}
        content={{
          title: "Convertir Objetos",
          description: "Convierte un tipo de elemento a otro tipo de elemento",
          agree: "Guardar",
          content: (
            <ContentDialogConvertObject
              handleConvertObject={handleConvertObject}
              typeElementId={typeElementId}
              setTypeElement={setTypeElement}
              setTypeElementId={setTypeElementId}
              setTypeEquipmentName={setTypeEquipmentName}
            />
          ),
        }}
        style={{
          margin: 0,
          padding: 0,
          width: "50%",
          // height: "450px",
          maxWidth: "none",
        }}
      />
      {object && (
        <ContainerOperations seeArrow={objectRadiusIds?.length > 1}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <div className="current-object">
              {objectRadiusIds?.map((id, index) => {
                const before = (
                  <span
                    className={index === 0 && "disabled"}
                    onClick={() => handleCurrentObjectId(index - 1)}
                  >
                    <AiOutlineLeft />
                  </span>
                );
                const after = (
                  <span
                    className={
                      index === objectRadiusIds?.length - 1 && "disabled"
                    }
                  >
                    <AiOutlineRight
                      onClick={() => handleCurrentObjectId(index + 1)}
                    />
                  </span>
                );
                const render = (
                  <p>{index + 1 + "/" + objectRadiusIds.length}</p>
                );
                if (id !== currentObjectId) return null;
                if (objectRadiusIds?.length <= 1) return render;
                return (
                  <>
                    {before} {render} {after}
                  </>
                );
              })}
            </div>
          </Grid>
          {/* --OBJECT DETAILS */}
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <div className="info-operation">
              <WraperTitleCardMarker>
                <h1>
                  <span>{pointLibrary?.name} </span> OBJECT TYPE
                </h1>
                <div className="wraper-title-element">
                  <h2>{object.typeElement.name} </h2>
                  <img
                    width="30"
                    src={object.typeElement.urlIconColor}
                    alt="object-agile-icon"
                  ></img>
                </div>
              </WraperTitleCardMarker>
              <InfoTitle>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <section className="main-tables-container">
                    {status === "normal" && <TableObject object={object} />}
                    {status === "edit" && (
                      <EditObject object={object} setStatus={setStatus} />
                    )}
                    {status === "normal" && (
                      <ActionsObject>
                        <button
                          className="button-edit-object"
                          onClick={handleEdit}
                        >
                          Edit Object
                        </button>
                      </ActionsObject>
                    )}
                    {/* quantity of events */}
                    {amountEvents && (
                      <div className="main-currency-table">
                        <p className="currency-table--title">Events</p>
                        <div className="currency-table--container">
                          <table>
                            <tr>
                              <td className="table__top-left">Sketch</td>
                              <td className="table__top-right table__right">
                                {amountEvents.inspectionSniffer}
                              </td>
                            </tr>
                            <tr>
                              <td>FLIR</td>
                              <td className="table__right">
                                {amountEvents.inspectionFlir}
                              </td>
                            </tr>
                            <tr>
                              <td>Leaks</td>
                              <td className="table__right">
                                {amountEvents.leaks}
                              </td>
                            </tr>
                            <tr>
                              <td className="table__bottom-left">Repairs</td>
                              <td className="table__bottom-right table__right">
                                {amountEvents.reperations}
                              </td>
                            </tr>
                            {amountEvents.dynamics &&
                              amountEvents.dynamics.map((it) => {
                                return (
                                  <tr>
                                    <td className="table__bottom-left">
                                      <LibToolTip>
                                        {it.typeName}
                                        <span className="tooltiptrigger">
                                          <LocalLibraryIcon
                                            sx={{
                                              fontSize: "1.8rem",
                                              color: "#3f51b5",
                                            }}
                                          />
                                        </span>
                                        <span className="tooltip">
                                          Library {it.libName}
                                        </span>
                                      </LibToolTip>
                                    </td>
                                    <td className="table__bottom-right table__right">
                                      {it.quantity}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </div>
                    )}
                    {/* quantity of components  */}
                    {amountComponents && (
                      <div className="main-currency-table">
                        <p className="currency-table--title">Components</p>
                        <div className="currency-table--container">
                          <table>
                            {amountComponents.map((it) => {
                              return (
                                <tr>
                                  <td className="table__bottom-left">
                                    <LibToolTip>
                                      {it.typeName}
                                      <span className="tooltiptrigger">
                                        <LocalLibraryIcon
                                          sx={{
                                            fontSize: "1.8rem",
                                            color: "#3f51b5",
                                          }}
                                        />
                                      </span>
                                      <span className="tooltip">
                                        Library {it.libName}
                                      </span>
                                    </LibToolTip>
                                  </td>
                                  <td className="table__bottom-right table__right">
                                    {it.quantity}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    )}
                  </section>
                </Grid>
              </InfoTitle>
              {(decodeToken?.role === "Administrador de compañía" ||
                decodeToken?.sub === object?.userId) && (
                <Wrapperbtn>
                  <CustomButton
                    onClick={handleConvertObject}
                    text={"Convert to object"}
                  ></CustomButton>
                  {showDeleteButton && (
                    <CustomButton
                      onClick={handleDelete}
                      text={"Delete"}
                      primaryColor={"#d83939"}
                      secondaryColor={"#eb4123"}
                    ></CustomButton>
                  )}
                </Wrapperbtn>
              )}
            </div>
          </Grid>

          {/* --GHG GALLERY PHOTOS-- */}
          <Grid item xs={12} md={12} lg={8} xl={8}>
            <div className="photo-container-image">
              <h1 className="operation-title">
                <span>GALLERY</span>
              </h1>
              <div
                className="gallery"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "0px 24px 0px 20px",
                }}
              >
                {(object?.objectFiles?.length > 0 ||
                  object?.media?.length > 0) && (
                  <>
                    <br />
                    <PhotoGallery
                      photos={[
                        ...object.objectFiles.filter(
                          (photo) => photo.typeObjectFileId !== 5
                        ),
                        ...object.media,
                      ]}
                      ownId={object.id}
                      type={"object"}
                      mongoId={object.mongoId}
                      uploadImages={uploadImages}
                    />
                  </>
                )}
                <ContentButtonGallery>
                  {/* <Wrapperbtn> */}
                  <Label htmlFor="files">
                    <AddAPhotoIcon />
                    <p>Add Picture</p>
                  </Label>
                  <input
                    id="files"
                    style={{ visibility: "hidden" }}
                    accept="image/*"
                    type="file"
                    name="file"
                    onChange={uploadFile}
                  />
                  {/* </Wrapperbtn> */}
                </ContentButtonGallery>
                <UploadMediaObject
                  object={object}
                  photosFile={photosFile}
                  setPhotosFile={setPhotosFile}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              </div>
            </div>
          </Grid>

          {/* --EQUIPMENT PHOTO-- */}
          {equipmentPhoto && (
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="photo-container">
                <h1 className="operation-title">
                  <span>EQUIPMENT</span>
                </h1>
                {
                  <div
                    style={{
                      width: "85%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px 0px 20px 0px",
                    }}
                  >
                    <PhotoGallery
                      photos={equipmentPhotos}
                      ownId={object.id}
                      state={false}
                      type={"object"}
                      mongoId={object.mongoId}
                      uploadImages={uploadImages}
                    />{" "}
                  </div>
                }
              </div>
            </Grid>
          )}

          {/* --NAMEPLATE PHOTO-- */}
          {namePlatePhoto && (
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="photo-container">
                <h1 className="operation-title">
                  <span>NAMEPLATE</span>
                </h1>

                {
                  <div
                    style={{
                      width: "85%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px 0px 20px 0px",
                    }}
                  >
                    <PhotoGallery
                      ownId={object.id}
                      photos={namePlatePhotos}
                      state={false}
                      type={"object"}
                      mongoId={object.mongoId}
                      uploadImages={uploadImages}
                    />{" "}
                  </div>
                }
              </div>
            </Grid>
          )}

          {/* --MAP- */}
          {namePlatePhoto && equipmentPhoto ? (
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="content-map">
                <h1 className="operation-title">
                  <span>OBJECT LOCATION</span>
                </h1>
                <MapView
                  typeObjectId={object.typeElement.pointLibraryId}
                  objectId={currentObjectId}
                  location={object.location}
                  urlIcon={object.typeElement.urlIconColor}
                  markedEvent={markedEvent}
                  typeElementId={object.typeElementId}
                />
              </div>
            </Grid>
          ) : namePlatePhoto || equipmentPhoto ? (
            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div className="content-map">
                <h1 className="operation-title">
                  <span>OBJECT LOCATION</span>
                </h1>
                <MapView
                  typeObjectId={object.typeElement.pointLibraryId}
                  objectId={currentObjectId}
                  location={object.location}
                  urlIcon={object.typeElement.urlIconColor}
                  markedEvent={markedEvent}
                  typeElementId={object.typeElementId}
                />
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="content-map">
                <h1 className="operation-title">
                  <span>OBJECT LOCATION</span>
                </h1>
                <MapView
                  typeObjectId={object.typeElement.pointLibraryId}
                  objectId={currentObjectId}
                  location={object.location}
                  urlIcon={object.typeElement.urlIconColor}
                  markedEvent={markedEvent}
                  typeElementId={object.typeElementId}
                />
              </div>
            </Grid>
          )}

          {/* --EVENT HISTORY-- */}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: seeEventsTable ? "" : "none",
            }}
          >
            <div className="table-container">
              <>
                <div className="operation-title">
                  <h1
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      fontSize: "2rem",
                      textAlign: "center",
                    }}
                  >
                    <span>EVENT HISTORY</span>
                  </h1>
                </div>
                <div className="operation-table">
                  <div
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid #bebebe",
                      width: "100%",
                      padding: "1rem",
                      borderRadius: "5px",
                      marginBottom: "1rem",
                    }}
                  >
                    <ListEventsTable
                      objectId={currentObjectId}
                      setSeeEventsTable={setSeeEventsTable}
                      setMarkedEvent={setMarkedEvent}
                    ></ListEventsTable>
                  </div>
                </div>
              </>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: seePointEventsTable ? "" : "none",
            }}
          >
            <div className="table-container">
              <>
                <div className="operation-title">
                  <h1
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      fontSize: "2rem",
                      textAlign: "center",
                    }}
                  >
                    <span>EVENT HISTORY</span>
                  </h1>
                </div>
                <div className="operation-table">
                  <div
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid #bebebe",
                      width: "100%",
                      padding: "1rem",
                      borderRadius: "5px",
                    }}
                  >
                    <GenericTable
                      headers={columns}
                      rows={eventRows}
                      keyValues={idColumns}
                      handlerClick={() => {}}
                      state={true}
                      customTableWidth={"100%"}
                      customHeaderWidth={"5%"}
                    />
                  </div>
                </div>
              </>
            </div>
          </Grid>
        </ContainerOperations>
      )}
    </>
  );
};

export default ObjectDetails;
