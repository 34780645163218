import styled from "styled-components";
import { PRIMARY_COLOR } from "../../utils/const";

export const ButtonOpenOperation = styled.button`
  text-decoration: underline;
  vertical-align: inherit;
  margin: auto;
  outline: none;
  border: none;
  color: #005ec9;
  border-radius: 5px;
  cursor: pointer;
`;

export const GeneralButton = styled.button`
  outline: none;
  border: none;
  background: ${PRIMARY_COLOR};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
`;
