const getUniqueTypeInspectionFlirIds = (operations) => {
  const inspectionsFlir = operations.filter(
    (operation) => operation?.inspectionFlir
  );
  const typeOperationIds = inspectionsFlir.map(
    (operation) => operation.inspectionFlir.typeId
  );
  const uniqueTypeOperationIds = [...new Set(typeOperationIds)];
  return uniqueTypeOperationIds;
};

export default getUniqueTypeInspectionFlirIds;
