import Container from "@mui/material/Container";
import { ItemList } from "./UserRoutesElements";
import FetchTypeLines from "hooks/fetchTypeLines";

import { useStyles, CustomizedAccordion } from "./MapDrawerStyle";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";

const ListLines = () => {
  const { data: typeElements, error: errorTypeElements } = FetchTypeLines();
  const classes = useStyles();
  const [elements, setElements] = useState();
  const [names, setNames] = useState();

  useEffect(() => {
    typeElements &&
      !typeElements.error &&
      setElements(typeElements.map((typeElement) => typeElement.lineLibraryId));
    typeElements &&
      !typeElements.error &&
      setNames(typeElements.map((typeElement) => typeElement.lineLibrary.name));
  }, [typeElements]);

  const pointLibrariesIdsUnique = [...new Set(elements)];
  const namesUnique = [...new Set(names)];

  return (
    <Container>
      {!errorTypeElements && typeElements && elements && names !== undefined && (
        <div>
          <>
            {pointLibrariesIdsUnique.map((libraryId, index) => (
              <CustomizedAccordion
                classes={{
                  content: classes.content,
                  expanded: classes.expanded,
                }}
                defaultExpanded={false}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    Librería {namesUnique[index]}
                  </Typography>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  ></AccordionSummary>
                </div>

                {typeElements
                  .filter(
                    (typeElement) => typeElement.lineLibraryId === libraryId
                  )
                  .map((typeElementId) => (
                    <ItemList width={50} color={typeElementId.color}>
                      <span className="line"></span>
                      <p>{typeElementId.type}</p>
                    </ItemList>
                  ))}
              </CustomizedAccordion>
            ))}
          </>
        </div>
      )}
    </Container>
  );
};

export default ListLines;
