import { useEffect, useState, useRef } from "react";

import "mathlive/fonts.css";
import "mathlive/static.css";

import {
  OperationsContainer,
  OperationsExpression,
  MathfieldContainer,
  MathfieldDisclaimer,
} from "./OperationsElementsStyles";

const keyboardCustomFields = (fields) => {
  if (fields === undefined || fields.length === 0) return [];
  const campos = fields
    .filter((f) => f.type === "number" || f.type === "operation")
    .map((it) => {
      return {
        class: "ava",
        latex: it.name,
        width: 2,
        insert: it.name,
        datatype: it.type,
      };
    });
  return {
    label: "Available Fields",
    style: ".ava{ --keycap-font-size: 3rem; font-size: 2.5rem; }",
    rows: [campos],
  };
};

function OperationsColumns({
  fields,
  setFields,
  setExpression,
  update,
  field,
  setField,
}) {
  const [operation, setOperation] = useState("");
  
  const mathfieldRef = useRef(null);
 

  useEffect(() => {
    let mfe = mathfieldRef.current 
    mfe.value = operation;
    mfe.smartMode = true;
    mfe.defaultMode = "math";
    mfe.smartSuperscript = true;
    mfe.popoverPolicy = "auto";
    mfe.removeExtraneousParentheses = true;
    mfe.mathVirtualKeyboardPolicy = "auto";

    mfe.addEventListener("focus", (evt) => {
      evt.preventDefault();
      window.mathVirtualKeyboard.visible = true;
      window.mathVirtualKeyboard.layouts = [
        "numeric",
        keyboardCustomFields(fields),
      ];
    });

    mfe.addEventListener("input", (evt) => {
      evt.preventDefault();
      setOperation(mfe.getValue());
      setExpression(mfe.getValue());
  });
}, []);

useEffect(() => {
  if (update) {
    const mfe = mathfieldRef.current;
    const exprToUpdate = fields.find((f) => f.name === field.name);
    mfe.value = exprToUpdate?.operation;
      setField((current) => ({
        ...current,
        operation: mfe.getValue(),
      }));
    }
  }, []);

  return (
    <OperationsContainer id="operationcontainer">
      <OperationsExpression>
        <label style={{ fontSize: "2rem", marginBottom: "15px" }}>
          Expression Builder:
        </label>
        <link rel="stylesheet" href="mathlive-fonts.css" />
        <MathfieldContainer>
          <math-field
            ref={mathfieldRef}
            id="formula"
            className="math-field ML__isExtendedWidth"
          />
        </MathfieldContainer>
        <MathfieldDisclaimer>
          <span style={{ fontSize: "2rem" }}>🚀</span>
          <p>
            {" "}
            <strong> Note:</strong>{" "}
            <i>For this version only mathematical expressions are allowed.</i>
          </p>
          <p>
            {" "}
            <i>We are curretly working on text expressions.</i>{" "}
          </p>
        </MathfieldDisclaimer>
      </OperationsExpression>
    </OperationsContainer>
  );
}

export default OperationsColumns;
