import axios from "axios";

const deleteView= async (viewid) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/views/${parseInt(viewid)}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };  
  } catch (error) {
    console.error("🚩🚩🚩Error deleting view service", error);
  }
  
  
  
};
export default deleteView;