import NavBar from "components/NavBar/index";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import Container from "@mui/material/Container";
import ListItemButton from "@mui/material/ListItemButton";

import { useNavigate } from "react-router-dom";
import Icon from "images/route.svg";
import useGet from "hooks/useFetch";
import Loading from "components/Lodings/LoadingV2";

import {
  CollectionWrapper,
  CollectionCard,
  CollectionIcon,
  CollectionH1,
  CollectionH2,
  CollectionP,
  ViewModeContainer,
} from "./CollectionsElements";

import ViewMode from "./ViewMode";
import { useSelector } from "react-redux";

const Collections = () => {
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "hub",
    menuElements: {},
    button: {
      link: "hub",
      text: "Back",
    },
  };

  const navigate = useNavigate();
  const [dates, error] = useGet(`api/v1/objects/dates`);

  const handlerClick = (date) => {
    navigate(`/collections/${date}`);
  };

  const viewMode = useSelector(
    (state) => state.collectionReducer.viewModeCollection
  );

  const showListDates = () => {
    return dates.map((date, index) => {
      return (
        <ListItemButton key={index}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <p style={{ fontSize: "1.5rem" }}>{isoDateToString(date.date)}</p>
            }
            secondary={
              <p style={{ fontSize: "1.4rem" }}>{date.amount + " objects"}</p>
            }
            onClick={() => handlerClick(date.date)}
          />
        </ListItemButton>
      );
    });
  };

  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 15);
  };

  const showModuleDates = () => {
    return dates.map((date, index) => {
      return (
        <CollectionCard key={index}>
          <CollectionIcon
            src={Icon}
            id={date.id}
            onClick={() => handlerClick(date.date)}
          />
          <CollectionH2>Date: {isoDateToString(date.date)}</CollectionH2>
          <CollectionP>Amount: {date.amount}</CollectionP>
        </CollectionCard>
      );
    });
  };

  return (
    <div>
      <NavBar configNavbar={configNavbar} />
      <Container
        style={{
          paddingTop: 80,
        }}
        maxWidth="xl"
      >
        {error ? (
          <p>{error}</p>
        ) : dates ? (
          <>
            <CollectionH1>You have {dates.length} collections</CollectionH1>
            <ViewModeContainer>
              <ViewMode />
            </ViewModeContainer>
            {viewMode === "List" && (
              <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <List dense={false}>{showListDates()}</List>
                  </Grid>
                </Grid>
              </Box>
            )}
            {viewMode === "Module" && (
              <CollectionWrapper>{showModuleDates()}</CollectionWrapper>
            )}
          </>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Collections;
