import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { CustomizeToggleButtonGroup } from "../DigitalTwin/userRoutes";
import ListObjects from "../DigitalTwin/ListObjects";
import ListLines from "../DigitalTwin/ListLines";
import ListRegions from "../DigitalTwin/ListRegions";
import { ItemList } from "../DigitalTwin/UserRoutesElements";

export const ContentInformation = (section, handleTabInformation) => ({
  title: "Information",
  description: (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CustomizeToggleButtonGroup
          value={section}
          onChange={handleTabInformation}
          padding={4}
        >
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="puntos">
            <p>Puntos</p>
          </ToggleButton>
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="lineas">
            <p>Lineas</p>
          </ToggleButton>
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="poligonos">
            <p>Poligonos</p>
          </ToggleButton>
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="clusters">
            <p>Clusters</p>
          </ToggleButton>
        </CustomizeToggleButtonGroup>
        <br />
        {section === "puntos" && <ListObjects />}
        {section === "lineas" && <ListLines />}
        {section === "poligonos" && <ListRegions />}
        {section === "clusters" && (
          <Container>
            <ItemList>
              <span className="cluster-csv"></span>
              <p>File CSV</p>
            </ItemList>
            <ItemList>
              <span className="cluster-objects"></span>
              <p>Objects</p>
            </ItemList>
            <ItemList>
              <span className="cluster-components"></span>
              <p>Components</p>
            </ItemList>
            <ItemList>
              <span className="cluster-events"></span>
              <p>Events</p>
            </ItemList>
          </Container>
        )}
      </Grid>
    </>
  ),
});
