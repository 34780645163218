import React from 'react';
import { useCallback } from 'react';
import { ButtonExtend } from './userRoutes';
import * as turf from '@turf/turf';
import { useSelector } from "react-redux";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';


function ExtendView(props) {

    const allObjects = useSelector(
        (state) => state.adminReducer.dataObjects
    )

    /** Extended view handler */
    const handleExtendView = useCallback(() => {

        const features = (allObjects.length !== 0) ? allObjects.map(obj => {
            delete obj.location.id;
            return Array.of(obj.location.longitude, obj.location.latitude);
        }) : [];

        /* if there is no objects then a default extend view of -1 zoom  */
        const { lng, lat } = props.map?.current.getCenter();
        const zoom = props.map.current.getZoom();
        const defaultExtended = Math.round(zoom - 1);
        if (features.length === 0) {
            props.map.current?.flyTo({
                center: [lng, lat],
                duration: 1000,
                zoom: defaultExtended,
            })
            return;
        }

        const featuresCollection = turf.points(features);
        const envelopeFeatures = turf.envelope(featuresCollection);

        props.map.current?.getMap().fitBounds(envelopeFeatures.bbox, {
            padding: 50
        })

    }, [allObjects, props]);

    return (
        <ButtonExtend onClick={handleExtendView}>
            <AspectRatioIcon
                sx={{
                    color: "#282828",
                    fontSize: "1.6rem"
                }} />
        </ButtonExtend>
    );
}

export default ExtendView;