import { ContainerSelect } from "./style";

function SelectInput({ field, register }) {
  const { name, required, options, possible, type } = field;
  return (
    <ContainerSelect>
      <label className="label" htmlFor={name}>
        {type === "currency" ? "Currency:" : name}
        {required ? <span className="required">*</span> : null}
      </label>
      <select
        id={name}
        className="select"
        {...register(type === "currency" ? "Currency" : name)}
      >
        {type === "currency"
          ? possible.map((option, index) => {
              return (
                <option key={index} value={option}>
                  {option}
                </option>
              );
            })
          : options.map((option, index) => {
              return (
                <option key={index} value={option}>
                  {option}
                </option>
              );
            })}
      </select>
    </ContainerSelect>
  );
}

export default SelectInput;
