import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

export const ContentButton = styled.div`
  margin: 10px;
  width: 100%;
  button {
    width: 100%;
    display: flex;
    padding: 10px 20px;
    border: none;
    color: #f9f9f9;
    border-radius: 5px;
    background-color: ${(props) =>
      props.primaryColor ? props.primaryColor : PRIMARY_COLOR};
    align-items: center;
    justify-content: center;
  }

  button:hover {
    background-color: ${(props) =>
      props.secondaryColor ? props.secondaryColor : SECOND_COLOR};
    cursor: pointer;
  }
`;
