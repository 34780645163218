import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  text-align: center;
  width: 0 auto;

  h1 {
    font-size: 2rem;
    margin: 20px 0;
    color: ${PRIMARY_COLOR};
  }

  img {
    width: 100%;
  }
`;
