import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { PRIMARY_COLOR } from "../../utils/const";


export const ImagesContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 20px 0px;
  align-items: center;
  background-color: #f9f9f9;
`;

export const NavGallery = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #222729;
  padding: 10px 22px;
  color: #fff;
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #31A636;
    color: #222729;
  }
`;

export const GalleryTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const ImagesWrapper = styled.div`
  width:100%;
  display: grid;
  grid-auto-rows: 13em;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  align-items: center;
  gap: 1rem;
`;

export const Img = styled.img`
  height: 200px;
  width: 170px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 0.8;
    z-index: 10;
    cursor: pointer;
  }
`;

export const ContentImg = styled.div`
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 340px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 350px) {
    width: 200px;
  }
`;