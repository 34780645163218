import axios from "axios";

const fiendCompoenents = async (componentId) => {
  try {
    const token = localStorage.getItem("token");
    let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/point-library-components`;
    if (componentId) url = `${url}?id=${componentId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default fiendCompoenents;
