import {
  SHOW_EVENTS_BY_INSPECTION_TYPE,
  SHOW_OPERATIONS_BY_TYPE,
  SET_DATA_OPERATIONS,
  SHOW_LABELS,
  SET_SHOW_LINES,
  SET_FLY_TO,
  SET_OPERATIONS,
  SET_FILTERED_OPERATIONS,
  SET_SHOW_TRACKINGS,
  SET_DATA_EVENTS,
  SET_TABLE_DETAILS_FORWARD,
  SET_NEURON_SELECT_TOOL,
  DYNAMIC_ROW_NUM,
  GET_TABLE_ROWS_FILTER,
  GET_TABLE_ROWS_FULL,
  OPEN_SELECT_COLUMNS,
  DOWNLOAD_OPTION,
  DATA_NEW_TABLE_WINDOW,
  STATE_OBJECT_FILTER_TABLE,
  STATE_EVENT_FILTER_TABLE,
  STATE_COMPONENT_FILTER_TABLE,
  SET_DATA_COMPONENTS,
  SHOW_DATA_COMPONENTS_LIBRARY,
  SHOW_DATA_COMPONENTS_BY_TYPE,
  SHOW_DATA_COMPONENTS,
  SET_FILTER_COMPONENT,
  SET_FILTER_EVENTS,
  SHOW_EVENTS,
  SHOW_EVENTS_BY_LIBRARY,
  SHOW_EVENTS_BY_TYPE,
  STATE_FILTER_BUTTON_TABLE,
} from "./types";

export const setStateFilterButtonTable = (payload) => ({
  type: STATE_FILTER_BUTTON_TABLE,
  payload,
});

export const setFilterEvents = (payload) => ({
  type: SET_FILTER_EVENTS,
  payload,
});

export const setShowEvents = (payload) => ({
  type: SHOW_EVENTS,
  payload,
});

export const setShowEventsByLibrary = (payload) => ({
  type: SHOW_EVENTS_BY_LIBRARY,
  payload,
});

export const showEventsByType = (payload) => ({
  type: SHOW_EVENTS_BY_TYPE,
  payload,
});

export const setFilterComponent = (payload) => ({
  type: SET_FILTER_COMPONENT,
  payload,
});
export const setShowDataComponents = (payload) => ({
  type: SHOW_DATA_COMPONENTS,
  payload,
});
export const setShowDataComponentsByType = (payload) => ({
  type: SHOW_DATA_COMPONENTS_BY_TYPE,
  payload,
});

export const setShowDataComponentsLibrary = (payload) => ({
  type: SHOW_DATA_COMPONENTS_LIBRARY,
  payload,
});
export const setDataComponents = (payload) => ({
  type: SET_DATA_COMPONENTS,
  payload,
});
export const setStateComponentFilterTable = (payload) => ({
  type: STATE_COMPONENT_FILTER_TABLE,
  payload,
});
export const setStateObjectFilterTable = (payload) => ({
  type: STATE_OBJECT_FILTER_TABLE,
  payload,
});
export const setStateEventFilterTable = (payload) => ({
  type: STATE_EVENT_FILTER_TABLE,
  payload,
});
export const dataNewTableWindow = (payload) => ({
  type: DATA_NEW_TABLE_WINDOW,
  payload,
});
export const setDownloadOption = (payload) => ({
  type: DOWNLOAD_OPTION,
  payload,
});
export const openSelectColumns = (payload) => ({
  type: OPEN_SELECT_COLUMNS,
  payload,
});
export const getTableRowsFull = (payload) => ({
  type: GET_TABLE_ROWS_FULL,
  payload,
});
export const getTableRowsFilter = (payload) => ({
  type: GET_TABLE_ROWS_FILTER,
  payload,
});
export const setRowNum = (payload) => ({
  type: DYNAMIC_ROW_NUM,
  payload,
});

export const setShowTrackings = (payload) => ({
  type: SET_SHOW_TRACKINGS,
  payload,
});

export const setOperations = (payload) => ({
  type: SET_OPERATIONS,
  payload,
});

export const setFilteredOperations = (payload) => ({
  type: SET_FILTERED_OPERATIONS,
  payload,
});

export const setShowLines = (payload) => ({
  type: SET_SHOW_LINES,
  payload,
});

export const setFlyTo = (payload) => ({
  type: SET_FLY_TO,
  payload,
});

export const showEventsByInspectionType = (payload) => ({
  type: SHOW_EVENTS_BY_INSPECTION_TYPE,
  payload,
});

export const setDataOperations = (payload) => ({
  type: SET_DATA_OPERATIONS,
  payload,
});

export const showLabels = (payload) => ({
  type: SHOW_LABELS,
  payload,
});

export const showOperationsByType = (payload) => ({
  type: SHOW_OPERATIONS_BY_TYPE,
  payload,
});

export const setDataEvents = (payload) => ({
  type: SET_DATA_EVENTS,
  payload,
});

export const setTableDetailsForward = (payload) => ({
  type: SET_TABLE_DETAILS_FORWARD,
  payload,
});

export const setNeuronSelectTool = (payload) => ({
  type: SET_NEURON_SELECT_TOOL,
  payload,
});
