import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "../../../utils/const";

export const ActionsObject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  .button-edit-object {
    background: ${PRIMARY_COLOR};
    color: #fff;
    padding: 8px 13px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }
`;

export const ContentButtonConvert = styled.div`
  width: 100%;
  padding: 0px 0px 0px 0px;
`;

export const IconConvert = styled.div`
  width: 95%;

  margin: 11px 11px 11px 15px;
`;

export const LayerConvert = styled.p`
  margin: 0px 11px 0px 15px;
`;

export const LayerConvertCenter = styled.p`
  text-align: center;
  margin: 0px 11px 11px 15px;
`;

export const InputConvert = styled.input`
  width: 95%;
  margin: 5px 15px;
  padding: 5px;
`;

export const DivConvert = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const DivConvertBorder = styled.div`
  border-style: solid;
  border-color: black;
  padding: 40px;
  margin: 0px 0px 11px 0px;
`;

export const DivConvertBorderRight = styled.div`
  border-style: solid solid solid none;
  border-color: black;
  padding: 40px;
  margin: 0px 0px 11px 0px;
  @media screen and (max-width: 600px) {
    border-style: solid solid solid solid;
  }
`;

export const ContentButtonGallery = styled.div`
  width: 100%;
  padding: 10px 0px 10px 0px;
`;

export const ContentButton = styled.div`
  width: 93%;
  padding: 10px 10px 20px 10px;
`;

export const ButtonDeleteElement = styled.button`
  margin: 10px;
  background: #e13030;
  width: 100%;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  border: none;

  :hover {
    background-color: #ff2121;
    box-shadow: rgba(255, 61, 61, 0.7) 0px 0px 5px;
  }
`;

export const ButtonInfo = styled.div`
  cursor: pointer;
  .container-tooltip {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    z-index: 999;
  }

  .tooltip {
    display: block;
    position: absolute;
    border-radius: 3px;
    min-width: 150px;
    padding: 0px;
    padding-top: 5px;
    transform: translate(0%, -55px);
    border: solid 2px ${PRIMARY_COLOR};
    background-color: #0e4d45b5;
    color: #ffffff;
    text-align: center;
    z-index: 999;
    visibility: visible;
    opacity: 1;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .button {
    position: absolute;
    margin-top: -5px;
    border-radius: 1px;
    font-size: 0.8rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 3px;
    padding-right: 3px;
    right: 0;
    background-color: red;
    border: none;
  }

  .button:active {
    border: none;
    background-color: #1889ca;
  }

  .button:hover {
    transform: scale(1.5);
    color: ${WHITE_COLOR};
    cursor: pointer;
  }
`;

export const LibToolTip = styled.div`
  position: relative;
  .tooltip {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #0e4d45b5;
    border: solid 2px ${PRIMARY_COLOR};
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .tooltiptrigger:hover + .tooltip {
    display: block;
    z-index: 999;
  }
`;
