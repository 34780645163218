import axios from "axios";
import { config } from "config.js";

/**
 * Finds users by admin company ID.
 *
 * @param {Object} param - The parameter object.
 * @param {string} param.adminCompanyId - The admin company ID.
 * @return {Object} - The data of the found users.
 */
export const findUsersByAdminCompanyId = async ({ adminCompanyId }) => {
  const token = localStorage.getItem("token");
  const result = await axios.get(
    `${config.URL_BACKEND_PG}api/v1/admin-company/users/${adminCompanyId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await result.data;
  return data;
};
