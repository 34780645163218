import { useCallback, useEffect, useMemo } from "react";
import useSupercluster from "use-supercluster";
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";

// Components
import MarkerElement from "./MarkerElement";
import MarkerCluster from "./MarkerCluster";
import ComponentDetails from "components/ImageGallery/ComponentDetails/Index.jsx";
import { setDataCompoentsPolygon } from "redux/actions/admin";
import { setFilterComponent } from "redux/actions/digitalTwin";
/**
 * Renders the clusters of components on a map.
 *
 * @param {Object} components - an array of components
 * @param {Object} bounds - the bounds of the map
 * @param {Function} onSelectPoint - a function to handle selecting a point
 * @param {Object} viewState - the current state of the map view
 * @param {Function} setViewState - a function to set the state of the map view
 * @return {JSX.Element} the rendered clusters of components
 */
const ComponentsClusters = ({
  components,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
}) => {
  const dispatch = useDispatch();

  const componentClusters = useSelector(
    (state) => state.digitalTwinReducer.componentClusters
  );

  const stateFilterComponent = useSelector(
    (state) => state.digitalTwinReducer.stateFilterComponent
  );

  const showDataComponentsByType = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsByType
  );

  const showDataComponents = useSelector(
    (state) => state.digitalTwinReducer.showDataComponents
  );

  const dataTable = useSelector(
    (state) => state.adminReducer.componentsFilterTable
  );

  const filterComponents = showDataComponentsByType?.filter((elm) => {
    return elm.state === true;
  });

  const dataCompoents = components?.filter((component) => {
    return filterComponents?.some((componentFilter) => {
      return (
        component.pointTypeComponent.id === componentFilter.id &&
        component.pointTypeComponent.pointLibraryComponentId ===
          componentFilter.libraryId &&
        componentFilter.state === true
      );
    });
  });

  useEffect(() => {
    dispatch(setDataCompoentsPolygon(dataCompoents));
    dispatch(setFilterComponent(false));
  }, [showDataComponentsByType, stateFilterComponent, showDataComponents]);

  const newPoints =
    dataTable !== undefined && dataTable.length !== 0
      ? dataTable.map((component) => ({
          type: "Feature",
          properties: {
            cluster: false,
            element: {
              id: component.id,
              icon: component.pointTypeComponent.icon,
              location: {
                latitude: component.locationComponent.latitude,
                longitude: component.locationComponent.longitude,
              },
            },
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(component.locationComponent.longitude),
              parseFloat(component.locationComponent.latitude),
            ],
          },
        }))
      : dataCompoents.map((component) => ({
          type: "Feature",
          properties: {
            cluster: false,
            element: {
              id: component.id,
              icon: component.pointTypeComponent.icon,
              location: {
                latitude: component.locationComponent.latitude,
                longitude: component.locationComponent.longitude,
              },
            },
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(component.locationComponent.longitude),
              parseFloat(component.locationComponent.latitude),
            ],
          },
        }));

  const { clusters } = useSupercluster({
    points: newPoints,
    bounds,
    zoom: viewState.zoom,
    options: {
      radius: componentClusters.radius || 0,
      maxZoom: componentClusters.maxZoom || 0,
    },
  });

  const handleClickComponent = useCallback(
    (componentId) => {
      const component = {
        content: <ComponentDetails componentId={componentId} />,
        title: "Component Details",
      };
      dispatch(setOperationDetails(component));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );

  const renderMarkerElement = useCallback(
    (element) => {
      return (
        <MarkerElement
          key={element.id}
          element={element}
          handleClick={handleClickComponent}
        />
      );
    },
    [handleClickComponent]
  );

  const MarkersCluster = useMemo(
    () =>
      clusters.length > 0 &&
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const clusterData = {
          id: cluster.id,
          location: {
            latitude,
            longitude,
          },
          pointCount,
          elementsCount: dataCompoents.length,
          type: "component",
        };
        if (isCluster)
          return (
            <MarkerCluster
              cluster={clusterData}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        return renderMarkerElement(cluster.properties.element);
      }),
    [
      renderMarkerElement,
      onSelectPoint,
      clusters,
      setViewState,
      viewState,
      dataCompoents,
    ]
  );

  return MarkersCluster;
};

export default ComponentsClusters;
