import axios from "axios";

const deleteTypeComponentsTypeElements = async (id) => {
  const token = localStorage.getItem("token");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-components-type-elements/${id}`;
  const result = await axios.delete(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default deleteTypeComponentsTypeElements;
