import { useEffect } from "react";
import { useState } from "react";
import { Layer, Source } from "react-map-gl";
import { PRIMARY_COLOR } from "utils/const";

export default function Trackings({ trackings }) {
  const [trackingsFeatures, setTrackingsFeatures] = useState([]);

  useEffect(() => {
    if (!trackings || trackings.length <= 0) return;
    trackings.map((tracking) => {
      const features = tracking?.feature;
      const featuresFilter = features.filter(
        (feature) => feature.geometry.type === "LineString"
      );

      const trackingsFeatures = featuresFilter.map((feature) => ({
        type: "Feature",
        properties: {
          cluster: false,
          object: feature,
        },
        geometry: {
          type: "LineString",
          coordinates: feature.geometry.coordinates,
        },
      }));

      setTrackingsFeatures((current) => [...current, ...trackingsFeatures]);

      return null;
    });
  }, [trackings]);

  return (
    <Source
      type="geojson"
      data={{
        type: "FeatureCollection",
        features: trackingsFeatures,
      }}
    >
      <Layer
        key="tracking-id"
        id="tracking-id"
        type={"line"}
        paint={{
          "line-color": PRIMARY_COLOR,
          "line-width": 2,
        }}
      />
    </Source>
  );
}
