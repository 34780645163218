import { useCallback } from "react";
import { useDispatch, useStore } from "react-redux";

import {
  setGeometryPolygon,
  setFeaturesDetailsDT,
  setDataObjectsPolygon,
  setGeometryLine,
  setDataEventPolygon,
  setOriginalDataObjectsPolygon,
} from "redux/actions/admin";

import { setNeuronSelectTool } from "redux/actions/digitalTwin";

import { getPointsPolygon } from "services/sendPolygonGeometry";

const HandlerDrawerControl = () => {
  const dispatch = useDispatch();
  const store = useStore();

  // function to send polygon geometry to backend
  const sendPolygonGeometry = useCallback(
    (body) => {
      let { coordinates } = body;
      coordinates = coordinates[0];
      const { filteredObjects, filteredOperations } =
        store.getState().digitalTwinReducer;

      const { features } = getPointsPolygon(coordinates, filteredObjects);

      const objectsIntoPolygon = features.map(
        (feature) => feature.properties.object
      );
      dispatch(setOriginalDataObjectsPolygon(objectsIntoPolygon));
      dispatch(setDataObjectsPolygon(objectsIntoPolygon));

      const { features: featuresOperations } = getPointsPolygon(
        coordinates,
        filteredOperations
      );
      const operationsIntoPolygon = featuresOperations.map(
        (feature) => feature.properties.operation
      );
      dispatch(setDataEventPolygon(operationsIntoPolygon));
    },
    [dispatch, store]
  );

  const onCreate = useCallback(
    (e) => {
      if (e.features[0].geometry.type === "Polygon") {
        sendPolygonGeometry(e.features[0].geometry);
        dispatch(setGeometryPolygon(e.features[0].geometry));
      }
      if (e.features[0].geometry.type === "Point") {
        ((currFeatures) => {
          const newFeatures = { ...currFeatures };
          for (const f of e.features) {
            newFeatures[f.id] = f;
            dispatch(setFeaturesDetailsDT(f));
          }
          return newFeatures;
        })();
      }
      if (e.features[0].geometry.type === "LineString") {
        dispatch(setGeometryLine(e.features[0]));
      }
    },
    [dispatch, sendPolygonGeometry]
  );

  const onSelect = useCallback(
    (e) => {
      for (const f of e.features) {
        dispatch(setFeaturesDetailsDT(f));
      }
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    (e) => {
      if (e.features[0].geometry.type === "Polygon") {
        sendPolygonGeometry(e.features[0].geometry);
        dispatch(setGeometryPolygon(e.features[0].geometry));
      }
      if (e.features[0].geometry.type === "LineString") {
        dispatch(setGeometryLine(e.features[0]));
      }
      ((currFeatures) => {
        const newFeatures = { ...currFeatures };
        for (const f of e.features) {
          newFeatures[f.id] = f;
        }
        return newFeatures;
      })();
    },
    [dispatch, sendPolygonGeometry]
  );

  const onDelete = useCallback(
    (e) => {
      dispatch(
        setNeuronSelectTool({
          show: false,
          top: null,
        })
      );
      dispatch(setGeometryLine(null));
      dispatch(setGeometryPolygon(null));
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
    },
    [dispatch]
  );

  return {
    onCreate,
    onSelect,
    onUpdate,
    onDelete,
  };
};

export default HandlerDrawerControl;
