// React
import { useEffect } from "react";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useSWRConfig } from "swr";
// Style
import { ButtonInfo } from "./ObjectDetailsElements";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// import updateLeak from "services/updateLeak";
// Helpers
import { isUrl } from "helpers/isUrl";
import { formatFloat } from "helpers/formatFloat";
// Services
import findInspectionFlir from "services/findInspectionFlir";
// Components
import OpenDialog from "components/Dialogs/OpenDialog";
//Hooks
import useGet from "hooks/useFetch";

export default function TableObject({ object }) {
  const [operations] = useGet(`api/v1/operation?objectId=${object.id}`);
  const [inspectionsFlir, setInspectionsFlir] = useState({
    inspectionsFlir: [],
    lastInspectionFlir: [],
    lastDate: "",
  });
  const [videosProcessed, setVideosProcessed] = useState([]);
  const [seqProcessed, setSeqProcessed] = useState([]);
  const [highestValues, setHighestValues] = useState();
  const [leak, setLeak] = useState(null);

  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 25);
  };
  const reduceAttributes = () => {
    const filteredAttributes = object?.attributes?.map((item) => {
      let newItem = { ...item };
      try {
        if (typeof JSON.parse(item.value) === "object")
          newItem.value = JSON.parse(item.value);
      } catch (error) {
        return item;
      }
      return newItem;
    });
    return filteredAttributes;
  };

  useEffect(() => {
    if (operations) {
      const inspectionsFlir = operations.filter((operation) => {
        return operation.inspectionFlir;
      });
      const eventLeak = operations.filter((operation) => operation.leak);
      setLeak(eventLeak[0]);
      setInspectionsFlir((current) => ({
        ...current,
        inspectionsFlir: inspectionsFlir,
      }));
    }
  }, [operations]);

  useEffect(() => {
    if (inspectionsFlir.lastDate !== "") return;
    if (inspectionsFlir.inspectionsFlir.length <= 0) return;
    let lastDate = inspectionsFlir.inspectionsFlir.reduce((prev, current) => {
      return prev.date > current.date ? prev : current;
    });
    lastDate = lastDate.date.slice(0, 10);
    setInspectionsFlir((current) => ({
      ...current,
      lastDate: lastDate,
    }));
  }, [inspectionsFlir]);

  useEffect(() => {
    if (inspectionsFlir.lastDate === "") return;
    if (inspectionsFlir.inspectionsFlir.length <= 0) return;
    const lastInspectionFlir = inspectionsFlir.inspectionsFlir.filter((leak) =>
      leak.date.includes(inspectionsFlir.lastDate)
    );
    setInspectionsFlir((current) => ({
      ...current,
      lastInspectionFlir: lastInspectionFlir,
    }));
  }, [inspectionsFlir.lastDate, inspectionsFlir.inspectionsFlir]);

  useEffect(() => {
    if (inspectionsFlir.lastInspectionFlir.length <= 0) return;
    const getIndividualsInspections = async () => {
      const inspections = await Promise.all(
        inspectionsFlir.lastInspectionFlir.map(async (leak) => {
          const inspection = await findInspectionFlir(leak.inspectionFlir.id);
          return inspection;
        })
      );
      let flirVideosProcessed = inspections.map(
        (inspection) => inspection.flirVideos
      );
      flirVideosProcessed = flirVideosProcessed.filter((flir) => {
        return flir !== null;
      });
      setSeqProcessed(
        flirVideosProcessed.map((flir) => flir[0]?.flirVideoProcessed)
      );
      flirVideosProcessed = flirVideosProcessed.map((flir) => {
        const some = flir[0]?.flirVideoProcessed.filter(
          (videosProcessed) => videosProcessed?.type?.type === "ppm"
        );
        return some[0];
      });
      flirVideosProcessed = flirVideosProcessed.filter((flir) => flir !== null);

      setVideosProcessed(flirVideosProcessed);
    };
    getIndividualsInspections();
  }, [inspectionsFlir.lastInspectionFlir]);

  const { mutate } = useSWRConfig();
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  useEffect(() => {
    if (videosProcessed.length <= 0) return;

    const highValue = seqProcessed.reduce((prev, current) => {
      let previous = null;
      let next = null;
      prev.forEach((item) => {
        if (item.typeId === 1) previous = item.value;
      });
      current.forEach((item) => {
        if (item.typeId === 1) next = item.value;
      });
      return previous > next ? prev : current;
    });

    let ppm = null;
    let grh = null;
    let ltmin = null;

    highValue.forEach((item) => {
      if (item.typeId === 1) ppm = item.value;
      if (item.typeId === 2) grh = item.value;
      if (item.typeId === 3) ltmin = item.value;
    });

    // const eventLeak = leak?.leak;
    // const updateValuesLeak = async (eventLeak) => {
    //   mutate(
    //     `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/objects-web/${adminCompanyId}`
    //   );
    //   const { res } = await updateLeak(
    //     {
    //       ppm: ppm,
    //       grH: grh,
    //       ltMin: ltmin,
    //     },
    //     eventLeak.id
    //   );
    //   return res;
    // };

    // if (eventLeak) updateValuesLeak(eventLeak);

    setHighestValues({
      ppm,
      grh,
      ltmin,
    });
  }, [videosProcessed, seqProcessed, leak, adminCompanyId, mutate]);

  return (
    <div className="main-currency-table">
      <div className="currency-table--container">
        <table>
          <tr>
            <td className="table__top-left">QAQC</td>
            <td className="table__top-right table__right">
              {object?.QAQC ? <FaCheck style={{ color: "green" }} /> : "❌"}
            </td>
          </tr>
          <tr>
            <td className="">Leak regulated</td>
            <td
              style={
                highestValues?.ppm >= 500 ? { backgroundColor: "#ed3939" } : {}
              }
              className="table__right"
            >
              {highestValues?.ppm >= 500 ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td>ppm-meter</td>
            <td className="table__right">
              {highestValues?.ppm === undefined || highestValues?.ppm === null
                ? "-"
                : highestValues?.ppm}
            </td>
          </tr>
          <tr>
            <td>gram / hour</td>
            <td className="table__right">
              {highestValues?.grh === undefined || highestValues?.grh === null
                ? "-"
                : highestValues?.grh}
            </td>
          </tr>
          <tr>
            <td>litre / minute</td>
            <td className="table__right">
              {highestValues?.ltmin === undefined ||
              highestValues?.ltmin === null
                ? "-"
                : highestValues?.ltmin}
            </td>
          </tr>
          <tr>
            <td>Source</td>
            <td className="table__right">
              {object.dataSourceId === 1 ? (
                <p>Mobile</p>
              ) : object.dataSourceId === 2 ? (
                <p>Desktop</p>
              ) : object.dataSourceId === 3 ? (
                <p>CSV File</p>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>Field equipment name</td>
            <td className="table__right">{object.fieldEquipmentName}</td>
          </tr>
          {object?.link360 && (
            <tr>
              <td>360 link</td>
              <td className="table__right">
                <a href={object?.link360} target="_blank" rel="noreferrer">
                  video 360
                </a>
              </td>
            </tr>
          )}
          <tr>
            <td>Created by</td>
            <td className="table__right">
              {" "}
              {object.user.operator?.firstName}{" "}
              {object.user.operator?.firstLastName}
              {object.user.adminCompany?.firstName}{" "}
              {object.user.adminCompany?.firstLastName}
            </td>
          </tr>
          <tr>
            <td>Date</td>
            <td className="table__right">{isoDateToString(object.date)}</td>
          </tr>
          <tr>
            <td>Longitude</td>
            <td className="table__right">
              {parseFloat(object.location.longitude)}
            </td>
          </tr>
          <tr>
            <td>Latitude</td>
            <td className="table__right">
              {parseFloat(object.location.latitude)}
            </td>
          </tr>
          <tr>
            <td>Object ID</td>
            <td className="table__right">{object.id}</td>
          </tr>

          {object.lastUpdate !== null && (
            <>
              {object?.userUpdate?.adminCompany !== null ? (
                <tr>
                  <td>Update by</td>
                  <td className="table__right">
                    {" "}
                    {object?.userUpdate?.adminCompany.firstName}{" "}
                    {object?.userUpdate?.adminCompany.firstLastName}
                  </td>
                </tr>
              ) : object?.userUpdate?.adminDecimetrix !== null ? (
                <tr>
                  <td>Update by</td>
                  <td className="table__right">
                    {" "}
                    {object?.userUpdate?.adminDecimetrix.firstName}{" "}
                    {object?.userUpdate?.adminDecimetrix.firstLastName}
                  </td>
                </tr>
              ) : object?.userUpdate?.operator ? (
                <tr>
                  <td>Update by</td>
                  <td className="table__right">
                    {" "}
                    {object?.userUpdate?.operator.firstName}{" "}
                    {object?.userUpdate?.operator.firstLastName}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td>Last Update</td>
                <td className="table__right">
                  {isoDateToString(object.lastUpdate)}
                </td>
              </tr>
              {object?.fieldBaseline && (
                <tr>
                  <td>ID field baseline inventory</td>
                  <td className="table__right">{object.fieldBaseline}</td>
                </tr>
              )}
              <tr>
                <td>Comments</td>
                <td className="table__right">{object.comments}</td>
              </tr>
            </>
          )}
          {object &&
            reduceAttributes()?.map((item) => {
              return (
                <tr>
                  <td style={{ display: "flex", width: "100%" }}>
                    {(item.value?.alias || item?.alias) &&
                    (item.value?.alias?.length > 0 || item?.alias?.length > 0)
                      ? item.value.alias || item.alias
                      : item.name}
                    {item.value?.operation ? (
                      <OperationInfo content={item.value} />
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="table__right">
                    {isUrl(item.value) ? (
                      <a href={item.value} target="_blank" rel="noreferrer">
                        Go to link
                      </a>
                    ) : typeof item.value !== "object" ? (
                      item.value
                    ) : (
                      formatFloat(item.value.value, 4)
                    )}
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
}
const OperationInfo = ({ content }) => {
  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      disagree: "Close",
    },
  });

  const handleShow = () => {
    setDialog((current) => ({
      ...current,
      open: true,
    }));
  };
  return (
    <ButtonInfo>
      {
        <ContactSupportIcon
          sx={{
            fontSize: "1.8rem",
            color: "#3f51b5",
          }}
          onClick={handleShow}
        />
      }
      <OpenDialog
        openDialog={dialog?.open}
        setOpenDialog={setDialog}
        execute={dialog.execute}
        content={{
          title: "Operation detail",
          description: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <div>
                <span>
                  The result of this field is obtained from the following
                  expression:
                </span>
              </div>
              <div>
                <math-field
                  read-only
                  className="math-field"
                  id={content?.name}
                  style={{
                    display: "inline-block",
                    minWidth: "200px",
                    width: "100%",
                    minHeight: "60px",
                    fontSize: "2.2rem",
                    border: "none",
                  }}
                  value={content?.operation}
                />
              </div>
            </div>
          ),
          ...dialog.actions,
        }}
      />
    </ButtonInfo>
  );
};
