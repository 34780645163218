import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const SelectContainer = styled.div`
  width: 100%;

  .label {
    margin: 20px 0;
    padding: 10px 0;
    font-size: 1.6rem;
    font-weight: 600;
    color: #5c5757;
    cursor: pointer;
  }

  .select {
    border-radius: 5px;
    width: 100%;
    margin: 10px 0;
    background-color: ${PRIMARY_COLOR};
    border-color: ${PRIMARY_COLOR};
    color: #fff;
    padding: 6px 15px;
  }
`;
