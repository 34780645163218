import styled from "styled-components";

export const MarkerContainer = styled("div")`
  .cluster-marker,
  .cluster-marker-csv,
  .cluster-marker-operation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    color: #fff;
    background: #ff5f7e;
    border-radius: 50%;
    box-shadow: 0 0 8px #ff7893;
    cursor: pointer;
  }
`;
