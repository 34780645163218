import NavBar from "components/Admin/NavBar/NavBar";
import { useNavigate } from "react-router-dom";
// import Icon1 from "images/undraw_OCR.svg";
import Icon2 from "images/dashboardV2.svg";
import Icon3 from "images/DigitalTwin.svg";
import UserSvg from "images/user.svg";
import ImageVideoManager from "images/undraw_video_files_fu10.svg";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Loading from "components/Lodings/LoadingV2";
import FetchOperator from "hooks/fetchOperator";

import {
  UserContainer,
  UserH1,
  UserH2,
  HubContainer,
  HubWrapper,
  HubCard,
  HubIcon,
  HubH1,
  HubH2,
  HubP,
  UserH2Flex,
} from "./HubElements";

import { roles } from "utils/roles";

const Hub = () => {
  const navigate = useNavigate();

  const onClickUsers = () => navigate("/operators-user");
  const onClickDashboards = () => navigate("/dashboardUser");
  const onClickDigitalTwin = () => navigate("/digitalTwin");
  const onClickVideoManager = () => {
    if (customer.operator?.role?.role === roles.teamField)
      navigate(`/upload-videos/${customer.operator.userId}`);
    if (customer.operator.role?.role === roles.teamOffice)
      navigate("/field-video-manager");
  };
  const operatorId = localStorage.getItem("operatorId");

  const { data: customer, error: errorCustomer } = FetchOperator(operatorId);

  const formatDate = (string) => {
    if (string === null) {
      return "";
    } else {
      const newString = string.replace("T", " ");
      return newString.replace(".000Z", " ");
    }
  };
  return (
    <div>
      <NavBar linkLogo={"hub"} />
      <Container
        style={{
          paddingTop: 50,
        }}
        maxWidth="xl"
      >
        {customer ? (
          <>
            <UserContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={8} xl={8}>
                  {customer && !errorCustomer ? (
                    <UserH1>Hello, Welcome to a Green World!</UserH1>
                  ) : (
                    <UserH1>Loading ...</UserH1>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <UserH2Flex>
                      {" "}
                      <b>Last Login:</b>{" "}
                      {customer !== undefined &&
                        customer !== null &&
                        `${formatDate(
                          customer.operator.user.userVerification.lastLogin
                        )}`}
                    </UserH2Flex>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={10} xl={10}>
                  <UserH2>
                    Please select the module that you want to use.
                  </UserH2>
                </Grid>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <UserH2Flex>
                      {" "}
                      <b>Role: </b>
                      {customer !== undefined &&
                        customer !== null &&
                        `${customer.operator.user.userType.role.toLowerCase()}-${
                          customer?.operator?.role?.role
                        }`}
                    </UserH2Flex>
                  </Box>
                </Grid>
              </Grid>
            </UserContainer>
            <HubContainer>
              <HubH1>
                Decimetrix<sup style={{ fontSize: 25 }}>®</sup> Green Dragon{" "}
                <br /> Carbon footprint management system
              </HubH1>
              <HubWrapper>
                <HubCard onClick={onClickDashboards}>
                  <HubH2>DASHBOARD</HubH2>
                  <HubIcon src={Icon2} />
                  <HubP>Report of all the objects collected.</HubP>
                </HubCard>
                <HubCard onClick={onClickDigitalTwin}>
                  <HubH2>DIGITAL TWIN</HubH2>
                  <HubIcon src={Icon3} />
                  <HubP>View all objets and events in the map.</HubP>
                </HubCard>
                {customer?.operator?.role?.role === roles.teamOffice ||
                customer?.operator?.role?.role === roles.teamField ? (
                  <HubCard onClick={onClickVideoManager}>
                    <HubH2>VIDEO MANAGER</HubH2>
                    <HubIcon src={ImageVideoManager} />
                    <HubP>Manage your field collected video.</HubP>
                  </HubCard>
                ) : null}

                {customer?.operator?.role?.role === roles.itSupport && (
                  <HubCard onClick={onClickUsers} className="last-item">
                    <HubH2>USERS</HubH2>
                    <HubIcon src={UserSvg} />
                    <HubP>View your collected objects for day.</HubP>
                  </HubCard>
                )}
              </HubWrapper>
            </HubContainer>
          </>
        ) : (
          <Loading />
        )}
      </Container>
    </div>
  );
};

export default Hub;
