// Required modules;
import GenericTable from "components/Tables/GenericTable";
import Form from "components/Forms/Form.jsx";

// Config admin class
import ConfigAdmin from "../ConfigAdmin.js";

// Hooks
import { useCallback, useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableTypeElementsEvents,
  keyValuesTableTypeElementsEvents,
  initialValuesFormTypeElementComponents,
} from "../dataConfigAdmin";

// Styles
import {
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  SeparatorWithText,
  InformationShare,
  IconAdminConfigChangeColum,
  SelectContainerAdminConfig,
} from "../configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { BsChevronCompactUp } from "react-icons/bs";
import { ContainerForm } from "components/Forms/FormStyles";
import useGet from "hooks/useFetch.js";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import findTypeComponentsTypeElements from "services/findTypeComponentsTypeElements.js";
import createTypeComponentsTypeElements from "services/createTypeComponentsTypeElements.js";
import deleteTypeComponentsTypeElements from "services/deleteTypeComponentsTypeElements.js";
import Fields from "./Fields.jsx";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <BsChevronCompactUp />
    </IconAdminConfigChangeColum>
  );
};

const AccordionRender = ({
  library,
  typeComponentsTypeElements,
  handleChangeCheck,
}) => {
  const { name, id } = library;
  const [types] = useGet(`api/v1/type-elements?libraryId=${parseInt(id)}`);
  const [check, setCheck] = useState(false);

  const handleChangeCheckLibrary = () => {
    const thisCheck = check;
    if (thisCheck) {
      types.forEach((type) => {
        const element = typeComponentsTypeElements?.[type?.id];
        if (element) {
          handleChangeCheck(type.id);
        }
      });
      return;
    }
    types.forEach((type) => {
      const element = typeComponentsTypeElements?.[type?.id];
      if (!element) {
        handleChangeCheck(type.id);
      }
    });
  };

  useEffect(() => {
    if (!types || types.error) return;
    types.every((type) => {
      const element = typeComponentsTypeElements?.[type?.id];
      if (element) return setCheck(true);
      return setCheck(false);
    });
  }, [types, typeComponentsTypeElements]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <FormControlLabel
            control={
              <Checkbox checked={check} onChange={handleChangeCheckLibrary} />
            }
            label={name}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          {types &&
            !types.error &&
            types.length > 0 &&
            types.map((type, key) => {
              const element = typeComponentsTypeElements?.[type?.id];
              let check = false;
              if (element) check = true;
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={check}
                      onChange={() => handleChangeCheck(type.id)}
                    />
                  }
                  label={type.name}
                />
              );
            })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

const CheckListsObjectsElements = ({
  typeComponentsTypeElements,
  handleChangeCheck,
}) => {
  const [libraries] = useGet("api/v1/point-libraries");
  const [allTypes] = useGet("api/v1/type-elements");

  const res =
    allTypes &&
    !allTypes.error &&
    allTypes.map((elm) => {
      return elm.pointLibraryId;
    });

  const idTypes =
    res &&
    !res.error &&
    res.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
      }}
    >
      {libraries &&
        !libraries.error &&
        libraries.map((library, key) =>
          idTypes && !idTypes.error && idTypes.includes(library.id) ? (
            <AccordionRender
              handleChangeCheck={handleChangeCheck}
              typeComponentsTypeElements={typeComponentsTypeElements}
              key={key}
              library={library}
            />
          ) : (
            <></>
          )
        )}
    </div>
  );
};

const findLibrary = (libraries, e) => {
  return libraries.find((library) => library.id === parseInt(e.target.value));
};

export default function ComponentElements({ setOpenDialog, setContentDialog }) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [libraries] = useGet("api/v1/point-library-components");
  const [library, setLibrary] = useState({});
  const [pointTypeComponentId, setpointTypeComponentId] = useState(null);
  const [typeComponentsTypeElements, setTypeComponentsTypeElements] = useState(
    {}
  );

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormTypeElementComponents);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);
  // State button loadingCreate
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [typeElementsGhgRow, setTypeElementsGhgRow] = useState([]);

  // Click  on type element ghg into table
  const handleClickTypeElementGhg = (id) => {
    if (id !== pointTypeComponentId) setTypeComponentsTypeElements({});
    setpointTypeComponentId(id);
    configAdmin.handlerClickIntoTable(id);
  };

  // Change in library select
  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: findLibrary(libraries, e).name,
      typeLibraryId: findLibrary(libraries, e)?.typeLibraryId,
    }));
  };

  const create = useCallback(
    async (typeComponentTypeElemnt) => {
      await Promise.all(
        Object.keys(typeComponentsTypeElements).map(async (typeComponentId) => {
          await createTypeComponentsTypeElements({
            pointTypeComponentId: typeComponentTypeElemnt.id,
            typeElementId: parseInt(typeComponentId),
          });
        })
      );
      setTypeComponentsTypeElements({});
      setpointTypeComponentId(null);
    },
    [typeComponentsTypeElements]
  );

  const update = useCallback(
    async (typeComponentTypeElemnt) => {
      const { id } = typeComponentTypeElemnt;
      const lastComponentsTypes = await findTypeComponentsTypeElements({
        pointTypeComponentId: id,
      });
      const toDelete = [];
      const toCreate = [];

      lastComponentsTypes.forEach(({ typeElementId }) => {
        if (!typeComponentsTypeElements[typeElementId]) {
          const ids = lastComponentsTypes
            .filter((last) => last.typeElementId === typeElementId)
            .map((last) => last.id);
          ids.filter((item, index) => ids.indexOf(item) === index);
          toDelete.push(...ids);
        }
      });

      Object.keys(typeComponentsTypeElements).forEach((typeElementId) => {
        if (
          !lastComponentsTypes.find(
            (e) => parseInt(e.typeElementId) === parseInt(typeElementId)
          )
        )
          toCreate.push(typeElementId);
      });

      await Promise.all(
        toCreate.map(async (typeComponentId) => {
          await createTypeComponentsTypeElements({
            pointTypeComponentId: typeComponentTypeElemnt.id,
            typeElementId: parseInt(typeComponentId),
          });
        })
      );

      await Promise.all(
        toDelete.map(async (id) => {
          await deleteTypeComponentsTypeElements(id);
        })
      );

      setTypeComponentsTypeElements({});
      setpointTypeComponentId(null);
    },
    [typeComponentsTypeElements, setTypeComponentsTypeElements]
  );

  const handleChangeCheck = (id) => {
    const element = typeComponentsTypeElements?.[id];
    if (element) {
      setTypeComponentsTypeElements((current) => {
        const { [id]: value, ...rest } = current;
        return rest;
      });
    } else {
      setTypeComponentsTypeElements((current) => ({
        ...current,
        [id]: true,
      }));
    }
  };

  // Search relations with type point component id
  useEffect(() => {
    if (!pointTypeComponentId) return;
    const getData = async () => {
      const data = await findTypeComponentsTypeElements({
        pointTypeComponentId,
      });
      data.forEach(({ typeElementId }) => {
        setTypeComponentsTypeElements((current) => ({
          ...current,
          [typeElementId]: true,
        }));
      });
    };
    getData();
  }, [pointTypeComponentId]);

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableTypeElementsEvents);
    configAdmin.setKeyValuesTable(keyValuesTableTypeElementsEvents);
    configAdmin.setSetRows(setTypeElementsGhgRow);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/point-type-components`);
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialog, setContentDialog]);

  // Ser callback update
  useEffect(() => {
    configAdmin && configAdmin?.setUpdateCallback(update);
  }, [configAdmin, update]);

  // Set callback create
  useEffect(() => {
    configAdmin && configAdmin?.setCreateCallback(create);
  }, [configAdmin, create]);

  // Set library default
  useEffect(() => {
    if (libraries) {
      setLibrary(() => libraries[0]);
    }
  }, [libraries]);

  // Set library in config admin
  useEffect(() => {
    const setEndpoint = () => {
      configAdmin?.setEndpointFetch(
        `api/v1/point-type-components?libraryId=${parseInt(library?.id)}`
      );
      configAdmin?.setPointLibraryComponentId(parseInt(library?.id));
      configAdmin?.fetchData();
    };

    if (library?.id) setEndpoint();
  }, [configAdmin, library?.id, loadingCreate, loadingDelete]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  return (
    <RowAdminConfig>
      <ColumnAdminConfig>
        <SelectContainerAdminConfig>
          Select library:
          <select name="endPoint" id="endPoint" onChange={handleLibrary}>
            {libraries?.map((library) => (
              <option key={library.id} value={library.id}>
                {library.name}
              </option>
            ))}
          </select>
        </SelectContainerAdminConfig>
        {configAdmin && (
          <GenericTable
            headers={configAdmin.getHeadersTable()}
            rows={typeElementsGhgRow}
            keyValues={configAdmin.getKeyValuesTable()}
            handlerClick={handleClickTypeElementGhg}
            style={{
              width: 100,
            }}
            state={true}
          />
        )}
      </ColumnAdminConfig>
      <ColumnAdminConfig>
        <ContainerForm see={seeForm}>
          <Form
            form={form}
            handleChange={handleChangeForm}
            handleRows={handleClickTypeElementGhg}
            buttons={buttonsForm}
            loadingCreate={loadingCreate}
            loadingDelete={loadingDelete}
            renderBeforeTitle={[
              <ButtonSeeInitalView click={() => configAdmin.toggleForm()} />,
            ]}
            title={"Create a new point component"}
            renderBeforeButtons={[
              <Fields form={form} handleChange={handleChangeForm} />,
              <CheckListsObjectsElements
                typeComponentsTypeElements={typeComponentsTypeElements}
                handleChangeCheck={handleChangeCheck}
              />,
            ]}
          />
        </ContainerForm>

        {!seeForm && (
          <>
            <CreateNewRegionButton>
              Create a new point component
              <button onClick={() => {
                configAdmin.toggleForm()
                setTypeComponentsTypeElements({})
                }}>
                <span>
                  <IoMdAdd />
                </span>
                New {library?.name} component
              </button>
            </CreateNewRegionButton>

            <SeparatorWithText>
              <span className="left"></span>
              Or
              <span className="right"></span>
            </SeparatorWithText>

            <InformationShare>
              <div className="head">Edit Existing Digital Twin Point</div>
              <div className="body">
                To Edit Click on Existing Digital Twin Point ID
              </div>
            </InformationShare>
          </>
        )}
      </ColumnAdminConfig>
    </RowAdminConfig>
  );
}
