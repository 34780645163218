import useSwr from "swr";

const Roles = () => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/roles`
  );
  return { data, error };
};

export default Roles;
