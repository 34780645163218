import {
  createTypeEventsTypeComponents,
  findTypeEventsTypeComponents,
  deleteTypeEventsTypeComponents,
} from "services/typeEventsTypeComponents.js";

const UpdateEventsComponentsCallback = ({
  pointTypeEvent,
  typeEventsTypeComponents,
  setTypeEventsTypeComponents,
}) => {
  const update = async () => {
    const { id } = pointTypeEvent;
    const lastEventsTypes = await findTypeEventsTypeComponents({
      pointTypeEventId: id,
    });
    const toDelete = [];
    const toCreate = [];

    lastEventsTypes.forEach(({ pointTypeComponentId }) => {
      if (!typeEventsTypeComponents[pointTypeComponentId]) {
        const ids = lastEventsTypes
          .filter((last) => last.pointTypeComponentId === pointTypeComponentId)
          .map((last) => last.id);
        ids.filter((item, index) => ids.indexOf(item) === index);
        toDelete.push(...ids);
      }
    });

    Object.keys(typeEventsTypeComponents).forEach((pointTypeComponentId) => {
      if (
        !lastEventsTypes.find(
          (e) =>
            parseInt(e.pointTypeComponentId) === parseInt(pointTypeComponentId)
        )
      )
        toCreate.push(pointTypeComponentId);
    });

    await Promise.all(
      toCreate.map(async (pointTypeComponentId) => {
        await createTypeEventsTypeComponents({
          pointTypeEventId: pointTypeEvent.id,
          pointTypeComponentId: parseInt(pointTypeComponentId),
        });
      })
    );

    await Promise.all(
      toDelete.map(async (id) => {
        await deleteTypeEventsTypeComponents(id);
      })
    );

    setTypeEventsTypeComponents({});
  };
  return update;
};

export default UpdateEventsComponentsCallback;
