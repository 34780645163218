import { useState } from "react";
import { FormContainer, WrapperForm, Label, Input, Button } from "./FormStyles";
import updateObjectPg from "services/updateObjectPg";
import { useSWRConfig } from "swr";

export default function EditObject({ object, setStatus }) {

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  
  const [form, setForm] = useState({
    fieldEquipmentName: {
      value: object.fieldEquipmentName || "",
      validate: "true",
    },
    link360: {
      value: object.link360,
      validate: "true",
    },
    comments: {
      value: object.comments || "",
      validate: "true",
    },
    QAQC: {
      value: object.QAQC,
      validate: "true",
    },
  });
  const { mutate } = useSWRConfig();

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (type === "checkbox") {
      setForm((current) => ({
        ...current,
        [name]: {
          ...current[name],
          value: checked,
        },
      }));
      return;
    }

    setForm((current) => ({
      ...current,
      [name]: {
        ...current[name],
        value,
      },
    }));
  };

  const createBody = () => {
    const newForm = {
      fieldEquipmentName: form.fieldEquipmentName.value,
      link360: form.link360.value,
      comments: form.comments.value,
      QAQC: form.QAQC.value,
    };
    return newForm;
  };

  const handleUpdate = async () => {
    const newForm = createBody();
    await updateObjectPg(newForm, object.id);
    setStatus(() => "normal");
    mutate(`${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${object.id}`);
    mutate(`${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/objects-web/${adminCompanyId}`);
  };

  const handleCancel = () => {
    setStatus(() => "normal");
  };

  return (
    <FormContainer>
      <WrapperForm>
        <Label>Field Equipment Name</Label>
        <Input
          type="text"
          name="fieldEquipmentName"
          value={form.fieldEquipmentName.value}
          valid={form.fieldEquipmentName.validate}
          onChange={handleChange}
        />
        <Label>Link 360</Label>
        <Input
          type="text"
          name="link360"
          value={form.link360.value}
          valid={form.link360.validate}
          onChange={handleChange}
        />
        <Label>Comments</Label>
        <Input
          type="text"
          name="comments"
          value={form.comments.value}
          valid={form.comments.validate}
          onChange={handleChange}
        />
        <Label>QAQC:</Label>
        <Input
          className="little"
          type="checkbox"
          name="QAQC"
          checked={form.QAQC.value}
          value={form.QAQC.value}
          onChange={handleChange}
        />
        <Button onClick={handleUpdate}>Update</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </WrapperForm>
    </FormContainer>
  );
}
