import { useNavigate } from "react-router-dom";
import { HubCard } from "./indexStyles";

const MainCard = ({ title, image, description, toNavigate }) => {
  const navigate = useNavigate();
  return (
    <HubCard onClick={() => navigate(toNavigate)}>
      <h1 className="card-title">{title}</h1>
      <img src={image} alt="title" className="card-image" />
      <p className="card-description">{description}</p>
    </HubCard>
  );
};

export default MainCard;
