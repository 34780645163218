import {
  setShowDataComponentsByType,
  setShowDataComponentsLibrary,
} from "redux/actions/digitalTwin";
import deleteDuplicateComponents from "./deleteDuplicateComponents";
import fiendCompoenents from "services/findComponents";
import { PRIMARY_COLOR } from "utils/const";

const setDispatchFilterNewComponentsByType = async (components, dispatch) => {
  const typeComponents = await deleteDuplicateComponents(components);
  const pointLibraryComponents = await fiendCompoenents();

  const dataFilterComponentsLibrary = [];

  await typeComponents.forEach((component) => {
    pointLibraryComponents.forEach((libraryComponent) => {
      if (component.pointLibraryComponentId === libraryComponent.id) {
        const componentLibrary = {
          libraryId: component.pointLibraryComponentId,
          name: libraryComponent.name,
          state: true,
          visible: false,
          color: `${PRIMARY_COLOR}`,
        };
        dataFilterComponentsLibrary.push(componentLibrary);
      }
    });
  });

  await dispatch(
    setShowDataComponentsLibrary(
      dataFilterComponentsLibrary.length !== 0
        ? dataFilterComponentsLibrary
        : []
    )
  );

  const dataFilterComponentsByType = [];
  await typeComponents.forEach((typeComponentId) => {
    const componentType = {
      id: typeComponentId.id,
      name: typeComponentId.name,
      libraryId: typeComponentId.pointLibraryComponentId,
      state: true,
      visible: false,
      icon: typeComponentId.icon
    };
    dataFilterComponentsByType.push(componentType);
  });

  await dispatch(
    setShowDataComponentsByType(
      dataFilterComponentsByType.length !== 0 ? dataFilterComponentsByType : []
    )
  );
};

export default setDispatchFilterNewComponentsByType;
