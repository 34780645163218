import axios from "axios";

const updateLineMg = async (id, regionGeoJson) => {
  const res = await axios.put(
    `${process.env.REACT_APP_URL_MG_API}line/${id}`,
    regionGeoJson
  );
  const data = await res.data;
  return { data, res };
};

export default updateLineMg;
