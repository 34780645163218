import axios from "axios";

const uploadFieldVideo = async (body) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/field-video`,
      body
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default uploadFieldVideo;
