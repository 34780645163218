// Required modules
import NavBar from "components/Admin/NavBar/NavBar";

// Styles
import { ConfigAdminContainer } from "../configAdminStyles";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import { useState } from "react";
import ObjectLibraries from "./ObjectLibraries";

export default function LibraryPoint() {
  // State to current action
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  return (
    <div>
      <NavBar linkLogo={"admin"} />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={executeDialog}
        content={contentDialog}
      />
      <ConfigAdminContainer>
        <ObjectLibraries
          setOpenDialog={setOpenDialog}
          setContentDialog={setContentDialog}
        />
      </ConfigAdminContainer>
    </div>
  );
}
