import Typography from "@mui/material/Typography";
// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// custom style
import {
  useStyles,
  CustomizedAccordion,
} from "./AccordionStyles";

import PointsDigitalTwin from "./Points";

const AccordionDigitalTwin = ({ dataObjects }) => {
  const classes = useStyles();

  return (
    <>
      <CustomizedAccordion
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "1.5rem" }}>Digital Twin</Typography>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        </div>
        <PointsDigitalTwin dataObjects={dataObjects}/>
      </CustomizedAccordion>
    </>
  );
};

export default AccordionDigitalTwin;
