import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Home from "./pages";
import SignIn from "./pages/SignIn";

import "mapbox-gl/dist/mapbox-gl.css";

// Operator pages
import OperatorHub from "./pages/Operator/Hub";
import Collections from "./pages/Operator/Collections";
import FieldData from "./pages/Operator/FieldDataCollection";

// Auth pages
import EmailConfirmation from "./pages/Auth/emailConfirmation";
import PasswordConfirmation from "./pages/Auth/passwordConfirmation";
import ForgotPassword from "./pages/Auth/ForgotPassword";

// Admin decimetrix pages
import AdminDecimetrixHub from "./pages/AdminDecimetrix/Hub";
import AdminCompanies from "./pages/AdminDecimetrix/AdminCompanies";

// Admin company pages
import Profile from "./pages/AdminCompany/profile";
import AdminCompany from "./pages/AdminCompany/Hub";
import Operators from "./pages/AdminCompany/Operators";
import DigitalTwinAdmin from "./pages/AdminCompany/DigitalTwin";
import DashboardsAdmin from "./pages/AdminCompany/Dashboard";
import Chatbot from "./pages/AdminCompany/Chatbot";
import SensorOptimization from "./pages/AdminCompany/SensorOptimization";
import FlareEfficiency from "./pages/AdminCompany/FlareEfficiency";

import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// pages for operator
import VideoManager from "./pages/Operator/VideoManager";
import UploadVideos from "./pages/Operator/UploadVideo";
import Users from "pages/Operator/Users";
// import ConfigAdmin from "pages/ConfigAdmin/ConfigAdmin.jsx";
import ConfigAdmin from "pages/ConfigAdmin";
import ConfigObjects from "pages/ConfigAdmin/Objects";
import ConfigLibrarues from "pages/ConfigAdmin/Libraries";
import ActionsEvents from "pages/ConfigAdmin/ActionsEvents";
// import NewWindowTable from "components/Tables/NewWindowTable";
import ConfigComponents from "pages/ConfigAdmin/Components";
// Terms and conditions pages
import TermsCloud from "pages/TermsAndPrivacy/TermsAndConditions/TermsCloud";
import TermsMobile from "pages/TermsAndPrivacy/TermsAndConditions/TermsMobile";
import TermsSocrates from "pages/TermsAndPrivacy/TermsAndConditions/TermsSocrates";
import PolicyCloud from "pages/TermsAndPrivacy/PrivacyPolicy/PolicyCloud";
import PolicyMobile from "pages/TermsAndPrivacy/PrivacyPolicy/PolicyMobile";
import PolicySocrates from "pages/TermsAndPrivacy/PrivacyPolicy/PolicySocrates";

const App = () => {
  // Validate user
  const { token } = useSelector((state) => state.userReducer.user);
  let role = "None";
  if (token) role = jwt_decode(token).role;

  // Change material ui theme
  const theme = createTheme({
    typography: {
      fontFamily: "Noto Sans",
      fontSize: 22.4,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Any person */}
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/password-reset/:token"
            element={<PasswordConfirmation />}
          />
          <Route
            exact
            path="/confirm-email-address/:token/:id"
            element={<EmailConfirmation />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* Terms and conditions routes */}
          <Route
            path="/terms-and-conditions-cloud-web"
            element={<TermsCloud />}
          />
          <Route
            path="/terms-and-conditions-mobile"
            element={<TermsMobile />}
          />
          <Route
            path="/terms-and-conditions-socrates"
            element={<TermsSocrates />}
          />
          <Route path="/privacy-policy-cloud-web" element={<PolicyCloud />} />
          <Route path="/privacy-policy-mobile" element={<PolicyMobile />} />
          <Route path="/privacy-policy-socrates" element={<PolicySocrates />} />
          {/* Admin Company*/}
          <Route
            path="/admin"
            element={
              role === "Administrador de compañía" ? (
                <AdminCompany />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/profileAdmin"
            element={
              role === "Administrador de compañía" ? (
                <Profile />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/digital-twin-admin"
            element={
              role === "Administrador de compañía" ? (
                <DigitalTwinAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          {/* <Route exact path="/new-window-table" element={<NewWindowTable />} /> */}

          <Route
            path="/operators"
            element={
              role === "Administrador de compañía" ? (
                <Operators />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/dashboard-admin"
            element={
              role === "Administrador de compañía" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/chatbot"
            element={
              role === "Administrador de compañía" ? (
                <Chatbot />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/sensor-optimization"
            element={
              role === "Administrador de compañía" ? (
                <SensorOptimization />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/flare-efficiency"
            element={
              role === "Administrador de compañía" ? (
                <FlareEfficiency />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          {/* Admin */}
          <Route
            path="/admin-decimetrix"
            element={
              role === "Administrador de decimetrix" ? (
                <AdminDecimetrixHub />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/profile-admin-decimetrix"
            element={
              role === "Administrador de decimetrix" ? (
                <Profile />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/digital-twin-admin-decimetrix"
            element={
              role === "Administrador de decimetrix" ? (
                <DigitalTwinAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/admin-companies"
            element={
              role === "Administrador de decimetrix" ? (
                <AdminCompanies />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/dashboard-admin-decimetrix"
            element={
              role === "Administrador de decimetrix" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/dashboard-admin"
            element={
              role === "Administrador de decimetrix" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          {/* Admin decimetrix and Administrador de compañía */}
          <Route
            path="/config-admin"
            element={
              role === "Administrador de decimetrix" ||
              role === "Administrador de compañía" ? (
                <ConfigAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/config-actions-events"
            element={
              role === "Administrador de decimetrix" ||
              role === "Administrador de compañía" ? (
                <ActionsEvents />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/component-configurations"
            element={
              role === "Administrador de decimetrix" ||
              role === "Administrador de compañía" ? (
                <ConfigComponents />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/object-configurations"
            element={
              role === "Administrador de decimetrix" ||
              role === "Administrador de compañía" ? (
                <ConfigObjects />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/library-configurations"
            element={
              role === "Administrador de decimetrix" ||
              role === "Administrador de compañía" ? (
                <ConfigLibrarues />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          {/* Operator */}
          <Route
            path="/digitalTwin"
            element={
              role === "Operario" ? (
                <DigitalTwinAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          <Route
            path="/hub"
            element={
              role === "Operario" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/hub"
              element={
                role === "Operario" ? (
                  <OperatorHub />
                ) : (
                  <Navigate to="/signIn" />
                )
              }
            />
          </Route>
          <Route
            path="/operators-user"
            element={
              role === "Operario" ? <Users /> : <Navigate to="/signIn" />
            }
          />

          <Route
            path="/profile"
            element={
              role === "Operario" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/profile"
              element={
                role === "Operario" ? <Profile /> : <Navigate to="/signIn" />
              }
            />
          </Route>
          <Route
            path="/collections"
            element={
              role === "Operario" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/collections"
              element={
                role === "Operario" ? (
                  <Collections />
                ) : (
                  <Navigate to="/signIn" />
                )
              }
            />
          </Route>
          <Route
            path="/dashboardUser"
            element={
              role === "Operario" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          <Route
            path="/collections/:date"
            element={
              role === "Operario" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/collections/:date"
              element={
                role === "Operario" ? <FieldData /> : <Navigate to="/signIn" />
              }
            />
          </Route>

          <Route
            path="/field-video-manager"
            element={
              role === "Operario" ? <VideoManager /> : <Navigate to="/signIn" />
            }
          />

          <Route
            path="/upload-videos/:userId"
            element={
              role === "Operario" ? <UploadVideos /> : <Navigate to="/signIn" />
            }
          />

          {/* None user */}
          <Route
            path="/signIn"
            element={
              role === "None" ? (
                <SignIn />
              ) : role === "Administrador de compañía" ? (
                <Navigate to="/admin" />
              ) : role === "Administrador de decimetrix" ? (
                <Navigate to="/admin-decimetrix" />
              ) : (
                <Navigate to="/hub" />
              )
            }
          />
          <Route path="*" element={<h1>Page Not Found</h1>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
