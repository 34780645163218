import useSupercluster from "use-supercluster";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OperationDetails from "components/ImageGallery/OperationDetails/OperationDetails";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import AnemometerDetails from "components/AnemometerDetails";
import GenericDialog from "components/Dialogs/GenericDialog";
import {
  setFilterEvents,
  setFilteredOperations,
  setOperations,
} from "redux/actions/digitalTwin";

import EventDetails from "components/ImageGallery/EventDetails/EventDetails";
import { setDataEventPolygon } from "redux/actions/admin";

import MarkerElement from "components/Clusters/MarkerElement";
import MarkerCluster from "components/Clusters/MarkerCluster";

/**
 * Renders the map component with operation clusters and events.
 *
 * @param {Object} operations - an array of operations
 * @param {Object} events - an array of events
 * @param {Object} bounds - the boundaries of the map
 * @param {Function} onSelectPoint - callback function for selecting a point
 * @param {Object} viewState - the current view state of the map
 * @param {Function} setViewState - callback function for setting the view state of the map
 * @param {Array} anemometers - an array of anemometers
 * @return {JSX.Element} the rendered map component
 */
const OperationsClusters = ({
  operations,
  events,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
  anemometers,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [anemometerDetails, setAnemometerDetails] = useState(null);

  const dispatch = useDispatch();

  const showEventInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );
  const showOperationByType = useSelector(
    (state) => state.digitalTwinReducer.showOperationsByType
  );

  const operationClusters = useSelector(
    (state) => state.digitalTwinReducer.operationClusters
  );

  const dataEventFilterTable = useSelector(
    (state) => state.adminReducer.dataEventFilterTable
  );

  const showEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );

  const stateFilterEvents = useSelector(
    (state) => state.digitalTwinReducer.stateFilterEvents
  );

  useEffect(() => {
    dispatch(setFilterEvents(false));
  }, [stateFilterEvents, dispatch]);

  const filterEvents = showEventsByType?.filter((elm) => {
    return elm.state === true;
  });

  const dataCompoents = events?.filter((event) => {
    return filterEvents?.some((eventFilter) => {
      return (
        event.pointTypeEvent.id === eventFilter.id &&
        event.pointTypeEvent.pointLibraryEventId === eventFilter.libraryId &&
        eventFilter.state === true
      );
    });
  });

  let idInspectionTypes = [];
  for (let key in showEventByInspectionType) {
    if (showEventByInspectionType[key] === true) {
      idInspectionTypes.push(parseInt(key));
    }
  }

  const operationsFilterByType = operations.filter((event) => {
    const typeId = event?.typeOperationId;
    const inspectionFlirId = idInspectionTypes.filter((elm) => {
      if (elm === event?.inspectionFlir?.typeId) {
        return true;
      }
      return false;
    });
    if (
      showOperationByType[typeId] &&
      event?.inspectionFlir?.typeId === inspectionFlirId[0]
    ) {
      return true;
    } else {
      return false;
    }
  });

  const allEventsTypes = operationsFilterByType.concat(dataCompoents);

  const eventsFilterByInspectionType = operationsFilterByType.filter(
    (operation) => {
      const type = operation?.inspectionFlir?.typeId;
      if (showEventInspectionType[type] || operation.typeOperationId !== 2)
        return true;
      return false;
    }
  );

  const eventToPoint = (event, type) => ({
    type: "Feature",
    properties: {
      cluster: false,
      event: {
        ...event,
        id: event.id,
        type: type,
        icon:
          type === "operation"
            ? event.typeOperation.urlIcon
            : event.pointTypeEvent.icon,
        location: {
          latitude: event.locationOperation.latitude,
          longitude: event.locationOperation.longitude,
        },
        inspectionFlir: type === "operation" ? event.inspectionFlir : undefined,
      },
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(event.locationOperation.longitude),
        parseFloat(event.locationOperation.latitude),
      ],
    },
  });

  const filteredOperations = eventsFilterByInspectionType.map((operation) =>
    eventToPoint(operation, "operation")
  );

  const filteredEvents = dataCompoents?.map((event) =>
    eventToPoint(event, "event")
  );

  const operationsPoints = operations.map((operation) =>
    eventToPoint(operation, "operation")
  );

  const eventsPoints = events.map((event) => eventToPoint(event, "event"));

  useEffect(() => {
    dispatch(setOperations([...operationsPoints, ...eventsPoints]));
    dispatch(setDataEventPolygon(allEventsTypes));
  }, [operationsPoints, dispatch, eventsPoints, allEventsTypes]);

  useEffect(() => {
    dispatch(setFilteredOperations([...filteredOperations, ...filteredEvents]));
  }, [filteredOperations, dispatch, filteredEvents]);

  const pointEvents = useMemo(
    () => [...filteredOperations, ...filteredEvents],
    [filteredOperations, filteredEvents]
  );

  const { clusters } = useSupercluster({
    points:
      dataEventFilterTable.length !== 0 ? dataEventFilterTable : pointEvents,
    bounds,
    zoom: viewState.zoom,
    options: {
      radius: operationClusters.radius || 0,
      maxZoom: operationClusters.maxZoom || 0,
    },
  });

  const HandleClickOperation = useCallback(
    async (operationId) => {
      const operation = {
        content: <OperationDetails operationId={operationId} />,
        title: "Event Details",
      };
      dispatch(setOperationDetails(operation));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );

  const handleClickEvent = useCallback(
    (eventId) => {
      const event = {
        content: <EventDetails eventId={eventId} />,
        title: "Event Details",
      };
      dispatch(setOperationDetails(event));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );

  const HandleClickAnemometer = (anemometer) => {
    setOpenModal(true);
    setAnemometerDetails(anemometer);
  };

  const renderMarkerOperation = useCallback(
    (event) => {
      const { id, type } = event;
      return (
        <div key={type + id}>
          {
            <>
              <MarkerElement
                element={event}
                handleClick={() =>
                  type === "operation"
                    ? HandleClickOperation(id)
                    : handleClickEvent(id)
                }
              />

              {anemometers.length > 0 &&
                anemometers.map((anemometer) => {
                  return (
                    anemometer?.operationAnemometer?.inspectionFlirId ===
                      event?.inspectionFlir?.id && (
                      <MarkerElement
                        element={{
                          id: event?.inspectionFlir?.id,
                          location: {
                            latitude: Number(
                              anemometer?.locationAnemometer?.latitude || 0
                            ),
                            longitude: Number(
                              anemometer?.locationAnemometer?.longitude || 0
                            ),
                          },
                          icon: "https://icons-greendragon.s3.us-east-2.amazonaws.com/type_elements_events/anemometerIconMap.png",
                        }}
                        handleClick={() => HandleClickAnemometer(anemometer)}
                      />
                    )
                  );
                })}
            </>
          }
        </div>
      );
    },
    [HandleClickOperation, anemometers, handleClickEvent]
  );

  const MarkersCluster = useMemo(
    () =>
      clusters.length > 0 &&
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const dataCluster = {
          id: cluster.id,
          location: {
            latitude,
            longitude,
          },
          pointCount,
          elementsCount: pointEvents.length,
          type: "operation",
        };
        if (isCluster) {
          return (
            <MarkerCluster
              cluster={dataCluster}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        }
        return renderMarkerOperation(cluster.properties.event);
      }),
    [
      clusters,
      renderMarkerOperation,
      onSelectPoint,
      pointEvents,
      setViewState,
      viewState,
    ]
  );

  return (
    <>
      <GenericDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Anemometer Details",
          content: <AnemometerDetails anemometerDetails={anemometerDetails} />,
        }}
        style={{
          margin: 0,
          padding: 0,
          width: "70%",
          maxWidth: "none",
        }}
      />
      {MarkersCluster}
    </>
  );
};

export default OperationsClusters;
