import axios from "axios";

const deleteLine = async (id) => {
  try {
    const token = localStorage.getItem("token");

    // delete pg line
    const responsePg = await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/lines?geojsonId=${id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    // delete mg line
    const responseMg = await axios.delete(
      `${process.env.REACT_APP_URL_MG_API}line/${id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );

    const response = {
      responseMg: await responseMg.data,
      responsePg: await responsePg.data,
    };
    return response;
  } catch (error) {
    return { error };
  }
};

export default deleteLine;
