// React
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// Components
import DynamicFields from "components/Forms/SaveComponent/DynamicFields";
import SelectInput from "components/Forms/SelectInput";
// Style
import { FeatureDetails } from "components/Forms/SaveObject/indexStyle";
import { WraperTitleCardMarker } from "components/ImageGallery/ImageDetailsElements";
// Hooks
import { useFetchComponentsLibraries } from "hooks/fetchLibraries";

const CreateComponent = ({ feature, objectId, closeModal, typeComponents }) => {

  const [type, setType] = useState();
  const [currentComponentType, setCurrentComponentType] = useState("");
  const [currentLibraryType, setCurrentLibraryType] = useState("");

  const [libraryType, setLibraryType] = useState({
    name: "Library type",
    options: [],
    id: 0,
  });
  const [componentType, setComponentType] = useState({
    name: "Type component",
    options: [],
    icons: [],
    id: 0,
  });

  const { data: typeComponentLibraries, errorTypeComponentLibraries } =
    useFetchComponentsLibraries();

  useEffect(() => {
    const librarieIds = typeComponents.map(
      (component) => component.pointTypeComponent.pointLibraryComponentId
    );

    var typeIcons = typeComponents.map((elm) => {
      const library =
        !errorTypeComponentLibraries &&
        typeComponentLibraries?.find((library) => {
          return elm.pointTypeComponent.pointLibraryComponentId === library.id;
        });
      return {
        name: elm.pointTypeComponent.name,
        icon: elm.pointTypeComponent.icon,
        library: library?.name,
      };
    });

    const currentLibraryTypes = [];
    const currentComponentTypes = [];

    !errorTypeComponentLibraries &&
      typeComponentLibraries?.map((library) => {
        if (librarieIds?.includes(library.id)) {
          currentLibraryTypes.push(library.name);
        }
      });

    typeComponents.map((elm) => {
      !errorTypeComponentLibraries &&
        typeComponentLibraries?.map((elm2) => {
          if (
            !errorTypeComponentLibraries &&
            typeComponentLibraries &&
            elm.pointTypeComponent.pointLibraryComponentId === elm2.id
          ) {
            if (
              currentComponentTypes.length < 1 &&
              elm2.name === currentLibraryTypes[0]
            ) {
              currentComponentTypes.push(elm.pointTypeComponent.name);
            }
          }
        });
    });
    setComponentType((prev) => ({
      ...prev,
      options: currentComponentTypes,
      icons: typeIcons,
    }));

    setLibraryType((prev) => ({
      ...prev,
      options: currentLibraryTypes,
    }));

    setCurrentComponentType(currentComponentTypes[0]);
    setCurrentLibraryType(currentLibraryTypes[0]);
    const currentType = typeComponents.find((elm) => {
      return elm.pointTypeComponent.name === currentComponentTypes[0];
    });
    setType(currentType?.pointTypeComponent.id);
  }, [feature, objectId, closeModal, typeComponents, typeComponentLibraries]);

  const { register, handleSubmit } = useForm();

  const handleSelectLibrary = (data) => {
    setCurrentLibraryType(data["Library type"]);
    const currentLibrary = typeComponentLibraries.find(
      (event) => event.name === data["Library type"]
    );
    const currentLibraryOptions = [];
    const currentLibraryIds = [];
    typeComponents.forEach((elm) => {
      if (
        elm.pointTypeComponent.pointLibraryComponentId === currentLibrary.id
      ) {
        currentLibraryOptions.push(elm.pointTypeComponent.name);
        currentLibraryIds.push(elm.pointTypeComponent.id);
      }
    });
    setComponentType({ ...componentType, options: currentLibraryOptions });
    setType(currentLibraryIds[0]);
    setCurrentComponentType(currentLibraryOptions[0]);
  };

  const handleSelectTypeEvent = (data) => {
    setCurrentComponentType(data["Library type"]);
    typeComponents.forEach((event) => {
      if (event.pointTypeComponent.name === data["Type component"]) {
        setType(event.pointTypeComponent.id);
        setCurrentComponentType(data["Type component"]);
      }
    });
  };

  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {componentType?.icons.map((event) => {
            return (
              event.name === currentComponentType &&
              event.library === currentLibraryType && (
                <img className="dynamicImg" src={event.icon} alt="Decimetrix" />
              )
            );
          })}
          <h2>Components</h2>
        </div>
      </WraperTitleCardMarker>
      <div onChange={handleSubmit(handleSelectLibrary)}>
        <SelectInput field={libraryType} register={register} />
      </div>
      <div onChange={handleSubmit(handleSelectTypeEvent)}>
        <SelectInput field={componentType} register={register} />
      </div>
      {type && (
        <DynamicFields
          closeModal={closeModal}
          objectId={objectId}
          feature={feature}
          pointTypeComponentId={type}
        />
      )}
    </FeatureDetails>
  );
};

export default CreateComponent;
