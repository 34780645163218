import styled from "styled-components";

export const CustomDiv = styled("div")`
  align-items: "center";
  margin-left: 30%;
  margin-top: 10%;
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #0e4d45;
    color: white;
  }
`;

export const MapCardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  padding-top: 90px;
`;

export const MapWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  grid-gap: 5px;
  width: 90%;
  align-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MapH1 = styled.h1`
  font-size: 2.5rem;
  color: #31a636;
  margin-bottom: 10px;
  margin: 10px;
  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const MapCard = styled.div`
  position: relative;
  align-content: stretch;
  overflow: hidden;
  object-fit: cover;
  object-fit: fill;
  height: 100%;
  width: 100%;
  /* width: 80%; */
  /* object-fit: fill; */
  display: flex;
  flex-direction: column;
  /* justify-content: top; */
  /* align-items: center; */
  background-color: #fff;
  /* margin-bottom: 100px; */
  /* padding: 10px 10px; */
  padding: 0;
  margin: 0;
  /* padding-right: 10px; */
  /* padding-left: 10px;  */
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
  /* box-shadow: 5px 3px 6px #00000066; */
  /* box-shadow: 5px 3px 6px #00000066; */
`;

export const ButtonCard = styled.button`
  width: 200px;
  height: 60px;
  border-radius: 40px;
  border: none;
  background-color: rgb(14, 77, 69);
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    opacity: 0.8;
    z-index: 10;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

export const AnalysisCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  max-height: 640px;
  max-width: 320px;

  /* padding: 30px; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const WrapperButtons = styled.div`
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const FormInput = styled.input`
  margin-left: 10px;
  width: 100%;
  border: none;
  background-color: #fff;
  color: rgb(61, 61, 61);
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
`;
