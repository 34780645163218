const LicenseGrant = () => {
  return (
    <>
      <p className="text">
        1.1 Decimetrix grants you a non-exclusive, non-transferable, revocable
        license to download, install, and use the Application on a compatible
        mobile device solely for your own internal business purposes.
      </p>
      <p className="text">
        1.2 You acknowledge that Decimetrix retains all rights, title, and
        interest in the Application, including any intellectual property rights
        associated with it. You shall not reproduce, modify, distribute, sell,
        lease, sublicense, or otherwise exploit the Application or any part
        thereof without Decimetrix's prior written consent.
      </p>
    </>
  );
};
const UserObligations = () => {
  return (
    <>
      <p className="text">
        2.1 You agree to use the Application in compliance with applicable laws
        and regulations and in accordance with this Agreement.
      </p>
      <p className="text">
        2.2 You are responsible for maintaining the security of your mobile
        device and ensuring that unauthorized individuals do not gain access to
        the Application through your device.
      </p>
      <p className="text">
        2.3 You shall not use the Application in any manner that may interfere
        with its proper functioning or disrupt the experience of other users.
      </p>
      <p className="text">
        2.4 You agree not to reverse engineer, decompile, disassemble, or
        attempt to derive the source code of the Application, except to the
        extent expressly permitted by applicable law.
      </p>
    </>
  );
};
const DataCollection = () => {
  return (
    <>
      <p className="text">
        3.1 By using the Application, you consent to the collection, storage,
        and use of your personal information and data as described in our
        Privacy Policy.
      </p>
      <p className="text">
        3.2 You acknowledge that certain features of the Application may require
        access to your device's camera, location data, or other hardware and
        that such access may be required for the proper functioning of the
        Application.
      </p>
    </>
  );
};
const IntellectualProperty = () => {
  return (
    <>
      <p className="text">
        4.1 The Application, including all content and materials provided
        therein, is protected by copyright, trademark, and other intellectual
        property laws. All rights not expressly granted herein are reserved by
        Decimetrix.
      </p>
      <p className="text">
        4.2 You shall not remove, alter, or obscure any copyright, trademark, or
        other proprietary notices incorporated in or accompanying the
        Application.
      </p>
    </>
  );
};
const DisclaimerOfWarranty = () => {
  return (
    <>
      <p className="text">
        5.1 The Application is provided on an "as is" and "as available" basis
        without warranties of any kind, whether express or implied.
      </p>
      <p className="text">
        5.2 Decimetrix does not warrant that the Application will be error-free,
        uninterrupted, or free from viruses or other harmful components. You
        acknowledge that your use of the Application is at your own risk.
      </p>
    </>
  );
};
const LimitationOfLiability = () => {
  return (
    <>
      <p className="text">
        6.1 To the maximum extent permitted by applicable law, Decimetrix and
        its officers, directors, employees, affiliates, and agents shall not be
        liable for any indirect, incidental, special, consequential, or punitive
        damages arising out of or in connection with the use of the Application,
        even if Decimetrix has been advised of the possibility of such damages.
      </p>
      <p className="text">
        6.2 In no event shall Decimetrix's total liability to you for all
        claims, whether in contract, tort, or otherwise, exceed the amount paid
        by you, if any, for accessing or using the Application.
      </p>
    </>
  );
};
const Termination = () => {
  return (
    <>
      <p className="text">
        7.1 Decimetrix reserves the right to terminate or suspend your access to
        the Application at any time and for any reason without prior notice.
      </p>
      <p className="text">
        7.2 Upon termination, all licenses and rights granted to you under this
        Agreement will immediately cease, and you must promptly uninstall and
        delete the Application from your mobile device.
      </p>
    </>
  );
};

const ChangesAgreement = () => {
  return (
    <p className="text">
      8.1 Decimetrix may modify or update this Agreement at any time by posting
      the revised terms within the Application or on Decimetrix's website. Your
      continued use of the Application after the posting of any changes
      constitutes your acceptance of such changes.
    </p>
  );
};

const GoverningLaw = () => {
  return (
    <>
      <p className="text">
        9.1 This Agreement shall be governed by and construed in accordance with
        the laws of the jurisdiction in which Decimetrix is located, without
        regard to its conflict of laws principles.
      </p>
      <p className="text">
        9.2 Any dispute arising out of or relating to this Agreement shall be
        resolved exclusively through binding arbitration in accordance with the
        rules of the arbitration institution mutually agreed upon by the
        parties.
      </p>
    </>
  );
};
const Miscellaneous = () => {
  return (
    <>
      <p className="text">
        10.1 This Agreement constitutes the entire agreement between you and
        Decimetrix concerning the subject matter hereof and supersedes any prior
        or contemporaneous negotiations, discussions, or agreements, whether
        written or oral.
      </p>
      <p className="text">
        10.2 If any provision of this Agreement is found to be invalid or
        unenforceable, such provision shall be severed from the Agreement, and
        the remaining provisions shall remain in full force and effect.
      </p>
    </>
  );
};

const Indemnification = () => {
  return (
    <p className="text">
      11.1 You agree to indemnify, defend, and hold harmless Decimetrix and its
      officers, directors, employees, affiliates, and agents from and against
      any claims, liabilities, damages, losses, costs, or expenses (including
      reasonable attorneys' fees) arising out of or related to your use of the
      Application, your violation of any provision of this Agreement, or your
      infringement of any third-party rights.
    </p>
  );
};
const ThirdPartyService = () => {
  return (
    <p className="text">
      12.1 The Application may integrate with or provide access to third-party
      services, websites, or content. Decimetrix is not responsible for the
      availability, accuracy, legality, or any other aspect of such third-party
      services, websites, or content. Your use of any third-party services,
      websites, or content is subject to the terms and conditions and privacy
      policies of those third parties.
    </p>
  );
};

const Survival = () => {
  return (
    <p className="text">
      13.1 The provisions of Sections 4 (Intellectual Property), 5 (Disclaimer
      of Warranty), 6 (Limitation of Liability), 7 (Termination), 9 (Governing
      Law and Dispute Resolution), and 11 (Indemnification) shall survive any
      termination or expiration of this Agreement.
    </p>
  );
};
const Waiver = () => {
  return (
    <p className="text">
      14.1 The failure of Decimetrix to enforce any right or provision of this
      Agreement shall not constitute a waiver of such right or provision unless
      acknowledged and agreed to by Decimetrix in writing.
    </p>
  );
};

const ContactInformation = () => {
  return (
    <p className="text">
      15.1 If you have any questions or concerns regarding this Agreement or the
      Application, please contact us at business@decimetrix.com or
      www.decimetrix.com
    </p>
  );
};

const Acknowledgment = () => {
  return (
    <p className="text">
      By downloading, accessing, or using the Application, you acknowledge that
      you have read, understood, and agree to be bound by this Agreement. If you
      do not agree with any part of this Agreement, you should not download,
      access, or use the Application.
    </p>
  );
};

export const dataMobile = {
  title: "Decimetrix® Reality Capture Mobile Solution Terms and Conditions",
  mainText:
    'These Terms and Conditions ("Agreement") govern the use of the Decimetrix® Reality Capture mobile solution ("Application") provided by Decimetrix, Inc. ("Decimetrix," "we," or "us") and the rights and obligations between Decimetrix and the users ("User," "you," or "your") of the Application. By downloading, accessing, or using the Application, you agree to be bound by the terms and conditions set forth in this Agreement.',
  info: [
    {
      subtitle: "License Grant",
      text: <LicenseGrant />,
    },
    {
      subtitle: "User Obligations",
      text: <UserObligations />,
    },
    {
      subtitle: "Data Collection and Privacy",
      text: <DataCollection />,
    },
    {
      subtitle: "Intellectual Property",
      text: <IntellectualProperty />,
    },
    {
      subtitle: "Disclaimer of Warranty",
      text: <DisclaimerOfWarranty />,
    },
    {
      subtitle: "Limitation of Liability",
      text: <LimitationOfLiability />,
    },
    {
      subtitle: "Termination",
      text: <Termination />,
    },
    {
      subtitle: "Changes to the Agreement",
      text: <ChangesAgreement />,
    },
    {
      subtitle: "Governing Law and Dispute Resolution",
      text: <GoverningLaw />,
    },
    {
      subtitle: "Miscellaneous",
      text: <Miscellaneous />,
    },
    {
      subtitle: "Indemnification",
      text: <Indemnification />,
    },
    {
      subtitle: "Third-Party Services and Content",
      text: <ThirdPartyService />,
    },
    {
      subtitle: "Survival",
      text: <Survival />,
    },
    {
      subtitle: "Waiver",
      text: <Waiver />,
    },
    {
      subtitle: "Contact Information",
      text: <ContactInformation />,
    },
    {
      subtitle: "Acknowledgment",
      text: <Acknowledgment />,
    },
  ],
};
