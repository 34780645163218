// Required modules
import { FaCircle } from "react-icons/fa";
import { BsHexagon } from "react-icons/bs";
import { SECOND_COLOR } from "utils/const";

// Heafer to type regions table
export const headersTableTypeRegions = [
  "Id",
  "Type",
  "Description",
  "Border",
  "Background",
  "Opacity",
  "Width",
  // "Created At",
];

// Header to type Elements GHG
export const headersTableTypeElementsGhg = [
  "Id",
  "Icon",
  "Name",
  // "Icon Gray",
  "Created At",
];

// Header to type Elements Events
export const headersTableTypeElementsEvents = [
  "Id",
  "Icon",
  "Name",
  "Created At",
];

// Header to type lines
export const headersTableTypeLine = [
  "Id",
  "Type",
  "Description",
  "Color",
  "Width",
  "Separation",
  "Created At",
];

// Header to library point
export const headersTableLibraryPoint = [
  "Id",
  "Name",
  // "Type library",
  "Created At",
];
// Header to library lines
export const headersTableLibraryLine = ["Id", "Name", "Created At"];
// Header to library polygon
export const headersTableLibraryPolygon = ["Id", "Name", "Created At"];

// Key values to type regions table
export const keyValuesTableTypeRegions = [
  "id",
  "type",
  "description",
  "border",
  "background",
  "opacity",
  "width",
  // "createdAt",
];

// Key values to type elements GHG table
export const keyValuesTableTypeElementsGhg = [
  "id",
  "urlIconColor",
  "name",
  // "urlIconGray",
  "createdAt",
];

// Key values to type elements Events
export const keyValuesTableTypeElementsEvents = [
  "id",
  "icon",
  "name",
  "createdAt",
];

// Key values to type lines table
export const keyValuesTableTypeLine = [
  "id",
  "type",
  "description",
  "color",
  "dasharrayWidth",
  "dasharrayPixels",
  "createdAt",
];

// Key values to library point table
export const keyValuesTableLibraryPoint = [
  "id",
  "name",
  // "typeLibraryId",
  "createdAt",
];
// Key values to library point table
export const keyValuesTableLibraryLine = ["id", "name", "createdAt"];
// Key values to library point table
export const keyValuesTableLibraryPolygon = ["id", "name", "createdAt"];

// Initial values with name and value
export const initialValuesFormRegionType = {
  type: {
    value: "",
    validate: "true",
    type: "text",
    label: "Type",
    placeholder: "Facility...",
    name: "type",
    legendError: "Type is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  description: {
    value: "",
    validate: "true",
    type: "text",
    label: "Description",
    placeholder: "Many objects...",
    name: "description",
    legendError: "Description is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  border: {
    value: "#31A636",
    validate: "true",
    type: "color",
    label: "Border Color",
    name: "border",
  },
  background: {
    value: "#0E4D45",
    validate: "true",
    type: "color",
    label: "Background Color",
    name: "background",
  },
  opacity: {
    value: "0.5",
    validate: "true",
    type: "range",
    label: "Opacity",
    name: "opacity",
    min: "0",
    max: "1",
    step: "0.01",
  },
  width: {
    value: "1",
    validate: "true",
    type: "range",
    label: "Width",
    name: "width",
    min: "1",
    max: "3",
    step: "0.01",
  },
};

// Initial values to type element ghg
export const initialValuesFormTypeElementGhg = {
  name: {
    value: "",
    validate: "true",
    type: "text",
    label: "Name",
    placeholder: "name...",
    name: "name",
    legendError: "Name is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  urlIconColor: {
    value: "",
    validate: "true",
    type: "image",
    label: "Icon",
    name: "urlIconColor",
    regularExpression: /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIFSVG|svg)$/i,
    // validation: (value) => validateImage(value),
  },
  fields: {
    value: [],
    validate: "true",
    name: "fields",
  },
};

// Initial values to type element Events
export const initialValuesFormTypeElementEvents = {
  name: {
    value: "",
    validate: "true",
    type: "text",
    label: "Name",
    placeholder: "name...",
    name: "name",
    legendError: "Name is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  icon: {
    value: "",
    validate: "true",
    type: "image",
    label: "Icon",
    name: "icon",
    regularExpression: /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIFSVG|svg)$/i,
    // validation: (value) => validateImage(value),
  },
  fields: {
    value: [],
    validate: "true",
    name: "fields",
  },
};
// Initial values to type element Components
export const initialValuesFormTypeElementComponents = {
  name: {
    value: "",
    validate: "true",
    type: "text",
    label: "Name",
    placeholder: "name...",
    name: "name",
    legendError: "Name is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  icon: {
    value: "",
    validate: "true",
    type: "image",
    label: "Icon",
    name: "icon",
    regularExpression: /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIFSVG|svg)$/i,
  },
};

// Initial values to type line
export const initialValuesFormTypeLine = {
  type: {
    value: "",
    validate: "true",
    type: "text",
    label: "Type",
    placeholder: "Type Line...",
    name: "type",
    legendError: "Type is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  description: {
    value: "",
    validate: "true",
    type: "text",
    label: "Description",
    placeholder: "Description Type Line...",
    name: "description",
    legendError: "Description is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,1000}$/,
  },
  color: {
    value: "#31A636",
    validate: "true",
    type: "color",
    label: "Color",
    name: "color",
  },
  dasharrayWidth: {
    value: "1",
    validate: "true",
    type: "range",
    label: "Width",
    name: "dasharrayWidth",
    min: "1",
    max: "3",
    step: "0.01",
  },
  dasharrayPixels: {
    value: "1",
    validate: "true",
    type: "range",
    label: "Separation",
    name: "dasharrayPixels",
    min: "0",
    max: "3",
    step: "0.01",
  },
};

// Initial values to library point
export const initialValuesFormLibraryPoint = {
  name: {
    value: "",
    validate: "true",
    type: "text",
    label: "Name",
    placeholder: "Name...",
    name: "name",
    legendError: "Name is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  typeLibrary: {
    type: "select",
    options: [
      { id: 1, name: "Parametrized" },
      { id: 2, name: "Generic" },
    ],
    label: "Type Library",
    name: "typeLibrary",
    legendError: "Name is required",
  },
  fields: {
    value: [],
    validate: "true",
    name: "fields",
  },
};
// Initial values to library line
export const initialValuesFormLibraryLine = {
  name: {
    value: "",
    validate: "true",
    type: "text",
    label: "Name",
    placeholder: "Name...",
    name: "name",
    legendError: "Name is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
};
// Initial values to library polygon
export const initialValuesFormLibraryPolygon = {
  name: {
    value: "",
    validate: "true",
    type: "text",
    label: "Name",
    placeholder: "Name...",
    name: "name",
    legendError: "Name is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
};

// Circle icon
const circle = (green) => (
  <span
    style={{
      color: green ? SECOND_COLOR : "#000",
    }}
  >
    <FaCircle />
  </span>
);

// Line icon
const line = (green) => (
  <span
    style={{
      width: "100px",
      height: "3px",
      background: green ? SECOND_COLOR : "#000",
    }}
  ></span>
);

// Hexagon icon
const hexagon = (green) => (
  <span
    style={{
      fontSize: "15px",
      color: green ? SECOND_COLOR : "#000",
    }}
  >
    <BsHexagon />
  </span>
);

// Columns of Config Admin Page
export const columnsConfigAdmin = [
  {
    title: "Field Collection Digital Twin",
    items: [
      {
        text: "Add points to existing Collection library (s)",
        button: "Add Collection Points",
        icon: circle(),
        url: "/config-type-elemts",
      },
      {
        text: "Add Lines to existing Collection library (s)",
        button: "Add Collection Lines",
        icon: line(),
        url: "/config-type-lines",
      },
      {
        text: "Add Regions to existing Collection library (s)",
        button: "Add Collection Region(s) I Polygon(s)",
        icon: hexagon(),
        url: "/config-type-regions",
      },
    ],
  },
  {
    title: "Parametrized Digital Twin",
    items: [
      {
        text: "Create object points with specific parameters in library (s)",
        button: "Create Object Point",
        icon: circle(true),
      },
      {
        text: "Create object lines with specific parameters in system library",
        button: "Create Object Lines",
        icon: line(true),
      },
      {
        text: "Create object Polygons with specific parameters in system library",
        button: "Create Object Polygons",
        icon: hexagon(true),
      },
    ],
  },
  {
    title: "Digital Twin System Libraries",
    items: [
      {
        text: "Add Digital Twin System Field Collection Point Library",
        button: "Add Collection Point Library",
        icon: circle(),
        url: "/config-library-point",
      },
      {
        text: "Add Digital Twin System Field Collection Line Library",
        button: "Add Collection Line Library",
        icon: line(),
        url: "/config-library-line",
      },
      {
        text: "Add Digital Twin System Field Collection Region Library",
        button: "Add Collection Region (Polygon) Library",
        icon: hexagon(),
        url: "/config-library-region",
      },
    ],
  },
  {
    title: "Digital Twin System Actions and Events",
    items: [
      {
        text: "Add Actions I Events points to Point, Lines or Polygon system Library (s)",
        button: "Add Actions I Events points",
        icon: circle(true),
        url: "/config-actions-events",
      },
      {
        text: "Create Specific Actions I Events to System Objects or Operation",
        button: "Create Actions I Events lines",
        icon: line(true),
      },
      {
        text: "Create Action I Events Polygon(s) that interact with Digital Twin Objects",
        button: "Create Action I Events Polygon(s)",
        icon: hexagon(true),
      },
    ],
  },
];
