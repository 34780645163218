import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;
  width: 100%;

  .required {
    margin: 0 3px;
    color: #d60000;
  }

  .label {
    width: 100%;
    margin: 5px 0;
    display: flex;
  }

  .input {
    font-size: 1.4rem;
    margin: 0;
    padding: 1 10px;
    height: 30px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${PRIMARY_COLOR};
    background-color: #ffffff;
  }
  .divformathfield {
    margin: 0;
    padding: 1 10px;
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
  }

  .message {
    font-weight: 400;
    color: #d60000;
    margin: 0;
  }

  math-field {
    width: 100%;
    min-height: 50px;
    --caret-color: #000;
    --selection-background-color: rgba(49, 166, 55, 0.5);
    font-size: 20px;
    border-radius: 8px;
    text-align: center;
    padding-left: 3%;
  }
`;
