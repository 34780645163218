import { FaLinkedin, FaEnvelope } from "react-icons/fa";

import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  WebSiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import Img from "images/decimetrix_net_zero.png";

const Footer = () => {
  return (
    <FooterContainer id="connect">
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <img src={Img} width="250" alt="logo-footer"></img>
            <WebSiteRights>
              <ul> © {new Date().getFullYear} WebSite. All Rights Reserved.</ul>
              <ul>
                <a href="/terms-and-conditions-cloud-web" target="_blank">
                  Terms and Conditions
                </a>
              </ul>
              <ul>
                <a href="/privacy-policy-cloud-web" target="_blank">
                  Privacy policy
                </a>
              </ul>
              <ul>
                Decimetrix<sup>®</sup> 2023.
              </ul>
            </WebSiteRights>
            <SocialIcons>
              <SocialIconLink
                href="mailto:connect@decimetrix.com"
                aria-label="Mail"
              >
                <FaEnvelope />
              </SocialIconLink>
              <SocialIconLink
                href="https://co.linkedin.com/company/decimetrix"
                target="_blanck"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
