import { Grid } from "@mui/material";
import { Container } from "./styles";

export default function SmallCard({ title, content }) {
  return (
    <Container>
      <h2 className="title">{title}</h2>
      <p className="description">{content}</p>
    </Container>
  );
}
