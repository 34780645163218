import axios from "axios";
import { config } from "config.js";

/**
 * Counts the number of events associated with a given object ID.
 *
 * @param {Object} params - The parameters for counting events.
 * @param {string} params.objectId - The ID of the object.
 * @return {Promise<number>} - A promise that resolves to the number of events.
 */
const countEventsByObjectId = async ({ objectId }) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    `${config.URL_BACKEND_PG}api/v1/objects/count/object/${objectId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const amount = await response.data;
  return amount;
};

export default countEventsByObjectId;
