import { useState } from "react";
import DialogInformation from "components/Dialogs/DialogInformation.jsx";
import { ContentDatePicker } from "components/ContentDialogs/ContentDatePicker";
import { ButtonStyle } from "./DatePickerStyle";
import { FilterTabStyles } from "./AccordionStyles";

function DatePicker(props) {
  const { startdate, enddate, value, changefunction } = props;
  const [information, setInfoObjects] = useState(false);

  const handleInformationObjects = () => {
    setInfoObjects(true);
  };

  return (
    <>
      <DialogInformation
        openDialog={information}
        setOpenDialog={setInfoObjects}
        content={ContentDatePicker(
          startdate,
          enddate,
          changefunction,
          setInfoObjects
        )}
      />
      <FilterTabStyles>
        <div className="date" style={{ width: "100%" }}>
          <p className="date" style={{ textAlign: "center", width: "200px" }}>
            {" "}
            {value}{" "}
          </p>
        </div>
      </FilterTabStyles>
      <ButtonStyle>
        <button onClick={handleInformationObjects} style={{ width: "100%" }}>
          Select date
        </button>
      </ButtonStyle>
    </>
  );
}

export default DatePicker;
