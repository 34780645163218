import styled from "styled-components";

export const ProfileStyle = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 100px;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;

  .title-profile {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
  }

  @media screen and (max-width: 250px) {
    margin-top: 400px;
    height: 160vh;
  }
`;
