import axios from "axios";
import timeZoneOffset from "services/timeZoneOffset";

export const createPointEvent = (
  feature,
  info,
  objectId,
  pointComponentId,
  pointTypeEventId
) => {
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { coordinates } = feature.geometry;
  const [lng, lat] = coordinates;

  return {
    attributes: Object.keys(info).map((value) => {
      return {
        name: value,
        value: info[value],
      };
    }),
    locationOperation: {
      longitude: lng,
      latitude: lat,
    },
    pointTypeEventId,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneOffset: timeZoneOffset(),
    date: new Date(),
    objectId,
    pointComponentId,
    userId: parseInt(userId),
    adminCompanyId,
  };
};

export const savePointEvenRelation = async (existEventId, newEventId) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/point-events/relation`,
      {
        existEventId,
        newEventId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

const savePointEventApiPg = async (pointEvent) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/point-events`,
      pointEvent,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default savePointEventApiPg;
