import NavBar from "components/NavBar/index";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";

import ViewSection from "./ViewSection";
import ImageGallery from "components/ImageGallery";
import MapCollection from "components/MapCollection";

import {
  ViewModeContainer,
  FieldDataContainer,
} from "./FieldDataCollectionElements";
import { useParams } from "react-router-dom";

const FieldDataCollection = () => {
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "hub",
    menuElements: {
      collections: "collections",
    },
    button: {
      link: "collections",
      text: "Back",
    },
  };

  const { date } = useParams();

  const section = useSelector(
    (state) => state.collectionReducer.sectionCollection
  );

  return (
    <>
      <NavBar configNavbar={configNavbar} />
      <Container
        style={{
          paddingTop: 80,
        }}
        maxWidth="xl"
      >
        <FieldDataContainer>
          <ViewModeContainer>
            <ViewSection />
          </ViewModeContainer>
          {section === "gallery" && <ImageGallery date={date} />}
          {section === "map" && <MapCollection date={date} />}
        </FieldDataContainer>
      </Container>
    </>
  );
};

export default FieldDataCollection;
