const findSensors = async (body) => {
  const response = await fetch(
    `${process.env.REACT_APP_URL_OCR_API}findpositions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const data = await response.json();
  return { response, data };
};

export default findSensors;
