import axios from "axios";

const fieldVideosWithoutEventByUserId = async (userId) => {
  const token = localStorage.getItem("token");
  const result = await axios.get(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/field-video/userId/${userId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await result.data;
  return data;
};

export default fieldVideosWithoutEventByUserId;
