// Required modules
import GenericTable from "components/Tables/GenericTable";
import Form from "components/Forms/Form.jsx";

// Config admin class
import ConfigAdmin from "../ConfigAdmin.js";

// Hooks
import { useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableLibraryPoint,
  keyValuesTableLibraryPoint,
  initialValuesFormLibraryPoint,
} from "../dataConfigAdmin";

// Styles
import {
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  SeparatorWithText,
  InformationShare,
  IconAdminConfigChangeColum,
} from "../configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { BsChevronCompactUp } from "react-icons/bs";
import { ContainerForm } from "components/Forms/FormStyles";
import Fields from "pages/ConfigAdmin/typeElemts/Fields.jsx";
import { TYPE_FIELDS } from "utils/constStrings.jsx";
// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <BsChevronCompactUp />
    </IconAdminConfigChangeColum>
  );
};

export default function ComponentLibraries({
  setOpenDialog,
  setContentDialog,
}) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormLibraryPoint);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);
 // State button loadingCreate
 const [loadingCreate, setLoadingCreate] = useState(false);
 const [loadingDelete, setLoadingDelete] = useState(false);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [libreriesRows, setLibreriesRows] = useState([]);

  // Click  on type element into table
  const handleClickLibrary = (id) => {
    configAdmin.handlerClickIntoTable(id);
  };

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableLibraryPoint);
    configAdmin.setKeyValuesTable(keyValuesTableLibraryPoint);
    configAdmin.setSetRows(setLibreriesRows);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/point-library-components`);
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialog, setContentDialog]);

  // Set library in config admin
  useEffect(() => {
    configAdmin?.setEndpointFetch(`api/v1/point-library-components`);
    configAdmin?.fetchData();
  }, [configAdmin]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);
  return (
    <RowAdminConfig>
      <ColumnAdminConfig>
        {configAdmin && (
          <GenericTable
            headers={configAdmin.getHeadersTable()}
            rows={libreriesRows}
            keyValues={configAdmin.getKeyValuesTable()}
            handlerClick={handleClickLibrary}
            style={{
              width: 100,
            }}
          />
        )}
      </ColumnAdminConfig>
      <ColumnAdminConfig>
        <ContainerForm see={seeForm}>
          <Form
            form={form}
            // setSelect={setSelect}
            // select={select}
            handleChange={handleChangeForm}
            handleRows={handleClickLibrary}
            buttons={buttonsForm}
            loadingCreate={loadingCreate}
            loadingDelete={loadingDelete}
            renderBeforeTitle={[
              <ButtonSeeInitalView click={() => configAdmin.toggleForm()} />,
            ]}
            renderBeforeButtons={[
              <Fields
                typeFields={TYPE_FIELDS.typeToLibrary}
                form={form}
                handleChange={handleChangeForm}
              />,
            ]}
            title={"Create a new Digital Twin Library"}
          />
        </ContainerForm>

        {!seeForm && (
          <>
            <CreateNewRegionButton>
              Create a new Digital Twin Library
              <button onClick={() => configAdmin.toggleForm()}>
                <span>
                  <IoMdAdd />
                </span>
                New Type Library
              </button>
            </CreateNewRegionButton>

            <SeparatorWithText>
              <span className="left"></span>
              Or
              <span className="right"></span>
            </SeparatorWithText>

            <InformationShare>
              <div className="head">Edit Existing Digital Twin Library</div>
              <div className="body">
                To Edit Click on Existing Digital Twin Library ID
              </div>
            </InformationShare>
          </>
        )}
      </ColumnAdminConfig>
    </RowAdminConfig>
  );
}
