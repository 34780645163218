import {
  ContainerPictures,
  ContainerContentDialog,
} from "./ContentDialogStyle";

const ContentDialog = ({
  pictures,
  pointComponent,
  typeComponentMedia,
  setTypeComponentMedia,
}) => {
  const setTypeMediaHandler = (e) => {
    setTypeComponentMedia(e.target.value);
  };

  return (
    <ContainerContentDialog>
      <h3>Are you sure you want to save these pictures ?</h3>
      <ContainerPictures>
        {pictures.map((photo, index) => (
          <img key={index} src={photo} alt={photo} />
        ))}
      </ContainerPictures>
      {typeComponentMedia && pointComponent?.fieldsCaptureMedia?.length > 0 && (
        <div className="select-type">
          <h3>Please select the type of picture to upload:</h3>
          <select
            value={typeComponentMedia}
            onChange={setTypeMediaHandler}
            className="select"
          >
            {pointComponent?.fieldsCaptureMedia.map((item) => (
              <option key={item.name} value={item.name}>
                {item.alias}
              </option>
            ))}
          </select>
        </div>
      )}
    </ContainerContentDialog>
  );
};

export default ContentDialog;
