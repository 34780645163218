import { showEventsByInspectionType } from "redux/actions/digitalTwin";
import getUniqueTypeInspectionFlirIds from "helpers/filters/getTypeInspectionFlirIds";

const setDispatchFilterEventByTypeInspection = (operations, dispatch) => {
  const eventInspectionType = {};
  const typeInspectionIds = getUniqueTypeInspectionFlirIds(operations);
  typeInspectionIds.forEach((operationId) => {
    eventInspectionType[operationId] = true;
  });
  dispatch(showEventsByInspectionType({ ...eventInspectionType }));
};

export default setDispatchFilterEventByTypeInspection;
