import styled from "styled-components";

export const Head = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const TitleChart = styled.h3`
  font-size: 2.5rem;
  font-weight: 600;
  color: #000;
  margin: 0;
`;
