import { useEffect, useState } from "react";
import { SummaryFeature } from "./ContentUploadFieldVideoStyles";
import EditIcon from "@mui/icons-material/Edit";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import { PRIMARY_COLOR } from "utils/const";
import GenericDialog from "components/Dialogs/GenericDialog";
import changeFieldVideoName from "services/changeFieldVideoName";
import updateUrlFlirVideo from "services/updateUrlFlirVideo";
// import uploadFileToS3 from "services/uploadFileToS3";
import uploadFileToS3 from "services/uploadFileToS3sdk";
import { SyncVideoContainer } from "./ContentUploadVideoStyle";
import fieldVideosWithoutEventByUserId from "services/fieldVideosWithoutEventByUserId";
import CustomButton from "components/Buttons/CustomButton";
import removeFieldVideoWithoutEvent from "services/removeFieldVideoWithoutEvent";
import { useSWRConfig } from "swr";
import TextFileSVG from "images/text-files.svg";

const ContentUploadFieldVIdeo = ({
  namePlatePhoto,
  nameFlirVideo,
  videos,
  setNameFlirVideo,
  flirVideoId,
  userId,
  operationId,
  setOpenDialog,
}) => {
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [newVideoName, setNewVideoName] = useState("");
  const [checked, setChecked] = useState(false);
  const [video, setVideo] = useState(null);
  const [nameFlirVideoUpdate, setNameFlirVideoUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [fieldVideos, setFieldVideos] = useState([]);
  const [urlVideo, setUrlVideo] = useState();
  const [videoId, setVideoId] = useState();
  const [loadingAssingVideo, setLoadingAssingVideo] = useState(false);
  const { mutate } = useSWRConfig();

  useEffect(() => {
    const getFieldVideos = async () => {
      const fieldVideosUser = await fieldVideosWithoutEventByUserId(userId);
      setFieldVideos(fieldVideosUser);
    };
    getFieldVideos();
  }, [userId]);

  const handleChangeChecked = () => {
    setChecked((current) => !current);
  };

  const handleOpenEdit = () => setOpenModalEdit(!openModalEdit);

  const handleCloseEditModal = () => setOpenModalEdit(false);

  const changeVideoName = async () => {
    setLoading(true);
    await changeFieldVideoName(flirVideoId, newVideoName);
    setNameFlirVideo(newVideoName);
    setLoading(false);
    handleCloseEditModal();
  };

  const uploadVideo = async (e) => {
    const file = e.target.files[0];
    setVideo(file);
    setNameFlirVideoUpdate(e.target.files[0].name);
  };

  const saveVideo = async () => {
    try {
      setLoading(true);
      // const urlVideo = await uploadFileToS3(video, "5");
      const urlVideo = await uploadFileToS3(video, "5");
      await updateUrlFlirVideo(flirVideoId, urlVideo);
      setLoading(false);
      setOpenDialog();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeCheckbox = (event) => {
    const urlVideo = event.target.value;
    setUrlVideo(urlVideo);
    const videoId = event.target.id;
    setVideoId(videoId);
    if (event.target.checked) {
      const newVideos = fieldVideos.map((fieldVideo) => {
        if (fieldVideo.id === parseInt(videoId)) {
          fieldVideo.checked = true;
          return fieldVideo;
        }
        fieldVideo.checked = false;
        return fieldVideo;
      });
      setFieldVideos(newVideos);
    } else {
      const newVideos = fieldVideos.map((fieldVideo) => {
        fieldVideo.checked = undefined;
        return fieldVideo;
      });
      setFieldVideos(newVideos);
    }
  };

  const assignVideo = async () => {
    setLoadingAssingVideo(true);
    await updateUrlFlirVideo(flirVideoId, urlVideo);
    await removeFieldVideoWithoutEvent(videoId);
    setLoadingAssingVideo(false);
    const urlData = `${process.env.REACT_APP_URL_BACKEND}api/v1/operation/${operationId}`;
    mutate(urlData);
    setOpenDialog();
  };

  return (
    <>
      <SyncVideoContainer>
        {/* Upload video */}
        <section className="upload-video-container">
          <h1 className="title">Upload Video</h1>
          <div className="upload-video-details">
            {namePlatePhoto && (
              <picture>
                <img src={namePlatePhoto.urlPhoto} alt="placa" />
              </picture>
            )}
            <div>
              <div className="form-container">
                <div>
                  <label className="input-label">
                    <p>Video Name:</p>
                  </label>
                  <div className="input-container">
                    <input type="text" value={nameFlirVideo} />
                    <span className="input-icon">
                      <EditIcon
                        onClick={handleOpenEdit}
                        sx={{
                          cursor: "pointer",
                          mt: 1,
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div>
                  <label className="input-label">
                    <p>Upload video:</p>
                  </label>
                  {videos.length > 0 ? (
                    <div className="input-container">
                      <input type="text" value={nameFlirVideoUpdate} />
                      <label htmlFor="uploadVideo" className="input-icon">
                        <CloudUploadIcon
                          sx={{
                            cursor: "pointer",
                            mt: 1,
                          }}
                        />
                      </label>
                      <input
                        id="uploadVideo"
                        type="file"
                        name="file"
                        style={{ display: "none" }}
                        onChange={uploadVideo}
                        accept=".seq"
                      ></input>
                    </div>
                  ) : (
                    <input type="text" />
                  )}

                  <div className="input-container">
                    <label className="input-label">
                      <p>Name video validated</p>
                    </label>
                    <input
                      className="checkbox"
                      type="checkbox"
                      value={checked}
                      onChange={handleChangeChecked}
                      id="check"
                    />
                  </div>

                  {videos.length > 0 &&
                    (nameFlirVideoUpdate === nameFlirVideo ? (
                      <p
                        style={{
                          color: "green",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <b>Done! </b>
                        <b>The video names match.</b>
                      </p>
                    ) : nameFlirVideoUpdate === undefined ? null : (
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <b>The video names does not match.</b>
                        <b>please try again.</b>
                      </p>
                    ))}
                </div>
              </div>

              {nameFlirVideoUpdate === nameFlirVideo && checked ? (
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    saveVideo();
                  }}
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    borderColor: PRIMARY_COLOR,
                    color: "#FFF",
                    marginTop: "20px",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  Save
                </LoadingButton>
              ) : null}
            </div>
          </div>
        </section>
        {/* Unassigned Videos */}

        {fieldVideos && (
          <div>
            <div className="divider">
              <Divider>Or</Divider>
            </div>
            <section className="unassigned-videos">
              <h1 className="title">Assign from existing video in database</h1>
              <br />
              <section className="card-videos-container">
                {fieldVideos.map((fieldVideo) => (
                  <div className="card-video">
                    {(fieldVideo.checked === undefined ||
                      fieldVideo.checked === true) && (
                      <label className="video-select">
                        <input
                          id={fieldVideo.id}
                          type="checkbox"
                          value={fieldVideo.url}
                          onChange={handleChangeCheckbox}
                        />
                      </label>
                    )}
                    <div className="video-details">
                      <img width="100px" src={TextFileSVG} alt="" />
                      <p>
                        <b>Name: </b>
                        {fieldVideo.name}
                      </p>
                      <p>
                        <b>Size: </b>
                        {fieldVideo.size} MB
                      </p>
                    </div>
                    {fieldVideo.checked && (
                      <CustomButton
                        text={"Assing Video"}
                        onClick={assignVideo}
                        isLoad={loadingAssingVideo}
                      />
                    )}
                  </div>
                ))}
              </section>
            </section>
          </div>
        )}
      </SyncVideoContainer>
      <GenericDialog
        openDialog={openModalEdit}
        setOpenDialog={handleOpenEdit}
        content={{
          title: "Update Video Name",
          description: null,
          disagree: null,
          agree: null,
          content: (
            <div>
              <SummaryFeature
                style={{
                  marginTop: "4%",
                }}
              >
                <input
                  type="text"
                  placeholder={nameFlirVideo}
                  onChange={(e) => setNewVideoName(e.target.value)}
                />
              </SummaryFeature>

              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                color="primary"
                onClick={() => {
                  changeVideoName();
                }}
                style={{
                  backgroundColor: PRIMARY_COLOR,
                  borderColor: PRIMARY_COLOR,
                  color: "#FFF",
                  marginTop: "20px",
                }}
              >
                Save
              </LoadingButton>
            </div>
          ),
        }}
        style={{
          margin: 0,
          padding: 0,
          width: "30%",
          height: "250px",
          maxWidth: "none",
        }}
      />
    </>
  );
};

export default ContentUploadFieldVIdeo;
