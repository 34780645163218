import useSwr from "swr";

const FetchOperator = (operatorId) => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/operators/${operatorId}`
  );
  return { data, error };
};

export default FetchOperator;
