import { showOperationsByType } from "redux/actions/digitalTwin";
import deleteDuplicateEvents from "helpers/filters/deleteDuplicateOperations";

const setDispatchFilterEventByType = (operations, dispatch) => {
  const eventTypesFilter = {};
  const typeEvents = deleteDuplicateEvents(operations);
  const typeEventsIds = typeEvents.map((typeEvent) => typeEvent.id);
  typeEventsIds.forEach(
    (typeEventId) => (eventTypesFilter[typeEventId] = true)
  );
  dispatch(showOperationsByType({ ...eventTypesFilter }));
};

export default setDispatchFilterEventByType;
