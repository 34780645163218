import styled from "styled-components";

export const SyncVideoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #f9f9f9; */

  .upload-video-container,
  .unassigned-videos {
    width: 90%;
    margin: 0 auto;
  }

  .divider {
    margin: 30px 0;
    font-size: 2.4rem;
  }

  .upload-video-details {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
  }

  .upload-video-details picture img {
    width: 300px;
    max-height: 400px;
    object-fit: cover;
  }

  @media (min-width: 900px) {
    .upload-video-details picture img {
      width: 600px;
    }
  }

  .title {
    font-size: 3rem;
    text-align: center;
    margin: 10px 0;
    font-weight: 400;
  }

  .form-container {
    /* width: 400px; */
    min-width: 300px;
  }

  .form-container input {
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .input-container {
    display: flex;
    position: relative;
  }

  .input-icon {
    position: absolute;
    right: 4px;
    top: -5px;
  }

  .input-label p {
    font-size: 1.6rem;
    margin: 10px 0;
    font-weight: 600;
  }

  .input-container .checkbox {
    width: 20px;
    height: 20px;
    margin: 10px;
  }

  .card-videos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-around;
  }

  .card-video {
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #8c8c8c;
  }
  .card-video .video-select {
    position: absolute;
    z-index: 10;
    right: 0;
  }

  .video-select input {
    width: 30px;
    height: 30px;
    background-color: white;
  }

  .card-video p {
    text-align: center;
    margin: 5px 0;
    max-width: 240px;
  }

  .video-details {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    line-height: 1rem;
  }
  .video-details img {
    padding: 10px 0;
  }
`;
