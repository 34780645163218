import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const HubContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 3rem;
    font-weight: 600;
    color: ${PRIMARY_COLOR};
    margin-bottom: 64px;
    text-align: center;

    @media (max-width: 480px) {
      font-size: 2rem;
    }
  }
`;