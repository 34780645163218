import useSupercluster from "use-supercluster";
import { useSelector, useDispatch } from "react-redux";

import ObjectDetails from "components/ImageGallery/ObjectDetails/ObjectDetails";
import {
  setOperationDetails,
  setObjectsDigitalTwin,
  setFilteredObjectsDigitalTwin,
  setShowOperationDetails,
} from "redux/actions";
import { setDataObjectsPolygon } from "redux/actions/admin";
import { useCallback, useEffect, useMemo } from "react";

import MarkerCluster from "./MarkerCluster";
import MarkerElement from "./MarkerElement";

import useFilteredObjects from "hooks/useFilteredObjects";
/**
 * Renders object clusters on the map.
 *
 * @param {Object} objects - array of objects to render
 * @param {Object} bounds - bounds of the map
 * @param {Function} onSelectPoint - function to handle point selection
 * @param {Object} viewState - current view state of the map
 * @param {Function} setViewState - function to update the view state
 * @return {JSX.Element} - JSX object representing the object clusters on the map
 */
const ObjectsClusters = ({
  objects,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
}) => {
  const dispatch = useDispatch();
  
  const objectClusters = useSelector(
    (state) => state.digitalTwinReducer.objectClusters
  );

  const showMarkersByLibrary = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );
  const showMarkerByElementWithMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );
  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );

  const dataOriginalObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataOriginalObjectsPolygon
  );
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const dataTable = useSelector(
    (state) => state.adminReducer.objectsFilterTable
  );

  // data to geoJSON
    const objectsFilter = useFilteredObjects(objects);
    const originalData = useFilteredObjects(dataOriginalObjectsPolygon);

  const objectToPoint = (object) => ({
    type: "Feature",
    properties: {
      cluster: false,
      object: {
        ...object,
        id: object.id,
        icon: object.typeElement.urlIconColor,
        location: {
          latitude: object.location.latitude,
          longitude: object.location.longitude,
        },
        label: object?.fieldEquipmentName,
        haveMedia: object.haveMedia,
        QAQC: object.QAQC,
      },
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(object.location.longitude),
        parseFloat(object.location.latitude),
      ],
    },
  });

  const filteredObjects =
    dataTable !== undefined && dataTable.length !== 0
      ? dataTable.map((object) => objectToPoint(object))
      : objectsFilter?.map((object) => objectToPoint(object));

  const objectsPoints = objects?.map((object) => objectToPoint(object));

  useEffect(() => {
    dispatch(setObjectsDigitalTwin(objectsPoints));
    dispatch(setDataObjectsPolygon(objectsFilter));
  }, [dispatch, objectsPoints, objectsFilter, objects]);

  useEffect(() => {
    dispatch(setFilteredObjectsDigitalTwin(filteredObjects));
  }, [dispatch, filteredObjects, objects]);

  useEffect(() => {
    if (geometryPolygon?.type === "Polygon") {
      dispatch(setDataObjectsPolygon(originalData));
    } else {
      dispatch(setDataObjectsPolygon(objectsFilter));
    }
  }, [
    originalData,
    objectsFilter,
    geometryPolygon,
    showMarkersByLibrary,
    showMarkersByTypeElement,
    showMarkerByElementWithMedia,
    dispatch,
    objects
  ]);

  const { clusters } = useSupercluster({
    points: filteredObjects,
    bounds,
    zoom: viewState.zoom,
    options: {
      radius: objectClusters.radius || 0,
      maxZoom: objectClusters.maxZoom || 0,
    },
  });

  const HandleClickMarker = useCallback(
    async (objectId) => {
      const object = {
        content: <ObjectDetails objectId={objectId} />,
        title: "Objects Details",
      };
      if (!drawAnyThing) {
        dispatch(setOperationDetails(object));
        dispatch(setShowOperationDetails(true));
      }
    },
    [dispatch, drawAnyThing]
  );
  // Show objects in the map
  const renderObjectMarker = useCallback(
    (object) => {
      return <MarkerElement element={object} handleClick={HandleClickMarker} />;
    },
    [HandleClickMarker]
  );
  
  const MarkersCluster = useMemo(
    () =>
      clusters?.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const clusterData = {
          id: cluster.id,
          location: {
            latitude,   
            longitude,
          },
          pointCount,
          elementsCount: filteredObjects.length,  
          type: "object",
        };
        if (isCluster)
          return (
            <MarkerCluster
              cluster={clusterData}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        return renderObjectMarker(cluster.properties.object);
      }),
    [
      clusters,
      renderObjectMarker,
      onSelectPoint,
      filteredObjects,
      setViewState,
      viewState,
      objects
    ]
  );
  return MarkersCluster;
};

export default ObjectsClusters;
