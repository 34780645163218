import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "utils/const";

export const ContentTable = styled.div`
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 14px;
  max-height: ${(props) => props.maxHeight}vh;
  overflow-x: auto;

  .tableCell {
    width: 90%;
    border: solid #9bc4cb 1px;
    border-radius: 5px;
    padding: 5px;
    outline: none;
    background-color: rgba(165, 162, 162, 0);
    margin-bottom: 5px;
    margin-left: 5%;
    margin-right: 5%;
    background-color: #ffffff;
    &::placeholder {
      text-align: center;
      color: #9f9b9b;
    }
  }

  .tableCell2 {
    width: 45%;
    justify-content: space-around;
    border: solid #9bc4cb 1px;
    border-radius: 5px;
    padding: 5px;
    outline: none;
    background-color: rgba(165, 162, 162, 0);
    background-color: #ffffff;
    &::placeholder {
      text-align: center;
      color: #aaa6a6;
    }
  }

  table {
    width: fit-content;
    border-spacing: 0px;
  }
  thead {
    position: sticky;
    top: 0;
  }
  tbody {
    margin-top: 32px;
  }

  tr {
    width: fit-content;
    height: fit-content;
  }

  td {
    padding: 0.25rem;
    height: 40px;
    text-align: center;
  }

  th {
    position: relative;
    font-weight: bold;
    text-align: center;
    padding-bottom: 8px;
    border: none;
    background-color: #9bc4cb;
    font-size: 1.8rem;
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -4px;
    height: 100%;
    width: 7px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: ${PRIMARY_COLOR};
    opacity: 1;
  }

  select {
    border: none;
    padding: 0;
    height: 20px;
    outline: none;
    cursor: pointer;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }
`;

export const ArrowPagination = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonInfo = styled.div`
  cursor: pointer;
  .container-tooltip {
    position: relative;
  }

  .tooltip {
    display: block;
    position: absolute;
    border-radius: 3px;
    width: 150px;
    padding: 0px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 5px;
    padding-bottom: 3px;
    transform: translate(0%, -50px);
    border: solid 2px ${PRIMARY_COLOR};
    background-color: #0e4d45b5;
    color: #ffffff;
    text-align: center;
    z-index: 999;
    visibility: visible;
    opacity: 1;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .button {
    position: absolute;
    margin-top: -5px;
    border-radius: 50%;
    font-size: 0.8rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 3px;
    padding-right: 3px;
    right: 0;
    background-color: ${WHITE_COLOR};
    color: ${PRIMARY_COLOR};
    border: none;
  }
  .button:active {
    border: none;
    background-color: #1889ca;
  }
  .button:hover {
    transform: scale(1.5);
    background-color: ${WHITE_COLOR};
    color: ${PRIMARY_COLOR};
    cursor: pointer;
  }
`;

export const SearchTable = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
  margin-bottom: 5px;
  width: ${(props) => props.width}%;

  .space {
    margin-left: ${(props) => props.margin}px;
  }

  .input {
    border-radius: 5px;
    font-size: 16px;
    padding: 0.6rem;
    outline: none;
    border: 1px solid #c2c9ca;
    width: 200px;
  }

  .input:hover {
    border-color: #b3b5b5;
  }

  .input:focus {
    border: 2px solid #2d69ec;
  }
  .check {
    width: 2rem;
    height: 2rem;
    margin-top: 5px;
    margin-left: 5px;
  }
  button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding-top: 2px;
    padding-left: 3px;
    background-color: #fff;
    color: ${PRIMARY_COLOR};
    cursor: pointer;

    .container-tooltip {
      position: relative;
    }

    .tooltip {
      display: block;
      position: absolute;
      border-radius: 5px;
      width: 90px;
      padding: 1px;
      margin-top: 2px;
      transform: translate(-65%, 10px);
      border: solid 2px ${PRIMARY_COLOR};
      background-color: #0e4d45b5;
      color: #ffffff;
      text-align: center;
      z-index: 999;
      visibility: hidden;
    }

    .tooltipOpenWindow {
      display: block;
      position: absolute;
      border-radius: 5px;
      width: 100px;
      padding: 1px;
      margin-top: 2px;
      transform: translate(-25%, 10px);
      border: solid 2px ${PRIMARY_COLOR};
      background-color: #0e4d45b5;
      color: #ffffff;
      text-align: center;
      z-index: 999;
      visibility: hidden;
    }

    &:hover .tooltip {
      opacity: 1;
      visibility: visible;
      transition-delay: 1s;
    }
    &:hover .tooltipOpenWindow {
      opacity: 1;
      visibility: visible;
      transition-delay: 1s;
    }
  }
  button:active {
    border: solid 2px #fff;
  }
  button:hover {
    color: ${SECOND_COLOR};
  }
`;

export const Change = styled.button`
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);

  .container-tooltip {
    position: relative;
  }

  .tooltip {
    display: block;
    position: absolute;
    border-radius: 5px;
    width: 80px;
    padding: 1px;
    transform: translate(-35%, 5px);
    border: solid 2px ${PRIMARY_COLOR};
    background-color: #0e4d45b5;
    color: #ffffff;
    text-align: center;
    z-index: 999;
    visibility: hidden;
  }
  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
    transition-delay: 1s;
  }
`;

export const Divider = styled.div`
  width: 100%;
  background-color: #c2c9ca34;
  height: 2px;
`;
