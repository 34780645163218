/**
 * Formats a float value to a specified number of decimal places.
 *
 * @param {number|string} value - The value to be formatted as a float.
 * @param {number} maxDecimals - The maximum number of decimal places to include in the formatted value.
 * @return {string} The formatted float value.
 */
export function formatFloat(value, maxDecimals) {
  const floatValue = Number.parseFloat(value);
  const numDecimals = Math.min(
    maxDecimals,
    floatValue.toString().split(".")[1]?.length || 0
  );
  return floatValue.toFixed(numDecimals);
}
