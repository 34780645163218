import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useGet from "hooks/useFetch.js";
import { useSWRConfig } from "swr";
// custom style
import {
  Input,
  FormTextarea,
  ButtonCard,
  SelectRegion,
} from "./AccordionStyles";
// redux
import OpenDialog from "../Dialogs/OpenDialog";
import saveLineApiMg from "services/saveLineApiMg";
import saveLineApiPg from "services/saveLineApiPg";
// redux actions Admin
import { setGeometryLine } from "redux/actions/admin";
import updateLineMg from "services/updateLineApiMg";
import updateLineApiPg from "services/updateLineApiPg";

const AccordionDetailsLine = ({ setIsUpdateLine, isUpdateLine }) => {
  //Get Libraries
  const [libraries, errorLibraries] = useGet("api/v1/line-libraries");
  const role = localStorage.getItem("role");

  useEffect(() => {
    libraries?.at(0) && !errorLibraries && setTypeLibrary(libraries[0]);
  }, [errorLibraries, libraries]);

  const [lineName, setLineName] = useState("nombre linea");
  const [comments, setComments] = useState("no comments");
  const [library, setTypeLibrary] = useState({});
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [idMongoUpdate, setIdMongoUpdate] = useState("");

  const [typeLine, setTypeLine] = useState();
  //Get type Lines
  const [typesLine, errorTypeLine] = useGet(
    `api/v1/type-lines/library/${parseInt(library?.id)}`
  );

  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  //modal confirmation add line
  const [loadSaveLine, setLoadSaveLine] = useState(false);

  const [contentDialog, setContentDialog] = useState({
    title: "Save Line",
    description: "Are you sure you want to save the Line",
    disagree: "no",
    agree: "yes",
  });

  useEffect(() => {
    !typesLine?.error && typesLine?.at(0) && setTypeLine(typesLine[0]);
  }, [errorTypeLine, typesLine]);

  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );

  // If geometry line in properties is empty is new
  // If geometry line in properties is not empty is update
  useEffect(() => {
    const next =
      geometryLine?.properties?.lineLibraryId &&
      geometryLine?.properties?.lineTypeId &&
      libraries &&
      !libraries.error &&
      typesLine &&
      !typesLine.error;

    if (!next) {
      setIsUpdateLine(false);
      return null;
    }

    if (isUpdateLine) return null;

    const properties = geometryLine.properties;
    const { name, comments, lineLibraryId, lineTypeId, idMongo } = properties;
    setLineName(name);
    setComments(comments);
    setTypeLibrary((current) => ({
      ...current,
      id: lineLibraryId,
      name: libraries.find((library) => library.id === lineLibraryId),
    }));
    setTypeLine((current) => ({
      ...current,
      id: lineTypeId,
      type: typesLine.find((type) => type.id === lineTypeId),
    }));
    setIdMongoUpdate(idMongo);
    setIsUpdateLine(true);
  }, [
    geometryLine?.properties,
    libraries,
    typesLine,
    setIsUpdateLine,
    isUpdateLine,
  ]);

  //open modal confirmation
  const handleClose = () => setLoadSaveLine(false);

  // Change in library select
  const handleLibrary = (e) => {
    setTypeLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: libraries.find((library) => library.id === parseInt(e.target.value))
        ?.name,
    }));
  };

  // Change in type region select
  const handleTypeLine = (e) => {
    setTypeLine((currentTypeLine) => ({
      ...currentTypeLine,
      id: e.target.value,
      type: typesLine.find((type) => type.id === parseInt(e.target.value))
        ?.type,
    }));
  };

  //Save Line
  const openSaveLineDialog = (e) => {
    if (isUpdateLine) {
      setContentDialog((current) => ({
        ...current,
        title: "Update Line",
        description: "Are you sure you want to update the Line",
      }));
    }
    setLoadSaveLine(true);
  };

  const createFormartGeoJsonLine = () => ({
    userId: userId,
    type: "geojson",
    name: lineName,
    createdAt: new Date(),
    updatedAt: new Date(),
    comments: comments,
    feature: {
      id: geometryLine.id,
      type: "Feature",
      properties: {
        name: lineName,
        userId: userId,
        lineTypeId: parseInt(typeLine?.id),
        comments: comments,
        adminCompanyId,
      },
      geometry: geometryLine.geometry,
    },
  });

  const createDataLine = (data) => ({
    userId: data.feature[0].properties.userId,
    lineTypeId: data.feature[0].properties.lineTypeId,
    name: data.feature[0].properties.name,
    geojsonLineId: data._id,
    comments: data.feature[0].properties.comments,
  });

  const updateDataLine = (data) => ({
    name: data.feature[0].properties.name,
    comments: data.feature[0].properties.comments,
    lineTypeId: data.feature[0].properties.lineTypeId,
  });

  const saveLine = async () => {
    setContentDialog({ ...contentDialog, agree: <CircularProgress /> });
    //geoJson format for line
    const lineGeoJson = createFormartGeoJsonLine();
    const { data, res } = await saveLineApiMg(lineGeoJson);
    const dataLine = createDataLine(data);

    if (res.status === 200) {
      try {
        const { res: resPg } = await saveLineApiPg(dataLine);
        if (resPg.status === 200) {
          handleClose();
          dispatch(setGeometryLine(null));
          mutate(
            `${process.env.REACT_APP_URL_MG_API}line?admin_company_id=${adminCompanyId}`
          );
        }
      } catch (e) {
        alert("Error al guardar la línea");
        handleClose();
      }
    }
  };

  const updateLine = async () => {
    setContentDialog({ ...contentDialog, agree: <CircularProgress /> });
    const lineGeoJson = createFormartGeoJsonLine();
    delete lineGeoJson.createdAt;
    const { data, res } = await updateLineMg(idMongoUpdate, lineGeoJson);
    const dataLine = updateDataLine(data);

    if (res.status === 200) {
      try {
        const { res: resPg } = await updateLineApiPg(idMongoUpdate, dataLine);
        if (resPg.status === 200) {
          await mutate(
            `${process.env.REACT_APP_URL_MG_API}line?admin_company_id=${adminCompanyId}`
          );
          dispatch(setGeometryLine(null));
          handleClose();
        }
      } catch (error) {
        alert("Error al actualizar la línea");
        handleClose();
      }
    }

    handleClose();
  };

  const handleAction = () => {
    if (isUpdateLine) {
      updateLine();
      return;
    }
    saveLine();
  };

  return (
    <>
      <form>
        <div>
          <p className="geometry-type">
            <b>Line name:</b>
          </p>
          <Input
            type="text"
            value={lineName}
            onChange={(e) => setLineName(e.target.value)}
            required
          />
        </div>
        <div>
          <p className="geometry-type">
            <b>Library:</b>
          </p>
          <SelectRegion
            name="endPoint"
            id="endPoint"
            onChange={handleLibrary}
            value={library?.id}
          >
            {libraries?.map((item) => (
              <option key={item.id} value={item.id}>
                Librería {item.name}
              </option>
            ))}
          </SelectRegion>
        </div>
        <div>
          <p className="geometry-type">
            <b>Type Line:</b>
          </p>
          {typesLine && (
            <SelectRegion
              name="regions"
              id="region-select"
              onChange={handleTypeLine}
              value={typeLine?.id}
            >
              {!typesLine?.error &&
                typesLine?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.type}
                  </option>
                ))}
            </SelectRegion>
          )}
        </div>
        <div>
          <p>
            <b>Comments:</b>
            <FormTextarea
              type="text"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              required
            />
          </p>
        </div>
        {(role === "Administrador de compañía" || "Operario") &&
          lineName &&
          comments &&
          typesLine?.length > 0 && (
            <ButtonCard type="button" onClick={openSaveLineDialog}>
              <p>{isUpdateLine ? "Actualizar Línea" : "Guardar Línea"}</p>
            </ButtonCard>
          )}
      </form>
      <OpenDialog
        openDialog={loadSaveLine}
        setOpenDialog={setLoadSaveLine}
        execute={handleAction}
        content={contentDialog}
      />
    </>
  );
};

export default AccordionDetailsLine;
