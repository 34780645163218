import OpenDialog from "components/Dialogs/OpenDialog";

const DeletePolygonDialog = ({ openDialog, setOpenDialog, handle }) => {
  return (
    <OpenDialog
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      execute={handle}
      content={{
        title: "",
        description: "Are you sure you want to delete this polygon?",
        disagree: "Cancel",
        agree: "Accept",
      }}
    />
  );
};

export default DeletePolygonDialog;
