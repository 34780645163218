export const createLineString = (locations, color) => {
  const geojsonLineString = {
    type: "Feature",
    properties: {
      color,
    },
    geometry: {
      type: "LineString",
      coordinates: [...locations],
    },
  };
  return geojsonLineString;
};
