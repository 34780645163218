import { CircularProgress } from "@mui/material";
import { ContentButton } from "./CustomButtonStyle";
import PropTypes from "prop-types";

const CustomButton = ({
  primaryColor,
  secondaryColor,
  text,
  onClick,
  isLoad,
  type,
}) => {
  return (
    <ContentButton primaryColor={primaryColor} secondaryColor={secondaryColor}>
      <button onClick={onClick} type={type}>

        {isLoad && <CircularProgress style={circularProgressStyle} />}

        {text}
      </button>
    </ContentButton>
  );
};

CustomButton.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

CustomButton.defaultProps = {
  isLoading: false,
  type: "button",
};

const circularProgressStyle = {
  width: "20px",
  height: "20px",
  color: "#f9f9f9",
  marginRight: "5px",
};

export default CustomButton;
