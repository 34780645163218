// Services
import timeZoneOffset from "services/timeZoneOffset";
import uploadFileToS3 from "services/uploadFileToS3sdk";

const createBodyObject = (formFields, otherFields) => {
  // Extract longitude and latitude from formFields

  // Create initial body object
  const body = {
    dataSourceId: 2, // desktop source
    date: new Date(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneOffset: timeZoneOffset(),
    typeElementId: parseInt(formFields["Type element"]),
    ...otherFields,
  };

  // Extract key fields from formFields
  const keyFields = getKeyFields(formFields);

  // Create attributes object
  const attributes = createAttributes(formFields, keyFields);

  // Filter out attributes with empty values
  const objectAttributes = filterEmptyAttributes(attributes);

  // Combine attributes with initial body object
  const newBody = {
    ...body,
    attributes: objectAttributes,
  };

  return newBody;
};

// Extract key fields from formFields, excluding exceptions
const getKeyFields = (formFields) => {
  const exceptions = [
    "Object type",
    "Type element",
    "Field baseline",
    "Field equipment name",
    "Comments",
  ];

  return Object.keys(formFields).filter((key) => !exceptions.includes(key));
};

// Create attributes object from key fields and formFields
const createAttributes = (formFields, keyFields) => {
  return keyFields.map((key) => {
    let value = formFields[key];
    if (typeof value === "boolean") value = value.toString();
    return {
      name: key,
      value,
    };
  });
};

// Filter out attributes with empty values
const filterEmptyAttributes = (attributes) => {
  return attributes.filter((attribute) => attribute.value !== "");
};

const bodyObjectWithFiles = async (fileFields, data, genericData) => {
  const bodyObject = await Promise.all(
    fileFields.map(async (fileField) => {
      const files = data[fileField.name];
      const urlFile = files[0] !== undefined && await uploadFileToS3(files[0], 8);
      const newData = {
        [fileField.name]: urlFile,
      };
      return newData;
    })
  ).then((res) => {
    const files = Object.assign({}, ...res);
    for (let key in files) {
      if (key in data) {
        data[key] = files[key];
      }
    }
    const bodyObject = createBodyObject(data, genericData.point);
    return bodyObject;
  });
  return bodyObject;
};

export default bodyObjectWithFiles;
