// Required modules
import NavBar from "components/Admin/NavBar/NavBar";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  ConfigAdminContainer,
  ReturnSettingsPage,
  TitleAdminConfig,
} from "./configAdminStyles";
import { useState } from "react";
import ComponentsElements from "./typeElemts/ComponentsElements";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";

export default function Components() {
  const navigate = useNavigate();

  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  // Action to execute dialog
  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  // To navigate any page
  const navigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <NavBar linkLogo={"admin"} />

      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={executeDialog}
        content={contentDialog}
      />

      <ConfigAdminContainer>
        <ReturnSettingsPage onClick={() => navigation("/config-admin")}>
          <AiOutlineLeft />
        </ReturnSettingsPage>

        <TitleAdminConfig>
          Digital Twin Component Configurations
        </TitleAdminConfig>

        <ComponentsElements
          setOpenDialog={setOpenDialog}
          setContentDialog={setContentDialog}
        />
      </ConfigAdminContainer>
    </div>
  );
}
