import { Container, Panel } from "./styles";
import { AiFillSetting } from "react-icons/ai";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperationClusters,
  setObjectClusters,
  setComponentClusters,
} from "redux/actions";
import GenericDialog from "components/Dialogs/GenericDialog";

export default function FloatingSetting() {
  const [seePanel, setSeePanel] = useState(false);
  const dispatch = useDispatch();
  const operationClusters = useSelector(
    (state) => state.digitalTwinReducer.operationClusters
  );
  const objectClusters = useSelector(
    (state) => state.digitalTwinReducer.objectClusters
  );

  const componentClusters = useSelector(
    (state) => state.digitalTwinReducer.componentClusters
  );

  const handleOperationClusters = (e) => {
    const newValues = {
      ...operationClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("operationCluster", JSON.stringify(newValues));
    dispatch(setOperationClusters(newValues));
  };

  const handleObjectClusters = (e) => {
    const newValues = {
      ...objectClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("objectClusters", JSON.stringify(newValues));
    dispatch(setObjectClusters(newValues));
  };

  const handleComponentClusters = (e) => {
    const newValues = {
      ...componentClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("componentClusters", JSON.stringify(newValues));
    dispatch(setComponentClusters(newValues));
  };

  const handleSeePanel = (status) => {
    setSeePanel(() => status);
  };

  return (
    <>
      <Container>
        {!seePanel && (
          <span className="icon-settings" onClick={() => handleSeePanel(true)}>
            <AiFillSetting />
            <div className="container-tooltip">
              <span className="tooltip">Settings of Map</span>
            </div>
          </span>
        )}
      </Container>

      <GenericDialog
        openDialog={seePanel}
        setOpenDialog={setSeePanel}
        content={{
          title: "Settings of Map",
          content: (
            <Panel>
              <div className="body">
                <section>
                  <p>
                    <b>Radius: </b>
                    Determines the maximum distance at which points are grouped
                    in a cluster.
                  </p>
                  <br />
                  <p>
                    <b>Max zoom: </b>
                    Sets the maximum zoom level on the map at which clusters are
                    ungrouped into individual points. The maximum zoom value is
                    23.
                  </p>
                </section>
                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>Object Clusters:</p>
                    <span className="color-cluster-object"></span>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        Radius:
                        <input
                          type="number"
                          name="radius"
                          value={objectClusters.radius}
                          onChange={handleObjectClusters}
                        />
                      </label>
                    </div>
                    <div className="body-item">
                      <label className="label">
                        Max Zoom:
                        <input
                          type="number"
                          name="maxZoom"
                          value={objectClusters.maxZoom}
                          onChange={handleObjectClusters}
                          min={1}
                          max={23}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>Event Clusters:</p>
                    <span className="color-cluster-event"></span>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        Radius:
                        <input
                          type="number"
                          name="radius"
                          value={operationClusters.radius}
                          onChange={handleOperationClusters}
                        />
                      </label>
                    </div>
                    <div className="body-item">
                      <label className="label">
                        Max Zoom:
                        <input
                          type="number"
                          name="maxZoom"
                          value={operationClusters.maxZoom}
                          onChange={handleOperationClusters}
                          min={1}
                          max={23}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="body-items">
                  <div className="object-clusters-title">
                    <p>Component Clusters:</p>
                    <span className="color-cluster-component"></span>
                  </div>
                  <div className="items">
                    <div className="body-item">
                      <label className="label">
                        Radius:
                        <input
                          type="number"
                          name="radius"
                          value={componentClusters.radius}
                          onChange={handleComponentClusters}
                        />
                      </label>
                    </div>
                    <div className="body-item">
                      <label className="label">
                        Max Zoom:
                        <input
                          type="number"
                          name="maxZoom"
                          value={componentClusters.maxZoom}
                          onChange={handleComponentClusters}
                          min={1}
                          max={23}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          ),
        }}
      />
    </>
  );
}
