import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { FiLogIn } from "react-icons/fi";
import InfoIcon from "@mui/icons-material/Info";
import ReactTooltip from "react-tooltip";
import { BoxNav } from "../DigitalTwin/UserRoutesElements";
import { SideBarItems, iconStyle } from "./navDrawerData";
import { useState } from "react";

const drawerWidth = 85;

const Item = (props) => {
  const { text, icon, styleListIcon, url, click } = props;
  const navigate = useNavigate();

  const navigation = () => {
    if (click) {
      click();
      return;
    }

    if (url && url.includes("https")) {
      window.open(url);
      return;
    }

    url && navigate(url);
  };

  return (
    <ListItem key={text} disablePadding>
      <ListItemButton onClick={(event) => navigation()}>
        <ListItemIcon sx={styleListIcon}>
          <>
            {icon}
            <ReactTooltip
              id={text}
              place="right"
              effect="solid"
              offset="{'left': -15}"
            >
              {text}
            </ReactTooltip>
          </>
        </ListItemIcon>
        <ListItemText />
      </ListItemButton>
    </ListItem>
  );
};

export default function PersistentDrawerLeft() {
  const styleListIcon = {
    justifyContent: "center",
  };

  const role = localStorage.getItem("role");

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <BoxNav>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="end"
        sx={{ mr: 1, ...(open && {}) }}
      >
        <MenuIcon
          sx={{ fontSize: "2.5rem" }}
          style={{
            fontSize: "2.5rem",
          }}
        />
      </IconButton>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#0E4D45",
          },
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        {/* Close  */}
        <List>
          <Item
            click={handleDrawerClose}
            text={"Close"}
            icon={
              <CloseIcon
                style={iconStyle}
                sx={iconStyle}
                data-tip
                data-for={"Close"}
              />
            }
            styleListIcon={styleListIcon}
          />
        </List>

        <Divider />

        <List>
          {/* Sign In only when no exis role */}
          {role === null && (
            <Item
              text={"Log In"}
              icon={<FiLogIn style={iconStyle} data-tip data-for="Log In" />}
              styleListIcon={styleListIcon}
              url={"/signIn"}
            />
          )}

          {/* // Home to any role */}
          <Item
            text={"Home"}
            icon={
              <HomeOutlinedIcon
                style={iconStyle}
                sx={iconStyle}
                data-tip
                data-for={"Home"}
              />
            }
            styleListIcon={styleListIcon}
            url={role ? "/hub" : "/"}
          />

          {/* Render items of sidebar depending on your role */}
          {role &&
            Object?.keys(SideBarItems[role])?.map((item, index) => {
              const { icon, url } = SideBarItems[role][item];
              return (
                <Item
                  text={item}
                  icon={icon}
                  styleListIcon={styleListIcon}
                  url={url}
                  key={index}
                />
              );
            })}
        </List>

        {/* About Us Item */}
        <List>
          <Item
            text={"About Us"}
            icon={
              <InfoIcon
                style={iconStyle}
                sx={iconStyle}
                data-tip
                data-for="About Us"
              />
            }
            styleListIcon={styleListIcon}
            url="https://www.linkedin.com/company/decimetrix/"
          />
        </List>
      </Drawer>
    </BoxNav>
  );
}
