const LicenseGrant = () => {
  return (
    <>
      <p className="text">
        1.1 Decimetrix grants you a limited, non-exclusive, non-transferable,
        revocable license to access and use the Platform solely for your own
        internal business purposes, subject to the terms and conditions of this
        Agreement.
      </p>
      <p className="text">
        1.2 You acknowledge that Decimetrix retains all rights, title, and
        interest in the Platform, including any intellectual property rights
        associated with it. You shall not reproduce, modify, distribute, sell,
        lease, sublicense, or otherwise exploit the Platform or any part thereof
        without Decimetrix's prior written consent.
      </p>
    </>
  );
};
const UserObligations = () => {
  return (
    <>
      <p className="text">
        2.1 You agree to use the Platform in compliance with all applicable
        laws, regulations, and industry standards and in accordance with this
        Agreement.
      </p>
      <p className="text">
        2.2 You shall be responsible for maintaining the confidentiality and
        security of your account credentials and ensuring that unauthorized
        individuals do not gain access to your account or the Platform.
      </p>
      <p className="text">
        2.3 You shall not use the Platform in any manner that may disrupt its
        proper functioning or interfere with the experience of other users.
      </p>
      <p className="text">
        2.4 You agree not to reverse engineer, decompile, disassemble, or
        attempt to derive the source code of the Platform, except to the extent
        expressly permitted by applicable law.
      </p>
    </>
  );
};
const DataPrivacy = () => {
  return (
    <>
      <p className="text">
        3.1 By using the Platform, you acknowledge and agree that Decimetrix may
        collect, store, process, and use your personal information and data in
        accordance with our Privacy Policy.
      </p>
      <p className="text">
        3.2 You shall ensure that you have the necessary rights and permissions
        to provide any documents, information, or data to the Platform for
        processing and analysis.
      </p>
      <p className="text">
        3.3 Decimetrix takes commercially reasonable measures to protect the
        security and confidentiality of your data. However, you acknowledge that
        no data transmission or storage system is completely secure, and
        Decimetrix cannot guarantee the absolute security of your data.
      </p>
    </>
  );
};
const PlatformFunctionality = () => {
  return (
    <>
      <p className="text">
        4.1 The Platform utilizes ChatGPT, an artificial intelligence model, to
        extract information from your provided documentation and generate
        responses. While Decimetrix strives to provide accurate and helpful
        responses, the Platform's outputs may not always be error-free or
        comprehensive.
      </p>
      <p className="text">
        4.2 You acknowledge that the Platform's responses are generated based on
        the information provided by you, and Decimetrix does not warrant the
        accuracy, completeness, or reliability of the responses.
      </p>
    </>
  );
};
const IntellectualProperty = () => {
  return (
    <>
      <p className="text">
        5.1 The Platform, including its design, features, and content, is
        protected by copyright, trademark, and other intellectual property laws.
        All rights not expressly granted herein are reserved by Decimetrix.
      </p>
      <p className="text">
        5.2 You shall not remove, alter, or obscure any copyright, trademark, or
        other proprietary notices incorporated in or displayed by the Platform.
      </p>
    </>
  );
};
const DisclaimerOfWarranty = () => {
  return (
    <>
      <p className="text">
        6.1 The Platform is provided on an "as is" and "as available" basis
        without warranties of any kind, whether express or implied. Decimetrix
        disclaims all warranties, including, but not limited to, warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
      </p>
      <p className="text">
        6.2 Decimetrix does not warrant that the Platform will be error-free,
        uninterrupted, or free from viruses or other harmful components. You
        acknowledge that your use of the Platform is at your own risk.
      </p>
    </>
  );
};
const LimitationOfLiability = () => {
  return (
    <>
      <p className="text">
        7.1 To the maximum extent permitted by applicable law, Decimetrix and
        its officers, directors, employees, affiliates, and agents shall not be
        liable for any indirect, incidental, special, consequential, or punitive
        damages, including but not limited to loss of profits, data, or business
        opportunities, arising out of or in connection with your use of the
        Platform.
      </p>
      <p className="text">
        7.2 In no event shall Decimetrix's total liability to you for all
        claims, whether in contract, tort, or otherwise, exceed the amount paid
        by you, if any, for accessing or using the Platform.
      </p>
    </>
  );
};
const Termination = () => {
  return (
    <>
      <p className="text">
        8.1 Decimetrix reserves the right to terminate or suspend your access to
        the Platform at any time and for any reason without prior notice.
      </p>
      <p className="text">
        8.2 Upon termination, all licenses and rights granted to you under this
        Agreement will immediately cease, and you shall promptly cease all use
        of the Platform.
      </p>
    </>
  );
};

const ChangesAgreement = () => {
  return (
    <p className="text">
      9.1 Decimetrix may modify or update this Agreement at any time by posting
      the revised terms within the Platform or on Decimetrix's website. Your
      continued use of the Platform after the posting of any changes constitutes
      your acceptance of such changes.
    </p>
  );
};

const GoverningLaw = () => {
  return (
    <>
      <p className="text">
        10.1 This Agreement shall be governed by and construed in accordance
        with the laws of the jurisdiction in which Decimetrix is located,
        without regard to its conflict of laws principles.
      </p>
      <p className="text">
        10.2 Any dispute arising out of or relating to this Agreement shall be
        exclusively resolved through binding arbitration in accordance with the
        rules of the arbitration institution mutually agreed upon by the
        parties.
      </p>
    </>
  );
};
const Miscellaneous = () => {
  return (
    <>
      <p className="text">
        11.1 This Agreement constitutes the entire agreement between you and
        Decimetrix concerning the subject matter hereof and supersedes any prior
        or contemporaneous negotiations, discussions, or agreements, whether
        written or oral.
      </p>
      <p className="text">
        11.2 If any provision of this Agreement is found to be invalid or
        unenforceable, such provision shall be severed from the Agreement, and
        the remaining provisions shall remain in full force and effect.
      </p>
    </>
  );
};

const ContactInformation = () => {
  return (
    <>
      <p className="text">
        12.1 If you have any questions or concerns regarding this Agreement or
        the Platform, please contact us at business@decimetrix.com or
        www.decimetrix.com.
      </p>
      <p className="text">
        By accessing or using the Platform, you acknowledge that you have read,
        understood, and agree to be bound by this Agreement. If you do not agree
        with any part of this Agreement, you should not access or use the
        Platform.
      </p>
    </>
  );
};

export const dataSocrates = {
  title: "Decimetrix® AI Socrates Software Platform Terms and Conditions",
  mainText:
    'These Terms and Conditions ("Agreement") govern the use of the Decimetrix AI Socrates software platform ("Platform") provided by Decimetrix® Inc. ("Decimetrix," "we," or "us") and the rights and obligations between Decimetrix and the users ("User," "you," or "your") of the Platform. By accessing or using the Platform, you agree to be bound by the terms and conditions set forth in this Agreement.',
  info: [
    {
      subtitle: "License Grant",
      text: <LicenseGrant />,
    },
    {
      subtitle: "User Obligations",
      text: <UserObligations />,
    },
    {
      subtitle: "Data Privacy and Security",
      text: <DataPrivacy />,
    },
    {
      subtitle: "Platform Functionality and Accuracy",
      text: <PlatformFunctionality />,
    },
    {
      subtitle: "Intellectual Property",
      text: <IntellectualProperty />,
    },
    {
      subtitle: "Disclaimer of Warranty",
      text: <DisclaimerOfWarranty />,
    },
    {
      subtitle: "Limitation of Liability",
      text: <LimitationOfLiability />,
    },
    {
      subtitle: "Termination",
      text: <Termination />,
    },
    {
      subtitle: "Changes to the Agreement",
      text: <ChangesAgreement />,
    },
    {
      subtitle: "Governing Law and Dispute Resolution",
      text: <GoverningLaw />,
    },
    {
      subtitle: "Miscellaneous",
      text: <Miscellaneous />,
    },
    {
      subtitle: "Contact Information",
      text: <ContactInformation />,
    },
  ],
};
