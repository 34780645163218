export const events = {
  repair: "Repair",
  sketch: "Sketch",
  leak: "Leak",
  inspectionFlir: "Inspection Flir",
};

export const eventsIcons = [
  {
    name: "Repair",
    icon: "https://icons-greendragon.s3.us-east-2.amazonaws.com/type_elements_events/repairIconMap.png",
    library: "Generic Events"
  },
  {
    name: "Sketch",
    icon: "https://icons-greendragon.s3.us-east-2.amazonaws.com/type_elements_events/sketchIconMap.png",
    library: "Generic Events"
  },
  {
    name: "Leak",
    icon: "https://icons-greendragon.s3.us-east-2.amazonaws.com/type_elements_events/leakIconMap.png",
    library: "Generic Events"
  },
  {
    name: "Inspection Flir",
    icon: "https://icons-greendragon.s3.us-east-2.amazonaws.com/type_elements_events/flirIconMap.png",
    library: "Generic Events"
  },
];

export const reparationField = [
  {
    key: "repair",
    name: "Repair",
    type: "check",
    required: false,
  },
  {
    key: "comments",
    name: "Comments",
    type: "text",
    required: true,
  },
];

export const fieldEquipmentName2 = [
  {
    key: "fileName",
    name: "Field Name",
    type: "text",
    required: true,
  },
  {
    key: "comments",
    name: "Comments",
    type: "text",
    required: false,
  },
];

export const fieldFormLeak = [
  {
    key: "ppm",
    name: "PPM",
    type: "number",
    required: false,
  },
  {
    key: "grH",
    name: "Gr/H",
    type: "number",
    required: false,
  },
  {
    key: "ltMn",
    name: "Lt/Min",
    type: "number",
    required: false,
  },
  {
    key: "leak",
    name: "Leak",
    type: "check",
    required: false,
  },
  {
    key: "label",
    name: "Tag",
    type: "text",
    required: false,
  },
  {
    key: "comments",
    name: "Comments",
    type: "text",
    required: false,
  },
];

export const fieldFormIspectionFlir = [
  {
    key: "fileSeqName",
    name: "File .seq name",
    type: "text",
    required: true,
  },
  {
    key: "distance",
    name: "distance",
    type: "number",
    required: true,
  },
  {
    key: "leak",
    name: "leak",
    type: "check",
    required: false,
  },
  {
    key: "comments",
    name: "comments",
    type: "text",
    required: true,
  },
];
