import AccordionRender from "./AccordionRenderTypesComponents.jsx";
import useGet from "hooks/useFetch.js";

const CheckListsComponents = ({
  typeEventsTypeComponents,
  handleChangeCheckComponents,
}) => {
  const [pointLibraryComponents] = useGet("api/v1/point-library-components");
  const [pointTypeComponents] = useGet("api/v1/point-type-components");

  const res =
    pointTypeComponents &&
    !pointTypeComponents.error &&
    pointTypeComponents.map((elm) => {
      return elm.pointLibraryComponentId;
    });

  const idTypes =
    res &&
    !res.error &&
    res.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
      }}
    >
      {pointLibraryComponents &&
        !pointLibraryComponents.error &&
        pointLibraryComponents.map((library, key) =>
          idTypes && !idTypes.error && idTypes.includes(library.id) ? (
            <AccordionRender
              handleChangeCheckComponents={handleChangeCheckComponents}
              typeEventsTypeComponents={typeEventsTypeComponents}
              key={key}
              library={library}
            />
          ) : (
            <></>
          )
        )}
    </div>
  );
};

export default CheckListsComponents;
