import React from "react";
import { useState, useEffect } from "react";
// @material-ui/core components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import WorkIcon from "@mui/icons-material/Work";
import BadgeIcon from "@mui/icons-material/Badge";
import DescriptionIcon from "@mui/icons-material/Description";
import OpenDialog from "../Dialogs/OpenDialog";
import { setProfileImage } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserParams } from "redux/actions";
import avatar from "assets/user.jpg";
import empresas from "assets/empresas.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "components/Lodings/LoadingV2";

import {
  WrapperForm,
  Form,
  Wrapperbtn,
  AddButton,
  ProfileButton,
  ProfileFormContainer,
  ProfileContainer,
  StyledP,
} from "../Forms/FormStyles";

import InputComponent from "../Forms/InputProfile";
import { ProfileStyle } from "./profileStyle";

const Item = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "left",
}));

const Item2 = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function UserProfile({ userId }) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [area, setArea] = useState({ field: "", valid: null });
  const [project, setProject] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });

  const [role, setRole] = useState({ field: "", valid: null });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [photosFile, setPhotosFile] = useState([]);
  const [dialogs, setDialog] = React.useState(false);
  const [pass, setPass] = useState(false);
  const [passdialog, setPassdialog] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [user, setUser] = useState({});
  const [showinfo, setShowinfo] = useState(false);
  const [openDialogSavePhoto, setOpenDialogSavePhoto] = useState({
    title: "Guardar Imagen",
    description: "Estas seguro que quieres guardar la foto de perfil?",
    disagree: "no",
    agree: "si",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/users/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
        const res = await response.json();

        setUser(res);
        dispatch(setCurrentUserParams(res));
        if (res.userTypeId === 2) {
          setFirstName(res.operator.firstName);
          setSecondName(res.operator.secondName);
          setFirstLastName(res.operator.firstLastName);
          setEmail(res.email);
          setRole(
            `${capitalizeFirstLetter(res?.userType?.role.toLowerCase())} - ${
              res?.operator?.role?.role
            }`
          );
          setArea(res.operator.area);
          setShowinfo(true);
        }
        if (res.userTypeId === 1) {
          setFirstName(res.adminCompany.firstName);
          setSecondName(res.adminCompany.secondName);
          setFirstLastName(res.adminCompany.firstLastName);
          setEmail(res.email);
          setRole(capitalizeFirstLetter(res.userType.role.toLowerCase()));
          setArea(res.adminCompany.area);
          setProject(res.adminCompany.project);
          setShowinfo(true);
        }
        if (res.userTypeId === 3) {
          setFirstName(res.adminDecimetrix.firstName);
          setSecondName(res.adminDecimetrix.secondName);
          setFirstLastName(res.adminDecimetrix.firstLastName);
          setEmail(res.email);
          setRole(capitalizeFirstLetter(res.userType.role.toLowerCase()));
          setShowinfo(true);
        }
        return res;
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dispatch, token, userId]);

  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );

  // define functions to handle photo upload
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setProfileImage([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setDialog(true);
  };

  const savePhotos = async () => {
    photosFile.forEach(async (file) => {
      setOpenDialogSavePhoto({
        title: "Save picture",
        description: "Are you sure you want to save the profile picture?",
        disagree: "no",
        agree: <CircularProgress />,
      });
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucketId", "8");
      const urlPhoto = await fetch(
        `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
        {
          method: "POST",
          body: formData,
        }
      );

      const photo = await urlPhoto.json();
      const body = {
        urlPhoto: photo.urlFile,
      };

      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/users/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: JSON.stringify(body),
        }
      );
      await res.json();
      setPhotosFile([]);
      setDialog(false);
      setOpenDialogSavePhoto({
        title: "Guardar Imagen",
        description: "Estas seguto que quieres guardar la foto de perfil?",
        disagree: "no",
        agree: "si",
      });
      return res;
    });
  };

  const capitalizeFirstLetter = (string) => {
    if (string === null) {
      return "";
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  const changePassword = () => {
    setPass(true);
  };

  const executeChangePassword = async () => {
    const body = {
      email: email,
    };
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/auth/changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const response = await res.json();
      setPass(false);
      setPassdialog(true);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <br />
      {showinfo ? (
        <Container maxWidth="xl" sx={{}}>
          <br />
          <ProfileStyle>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={5}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    "box-shadow": "0px 0px 10px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    spacing={2}
                  >
                    <Avatar
                      alt="Avatar"
                      src={user.urlPhoto === null ? avatar : user.urlPhoto}
                      sx={{ width: 206, height: 210 }}
                    ></Avatar>
                  </Stack>

                  <Item>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={2} md={2} lg={1} xl={1}>
                        <WorkIcon sx={{ mr: 1 }} />{" "}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        md={10}
                        lg={11}
                        xl={11}
                        className="profile-details-item"
                      >
                        <b>Role: </b>
                        {`${capitalizeFirstLetter(
                          user.userType.role.toLowerCase()
                        )}`}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={2} md={2} lg={1} xl={1}>
                        <BadgeIcon sx={{ mr: 1 }} />{" "}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        md={10}
                        lg={11}
                        xl={11}
                        className="profile-details-item"
                      >
                        <b>Nombre: </b>{" "}
                        {user.userTypeId === 1
                          ? `${capitalizeFirstLetter(
                              user.adminCompany.firstName
                            )} ${capitalizeFirstLetter(
                              user.adminCompany.secondName
                            )} ${capitalizeFirstLetter(
                              user.adminCompany.firstLastName
                            )}`
                          : user.userTypeId === 2
                          ? `${capitalizeFirstLetter(
                              user.operator.firstName
                            )} ${capitalizeFirstLetter(
                              user.operator.secondName
                            )} ${capitalizeFirstLetter(
                              user.operator.firstLastName
                            )}`
                          : user.userTypeId === 3
                          ? `${capitalizeFirstLetter(
                              user.adminDecimetrix.firstName
                            )} ${capitalizeFirstLetter(
                              user.adminDecimetrix.secondName
                            )} ${capitalizeFirstLetter(
                              user.adminDecimetrix.firstLastName
                            )}`
                          : null}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={2} md={2} lg={1} xl={1}>
                        <DescriptionIcon sx={{ mr: 1 }} />{" "}
                      </Grid>
                      <Grid
                        item
                        className="profile-details-item"
                        xs={10}
                        md={10}
                        lg={11}
                        xl={11}
                      >
                        {" "}
                        <b>Descripción: </b> {user.userType.description}
                      </Grid>
                    </Grid>
                  </Item>

                  <Item2>
                    <Wrapperbtn>
                      <ProfileButton>
                        <AddButton htmlFor="files">
                          <p>Editar foto de perfil</p>
                        </AddButton>
                        <input
                          id="files"
                          style={{ visibility: "hidden" }}
                          accept="image/*"
                          type="file"
                          name="file"
                          onChange={uploadFile}
                        />
                      </ProfileButton>
                      <ProfileButton onClick={changePassword}>
                        <AddButton>
                          <p>Cambiar contraseña</p>
                        </AddButton>
                      </ProfileButton>
                    </Wrapperbtn>
                    <OpenDialog
                      openDialog={pass}
                      setOpenDialog={setPass}
                      execute={executeChangePassword}
                      content={{
                        title: "Change password",
                        description:
                          "Are you sure you want to change your password?",
                        disagree: "no",
                        agree: "yes",
                      }}
                    />
                    <OpenDialog
                      openDialog={passdialog}
                      setOpenDialog={setPassdialog}
                      content={{
                        title: "Check your email",
                        description:
                          "A e-mail has been send. Please follow the password change instructions. Please remember to check your spam or junk mail folder",
                        disagree: "Continue",
                      }}
                    />
                    <OpenDialog
                      openDialog={dialogs}
                      setOpenDialog={setDialog}
                      execute={savePhotos}
                      content={openDialogSavePhoto}
                    />
                  </Item2>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <ProfileFormContainer>
                  <WrapperForm>
                    <Form action="">
                      <InputComponent
                        state={firstName}
                        changeState={setFirstName}
                        value={capitalizeFirstLetter(firstName)}
                        className="field left"
                        type="text"
                        label="Primer Nombre"
                        name="firstName"
                        // value={firstName}
                        legendError="The name can only contain letters."
                        disabled={"disabled"}
                        readonly
                        // regularExpression={expressions.firstName}
                      />
                      {secondName === null ? (
                        <InputComponent
                          state=""
                          changeState=""
                          value=""
                          type="text"
                          label="Segundo Nombre"
                          name="secondName"
                          legendError="The name can only contain letters."
                          disabled={"disabled"}
                          // regularExpression={expressions.secondName}
                        />
                      ) : (
                        <InputComponent
                          state={secondName}
                          changeState={setSecondName}
                          value={capitalizeFirstLetter(secondName)}
                          type="text"
                          label="Segundo Nombre"
                          name="secondName"
                          legendError="The name can only contain letters."
                          disabled={"disabled"}
                          // regularExpression={expressions.secondName}
                        />
                      )}
                      <InputComponent
                        state={firstLastName}
                        changeState={setFirstLastName}
                        value={capitalizeFirstLetter(firstLastName)}
                        type="text"
                        label="Apellido"
                        name="firstLastName"
                        legendError="The last name can only contain letters."
                        disabled={"disabled"}
                        // regularExpression={expressions.firstLastName}
                      />
                      <InputComponent
                        state={role}
                        changeState={setRole}
                        value={capitalizeFirstLetter(role)}
                        type="text"
                        label="Role"
                        name="role"
                        legendError="The role can only contain letters and spaces."
                        disabled={"disabled"}
                        // regularExpression={expressions.role}
                      />
                      <InputComponent
                        state={email}
                        changeState={setEmail}
                        value={capitalizeFirstLetter(email)}
                        type="email"
                        readOnly
                        label="E-mail"
                        placeholder="email@email.com"
                        name="email"
                        legendError="The e-mail can only contain letters, numbers, periods, hyphens and underscore."
                        disabled={"disabled"}
                        // regularExpression={expressions.email}
                      />

                      {user.userTypeId === 1 || user.userTypeId === 2 ? (
                        <>
                          {" "}
                          <InputComponent
                            state={area}
                            changeState={setArea}
                            value={capitalizeFirstLetter(area)}
                            className="field left"
                            type="text"
                            label="Area"
                            name="area"
                            // value={firstName}
                            legendError="The name can only contain letters."
                            disabled={"disabled"}
                            readonly
                            // regularExpression={expressions.firstName}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {user.userTypeId === 1 ? (
                        <>
                          {" "}
                          <InputComponent
                            state={project}
                            changeState={setProject}
                            value={capitalizeFirstLetter(project)}
                            className="field left"
                            type="text"
                            label="Proyecto"
                            name="firstName"
                            // value={firstName}
                            legendError="The name can only contain letters."
                            disabled={"disabled"}
                            readonly
                            // regularExpression={expressions.firstName}
                          />
                        </>
                      ) : (
                        <> </>
                      )}
                    </Form>
                  </WrapperForm>
                </ProfileFormContainer>
              </Grid>
            </Grid>
            {user.userTypeId === 1 ? (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={5}>
                    <Card
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: 4,
                        height: "100%",
                        "box-shadow": "0px 0px 10px 0 rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="20px"
                        spacing={2}
                      >
                        <Avatar
                          alt="Avatar"
                          src={empresas}
                          sx={{ width: 206, height: 210 }}
                        ></Avatar>
                      </Stack>
                      <br />

                      <Item>
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={2} md={2} lg={1} xl={1}>
                            <WorkIcon sx={{ mr: 1 }} />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={10}
                            lg={11}
                            xl={11}
                            className="profile-details-item"
                          >
                            <b>Empresa: </b>
                            {`${capitalizeFirstLetter(
                              user.adminCompany.company.name.toLowerCase()
                            )}`}
                          </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={2} md={2} lg={1} xl={1}>
                            <BadgeIcon sx={{ mr: 1 }} />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={10}
                            lg={11}
                            xl={11}
                            className="profile-details-item"
                          >
                            <b>Composicion: </b>{" "}
                            {`${capitalizeFirstLetter(
                              user.adminCompany.company.composicionUt.toLowerCase()
                            )}`}
                          </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={2} md={2} lg={1} xl={1}>
                            <DescriptionIcon sx={{ mr: 1 }} />{" "}
                          </Grid>
                          <Grid
                            item
                            className="profile-details-item"
                            xs={10}
                            md={10}
                            lg={11}
                            xl={11}
                          >
                            {" "}
                            <b>Nit: </b> {user.adminCompany.company.nit}
                          </Grid>
                        </Grid>
                      </Item>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <ProfileContainer>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <StyledP>Representante Legal</StyledP>
                          </Box>
                          <br />
                          <hr />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>Nombres</StyledP>
                          {user.adminCompany.company.legalRepresentative
                            .secondName === null ? (
                            <></>
                          ) : (
                            <p>
                              {
                                user.adminCompany.company.legalRepresentative
                                  .firstName
                              }{" "}
                              {
                                user.adminCompany.company.legalRepresentative
                                  .secondName
                              }
                            </p>
                          )}
                        </Grid>

                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>Apellido</StyledP>
                          <p>
                            {
                              user.adminCompany.company.legalRepresentative
                                .lastName
                            }
                          </p>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>E-mail</StyledP>
                          <p>
                            {
                              user.adminCompany.company.legalRepresentative
                                .email
                            }
                          </p>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>Telefono</StyledP>
                          <p>
                            {
                              user.adminCompany.company.legalRepresentative
                                .phone
                            }
                          </p>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {" "}
                            <StyledP>Responsable LDAR</StyledP>
                          </Box>
                          <br />
                          <hr />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>Nombres</StyledP>
                          {user.adminCompany.company.responsibleLdar
                            .secondName === null ? (
                            <></>
                          ) : (
                            <p>
                              {
                                user.adminCompany.company.responsibleLdar
                                  .firstName
                              }{" "}
                              {
                                user.adminCompany.company.responsibleLdar
                                  .secondName
                              }
                            </p>
                          )}
                        </Grid>

                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>Apellido</StyledP>
                          <p>
                            {user.adminCompany.company.responsibleLdar.lastName}
                          </p>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>E-mail</StyledP>
                          <p>
                            {user.adminCompany.company.responsibleLdar.email}
                          </p>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          {" "}
                          <StyledP>Telefono</StyledP>
                          <p>
                            {user.adminCompany.company.responsibleLdar.phone}
                          </p>
                        </Grid>
                      </Grid>
                    </ProfileContainer>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ProfileStyle>
        </Container>
      ) : (
        <Loading />
      )}
    </div>
  );
}
