import { useEffect } from "react";
import Container from "@mui/material/Container";
import Loading from "components/Lodings/LoadingV2";

import AdminTabs from "./AdminTabs";

import useGet from "hooks/useFetch";
import neuronaGreen from "../../../images/neuron-3.png";

import {
  HubH1,
  UserH2Flex,
  HubD,
  LogoBig,
  ContainerIntro,
  ContainerTitle,
  LastConteiner,
} from "./HubStyles";
import Layout from "components/Layout/AdminDecimetrix";

const Admin = () => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [adminCompany, errorAdminCompany] = useGet(
    `api/v1/admin-company/${adminCompanyId}`
  );

  useEffect(() => {
    const persist = localStorage.getItem("persist:root");
    if (persist !== null) {
      localStorage.removeItem("persist:root");
    }
  }, []);

  const formatDate = (string) => {
    if (string === null) {
      return "";
    } else {
      const newString = string.replace("T", " ");
      return newString.replace(".000Z", " ");
    }
  };
  return (
    <Layout>
      <Container
        maxWidth="false"
        style={{
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        {!errorAdminCompany && adminCompany ? (
          <>
            <ContainerIntro>
              <img
                src={neuronaGreen}
                alt="DecimetrixGreen"
                style={{ width: "60px", height: "auto", marginLeft: "20px", marginRight: "50px" }}
              />
              <ContainerTitle>
                <HubH1>
                  Decimetrix<sup style={{ fontSize: 25 }}>®</sup> Green Dragon,
                  accelerate your journey to NET-ZERO.
                </HubH1>

                <HubD>
                  Create and get value from your digital Inventory, Inspect and
                  integrate your efforts around sustainability, detect, quantify
                  and report your progress towards NET ZERO, all in one tool for
                  your enterprise. Collaborate like never before to meet your
                  goals.{" "}
                </HubD>
              </ContainerTitle>
              <LogoBig />
            </ContainerIntro>
              <LastConteiner>
            <UserH2Flex>
              <b>Last login:&nbsp;</b>{" "}
              {adminCompany !== undefined && adminCompany !== null
                ? `${formatDate(
                    adminCompany?.user?.userVerification?.lastLogin
                  )}`
                : null}
            </UserH2Flex>
            </LastConteiner>
            <AdminTabs />
          </>
        ) : (
          <Loading />
        )}
      </Container>
    </Layout>
  );
};

export default Admin;
