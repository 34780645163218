import { Grid } from "@mui/material";
import { Container } from "./styles";
import CustomButton from "../CustomButton";

export default function GroupButtons({ infoButtons, setButton }) {
  return (
    <Grid item xs={12} md={12} lg={12} xl={12}>
      <Container>
        {infoButtons.map((infoButtons) => (
          <CustomButton
            text={infoButtons}
            onClick={() => setButton(infoButtons)}
          />
        ))}
      </Container>
    </Grid>
  );
}
