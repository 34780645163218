import {
  ContainerOperations,
  CustomizedChevronLeftIcon,
} from "./EventDetailsElements";
import { WraperTitleCardMarker, InfoTitle } from "../ImageDetailsElements";
import OpenDialog from "../../Dialogs/OpenDialog";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import useSwr from "swr";
import { useSWRConfig } from "swr";

import ObjectDetails from "../ObjectDetails/ObjectDetails";
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";

import TableEvent from "./TableEvent";
import EventMap from "components/Maps/EventMap";

import findObjects from "services/findObjects";

import PhotoGallery from "../PhotoGalley/PhotoGallery";
import AddPicture from "./AddPicture/AddPicture";
import { config } from "config.js";

import { LibToolTip } from "./EventDetailsElements";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ComponentDetails from "../ComponentDetails/Index";

const EventDetails = ({ eventId, fromObject, fromComponent, fromEvent }) => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const [errorMessage, setErrorMessage] = useState(false);

  const [objectGHG, setObjectGHG] = useState(false);

  const [event, setEvent] = useState([]);
  const [eventCount, setEventCount] = useState([]);

  const { data: eventObject } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-events?id=${eventId}`
  );

  const { data: eventComponent } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${eventId}`
  );

  const { data: eventRelation } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${eventId}`
  );

  useEffect(() => {
    eventObject?.length > 0 && setEvent(eventObject);
    eventComponent?.length > 0 && setEvent(eventComponent);
    eventRelation?.length > 0 && setEvent(eventRelation);
  }, [eventObject, eventComponent, eventRelation]);

  useEffect(() => {
    eventObject?.length > 0 &&
      eventObject[0].eventsCount?.length > 0 &&
      setEventCount(eventObject[0]?.eventsCount);
  }, [eventObject]);

  //contorl whenever event is a child
  useEffect(() => {
    event?.length > 0 &&
      event[0]?.eventsCount?.length > 0 &&
      setEventCount(event[0]?.eventsCount);
  }, [event]);

  // Get object the current event
  useEffect(() => {
    const getObject = async () => {
      const objectId = eventObject[0]?.objectId;
      const data = await findObjects(objectId);
      setObjectGHG(data);
    };
    eventObject?.length > 0 && getObject();
  }, [eventObject]);

  const returnToObject = () => {
    const object = {
      content: <ObjectDetails objectId={objectGHG.id} />,
      title: "Object Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };
  const returnToComponent = () => {
    const component = {
      content: (
        <ComponentDetails componentId={eventComponent[0].pointComponentId} />
      ),
      title: "Component Details",
    };
    dispatch(setOperationDetails(component));
    dispatch(setShowOperationDetails(true));
  };

  const returnToEvent = () => {
    setEvent([]);
    const event = {
      content: (
        <EventDetails eventId={eventRelation[0].PointEventRelationWith[0].id} />
      ),
      title: "Event Details",
    };
    dispatch(setOperationDetails(event));
    dispatch(setShowOperationDetails(true));
  };

  const uploadImages = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/point-events?id=${eventId}`);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${eventId}`
    );
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${eventId}`
    );
  };

  return (
    <>
      {event?.length > 0 && (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            position: "relative",
          }}
        >
          {fromObject && eventObject?.length > 0 && (
            <IconButton
              onClick={returnToObject}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          {fromComponent && eventComponent?.length > 0 && (
            <IconButton
              onClick={returnToComponent}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          {fromEvent && eventRelation?.length > 0 && (
            <IconButton
              onClick={returnToEvent}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          <OpenDialog
            openDialog={errorMessage}
            setOpenDialog={setErrorMessage}
            content={{
              title: "Conexion lost",
              description: "Please check your internet conexion and try again.",
              disagree: "Continue",
            }}
          />
          <ContainerOperations>
            {/* --EVENT TYPE-- */}
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="info-event">
                <WraperTitleCardMarker>
                  <h1>
                    EVENT TYPE
                    <br />
                    <span> {event[0].pointTypeEvent?.name} </span>
                  </h1>
                  <img
                    width="30px"
                    height="100px"
                    alt="ico"
                    src={event[0].pointTypeEvent?.icon}
                  ></img>
                </WraperTitleCardMarker>
                <TableEvent
                  event={event[0]}
                  deleteButton={event.length > 0 ? false : true}
                />
                <InfoTitle>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <section className="main-tables-container">
                      <div className="main-currency-table">
                        {eventCount?.length > 0 && (
                          <p className="currency-table--title">Events</p>
                        )}
                        <div className="currency-table--container">
                          <table>
                            {eventCount?.length > 0 &&
                              eventCount.map((it) => {
                                return (
                                  <tr>
                                    <td className="table__bottom-left">
                                      <LibToolTip>
                                        {it.typeName}
                                        <span className="tooltiptrigger">
                                          <LocalLibraryIcon
                                            sx={{
                                              fontSize: "1.8rem",
                                              color: "#3f51b5",
                                            }}
                                          />
                                        </span>
                                        <span className="tooltip">
                                          Library {it.libName}
                                        </span>
                                      </LibToolTip>
                                    </td>
                                    <td className="table__bottom-right table__right">
                                      {it.quantity}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </div>
                    </section>
                  </Grid>
                </InfoTitle>
              </div>
            </Grid>

            {/* --OPERATION PHOTOS-- */}

            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div className="photo-container-image">
                <h1 className="event-title">
                  <span>GALLERY</span>
                </h1>
                <div
                  className="gallery"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 24px 0px 20px",
                  }}
                >
                  {event[0].eventFiles.length > 0 ||
                  event[0]?.media?.length > 0 ? (
                    <>
                      <p style={{ fontSize: "1.6rem" }}>
                        <b>Total Pictures: </b> {event[0].eventFiles.length}
                      </p>
                      <br />
                      <PhotoGallery
                        photos={[...event[0]?.eventFiles, ...event[0]?.media]}
                        ownId={event[0]?.id}
                        mongoId={event[0]?.mongoId}
                        type={"event"}
                        uploadImages={uploadImages}
                      />
                      <AddPicture event={event[0]} />
                    </>
                  ) : (
                    <AddPicture event={event[0]} />
                  )}
                </div>
              </div>
            </Grid>

            {/* -- MAP -- */}
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="content-map">
                <h1 className="event-title">
                  <span>LOCATION</span>
                </h1>
                <EventMap event={event[0]} />
              </div>
            </Grid>
          </ContainerOperations>
        </Box>
      )}
    </>
  );
};

export default EventDetails;
