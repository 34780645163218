import {
  CustomContainer,
  BannerDescription,
  BannerTitle,
  LogoDecimetrix,
  NeuronImage,
} from "./ForgotPasswordElemts";

export default function BannerDecimetrix() {
  const decimetrixImageUrl =
    "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/img_footer.png";
  const neuronUrl =
    "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png";
  return (
    <CustomContainer>
      <NeuronImage src={neuronUrl} />
      <LogoDecimetrix
        className="form-image"
        src={decimetrixImageUrl}
        alt="logo"
      />
      <BannerTitle>
        GREEN DRAGON <br /> CARBON FOOTPRINT MANAGEMENT SYSTEM
      </BannerTitle>
      <BannerDescription>
        End to end solution for the invetory, detection, quantification,
        estimation and management of GHG emissions.
      </BannerDescription>
    </CustomContainer>
  );
}
