import useSwr from "swr";

const FetchFieldVideosByUserId = (userId) => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/field-video/userId/${userId}`
  );
  return { data, error };
};

export default FetchFieldVideosByUserId;
