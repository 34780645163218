import axios from "axios";

const updateRegionApiMg = async (regionGeoJson, newIdUpdate) => {
  const res = await axios.put(
    `${process.env.REACT_APP_URL_MG_API}region/${newIdUpdate}`,
    regionGeoJson
  );
  const data = await res.data;
  return { data, res };
};

export default updateRegionApiMg;
