import TopBar from "components/Bar/TopBar/TopBar";

export default function DecimetrixAdmin({ children }) {
  return (
    <>
      <TopBar linkLogo="hub" />
      {children}
    </>
  );
}
