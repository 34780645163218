import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ManageUserButtons } from "./TableStyle";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import Profile from "components/Profile/Profile";
import OperationDialog from "components/Dialogs/OperationDialog";
import { useDispatch } from "react-redux";
import {
  setOperationDetails,
  setShowOperationDetails,
} from "redux/actions/index.jsx";
import Loading from "components/Lodings/LoadingV2";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  RED_COLOR,
  VISIBILITY_OFF_ICON,
  WHITE_COLOR,
} from "utils/const";

import FetchOperator from "hooks/fetchOperator";
import { roles } from "utils/roles";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "secondName",
    numeric: false,
    disablePadding: false,
    label: "Segundo Nombre",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Apellido",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "E-mail",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "area",
    numeric: false,
    disablePadding: false,
    label: "Area",
  },
  {
    id: "confirmationToken",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Fecha de Creacion",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const manageUser = async (email) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/user-verification?email=${email}`,
      {
        method: "GET",
      }
    );
    const response = await res.json();

    return response;
  } catch (error) {
    console.error(error);
  }
};

const createRows = (operators) => {
  return Promise.all(
    operators.map(async (operator) => {
      const res = await manageUser(operator.user.email);
      return {
        id: operator.userId,
        firstName: operator.firstName,
        secondName: operator.secondName,
        lastName: operator.firstLastName,
        email: operator.user.email,
        company: operator.company.name,
        role: operator.role?.role,
        area: operator.area,
        canDelete: operator.user.objects.length === 0,
        confirmationToken: operator.user.userVerification.confirmationToken,
        createdAt: operator.createdAt.substring(0, 10),
        isEnable: res.isEnable,
      };
    })
  );
};

export default function EnhancedTable({ handleClose }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [idUserDelete, setIdUserDelete] = useState(null);
  const [contentDialog, setContentDialog] = useState({});
  const [openManageUser, setOpenManageUser] = useState(false);
  const [infoUser, setInfoUser] = useState({
    id: 0,
    email: "",
  });
  const [isLoad, setIsLoad] = useState(false);

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const operatorId = localStorage.getItem("operatorId");

  const { data: operatorRole, error: errorOperator } =
    FetchOperator(operatorId);

  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoad(true);
      const result = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/operators/${adminCompanyId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const res = result.data;
      const operators = await createRows(res.operators);
      setRows(operators);
      setIsLoad(false);
    };
    fetchData();
  }, [handleClose, adminCompanyId, token, infoUser]);

  const manageUser = async (email) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/user-verification?email=${email}`,
        {
          method: "GET",
        }
      );
      const response = await res.json();

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleDelete = async (operator) => {
    setIdUserDelete(operator.id);
    setContentDialog({
      title: "",
      description: `Are you sure you want to delete ${operator.firstName}?`,
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenDialog(true);
  };

  const handleUserActivation = async (operator) => {
    setInfoUser({
      id: operator.id,
      email: operator.email,
    });
    setContentDialog({
      title: "The activation",
      description: operator.isEnable
        ? `Do you want disable ${operator.firstName} user?`
        : `Do you want enable ${operator.firstName} user?`,
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenManageUser(true);
  };

  const deleteUser = async () => {
    await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/operators/${idUserDelete}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const rowsHelp = rows.filter((row) => row.id !== idUserDelete);
    setRows(rowsHelp);
    setContentDialog({});
    setIdUserDelete(null);
    setOpenDialog(false);
  };

  const statusUser = async () => {
    const userVerificationResponse = await manageUser(infoUser.email);
    await axios.patch(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/user-verification/status/${infoUser.id}`,
      {
        isEnable: !userVerificationResponse.isEnable,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    setRows(rows);
    setOpenManageUser(false);
    setContentDialog({});
    setInfoUser({});
  };

  const handleClickUser = async (userId) => {
    if (userId) {
      const user = {
        content: <Profile userId={userId} />,
        title: "User Details",
      };
      dispatch(setOperationDetails(user));
      dispatch(setShowOperationDetails(true));
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {rows.length > 0 ? (
        <>
          <OpenDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            execute={deleteUser}
            content={contentDialog}
            disagree={() => {}}
          />
          <OpenDialog
            openDialog={openManageUser}
            setOpenDialog={setOpenManageUser}
            execute={statusUser}
            content={contentDialog}
            disagree={() => {}}
          />
          <OperationDialog
            style={{
              margin: 0,
              padding: 0,
              width: "70%",
              height: "70%",
              maxWidth: "none",
            }}
          />

          <Paper sx={{ width: "100%", mb: 2 }}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                sx={{
                  minWidth: 750,
                  "& th": {
                    color: `${BLACK_COLOR}`,
                    backgroundColor: `${WHITE_COLOR}`,
                  },
                }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const prop = row.canDelete
                        ? { onClick: () => handleDelete(row) }
                        : { disable: "true" };
                      const prop2 = {
                        onClick: () => handleUserActivation(row),
                      };
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell id={labelId} align="left">
                            <buttom
                              style={{
                                cursor: "pointer",
                                backgroundColor: `${WHITE_COLOR}`,
                                border: "none",
                              }}
                              onClick={() => handleClickUser(row.id)}
                            >
                              {row.firstName}
                            </buttom>
                          </TableCell>
                          <TableCell align="left">{row.secondName}</TableCell>
                          <TableCell align="left">{row.lastName}</TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">{row.company}</TableCell>
                          <TableCell align="left">{row.role}</TableCell>
                          <TableCell align="left">{row.area}</TableCell>
                          <TableCell align="left">
                            {row.confirmationToken === null
                              ? "Verified e-mail"
                              : "E-Mail pending of verification"}
                          </TableCell>
                          <TableCell align="left">
                            <ManageUserButtons {...prop}>
                              {!errorOperator &&
                                operatorRole?.operator?.role?.role !==
                                  roles.itSupport && (
                                  <DeleteIcon
                                    sx={{
                                      color: prop.disable
                                        ? `${VISIBILITY_OFF_ICON}`
                                        : `${RED_COLOR}`,
                                      cursor: prop.disable
                                        ? "not-allowed"
                                        : "pointer",
                                      fontSize: "2.6rem",
                                    }}
                                  />
                                )}
                            </ManageUserButtons>
                            <ManageUserButtons {...prop2}>
                              {row.isEnable === false ? (
                                <PersonOffIcon
                                  sx={{
                                    color: `${VISIBILITY_OFF_ICON}`,
                                    cursor: "pointer",
                                    fontSize: "2.6rem",
                                  }}
                                />
                              ) : (
                                <PersonOutlineIcon
                                  sx={{
                                    color: `${PRIMARY_COLOR}`,
                                    cursor: "pointer",
                                    fontSize: "2.6rem",
                                  }}
                                />
                              )}
                            </ManageUserButtons>
                          </TableCell>
                          <TableCell align="left">{row.createdAt}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[8, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : isLoad ? (
        <Loading />
      ) : (
        <h2>No hay operarios registrados.</h2>
      )}
    </Box>
  );
}
