import axios from "axios";

const findFlirVideo = async (id, inspectionFlirId) => {
  const token = localStorage.getItem("token");
  let path;
  if (id) path = `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video/${id}`;
  if (!id && inspectionFlirId)
    path = `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video/inspection-flir/${inspectionFlirId}`;
  const res = await axios.get(path, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  if (res) return res;
  return new Error("Find failed");
};

export default findFlirVideo;
