import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { useState } from "react";
// Components
import MarkerObjets from "./MarkerObjets";
import Settings from "./Settings";
import Sensors from "./Sensors";
// Data
import objects from "./objects.json";
// Style
import { MainContainer } from "./SensorOptimizationStyle";
import fogStyle from "utils/fogStyle";

const Index = () => {
  const [sensors, setSensors] = useState();
  const [settings, setSettings] = useState();

  return (
    <MainContainer>
      <div className="settings">
        <Settings
          objects={objects}
          setSensors={setSensors}
          setSettings={setSettings}
        />
      </div>
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        initialViewState={{
          latitude: 6.933774,
          longitude: -71.165612,
          zoom: 17,
        }}
        style={{ width: "100%", height: "100vh", margin: "auto" }}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        attributionControl={false}
        projection={"globe"}
        fog={fogStyle}
      >
        <MarkerObjets objects={objects} />
        {settings && sensors && (
          <Sensors sensors={sensors} settings={settings} />
        )}
      </Map>
    </MainContainer>
  );
};

export default Index;
