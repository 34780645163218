import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useCallback } from "react";
import { useControl } from "react-map-gl";
import { useDispatch, useStore } from "react-redux";
import { setDrawAnyThing, setDrawerControl, setMap } from "redux/actions/index";
import { setFeaturesDetailsDT } from "redux/actions/admin";
import { setNeuronSelectTool } from "redux/actions/digitalTwin";

const typeDraw = {
  point: "draw_point",
  polygon: "draw_polygon",
  line: "draw_line_string",
};

export default function DrawControl(props) {
  const store = useStore();
  const dispatch = useDispatch();

  // Find points close to a coordinate
  const findClosePoint = useCallback(
    (coordinate, radius) => {
      let closestPoint = null;
      let closestDistance = Infinity;
      const { filteredObjects: points } = store.getState().digitalTwinReducer;
      const [x, y] = coordinate;
      points.filter((point) => {
        const [px, py] = point.geometry.coordinates;
        const fx = Math.sqrt((px - x) ** 2 + (py - y) ** 2);
        const r = fx < radius;
        if (r && fx < closestDistance) {
          closestDistance = fx;
          closestPoint = point;
        }
        return r;
      });
      return closestPoint;
    },
    [store]
  );

  const clickMap = useCallback(
    (controlGl, map) => {
      const { type } = store.getState().digitalTwinReducer.drawAnyThing;
      let mode = controlGl.getMode();
      const zoom = map.getZoom();
      const maxZoom = 23;
      const radius = 0.00001;
      const radiusZoom = radius * (maxZoom - zoom);
      if (mode !== "draw_line_string" && type !== "point") {
        return;
      }
      const { features } = controlGl.getAll();
      const lastFeature = features[features.length - 1];
      const { geometry } = lastFeature;
      const { coordinates } = geometry;
      const lenCoordinates = coordinates.length;
      const point =
        type === "point" ? coordinates : coordinates[lenCoordinates - 2];
      const closePint = findClosePoint(point, radiusZoom);
      if (closePint) {
        const [lon, lat] = closePint.geometry.coordinates;
        if (type === "point") {
          coordinates[0] = lon;
          coordinates[1] = lat;
        } else {
          coordinates[coordinates.length - 2] = [lon, lat];
        }
        controlGl.set({
          type: "FeatureCollection",
          features: [{ ...lastFeature }],
        });
        if (type === "point") {
          dispatch(setFeaturesDetailsDT({ ...lastFeature }));
        }
      }
    },
    [findClosePoint, store, dispatch]
  );

  useControl(
    ({ map }) => {
      const controlGl = new MapboxDraw({ ...props }); 
      dispatch(setDrawerControl(controlGl));
      dispatch(setMap(map));
      window.addEventListener("storage", (event) => {
        if (event.key === "coordinates") {
          const coordinatesParse = JSON.parse(
            localStorage.getItem("coordinates")
          );
          map.flyTo({
            center: [coordinatesParse[0], coordinatesParse[1]],
            duration: 2000,
            zoom: 24,
          });
        }
      });
      map.on("draw.select", props.onSelect);
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
      map.on("load", function () {
        map.addControl(controlGl);
        map.on("click", () => clickMap(controlGl, map));
        map.on("draw.modechange", () => {
          const mode = controlGl.getMode();
          if (mode === typeDraw.line)
            dispatch(
              setNeuronSelectTool({
                show: true,
                top: 221,
              })
            );
          if (mode === typeDraw.polygon)
            dispatch(
              setNeuronSelectTool({
                show: true,
                top: 250,
              })
            );
          if (mode === typeDraw.point)
            dispatch(
              setNeuronSelectTool({
                show: true,
                top: 280,
              })
            );
          const isDraw = mode.includes("draw");
          setTimeout(() => {
            dispatch(
              setDrawAnyThing({
                isDraw,
                type: mode.replace("draw_", ""),
              })
            );
          }, 10);
        });
      });
    },
    {
      position: props.position,
    }
  );
  return null;
}

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
};
