import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import {
  FormContainer,
  WrapperForm,
  Form,
  ContainerButtonCenter,
  ContainerButtonBottom,
  ContainerButtonRegister,
  ContainerButtonBack,
  Button,
  MessageError,
  MessageSuccess,
} from "components/Forms/FormStyles";

import InputComponent from "components/Forms/Input";

const steps = [
  "Datos del usuario",
  "Empresa y rep. legal",
  "Datos del respresentante",
  "Datos ing petroleos",
];

const FormSignUp = ({ handleClose }) => {
  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });
  const [company, setCompany] = useState({ field: "", valid: null });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [area, setArea] = useState({ field: "", valid: null });
  const [project, setProject] = useState({ field: "", valid: null });
  const [FormValidated, setFormValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const token = localStorage.getItem("token");
  const [messageError, setMessageError] = useState("");
  const [step, setStep] = useState(1);
  const [nameCompany, setNameCompany] = useState({ field: "", valid: null });
  const [nitCompany, setNitCompany] = useState({ field: "", valid: null });
  const [typeCompany, setTypeCompany] = useState({ field: "", valid: null });
  const [composicionUtCompany, setComposicionUtCompany] = useState({
    field: "",
    valid: null,
  });
  const [firstNameCompany, setFirstNameCompany] = useState({
    field: "",
    valid: null,
  });
  const [secondNameCompany, setSecondNameCompany] = useState({
    field: "",
    valid: null,
  });
  const [lastNameCompany, setLastNameCompany] = useState({
    field: "",
    valid: null,
  });
  const [roleCompany, setRoleCompany] = useState({
    field: "",
    valid: null,
  });
  const [emailCompany, setEmailCompany] = useState({
    field: "",
    valid: null,
  });
  const [phoneCompany, setPhoneCompany] = useState({
    field: "",
    valid: null,
  });
  // Data responsible ldar
  const [firstNameResponsible, setFirstNameResponsible] = useState({
    field: "",
    valid: null,
  });
  const [secondNameResponsible, setSecondNameResponsible] = useState({
    field: "",
    valid: null,
  });
  const [lastNameResponsible, setLastNameResponsible] = useState({
    field: "",
    valid: null,
  });
  const [roleResponsible, setRoleResponsible] = useState({
    field: "",
    valid: null,
  });
  const [emailResponsible, setEmailResponsible] = useState({
    field: "",
    valid: null,
  });
  const [phoneResponsible, setPhoneResponsible] = useState({
    field: "",
    valid: null,
  });
  // Data oil engineer
  const [firstNameOilEngineer, setFirstNameOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [secondNameOilEngineer, setSecondNameOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [lastNameOilEngineer, setLastNameOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [roleOilEngineer, setRoleOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [emailOilEngineer, setEmailOilEngineer] = useState({
    field: "",
    valid: null,
  });
  const [phoneOilEngineer, setPhoneOilEngineer] = useState({
    field: "",
    valid: null,
  });

  const expressions = {
    firstName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    firstLastName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    secondName: /^[a-zA-ZÀ-ÿ]{0,40}$/, // letters only
    company: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    area: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    project: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    password: /^.{8,60}$/, // 8 a 60 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    nameCompany: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    nitCompany: /^[0-9]+$/,
    typeCompany: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    composicionUtCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    firstNameCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    secondNameCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    lastNameCompany: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    roleCompany: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    emailCompany: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    phoneCompany: /^[0-9]+$/,
    firstNameResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    secondNameResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    lastNameResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    roleResponsible: /^[a-zA-ZÀ-ÿ]{0,40}$/,
    emailResponsible: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    phoneResponsible: /^[0-9]+$/,
  };

  const saveUser = async (body) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: JSON.stringify(body),
        }
      );
      await res.json();
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      firstName.valid === "true" &&
      firstLastName.valid === "true" &&
      company.valid === "true" &&
      email.valid === "true" &&
      area.valid === "true" &&
      project.valid === "true"
    ) {
      const body = {
        firstName: firstName.field,
        secondName: secondName.field,
        firstLastName: firstLastName.field,
        area: area.field,
        project: project.field,
        adminDecimetrixId: adminDecimetrixId,
        user: {
          email: email.field,
        },
        company: {
          name: nameCompany.field,
          nit: nitCompany.field,
          type: typeCompany.field,
          composicionUt: composicionUtCompany.field,
          legalRepresentative: {
            firstName: firstNameCompany.field,
            secondName: secondNameCompany.field,
            lastName: lastNameCompany.field,
            role: roleCompany.field,
            email: emailCompany.field,
            phone: phoneCompany.field,
          },
          responsibleLdar: {
            firstName: firstNameResponsible.field,
            secondName: secondNameResponsible.field,
            lastName: lastNameResponsible.field,
            role: roleResponsible.field,
            email: emailResponsible.field,
            phone: phoneResponsible.field,
          },
          oilEngineer: {
            firstName: firstNameOilEngineer.field,
            secondName: secondNameOilEngineer.field,
            lastName: lastNameOilEngineer.field,
            role: roleOilEngineer.field,
            email: emailOilEngineer.field,
            phone: phoneOilEngineer.field,
          },
        },
      };

      const res = await saveUser(body);
      setLoading(false);
      if (res.status === 409) {
        setFormValidated(false);
        setMessageError("This email is already registered");
      } else if (res.status === 201) {
        handleClose();
        setFormValidated(true);
        setMessageError(null);
        setFirstName({ field: "", valid: null });
        setSecondName({ field: "", valid: null });
        setFirstLastName({ field: "", valid: null });
        setEmail({ field: "", valid: null });
        setArea({ field: "", valid: null });
        setProject({ field: "", valid: null });

        setNameCompany({ field: "", valid: null });
        setNitCompany({ field: "", valid: null });
        setTypeCompany({ field: "", valid: null });
        setComposicionUtCompany({ field: "", valid: null });

        setFirstNameCompany({ field: "", valid: null });
        setSecondNameCompany({ field: "", valid: null });
        setLastNameCompany({ field: "", valid: null });
        setRoleCompany({ field: "", valid: null });

        setEmailCompany({ field: "", valid: null });
        setPhoneCompany({ field: "", valid: null });
        setFirstNameResponsible({ field: "", valid: null });
        setSecondNameResponsible({ field: "", valid: null });

        setLastNameResponsible({ field: "", valid: null });
        setRoleResponsible({ field: "", valid: null });
        setEmailResponsible({ field: "", valid: null });
        setPhoneResponsible({ field: "", valid: null });

        setFirstNameOilEngineer({ field: "", valid: null });
        setSecondNameOilEngineer({ field: "", valid: null });
        setLastNameOilEngineer({ field: "", valid: null });
        setRoleOilEngineer({ field: "", valid: null });
        setEmailOilEngineer({ field: "", valid: null });
        setPhoneOilEngineer({ field: "", valid: null });
      } else {
        setFormValidated(false);
        setMessageError("Filld all the fields correctly.");
      }
    } else {
      setFormValidated(false);
      setMessageError("Fill all the fields correctly.");
    }
    setLoading(false);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  return (
    <>
      <br></br>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={step - 1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormContainer>
        <WrapperForm>
          <Form action="" onSubmit={onSubmit}>
            {step === 1 ? (
              <>
                <>
                  <InputComponent
                    state={firstName}
                    changeState={setFirstName}
                    type="text"
                    label="Primer Nombre *"
                    name="firstName"
                    legendError="El nombre solo puede contener letras."
                    regularExpression={expressions.firstName}
                  />
                  <InputComponent
                    state={secondName}
                    changeState={setSecondName}
                    type="text"
                    label="Segundo Nombre"
                    name="secondName"
                    legendError="El nombre solo puede contener letras."
                    regularExpression={expressions.secondName}
                  />
                  <InputComponent
                    state={firstLastName}
                    changeState={setFirstLastName}
                    type="text"
                    label="Apellido *"
                    name="firstLastName"
                    legendError="El apellido solo puede contener letras."
                    regularExpression={expressions.firstLastName}
                  />
                  <InputComponent
                    state={company}
                    changeState={setCompany}
                    type="text"
                    label="Empresa *"
                    name="company"
                    legendError="La compañia solo puede contener letras, numeros, puntos, guiones y guion bajo.."
                    regularExpression={expressions.company}
                  />
                  <InputComponent
                    state={email}
                    changeState={setEmail}
                    type="email"
                    label="E-mail *"
                    placeholder="email@email.com"
                    name="email"
                    legendError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo.."
                    regularExpression={expressions.email}
                  />
                  <InputComponent
                    state={area}
                    changeState={setArea}
                    type="text"
                    label="Area *"
                    placeholder=""
                    name="area"
                    legendError="El area solo puede contener letras."
                    regularExpression={expressions.area}
                  />
                  <InputComponent
                    state={project}
                    changeState={setProject}
                    type="project"
                    label="Proyecto *"
                    placeholder=""
                    name="project"
                    legendError="El area solo puede contener letras."
                    regularExpression={expressions.project}
                  />
                </>
              </>
            ) : step === 2 ? (
              <>
                <InputComponent
                  state={nameCompany}
                  changeState={setNameCompany}
                  type="text"
                  label="Nombre de la empresa *"
                  name="nameCompany"
                  legendError="El nombre de la empresa solo puede contener letras."
                  regularExpression={expressions.nameCompany}
                />
                <InputComponent
                  state={nitCompany}
                  changeState={setNitCompany}
                  type="number"
                  label="Nit *"
                  name="nitCompany"
                  legendError="El telefono solo puede contener numeros."
                  regularExpression={expressions.nitCompany}
                />
                <InputComponent
                  state={typeCompany}
                  changeState={setTypeCompany}
                  type="text"
                  label="Tipo de Empresa *"
                  name="typeCompany"
                  legendError="El tipo de empresa solo puede contener letras."
                  regularExpression={expressions.typeCompany}
                />
                <InputComponent
                  state={composicionUtCompany}
                  changeState={setComposicionUtCompany}
                  type="text"
                  label="Composicion *"
                  name="composicionUtCompany"
                  legendError="La composicion solo puede contener letras."
                  regularExpression={expressions.composicionUtCompany}
                />
                <InputComponent
                  state={firstNameCompany}
                  changeState={setFirstNameCompany}
                  type="text"
                  label="Primer Nombre Rep Legal *"
                  name="firstNameCompany"
                  legendError="El primer nombre solo puede contener letras."
                  regularExpression={expressions.firstNameCompany}
                />
                <InputComponent
                  state={secondNameCompany}
                  changeState={setSecondNameCompany}
                  type="text"
                  label="Segundo Nombre Rep Legal"
                  name="secondNameCompany"
                  legendError="El segundo nombre solo puede contener letras."
                  regularExpression={expressions.secondNameCompany}
                />
                <InputComponent
                  state={lastNameCompany}
                  changeState={setLastNameCompany}
                  type="text"
                  label="Apellido Rep Legal *"
                  name="lastNameCompany"
                  legendError="El apellido solo puede contener letras."
                  regularExpression={expressions.lastNameCompany}
                />
                <InputComponent
                  state={roleCompany}
                  changeState={setRoleCompany}
                  type="text"
                  label="Role Rep Legal *"
                  name="roleCompany"
                  legendError="El rol solo puede contener letras."
                  regularExpression={expressions.roleCompany}
                />
                <InputComponent
                  state={emailCompany}
                  changeState={setEmailCompany}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="emailCompany"
                  legendError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo.."
                  regularExpression={expressions.emailCompany}
                />
                <InputComponent
                  state={phoneCompany}
                  changeState={setPhoneCompany}
                  type="tel"
                  label="Telefono *"
                  placeholder=""
                  name="phoneCompany"
                  legendError="El telefono solo puede contener numeros."
                  regularExpression={expressions.phoneCompany}
                />
              </>
            ) : step === 3 ? (
              <>
                <InputComponent
                  state={firstNameResponsible}
                  changeState={setFirstNameResponsible}
                  type="text"
                  label="Primer Nombre *"
                  name="firstNameResponsible"
                  legendError="El primer nombre solo puede contener letras."
                  regularExpression={expressions.firstNameResponsible}
                />
                <InputComponent
                  state={secondNameResponsible}
                  changeState={setSecondNameResponsible}
                  type="text"
                  label="Segundo Nombre "
                  name="secondNameResponsible"
                  legendError="El segundo nombre solo puede contener letras."
                  regularExpression={expressions.secondNameResponsible}
                />
                <InputComponent
                  state={lastNameResponsible}
                  changeState={setLastNameResponsible}
                  type="text"
                  label="Apellido *"
                  name="lastNameResponsible"
                  legendError="El apellido solo puede contener letras."
                  regularExpression={expressions.lastNameResponsible}
                />
                <InputComponent
                  state={roleResponsible}
                  changeState={setRoleResponsible}
                  type="text"
                  label="Role *"
                  name="roleResponsible"
                  legendError="El rol solo puede contener letras."
                  regularExpression={expressions.roleResponsible}
                />
                <InputComponent
                  state={emailResponsible}
                  changeState={setEmailResponsible}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="emailResponsible"
                  legendError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo.."
                  regularExpression={expressions.emailResponsible}
                />
                <InputComponent
                  state={phoneResponsible}
                  changeState={setPhoneResponsible}
                  type="tel"
                  label="Telefono *"
                  name="phoneResponsible"
                  legendError="El telefono solo puede contener numeros."
                  regularExpression={expressions.phoneResponsible}
                />
              </>
            ) : step === 4 ? (
              <>
                <InputComponent
                  state={firstNameOilEngineer}
                  changeState={setFirstNameOilEngineer}
                  type="text"
                  label="Primer Nombre *"
                  name="firstNameOilEngineer"
                  legendError="El primer nombre solo puede contener letras."
                  regularExpression={expressions.firstNameResponsible}
                />
                <InputComponent
                  state={secondNameOilEngineer}
                  changeState={setSecondNameOilEngineer}
                  type="text"
                  label="Segundo Nombre "
                  name="secondNameOilEngineer"
                  legendError="El segundo nombre solo puede contener letras."
                  regularExpression={expressions.secondNameResponsible}
                />
                <InputComponent
                  state={lastNameOilEngineer}
                  changeState={setLastNameOilEngineer}
                  type="text"
                  label="Apellido *"
                  name="lastNameOilEngineer"
                  legendError="El apellido solo puede contener letras."
                  regularExpression={expressions.lastNameResponsible}
                />
                <InputComponent
                  state={roleOilEngineer}
                  changeState={setRoleOilEngineer}
                  type="text"
                  label="Role *"
                  name="roleOilEngineer"
                  legendError="El rol solo puede contener letras."
                  regularExpression={expressions.roleResponsible}
                />
                <InputComponent
                  state={emailOilEngineer}
                  changeState={setEmailOilEngineer}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="emailOilEngineer"
                  legendError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo.."
                  regularExpression={expressions.emailResponsible}
                />
                <InputComponent
                  state={phoneOilEngineer}
                  changeState={setPhoneOilEngineer}
                  type="tel"
                  label="Telefono *"
                  name="phoneOilEngineer"
                  legendError="El telefono solo puede contener numeros."
                  regularExpression={expressions.phoneResponsible}
                />
              </>
            ) : null}
          </Form>
          {step === 4 && (
            <>
              <ContainerButtonBack>
                <Button onClick={prevStep}>Regresar</Button>
              </ContainerButtonBack>
              <ContainerButtonRegister>
                <Button type="submit" onClick={onSubmit}>
                  Registrar
                </Button>
                {loading && (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}
              </ContainerButtonRegister>
            </>
          )}
          {step === 3 && (
            <>
              <ContainerButtonBack>
                <Button onClick={prevStep}>Regresar</Button>
              </ContainerButtonBack>
              <ContainerButtonRegister>
                <Button onClick={nextStep}>Avanzar</Button>
              </ContainerButtonRegister>
            </>
          )}
          {step === 2 ? (
            <>
              <ContainerButtonCenter>
                <Button onClick={prevStep}>Regresar</Button>
              </ContainerButtonCenter>
              <ContainerButtonBottom>
                <Button onClick={nextStep}>Avanzar</Button>
              </ContainerButtonBottom>
            </>
          ) : step === 1 ? (
            <>
              <ContainerButtonBottom>
                <Button onClick={nextStep}>Avanzar</Button>
              </ContainerButtonBottom>
            </>
          ) : null}
          {FormValidated ? (
            <MessageSuccess>
              <p>User saved successfully</p>
            </MessageSuccess>
          ) : messageError !== "" ? (
            <MessageError>
              <p>{messageError}</p>
            </MessageError>
          ) : null}
        </WrapperForm>
      </FormContainer>
    </>
  );
};

export default FormSignUp;
