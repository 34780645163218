import useSwr from "swr";

const FetchTypeElements = (libraryId) => {
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-elements`;
  if (libraryId) {
    url = `${url}?libraryId=${libraryId}`;
  }
  const { data, error } = useSwr(url);

  return { data, error };
};

export default FetchTypeElements;
