import axios from "axios";

const changeFieldVideoName = async (flirVideoId, newVideoName) => {
  const token = localStorage.getItem("token");
  const res = await axios.patch(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video/name/${flirVideoId}`,
    {
      name: newVideoName,
    },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  if (res) return res;
  return new Error("Update name failed");
};

export default changeFieldVideoName;
