export const isUrl = (string) => {
  const patronURL = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return patronURL.test(string);

};

export const nameUrl = (url) =>  {
  const nameUrl = url.split('/');
  return nameUrl[nameUrl.length - 1] || nameUrl[nameUrl.length - 2];
}
