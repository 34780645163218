import { pointsWithinPolygon, polygon } from "@turf/turf";
import axios from "axios";
// import { useSelector } from "react-redux";

import findEventsPolygon from "services/findEventsPolygon";

var data = [];
const token = localStorage.getItem("token");

const getObjestsInPolygon = async (current, newCurrent, items) => {
  const newItems = items.slice(current, newCurrent);
  const newData = await Promise.all(
    newItems.map(async (object, index) => {
      let locationId = object;
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/location/${locationId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const response = await res.data;
      return response;
    })
  );
  data.push(newData);
  let tempCurrent = current + newItems.length;
  let newTempCurrent = newCurrent + newItems.length;
  if (tempCurrent >= items.length) return;
  return await getObjestsInPolygon(tempCurrent, newTempCurrent, items);
};

// Iterator objects that are inside the polygon
export const iteratorRegionLocationId = async (locationsIdPolygon) => {
  // const newLocations = locationsIdPolygon.slice(0, 200);
  const current = 0;
  const newCurrent = 200;
  await getObjestsInPolygon(current, newCurrent, locationsIdPolygon);
  const newData = [...data.flat()];
  data = [];
  return newData;
};

export const createFeatureCollection = (points) => {
  return {
    type: "FeatureCollection",
    features: points,
  };
};

// Function to know points of feature collection into polygon
export const getPointsPolygon = (coordinatesPolygon, points) => {
  const searchWithin = polygon([coordinatesPolygon]);
  const featureCollection = createFeatureCollection(points);
  const ptsWithin = pointsWithinPolygon(featureCollection, searchWithin);
  return ptsWithin;
};

// function to send polygon geometry to backend
export const sendPolygonGeometry = async (body, objects) => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const res = await axios.post(
    `${process.env.REACT_APP_URL_OCR_API}filterpolygon?adminCompanyId=${adminCompanyId}`,
    body
  );
  const response = await res.data;
  const dataEventsPolygon = await findEventsPolygon(adminCompanyId, body);
  return { response, dataEventsPolygon };
};
