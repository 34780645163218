import AWS from "aws-sdk";
import { config } from "config";

const subBucketName = (bucketId) => {
  const bucketIdToSubBucket = {
    1: "nameplates",
    2: "equipment",
    3: "general",
    4: "nameplates-processed",
    5: "unprocessed-videos",
    6: "processed-videos",
    7: "pdf-reports",
    8: "default",
  };

  const subBucket = bucketIdToSubBucket[bucketId] || "default";
  return subBucket;
};

/**
 * Uploads a file to S3.
 *
 * @param {File} file - The file to be uploaded.
 * @param {string} bucketId - The ID of the bucket where the file will be uploaded. options for bucketId: {
    1: "nameplates",
    2: "equipment",
    3: "general",
    4: "nameplates-processed",
    5: "unprocessed-videos",
    6: "processed-videos",
    7: "pdf-reports",
    8: "default",
  }
 * @return {string} The URL of the uploaded file.
 */
const uploadFileToS3 = async (file, bucketId) => {
  const keyName = file.name;
  const BUCKET_NAME = config.BUCKET_NAME;
  const folderName = subBucketName(bucketId);
  const bucketName = `${BUCKET_NAME}/${folderName}`;
  // Configura las credenciales de AWS
  AWS.config.update({
    region: "us-east-2",
    accessKeyId: config.ACCESS_KEY_ID,
    secretAccessKey: config.SECRET_ACCESS_KEY,
  });

  // Crea un objeto S3
  const s3 = new AWS.S3();

  // Prepara el archivo para subirlo por partes
  const fileSize = file.size;
  const partSize = 1024 * 1024 * 5; // 5 MB
  const numParts = Math.ceil(fileSize / partSize);

  // Crea una carga multipart para el archivo
  const { UploadId } = await s3
    .createMultipartUpload({
      Bucket: bucketName,
      Key: keyName,
    })
    .promise();

  // Sube las partes del archivo
  const uploadParts = [];
  for (let i = 0; i < numParts; i++) {
    console.log("part", i);
    const start = i * partSize;
    const end = Math.min(start + partSize, fileSize);
    const part = file.slice(start, end);

    const { UploadId: PartUploadId, ETag } = await s3
      .uploadPart({
        Bucket: bucketName,
        Key: keyName,
        PartNumber: i + 1,
        UploadId: UploadId,
        Body: part,
      })
      .promise();

    uploadParts.push({ PartNumber: i + 1, ETag });
  }

  // Completar la carga multipart
  const { Location } = await s3
    .completeMultipartUpload({
      Bucket: bucketName,
      Key: keyName,
      UploadId: UploadId,
      MultipartUpload: { Parts: uploadParts },
    })
    .promise();

  // Retornar la URL del archivo subido
  const decodedUrl = decodeURIComponent(Location);
  console.log("file saved:", decodedUrl);
  console.log("key", decodedUrl.split("/").pop());
  // Establece los permisos públicos para el archivo
  const aclParams = {
    Bucket: BUCKET_NAME,
    Key: `${folderName}/${keyName}`,
    ACL: "public-read",
  };
  await s3.putObjectAcl(aclParams).promise();
  return decodedUrl;
};

export default uploadFileToS3;
