import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CardDetails } from "./AnemometerDetailsElements";
import Table from "components/Tables/Table";
import PhotoGallery from "components/ImageGallery/PhotoGalley/PhotoGallery";
import UseGet from "hooks/useFetch";

const index = ({ anemometerDetails }) => {
  const [photos, error] = UseGet(
    `api/v1/anemometer-photo?anemometerId=${anemometerDetails.id}`
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} xl={5}>
          <CardDetails>
            <h1>Anemometer Details</h1>
            <Table
              data={{
                ID: anemometerDetails.id,
                Velocidad: anemometerDetails.velocity,
                Dirección: anemometerDetails.direction,
                Temperatura: anemometerDetails.temperature,
                Longitud: anemometerDetails.locationAnemometer.longitude,
                Latitud: anemometerDetails.locationAnemometer.latitude,
                "Fecha creación": anemometerDetails.createdAt.substring(0, 10),
                Comentarios: anemometerDetails.comments,
              }}
            />
          </CardDetails>
        </Grid>
        <Grid item xs={12} md={12} xl={7}>
          <CardDetails>
            <h1>Pictures</h1>
            {!error && photos && photos.length > 0 && (
              <PhotoGallery photos={photos} />
            )}
          </CardDetails>
        </Grid>
      </Grid>
    </Box>
  );
};

export default index;
