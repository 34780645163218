import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    // get login status from localStorage
    const loginStatus = localStorage.getItem('loginStatus');
    return loginStatus ? <Outlet /> : <Navigate to="/signIn" />;
}

export default PrivateRoute;
