import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LayersIcon from "@mui/icons-material/Layers";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { setLocationsCSV, reloadCsv, setLocationsJson } from "redux/actions";
import { setUpdateRegion } from "redux/actions/index";
import { setTableDetailsForward } from "redux/actions/digitalTwin";

import OpenDialog from "components/Dialogs/OpenDialog";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { PRIMARY_COLOR } from "utils/const";

import { Checkbox } from "@mui/material";

import timeZoneOffset from "services/timeZoneOffset";

// check layer
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadCSV from "../Uploads/loadCSV/LoadCSV";

// redux actions Admin
import {
  showRegionDetailsDrawer,
  SetUsers,
  SetShowUsers,
  setGeometryPolygon,
  setDataObjectsPolygon,
  setGeometryLine,
  setFeaturesDetailsDT,
} from "redux/actions/admin";

import AccordionDigitalTwin from "./../Accordions/AccordionDigitalTwin";
import AccordionLoadCsv from "./../Accordions/AccordionLoadCsv";
import AccordionObjectsPolygon from "./../Accordions/AccordionObjectsPolygon";
import AccordionDetailsPolygon from "./../Accordions/AccordionDetailsPolygon";
import AccordionDetailsLine from "../Accordions/AccordionDetailsLine";
import AccordionBookmarksViews from "../Accordions/AccordionBookmarksViews";

import {
  useStyles,
  CustomizedDiv,
  CustomizedTypography,
  CustomizeToggleButtonGroup,
  CustomizedAccordion,
  FilterTabStyles,
  HeadModal,
  ContentFilters,
  ContentFilterItem,
} from "./MapDrawerStyle";

import saveObject from "services/saveObject";
import FilterByDate from "components/Accordions/FilterByDate";
import GenericFields from "components/Forms/SaveObject";
import AccordionSpecialFilters from "components/Accordions/AccordionSpecialFilters";

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MapDrawer({ drawState, dataObjects }) {
  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const openRegionDetails = useSelector(
    (state) => state.adminReducer.showRegionDetailsDrawer
  );

  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [open, setOpen] = useState(false);
  const [dialogs, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [section, setSection] = useState("layers");
  const [isUpdateLine, setIsUpdateLine] = useState(false);
  const [showSaveObject, setShowSaveObject] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleAlignment = (event) => {
    setSection(event.currentTarget.value);
  };
  const locationsJSON = useSelector(
    (state) => state.locationCSVReducer.locationsJSON
  );

  const locationsCSV = useSelector(
    (state) => state.locationCSVReducer.locationsCSV
  );

  const reloadCsvMap = useSelector(
    (state) => state.locationCSVReducer.reloadCSV
  );

  const featureDetails = useSelector(
    (state) => state.adminReducer.setFeaturesDetailsDT
  );

  const users = useSelector((state) => state.adminReducer.SetUsers);
  const showUsers = useSelector((state) => state.adminReducer.SetShowUsers);

  useEffect(() => {
    setShowSaveObject(() => {
      if (featureDetails.geometry.type !== null) return true;
      return false;
    });
  }, [featureDetails]);

  const handleChangeUserFilter = (event, id) => {
    const { isShow } = users[id];
    users[id].isShow = !isShow;
    dispatch(SetUsers(users));
    dispatch(SetShowUsers(!showUsers));
  };

  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  const updateRegion = useSelector(
    (state) => state.digitalTwinReducer.updateRegion
  );

  useEffect(() => {
    if (drawState === true) {
      handleDrawerOpen();
    } else if (drawState === false) {
      handleDrawerClose();
    } else {
    }
  }, [role, drawState, locationsJSON]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const cleanLocationsCSV = () => {
    dispatch(setLocationsCSV({}));
    dispatch(setLocationsJson([]));
  };

  const openSaveCsvDialog = () => {
    setDialog(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const saveCSV = async () => {
    setLoading(true);
    const bodyObject = (locationCSV) => {
      return {
        userId: Number(userId),
        dataSourceId: 3,
        date: new Date(),
        location: {
          latitude: Number(locationCSV.latitude),
          longitude: Number(locationCSV.longitude),
        },
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeZoneOffset: timeZoneOffset(),
        adminCompanyId: adminCompanyId,
        typeElementId: locationCSV.typeElementId,
        comments: "no comments",
      };
    };
    return Promise.all(
      locationsJSON.map(async (object) => {
        const newObjet = await saveObject(bodyObject(object));
        return newObjet;
      })
    ).then(() => {
      setLoading(false);
      setDialog(false);
      setSuccess(true);
    });
  };

  const cleanLocations = () => {
    setSuccess(false);
    cleanLocationsCSV();
    if (reloadCsvMap === false) {
      dispatch(reloadCsv(true));
    }
    if (reloadCsvMap === true) {
      dispatch(reloadCsv(false));
    }
  };

  const closeRegionDetails = () => {
    dispatch(showRegionDetailsDrawer(false));
  };

  const closeRegionCreatingDetails = () => {
    dispatch(setGeometryPolygon(null));
    dispatch(setDataObjectsPolygon([]));
    dispatch(setUpdateRegion({ id: null, isUpdate: false, data: {} }));
  };
  const closeLineDetails = () => {
    dispatch(setGeometryLine(null));
    setIsUpdateLine(false);
  };

  const handleClickDrawer = () => {
    dispatch(setTableDetailsForward(9));
  };

  return (
    <div onClick={handleClickDrawer}>
      <Drawer
        sx={{
          width: 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: {
              xs: 240,
              sm: 320,
            },
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader></DrawerHeader>
        <div>
          <div>
            <Divider />
            <List>
              <ListItem button></ListItem>
            </List>

            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              <CustomizeToggleButtonGroup
                value={section}
                onChange={handleAlignment}
                size="large"
                exclusive
                fullWidth={true}
                aria-label="text view"
              >
                <ToggleButton
                  sx={{ fontSize: "1.4rem", textTransform: "none" }}
                  value="layers"
                >
                  <LayersIcon />
                  <p> Workspace</p>
                </ToggleButton>
                <ToggleButton
                  sx={{ fontSize: "1.4rem", textTransform: "none" }}
                  value="filters"
                >
                  <FilterAltIcon />
                  <p>Filters</p>
                </ToggleButton>
              </CustomizeToggleButtonGroup>
            </List>
            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              {section === "layers" && (
                <div>
                  <div>
                    {role === "Administrador de compañía" && (
                      <List
                        sx={{
                          height: "100%",
                          position: "bottom",
                        }}
                        subheader={<li />}
                      >
                        <ToggleButtonGroup
                          size="medium"
                          exclusive
                          fullWidth={true}
                          aria-label="text view"
                        >
                          <CustomizedTypography>
                            CSV Objects ({locationsJSON.length})
                          </CustomizedTypography>
                          <ToggleButton
                            sx={{
                              fontSize: "1.4rem",
                              width: {
                                xs: 120,
                                sm: 150,
                              },
                            }}
                            value="Add data"
                          >
                            <AddIcon></AddIcon>
                            <LoadCSV />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </List>
                    )}
                    <br />
                    <div
                      style={{
                        padding: "0px 12px",
                      }}
                    >
                      {dataObjects.length > 0 && (
                        <AccordionSpecialFilters dataObjects={dataObjects} />
                      )}
                      {dataObjects.length > 0 && (
                        <AccordionDigitalTwin dataObjects={dataObjects} />
                      )}
                      {/* card with the summary of the objects when creating the polygon */}
                      {geometryPolygon?.type === "Polygon" && (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                          >
                            <CloseIcon
                              onClick={closeRegionCreatingDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />
                            <Typography sx={{ fontSize: "1.5rem" }}>
                              {updateRegion?.isUpdate
                                ? "Update Region"
                                : "New Region"}
                            </Typography>
                          </AccordionSummary>
                          <AccordionObjectsPolygon
                            dataObjects={dataObjectsPolygon}
                          />
                        </CustomizedAccordion>
                      )}

                      {/* card with the summary when creating Lines */}
                      {geometryLine?.geometry?.type === "LineString" && (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                            // expandIcon={<ExpandMoreIcon />}
                          >
                            <CloseIcon
                              onClick={closeLineDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />
                            <Typography sx={{ fontSize: "1.5rem" }}>
                              {isUpdateLine ? "Update" : "Create"} New Line
                            </Typography>
                            {/* <button>Edit Geometry</button> */}
                          </AccordionSummary>
                          <AccordionDetailsLine
                            setIsUpdateLine={setIsUpdateLine}
                            isUpdateLine={isUpdateLine}
                          />
                        </CustomizedAccordion>
                      )}
                      {/* Boorkmarks views */}
                      {
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={false}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ fontSize: "1.5rem" }}>
                              Views
                            </Typography>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                            ></AccordionSummary>
                          </div>
                          <AccordionBookmarksViews />
                        </CustomizedAccordion>
                      }

                      {/* card with details of the region created  */}
                      {openRegionDetails &&
                      featureDetails.geometry.type == null ? (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                            // expandIcon={<ExpandMoreIcon />}
                          >
                            <CloseIcon
                              onClick={closeRegionDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />
                            <Typography sx={{ fontSize: "1.5rem" }}>
                              Region Details
                            </Typography>
                            {/* <button>Edit Geometry</button> */}
                          </AccordionSummary>
                          <AccordionDetailsPolygon
                            dataObjects={dataObjectsPolygon}
                          />
                        </CustomizedAccordion>
                      ) : null}

                      {Object.keys(locationsCSV).length > 0 &&
                      locationsJSON.length > 0 ? (
                        <Accordion
                          sx={{
                            border: "none",
                            boxShadow: "none",
                          }}
                          defaultExpanded={true}
                        >
                          <CustomizedDiv>
                            <p>{locationsCSV.name}</p>
                            <div>
                              <SaveTwoToneIcon
                                onClick={openSaveCsvDialog}
                                sx={{
                                  cursor: "pointer",
                                  color: "#006ad4",
                                }}
                              />
                              <DeleteTwoToneIcon
                                onClick={cleanLocationsCSV}
                                sx={{
                                  cursor: "pointer",
                                  color: "#c73e3e",
                                }}
                              />
                              <Modal
                                open={dialogs}
                                onClose={setDialog}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box
                                  sx={style}
                                  style={{
                                    position: "relative",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <HeadModal>
                                    <img
                                      src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
                                      alt=""
                                      className="neurona"
                                    />
                                    <span className="titleHeadModal">
                                      Save Locations
                                    </span>
                                  </HeadModal>
                                  <div style={{}}>head modal</div>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    style={{
                                      marginTop: "50px",
                                    }}
                                  >
                                    Are you sure you want to save the locations?
                                  </Typography>
                                  <LoadingButton
                                    sx={{
                                      margin: "10px",
                                    }}
                                    loadingPosition="start"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setDialog(false);
                                    }}
                                    style={{
                                      background: PRIMARY_COLOR,
                                      borderColor: PRIMARY_COLOR,
                                      color: "#fff",
                                    }}
                                  >
                                    Cancel
                                  </LoadingButton>
                                  <LoadingButton
                                    sx={{
                                      margin: "10px",
                                    }}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      saveCSV();
                                    }}
                                    style={{
                                      background: PRIMARY_COLOR,
                                      borderColor: PRIMARY_COLOR,
                                      color: "#fff",
                                    }}
                                  >
                                    Save
                                  </LoadingButton>
                                </Box>
                              </Modal>
                              <OpenDialog
                                openDialog={success}
                                setOpenDialog={setSuccess}
                                execute={cleanLocations}
                                content={{
                                  title: "Success",
                                  description:
                                    "The CSV locations was succesfuly saved",
                                  agree: "Continue",
                                }}
                              />
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                              ></AccordionSummary>
                            </div>
                          </CustomizedDiv>

                          <AccordionLoadCsv dataObjects={locationsJSON} />
                        </Accordion>
                      ) : null}

                      <OpenDialog
                        openDialog={showSaveObject}
                        setOpenDialog={setShowSaveObject}
                        disagree={() => {
                          dispatch(
                            setFeaturesDetailsDT({
                              geometry: {
                                type: null,
                              },
                            })
                          );
                        }}
                        content={{
                          title: "Add new object",
                          description: <GenericFields />,
                        }}
                        maxWidth="1500px"
                        minWidth="300px"
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* ---section filters--- */}
              {section === "filters" && (
                <ContentFilters>
                  <CustomizedAccordion
                    classes={{
                      content: classes.content,
                      expanded: classes.expanded,
                    }}
                    defaultExpanded={true}
                  >
                    <ContentFilterItem>
                      <Typography>Filter For User</Typography>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      ></AccordionSummary>
                    </ContentFilterItem>
                    <AccordionSummary>
                      <FilterTabStyles>
                        {Object.keys(users).map((key) => (
                          <div>
                            <Checkbox
                              defaultChecked={users[key].isShow}
                              onChange={(event) =>
                                handleChangeUserFilter(event, key)
                              }
                            />
                            <p>{users[key].operator}</p>
                          </div>
                        ))}
                        {/* {users.length !== 0 &&} */}
                      </FilterTabStyles>
                    </AccordionSummary>
                  </CustomizedAccordion>
                  <FilterByDate />
                  <OpenDialog
                    openDialog={showSaveObject}
                    setOpenDialog={setShowSaveObject}
                    disagree={() => {
                      dispatch(
                        setFeaturesDetailsDT({
                          geometry: {
                            type: null,
                          },
                        })
                      );
                    }}
                    content={{
                      title: "Add new object",
                      description: <GenericFields />,
                    }}
                    maxWidth="500px"
                  />
                </ContentFilters>
              )}
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
