import { useCallback, useState, useEffect } from "react";
// Map
import { Map, Marker, Source, Layer, AttributionControl } from "react-map-gl";
import DrawControl from "./DrawControl";
// Style
import Container from "@mui/material/Container";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Components
import ObjectDetails from "components/ImageGallery/ObjectDetails/ObjectDetails";
import OpenDialog from "components/Dialogs/OpenDialog";
import CreateEvent from "./CreateEvent";
import EventDetails from "components/ImageGallery/EventDetails/EventDetails";
// Consts
import fogStyle from "utils/fogStyle";
import { EVENT_CLUSTER_COLOR, COMPONENT_CLUSTER_COLOR } from "utils/const";
// Services
import findObjects from "services/findObjects";
// Hooks
import useFetchPointTypeEvent from "hooks/useFetchPointTypeEvents";
import FetchPointEventsById from "hooks/fetchPointEventsById";
// Helpers
import { ShowEventMarkers } from "helpers/ShowEventsMarker";
import ShowLinesJoin from "helpers/showLines";
import { createLineString } from "helpers/createLineString";
import BaseMapButton from "components/Buttons/BaseMapButton";

export default function EventMap({ event }) {
  const [viewState, setViewState] = useState({
    latitude: event.locationOperation.latitude,
    longitude: event.locationOperation.longitude,
    width: "100%",
    height: "100%",
    zoom: 20,
  });

  // Know if is draw any thing in the map
  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );

  // Dispatch to redux
  const dispatch = useDispatch();
  const [baseMap, setBaseMap] = useState("streets-v11");
  const [objectMarker, setObjectMarker] = useState([]);
  const [feature, setFeatures] = useState({});
  const [haveObjects, setHaveObjects] = useState(false);
  const [eventLines, setEventLines] = useState([]);

  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const { data: typeEventsTypeEvents } = useFetchPointTypeEvent({
    id: event.pointTypeEventId,
  });

  const { data: pointEventRelation } = FetchPointEventsById(event.id);

  useEffect(() => {
    const createLineFeature = () => {
      const eventLines = pointEventRelation.PointEventRelation.map(
        (pointEvent) => {
          const firstLocation = [
            event.locationOperation.longitude,
            event.locationOperation.latitude,
          ];
          const secondLocation = [
            pointEvent.locationOperation.longitude,
            pointEvent.locationOperation.latitude,
          ];
          const lineString = createLineString(
            [firstLocation, secondLocation],
            COMPONENT_CLUSTER_COLOR
          );
          return lineString;
        }
      );
      setEventLines(eventLines);
    };

    pointEventRelation && createLineFeature();
  }, [pointEventRelation, event]);

  // Get object the current event
  useEffect(() => {
    const getObject = async () => {
      const objectId = event?.objectId;
      const data = await findObjects(objectId);

      const newMarker = {
        id: data.id,
        latitude: data?.location?.latitude,
        longitude: data?.location?.longitude,
        urlIcon: data?.typeElement?.urlIconColor,
      };
      setObjectMarker((current) => {
        return current.concat(newMarker);
      });
    };
    event && getObject();
  }, [event]);

  useEffect(() => {
    if (!feature.geometry) return;
    typeEventsTypeEvents &&
      setContentDialog({
        title: "Assign Event",
        description: (
          <CreateEvent
            feature={feature}
            pointEventId={event.id}
            closeModal={setHaveObjects}
            typeEventsTypeEvents={
              typeEventsTypeEvents[0].PointTypeEventRelation
            }
          />
        ),
      });
    setHaveObjects(true);
  }, [feature, typeEventsTypeEvents, event.id]);

  // To click in marker of object
  const handleClickMarkerObject = async (objectId) => {
    const object = {
      content: (
        <ObjectDetails
          objectId={objectId}
          currentRelation={event}
          fromRelation={true}
          isEvent={true}
        />
      ),
      title: "Objects Details",
    };
    if (!drawAnyThing) {
      dispatch(setOperationDetails(object));
      dispatch(setShowOperationDetails(true));
    }
  };

  const handleClickDynamicEvent = (eventId) => {
    const event = {
      content: <EventDetails eventId={eventId} fromEvent={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(event));
  };

  const showObjectMarkers = () => {
    return objectMarker.map((marker, index) => {
      const firstLocation = [
        event.locationOperation.longitude,
        event.locationOperation.latitude,
      ];
      const secondLocation = [marker.longitude, marker.latitude];
      const lineString = createLineString(
        [firstLocation, secondLocation],
        EVENT_CLUSTER_COLOR
      );

      return (
        <>
          <div key={index}>
            <Source type="geojson" data={lineString} key={index}>
              <Layer
                key={index}
                id={index}
                type="line"
                paint={{
                  "line-color": ["get", "color"],
                  "line-width": 3,
                  "line-dasharray": [2, 2],
                }}
              />
            </Source>
          </div>
          <Marker
            latitude={Number(marker.latitude)}
            longitude={Number(marker.longitude)}
            onClick={() => handleClickMarkerObject(marker.id)}
            style={{
              cursor: "pointer",
            }}
          >
            <div className="marker">
              <img src={marker.urlIcon} alt="marker" width={30} height={30} />
            </div>
          </Marker>
        </>
      );
    });
  };

  return (
    <Container
      maxWidth="l"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
        position: "relative",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        disagree={() => {}}
        content={contentDialog}
        maxWidth={contentDialog.title === "Base Map" ? null : "500px"}
      />
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        style={{
          width: "100%",
          height: "593px",
          margin: "auto",
          zIndex: "10000",
        }}
        mapStyle={`mapbox://styles/mapbox/${baseMap}`}
        attributionControl={false}
        initialViewState={{
          latitude: viewState.latitude,
          longitude: viewState.longitude,
          zoom: viewState.zoom,
        }}
        onMove={useCallback((e) => {
          setViewState(e.viewState);
        }, [])}
        projection={"globe"}
        fog={fogStyle}
      >
        <DrawControl handleCreateEvent={setFeatures} />
        <BaseMapButton
          setHaveObjects={setHaveObjects}
          setContentDialog={setContentDialog}
          setBaseMap={setBaseMap}
          position={75}
        />
        <Marker
          key={event.id}
          longitude={event.locationOperation.longitude}
          latitude={event.locationOperation.latitude}
        >
          <div className="marker">
            <img
              src={event.pointTypeEvent.icon}
              alt="marker"
              width={30}
              height={30}
            />
          </div>
        </Marker>
        {objectMarker && event?.objectId && showObjectMarkers()}
        {eventLines && <ShowLinesJoin features={eventLines} />}
        {pointEventRelation &&
          ShowEventMarkers(
            pointEventRelation.PointEventRelation,
            handleClickDynamicEvent,
            () => {}
          )}
        <AttributionControl customAttribution="© Decimetrix® 2023" />
      </Map>
    </Container>
  );
}
