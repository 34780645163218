import axios from "axios";

const findInspectionFlir = async (id) => {
  const token = localStorage.getItem("token");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/inspection-flir/${id}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default findInspectionFlir;
