import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

export const Container = styled.div`
  width: 100%;
  height: ${(props) => props.height}%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 3px;
  background-color: #fff;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 10)};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    border-top: #fff;
    border-left: #fff;
    border-right: #fff;
  }

  .head-right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .select {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #9bc4cb;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    padding: 0 1rem;
    outline: none;
  }

  .search {
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
    padding: 0 1rem;
    outline: none;
    border: 1px solid #9bc4cb;
  }

  .close {
    cursor: pointer;
    color: ${PRIMARY_COLOR};
    font-size: 1.7rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  .title {
    font-size: 2rem;
    font-weight: 500;
  }

  .title.total {
    margin-right: 1rem;
  }

  .bold {
    font-weight: 700;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 5px;
    padding: 5px 8px;
    border: 1px solid #9bc4cb;
    background-color: ${PRIMARY_COLOR};
    color: #fff;
    font-size: 1.6rem;
    margin: auto;
    cursor: pointer;
  }

  .button:hover {
    background-color: ${SECOND_COLOR};
  }
`;
export const SearchPosition = styled.div`
  margin-top: -50px;

  @media (max-width: 1201px) {
    margin-top: 0;
  }
`;

export const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
  menu: (base) => ({
    ...base,
    width: "200px",
    maxWidth: "none",
    maxHeight: "auto",
  }),
};

export const customStylesLibraries = {
  option: (provided) => ({
    ...provided,
    fontSize: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
  menu: (base) => ({
    ...base,
    width: "150px",
    maxWidth: "none",
    maxHeight: "auto",
  }),
};



