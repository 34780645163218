import DynamicEvent from "components/Forms/Events/DynamicEvent";
import { useState } from "react";
import SelectInput from "components/Forms/SelectInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FeatureDetails } from "components/Forms/SaveObject/indexStyle";
import { WraperTitleCardMarker } from "components/ImageGallery/ImageDetailsElements";
import { useFetchEventLibraries } from "hooks/fetchLibraries";

/**
 * Creates an event with the given parameters.
 *
 * @param {Object} options - The options for creating the event.
 * @param {string} options.feature - The feature of the event.
 * @param {string} options.pointEventId - The ID of the point evebt.
 * @param {function} options.closeModal - A function to close the modal.
 * @param {Array} options.typeEventsTypeEvents - The type events type events.
 * @return {void}
 */
export default function CreateEvent({
  feature,
  pointEventId,
  closeModal,
  typeEventsTypeEvents,
}) {
  const [type, setType] = useState();
  const [currentEventType, setCurrentEventType] = useState("");
  const [currentLibraryType, setCurrentLibraryType] = useState("");

  const [libraryType, setLibraryType] = useState({
    name: "Library type",
    options: [],
    id: 0,
  });
  const [eventType, setEventType] = useState({
    name: "Type event",
    options: [],
    icons: [],
    id: 0,
  });

  const { data: typeEventLibraries, errorTypeEventLibraries } =
    useFetchEventLibraries();

  useEffect(() => {
    const librarieIds = typeEventsTypeEvents.map(
      (event) => event.pointLibraryEventId
    );
    var typeIcons = typeEventsTypeEvents.map((typeEvent) => {
      const library =
        !errorTypeEventLibraries &&
        typeEventLibraries?.find((library) => {
          return typeEvent.pointLibraryEventId === library.id;
        });
      return {
        name: typeEvent.name,
        icon: typeEvent.icon,
        library: library?.name,
      };
    });

    const currentLibraryTypes = [];
    const currentEventTypes = [];

    !errorTypeEventLibraries &&
      typeEventLibraries?.map((library) => {
        if (librarieIds?.includes(library.id)) {
          currentLibraryTypes.push(library.name);
        }
      });

      typeEventsTypeEvents.map((elm) => {
      !errorTypeEventLibraries &&
      typeEventLibraries?.map((elm2) => {
        if (
          !errorTypeEventLibraries &&
          typeEventLibraries &&
          elm.pointLibraryEventId === elm2.id 
          ) {
            if(currentEventTypes.length < 1 && elm2.name === currentLibraryTypes[0]){
              currentEventTypes.push(elm.name);
            }
          }
        });
      })
    setEventType((prev) => ({
      ...prev,
      options: currentEventTypes,
      icons: typeIcons,
    }));

    setLibraryType((prev) => ({
      ...prev,
      options: currentLibraryTypes,
    }));

    setCurrentEventType(currentEventTypes[0]);
    setCurrentLibraryType(currentLibraryTypes[0]);
    const currentType = typeEventsTypeEvents.find((elm) => {
      return elm.name === currentEventTypes[0];
    });
    setType(currentType?.id);
  }, [
    feature,
    pointEventId,
    closeModal,
    typeEventsTypeEvents,
    typeEventLibraries,
  ]);

  const { register, handleSubmit } = useForm();

  const handleSelectLibrary = (data) => {
    setCurrentLibraryType(data["Library type"]);
    const currentLibrary = typeEventLibraries.find(
      (event) => event.name === data["Library type"]
    );
    const currentLibraryOptions = [];
    const currentLibraryIds = [];
    typeEventsTypeEvents.forEach((elm) => {
      if (elm.pointLibraryEventId === currentLibrary.id) {
        currentLibraryOptions.push(elm.name);
        currentLibraryIds.push(elm.id);
      }
    });
    setEventType({ ...eventType, options: currentLibraryOptions });
    setType(currentLibraryIds[0]);
    setCurrentEventType(currentLibraryOptions[0]);
  };

  const handleSelectTypeEvent = (data) => {
    setCurrentEventType(data["Library type"]);
    typeEventsTypeEvents.forEach((event) => {
      if (event.name === data["Type event"]) {
        setType(event.id);
        setCurrentEventType(data["Type event"]);
      }
    });
  };

  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {eventType?.icons.map((event) => {
            return (
              event.name === currentEventType &&
              event.library === currentLibraryType && (
                <img className="dynamicImg" src={event.icon} alt="Decimetrix" />
              )
            );
          })}
          <h2>Events</h2>
        </div>
      </WraperTitleCardMarker>
      <div onChange={handleSubmit(handleSelectLibrary)}>
        <SelectInput field={libraryType} register={register} />
      </div>
      <div onChange={handleSubmit(handleSelectTypeEvent)}>
        <SelectInput field={eventType} register={register} />
      </div>
      <DynamicEvent
        closeModal={closeModal}
        feature={feature}
        pointTypeEventId={type}
        pointEventId={pointEventId}
      />
    </FeatureDetails>
  );
}
