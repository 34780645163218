import styled from "styled-components";

export const WrapperCards = styled.div`
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
  justify-content: space-around;

  @media (max-width: 670px) {
    margin-top: 300px;
  }
`;