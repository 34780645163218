const uploadFileToS3 = async (file, bucketId) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bucketId", bucketId);
    const res = await fetch(`${process.env.REACT_APP_URL_OCR_API}uploadFile`, {
      method: "POST",
      body: formData,
    });

    const fileSaved = await res.json();
    return fileSaved.urlFile;
  } catch (error) {
    return error;
  }
};

export default uploadFileToS3;
