import useSwr from "swr";

const FetchFlirEventsByUserId = (userId) => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/operation/user/${userId}`
  );
  return { data, error };
};

export default FetchFlirEventsByUserId;
