import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  /* padding: 10px 20px; */
  width: 100%;
  min-width: 320px;
  color: #2c2c2c;

  b {
    display: flex;
    justify-content: center;
    font-size: medium;
  }

  h1 {
    font-size: 3rem;
  }

  .location {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .wrapper-inputs {
    display: flex;
    width: 100%;
    gap: 0 30px;
    justify-content: space-between;
    align-items: center;
  }

  .wrapper-inputs div {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
  }

  button[type="submit"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 20px 0;
    background-color: ${PRIMARY_COLOR};
    color: #fff;

    &:hover {
      cursor: pointer;
      background-color: ${SECOND_COLOR};
    }
  }

  @media (max-width: 780px) {
    .wrapper-inputs {
      display: flex;
      flex-direction: column;
    }
    .wrapper-inputs div {
      align-self: center;
      width: 100%;
    }
  }
`;
