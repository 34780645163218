import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";

// custom style
import {
  WrapperTypeObjectTitle,
  WrapperTypeElementTitle,
} from "../../AccordionStyles";

//redux
import {
  showEventsByInspectionType,
  showOperationsByType,
  showEventsByType,
  setShowEventsByLibrary,
  setFilterEvents,
  setShowEvents,
} from "redux/actions/digitalTwin";
import { useDispatch, useSelector } from "react-redux";

//helpers
import deleteDuplicateOperations from "helpers/filters/deleteDuplicateOperations";
import deleteDuplicatePointsLibrary from "helpers/filters/deleteDuplicatePointsLibrary";
import getLibrariesTrue from "helpers/filters/getLibrariesTrue";

//utils
import {
  PRIMARY_COLOR,
  SET_VISIBILITY_ICON,
  VISIBILITY_OFF_ICON,
} from "utils/const";

const deleteDuplicateObjects = (objects) => {
  return objects.filter(
    (object, index, self) => index === self.findIndex((t) => t.id === object.id)
  );
};

const getTypeInspectionsFlir = (operations) => {
  const inspectionsFlir = operations.filter(
    (operation) => operation?.inspectionFlir
  );
  const typeInspectionsFlir = inspectionsFlir.map(
    (operation) => operation.inspectionFlir.type
  );
  const uniqueTypeInspectionsType = deleteDuplicateObjects(typeInspectionsFlir);
  return uniqueTypeInspectionsType;
};

const FilterEvents = () => {
  const dispatch = useDispatch();

  const dataOperations = useSelector(
    (state) => state.digitalTwinReducer.setDataOperations
  );

  const uniqueOperations = deleteDuplicateOperations(dataOperations);

  const typeInspectionsFlir = getTypeInspectionsFlir(dataOperations);

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );

  const showOperationByType = useSelector(
    (state) => state.digitalTwinReducer.showOperationsByType
  );

  const showAllEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );

  const showEventsByLibrary = useSelector(
    (state) => state.digitalTwinReducer.showEventsByLibrary
  );

  const showEvents = useSelector(
    (state) => state.digitalTwinReducer.showEvents
  );

  const handlerClickFilterComponentsByLibrary = (state, libraryId, color) => {
    const dataLibraryObject = showEventsByLibrary.map((elm) => {
      if (elm.libraryId === libraryId) {
        return { ...elm, state: state, color: color };
      } else {
        return elm;
      }
    });
    showAllEventsByType.map((elm) => {
      if (elm.libraryId === libraryId) {
        elm.state = state;
      }
    });
    const operationByTypeOff = Object.values(showOperationByType).every(
      (value) => value === false
    );
    const operationByTypeTrue = Object.values(showOperationByType).every(
      (value) => value === true
    );
    const objectLibraryOff = dataLibraryObject.filter((elm) => {
      return elm.state === true;
    });
    if (showEvents.state === false) {
      dispatch(
        setShowEvents({ state: state, color: `${SET_VISIBILITY_ICON}` })
      );
    }
    if (objectLibraryOff.length === 0 && operationByTypeOff) {
      dispatch(
        setShowEvents({ state: false, color: `${VISIBILITY_OFF_ICON}` })
      );
    }
    if (objectLibraryOff.length > 0) {
      dispatch(setShowEvents({ state: true, color: `${SET_VISIBILITY_ICON}` }));
    }
    if (
      objectLibraryOff.length === dataLibraryObject.length &&
      operationByTypeTrue
    ) {
      dispatch(setShowEvents({ state: true, color: `${PRIMARY_COLOR}` }));
    }
    dispatch(showEventsByType(showAllEventsByType));
    dispatch(setShowEventsByLibrary(dataLibraryObject));
    dispatch(setFilterEvents(true));
  };

  const handlerClickFilterComponentsByType = (typeId, libraryId, state) => {
    const currentLibrary = showEventsByLibrary.filter((elm) => {
      return elm.libraryId === libraryId;
    });
    showAllEventsByType.map((elm) => {
      if (elm.libraryId === libraryId && typeId === elm.id) {
        elm.state = state;
      }
    });
    dispatch(showEventsByType(showAllEventsByType));
    const currentObjectsType = showAllEventsByType.filter((elm) => {
      return elm.libraryId === libraryId;
    });
    const currentObjectsActivate = currentObjectsType.filter((elm) => {
      return elm.state === true;
    });
    if (showEvents.state === false) {
      dispatch(
        setShowEvents({ state: state, color: `${SET_VISIBILITY_ICON}` })
      );
    }
    if (currentObjectsActivate.length === 0) {
      const dataLibraryObject = showEventsByLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: false, color: `${VISIBILITY_OFF_ICON}` };
        } else {
          return elm;
        }
      });
      if (getLibrariesTrue(showEventsByLibrary)) {
        dispatch(
          setShowEvents({
            state: true,
            color: `${SET_VISIBILITY_ICON}`,
          })
        );
      }
      dispatch(setShowEventsByLibrary(dataLibraryObject));
      if (getLibrariesTrue(showEventsByLibrary) === false) {
        dispatch(
          setShowEvents({
            state: false,
            color: `${VISIBILITY_OFF_ICON}`,
          })
        );
      }
    }
    if (currentObjectsActivate.length > 0) {
      const dataLibraryObject = showEventsByLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: true, color: `${SET_VISIBILITY_ICON}` };
        } else {
          return elm;
        }
      });
      dispatch(setShowEventsByLibrary(dataLibraryObject));
      dispatch(setShowEvents({ state: true, color: `${SET_VISIBILITY_ICON}` }));
      if (
        getLibrariesTrue(showEventsByLibrary) === false &&
        currentLibrary.length === 1
      ) {
        dispatch(setShowEvents({ state: true, color: `${PRIMARY_COLOR}` }));
      }
    }
    if (currentObjectsActivate.length === currentLibrary.length) {
      const dataLibraryObject = showEventsByLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: true, color: `${PRIMARY_COLOR}` };
        } else {
          return elm;
        }
      });
      dispatch(setShowEventsByLibrary(dataLibraryObject));
    }
    if (
      currentObjectsActivate.length === currentLibrary.length &&
      getLibrariesTrue(showEventsByLibrary)
    ) {
      dispatch(setShowEvents({ state: true, color: `${PRIMARY_COLOR}` }));
    }
    dispatch(setFilterEvents(true));
  };

  const handlerClickFilterEvents = (state, color) => {
    if (state) {
      for (let key in showEventByInspectionType) {
        showEventByInspectionType[key] = state;
      }
      for (let key in showOperationByType) {
        showOperationByType[key] = state;
      }
      dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
      dispatch(showOperationsByType({ ...showOperationByType }));
    } else {
      for (let key in showEventByInspectionType) {
        showEventByInspectionType[key] = state;
      }
      for (let key in showOperationByType) {
        showOperationByType[key] = state;
      }

      dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
      dispatch(showOperationsByType({ ...showOperationByType }));
    }
    showAllEventsByType.map((elm) => {
      elm.state = state;
    });

    const dataLibraryObject = showEventsByLibrary.map((elm) => ({
      ...elm,
      state: state,
      color: color,
    }));
    dispatch(showEventsByType(showAllEventsByType));
    dispatch(setShowEventsByLibrary(dataLibraryObject));
    dispatch(setShowEvents({ state: state, color: color }));
    dispatch(setFilterEvents(true));
  };

  const handlerFilterByInspectionType = (typeId) => {
    if (showOperationByType[2] === false && showEvents.state === true) {
      showEventByInspectionType[typeId] = !showEventByInspectionType[typeId];
      dispatch(
        setShowEvents({
          state: true,
          color: SET_VISIBILITY_ICON,
        })
      );
      showOperationByType[2] = {
        color: SET_VISIBILITY_ICON,
      };
      dispatch(showOperationsByType({ ...showOperationByType }));
      dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
    } else {
      if (showOperationByType[2].hasOwnProperty("color")) {
        let counter2 = 0;
        let counter3 = 0;
        for (let key in showOperationByType) {
          if (showOperationByType[key] === true) {
            counter2++;
          }
        }
        showAllEventsByType.forEach((elm) => {
          if (elm.state === true) {
            counter3++;
          }
        });
        if (counter2 > 0 && counter3 > 0) {
          showEventByInspectionType[typeId] =
            !showEventByInspectionType[typeId];
          dispatch(
            showEventsByInspectionType({ ...showEventByInspectionType })
          );
          dispatch(
            setShowEvents({
              state: true,
              color: SET_VISIBILITY_ICON,
            })
          );
          showOperationByType[2] = {
            color: SET_VISIBILITY_ICON,
          };
          dispatch(showOperationsByType({ ...showOperationByType }));
          const showEventByInspectionTypeTrue = Object.values(
            showEventByInspectionType
          ).every((value) => value === true);
          const objectLibraryOff = showEventsByLibrary.filter((elm) => {
            return elm.state === false;
          });
          if (showEventByInspectionTypeTrue) {
            showOperationByType[2] = true;
            dispatch(showOperationsByType({ ...showOperationByType }));
            const showOperationByTypeTrue = Object.values(
              showOperationByType
            ).every((value) => value === true);
            if (showOperationByTypeTrue && objectLibraryOff.length === 0) {
              dispatch(
                setShowEvents({
                  state: true,
                  color: PRIMARY_COLOR,
                })
              );
            }
          }
          let counter = 0;
          let quantity = 0;
          for (let key in showEventByInspectionType) {
            quantity++;
            if (showEventByInspectionType[key] === false) {
              counter++;
            }
          }
          if (quantity === counter) {
            showOperationByType[2] = false;
            dispatch(showOperationsByType({ ...showOperationByType }));
            const objectLibraryTrue = showEventsByLibrary.filter((elm) => {
              return elm.state === true;
            });
            const showOperationByTypeTrue = Object.values(
              showOperationByType
            ).every((value) => value === false);
            if (showOperationByTypeTrue && objectLibraryTrue.length === 0) {
              dispatch(
                setShowEvents({
                  state: false,
                  color: VISIBILITY_OFF_ICON,
                })
              );
            }
          }
        } else {
          showEventByInspectionType[typeId] =
            !showEventByInspectionType[typeId];
          dispatch(
            showEventsByInspectionType({ ...showEventByInspectionType })
          );
          dispatch(
            setShowEvents({
              state: true,
              color: SET_VISIBILITY_ICON,
            })
          );
          showOperationByType[2] = {
            color: SET_VISIBILITY_ICON,
          };
          dispatch(showOperationsByType({ ...showOperationByType }));
          let counter = 0;
          let quantity = 0;
          for (let key in showEventByInspectionType) {
            quantity++;
            if (showEventByInspectionType[key] === false) {
              counter++;
            }
          }
          if (quantity === counter) {
            showOperationByType[2] = false;
            dispatch(showOperationsByType({ ...showOperationByType }));
            dispatch(
              setShowEvents({
                state: false,
                color: VISIBILITY_OFF_ICON,
              })
            );
          }
        }
      } else if (
        showOperationByType[2] === false &&
        showEvents.state === false
      ) {
        showOperationByType[2] = {
          color: SET_VISIBILITY_ICON,
        };
        dispatch(
          setShowEvents({
            state: true,
            color: SET_VISIBILITY_ICON,
          })
        );
        dispatch(showOperationsByType({ ...showOperationByType }));
        showEventByInspectionType[typeId] = !showEventByInspectionType[typeId];
        dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
      } else if (showOperationByType[2] === true) {
        showEventByInspectionType[typeId] = !showEventByInspectionType[typeId];
        dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
        dispatch(
          setShowEvents({
            state: true,
            color: SET_VISIBILITY_ICON,
          })
        );
        showOperationByType[2] = {
          color: SET_VISIBILITY_ICON,
        };
        dispatch(showOperationsByType({ ...showOperationByType }));
        let counter = 0;
        let quantity = 0;

        for (let key in showEventByInspectionType) {
          quantity++;
          if (showEventByInspectionType[key] === false) {
            counter++;
          }
        }
        if (quantity === counter) {
          showOperationByType[2] = false;
          dispatch(showOperationsByType({ ...showOperationByType }));
          dispatch(
            setShowEvents({
              state: true,
              color: PRIMARY_COLOR,
            })
          );
        }
      } else {
        showEventByInspectionType[typeId] = !showEventByInspectionType[typeId];
        dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
      }
    }
  };

  const handlerFilterByOperationType = (operationTypeId) => {
    showOperationByType[operationTypeId] =
      !showOperationByType[operationTypeId];
    if (showEvents.state === true || showEvents.hasOwnProperty("color")) {
      dispatch(
        setShowEvents({
          state: true,
          color: SET_VISIBILITY_ICON,
        })
      );
      let counter = 0;
      let quantity = 0;
      for (let key in showOperationByType) {
        quantity++;
        if (showOperationByType[key] === false) {
          counter++;
        }
        if (showOperationByType[2] === true && operationTypeId === 2) {
          for (let key in showEventByInspectionType) {
            showEventByInspectionType[key] = true;
            dispatch(
              showEventsByInspectionType({ ...showEventByInspectionType })
            );
          }
        } else {
          if (operationTypeId === 2) {
            for (let key in showEventByInspectionType) {
              showEventByInspectionType[key] = false;
              dispatch(
                showEventsByInspectionType({ ...showEventByInspectionType })
              );
            }
          }
        }
      }
      if (counter === quantity) {
        let counter = 0;
        let quantity = 0;
        showAllEventsByType.forEach((elm) => {
          quantity++;
          if (elm.state === false) {
            counter++;
          }
        });
        if (counter === quantity) {
          dispatch(
            setShowEvents({
              state: false,
              color: VISIBILITY_OFF_ICON,
            })
          );
        }
      }
    } else if (showEvents.state === false) {
      let counter = 0;
      let quantity = 0;

      for (let key in showEventByInspectionType) {
        quantity++;
        if (showEventByInspectionType[key] === false) {
          counter++;
        }
      }
      if (quantity === counter) {
        for (let key in showEventByInspectionType) {
          showEventByInspectionType[key] = true;
        }
        dispatch(showEventsByInspectionType({ ...showEventByInspectionType }));
      }
    }
    dispatch(showOperationsByType({ ...showOperationByType }));
    const operationByTypeTrue = Object.values(showOperationByType).every(
      (value) => value === true
    );
    const objectLibraryOff = showEventsByLibrary.filter((elm) => {
      return elm.state === true;
    });

    if (
      operationByTypeTrue &&
      objectLibraryOff.length === showEventsByLibrary.length
    ) {
      dispatch(setShowEvents({ state: true, color: PRIMARY_COLOR }));
    }
  };

  const currentLibrarys = deleteDuplicatePointsLibrary(
    showEventsByLibrary.length > 0 ? showEventsByLibrary : []
  );

  const currentLibrarysSort = currentLibrarys.sort((a, b) => a.name.localeCompare(b.name));
  const showAllEventsByTypeSort = showAllEventsByType.sort((a, b) => a.name.localeCompare(b.name));


  return (
    <Accordion
      sx={{
        paddingLeft: "0px",
        border: "none",
        boxShadow: "none",
      }}
      defaultExpanded={false}
    >
      <WrapperTypeObjectTitle>
        <div className="icon-title">
          {showEvents.state ? (
            <VisibilityIcon
              sx={{
                color: showEvents.color,
                margin: "10px 10px 10px 15px",
                cursor: "pointer",
              }}
              onClick={() =>
                handlerClickFilterEvents(false, `${VISIBILITY_OFF_ICON}`)
              }
            />
          ) : (
            <VisibilityOffIcon
              sx={{
                color: showEvents.color,
                margin: "10px 15px",
                cursor: "pointer",
              }}
              onClick={() => handlerClickFilterEvents(true, `${PRIMARY_COLOR}`)}
            />
          )}
          <p>Eventos</p>
        </div>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
      </WrapperTypeObjectTitle>
      {currentLibrarys.length !== 0 &&
        currentLibrarysSort.map((elm) => {
          return (
            <AccordionDetails
              sx={{
                padding: "1px 1px 1px",
                marginLeft: "10px",
              }}
            >
              <Accordion
                sx={{
                  paddingLeft: "5px",
                  border: "none",
                  boxShadow: "none",
                }}
                defaultExpanded={false}
              >
                <WrapperTypeObjectTitle>
                  <div className="icon-title">
                    {elm.state ? (
                      <VisibilityIcon
                        sx={{
                          color: elm.color,
                          margin: "10px 10px 10px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handlerClickFilterComponentsByLibrary(
                            false,
                            elm.libraryId,
                            `${VISIBILITY_OFF_ICON}`
                          )
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{
                          color: elm.color,
                          margin: "10px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handlerClickFilterComponentsByLibrary(
                            true,
                            elm.libraryId,
                            `${PRIMARY_COLOR}`
                          )
                        }
                      />
                    )}
                    <p>{elm.name}</p>
                  </div>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  ></AccordionSummary>
                </WrapperTypeObjectTitle>
                <AccordionDetails>
                  {showAllEventsByTypeSort.map((elm2) => {
                    return (
                      <WrapperTypeObjectTitle>
                        {elm.libraryId === elm2.libraryId ? (
                          <>
                            <div className="icon-title">
                              {elm2.state ? (
                                <VisibilityIcon
                                  sx={{
                                    color: `${PRIMARY_COLOR}`,
                                    margin: "10px 10px 10px 15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlerClickFilterComponentsByType(
                                      elm2.id,
                                      elm2.libraryId,
                                      false,
                                      `${VISIBILITY_OFF_ICON}`
                                    )
                                  }
                                />
                              ) : (
                                <VisibilityOffIcon
                                  sx={{
                                    color: `${VISIBILITY_OFF_ICON}`,
                                    margin: "10px 15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlerClickFilterComponentsByType(
                                      elm2.id,
                                      elm2.libraryId,
                                      true,
                                      `${PRIMARY_COLOR}`
                                    )
                                  }
                                />
                              )}
                              <p>{elm2.name}</p>
                            </div>
                            <div>
                              <img
                                src={elm2.icon}
                                width="30px"
                                height="30px"
                                alt="ico"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </WrapperTypeObjectTitle>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          );
        })}
      <AccordionDetails
        sx={{
          padding: "8px 0px 16px 30px",
        }}
      >
        <Accordion
          sx={{
            border: "none",
            boxShadow: "none",
          }}
          defaultExpanded={false}
        >
          <WrapperTypeObjectTitle>
            <p>
              <strong>Generic events</strong>
            </p>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            ></AccordionSummary>
          </WrapperTypeObjectTitle>
          <AccordionDetails
            sx={{
              paddingBottom: "0px",
            }}
          >
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "bold",
                color: "#525151",
              }}
            >
              Tipo de inspección
            </p>
            {typeInspectionsFlir
              .sort((a, b) => a?.name?.localeCompare(b?.type))
              .map((typeInspection, index) => {
                return (
                  <FormGroup key={index}>
                    <WrapperTypeElementTitle>
                      <div className="icon-title">
                        {showEventByInspectionType[typeInspection.id] &&
                        showOperationByType[2] ? (
                          <VisibilityIcon
                            sx={{
                              color: `${PRIMARY_COLOR}`,
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlerFilterByInspectionType(
                                typeInspection.id,
                                false
                              )
                            }
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{
                              color: `${VISIBILITY_OFF_ICON}`,
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={
                              showOperationByType[2]
                                ? () =>
                                    handlerFilterByInspectionType(
                                      typeInspection.id,
                                      true
                                    )
                                : () =>
                                    handlerFilterByInspectionType(
                                      typeInspection.id,
                                      true
                                    )
                            }
                          />
                        )}
                        <p>{typeInspection.type}</p>
                      </div>
                    </WrapperTypeElementTitle>
                  </FormGroup>
                );
              })}
            <hr />
            {uniqueOperations
              .sort((a, b) => a?.name?.localeCompare(b?.name))
              .map((operation, index) => {
                return (
                  <FormGroup key={index}>
                    <WrapperTypeElementTitle>
                      <div className="icon-title">
                        {showOperationByType[operation.id] ? (
                          <VisibilityIcon
                            sx={{
                              color:
                                showOperationByType[
                                  operation.id
                                ].hasOwnProperty("color") &&
                                showOperationByType[2] &&
                                showOperationByType[2].hasOwnProperty("color")
                                  ? `${showOperationByType[2].color}`
                                  : `${PRIMARY_COLOR}`,
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlerFilterByOperationType(operation.id)
                            }
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{
                              color: `${VISIBILITY_OFF_ICON}`,
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlerFilterByOperationType(operation.id)
                            }
                          />
                        )}
                        <p>{operation.name}</p>
                      </div>
                    </WrapperTypeElementTitle>
                  </FormGroup>
                );
              })}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterEvents;
