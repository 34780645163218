import { useState } from "react";
import { useSWRConfig } from "swr";
import { useDispatch } from "react-redux";
import { setShowOperationDetails } from "redux/actions";

import CustomizedButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
import deleteEvent from "services/deleteNewEvent";

const DeleteButton = ({ pointEventId }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this event?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const handleClick = () => setOpenDialog(true);

  const handlerDeleteEvent = async () => {
    const { res } = await deleteEvent(pointEventId);
    if (res.status === 200) {
      dispatch(setShowOperationDetails(false));
      const urlEvents = `${process.env.REACT_APP_URL_BACKEND}api/v1/point-events?adminCompanyId=${adminCompanyId}`;
      mutate(urlEvents);
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
      setContentDialog({
        title: "",
        description: "❌ Error to delete event, try again.",
        disagree: "Cancel",
      });
    }
  };

  return (
    <>
      <CustomizedButton
        text={"Delete event"}
        primaryColor={"#d83939"}
        secondaryColor={"#eb4123"}
        onClick={handleClick}
      />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={handlerDeleteEvent}
        content={contentDialog}
      />
    </>
  );
};

export default DeleteButton;
