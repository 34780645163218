import axios from "axios";

const createTypeComponentsTypeElements = async (body) => {
  const token = localStorage.getItem("token");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-components-type-elements`;
  const result = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default createTypeComponentsTypeElements;
