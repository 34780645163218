import {
  createTypeEventsTypeEvents,
  findTypeEventsTypeEvents,
  deleteTypeEventsTypeEvents,
} from "services/typeEventsTypeEvents.js";

/**
 * Updates the events callback.
 *
 * @param {Object} pointTypeEvent - The point type event.
 * @param {Object} typeEventsTypeEvents - The type events type events.
 * @param {Function} setTypeEventsTypeEvents - The function to set the type events type events.
 * @return {Function} The updated events callback.
 */
const UpdateEventsEventsCallback = ({
  pointTypeEvent,
  typeEventsTypeEvents,
  setTypeEventsTypeEvents,
}) => {
  const update = async () => {
    const { id } = pointTypeEvent;
    const lastEventsTypes = await findTypeEventsTypeEvents({
      pointTypeEventId: id,
    });
    const toDelete = [];
    const toCreate = [];

    lastEventsTypes.forEach(({ pointTypeEventRelationId }) => {
      if (!typeEventsTypeEvents[pointTypeEventRelationId]) {
        const ids = lastEventsTypes
          .filter(
            (last) => last.pointTypeEventRelationId === pointTypeEventRelationId
          )
          .map((last) => last.id);
        ids.filter((item, index) => ids.indexOf(item) === index);
        toDelete.push(...ids);
      }
    });

    Object.keys(typeEventsTypeEvents).forEach((pointTypeEventRelationId) => {
      if (
        !lastEventsTypes.find(
          (e) =>
            parseInt(e.pointTypeEventRelationId) ===
            parseInt(pointTypeEventRelationId)
        )
      )
        toCreate.push(pointTypeEventRelationId);
    });

    await Promise.all(
      toCreate.map(async (pointTypeEventRelationId) => {
        await createTypeEventsTypeEvents({
          pointTypeEventId: pointTypeEvent.id,
          pointTypeEventRelationId: parseInt(pointTypeEventRelationId),
        });
      })
    );

    await Promise.all(
      toDelete.map(async (id) => {
        await deleteTypeEventsTypeEvents(id);
      })
    );

    setTypeEventsTypeEvents({});
  };
  return update;
};

export default UpdateEventsEventsCallback;
