import deleteView from "services/deleteBookmarksView.js";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormGroup from "@mui/material/FormGroup";
import List from "@mui/material/List";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSWR, { useSWRConfig } from "swr";
// custom style
import { WrapperTypeElementTitle } from "./AccordionStyles";
import OpenDialog from "components/Dialogs/OpenDialog";

import { config } from "config.js";
import { ButtonsContainer, ViewsTitle } from "./AccordionBookmarksViewsStyle";

/**
 * Renders a list of public and private views and handles their deletion. The function
 * uses the useSWR hook to fetch the data from the backend. When a view is deleted,
 * the function calls the deleteView function to remove the view from the database and
 * calls the mutate function to update the views list. When a view is clicked, the function
 * uses the handleToFly function to move the map to the view's location.
 *
 * @param {Object} map - The map object.
 * @return {JSX.Element} Returns the JSX element that displays the views list.
 */
const BookmarksViews = () => {
  const { mutate } = useSWRConfig();
  const map = useSelector((state) => state.digitalTwinReducer.map);

  const [confirmationPublic, setConfirmationPublic] = useState(false);
  const [confirmationPrivate, setConfirmationPrivate] = useState(false);

  const [publicViews, setPublicViews] = useState([]);
  const [privateViews, setPrivateViews] = useState([]);

  const [toDelete, setToDelete] = useState(0);

  const userId = parseInt(localStorage.getItem("userId"));

  const { data: userViews, error: errorUserViews } = useSWR(
    `${config.URL_BACKEND_PG}api/v1/views`
  );

    useEffect(()=>{
      setPrivateViews(userViews?.private)
      setPublicViews(userViews?.public)
    },[userViews])

  const handleToDeletePublic = async (id) => {
    setToDelete(parseInt(id));
    setConfirmationPublic(true);
  };
  const toDeletePublic = async (id) => {
    await deleteView(id);
    mutate(`${config.URL_BACKEND_PG}api/v1/views`);
    setConfirmationPublic(false);
  };

  const handleToDeletePrivate = async (id) => {
    setToDelete(parseInt(id));
    setConfirmationPrivate(true);
  };
  const toDeletePrivate = async (id) => {
    await deleteView(id);
    mutate(`${config.URL_BACKEND_PG}api/v1/views`);
    setConfirmationPrivate(false);
  };

  const handleToFly = (id, visibility) => {
    const view = visibility
      ? publicViews?.filter((item) => item.id === id)[0]
      : privateViews?.filter((item) => item.id === id)[0];
    const SW = {
      lng: view?.coordinates.bounds[0][0],
      lat: view?.coordinates.bounds[0][1],
    };
    const NE = {
      lng: view?.coordinates.bounds[1][0],
      lat: view?.coordinates.bounds[1][1],
    };
    const bbox = [SW, NE];
    map.getMap().fitBounds(bbox, {
      padding: 10,
      duration: 500,
    });
  };

  return (
    <>
      {!errorUserViews && (
        <List
          sx={{
            height: "100%",
            position: "bottom",
          }}
          subheader={<li />}
        >
          <AccordionDetails>
            <ViewsTitle style={{ marginBottom: "15px" }}>
              Public views [{publicViews && publicViews?.length}]
            </ViewsTitle>
            {publicViews &&
              publicViews?.map((item, index) => {
                return (
                  <FormGroup key={index}>
                    <WrapperTypeElementTitle>
                      <ButtonsContainer>
                        <button
                          onClick={() => handleToFly(item.id, true)}
                          className="buttons-views"
                        >
                          <OpenInNewIcon />
                        </button>
                        <div className="icon-title">
                          <p>{item?.description}</p>
                        </div>
                        {userId === parseInt(item?.userId) && (
                          <button
                            onClick={() => handleToDeletePublic(item.id)}
                            className="buttons-views button-delete"
                          >
                            <DeleteIcon />
                          </button>
                        )}
                      </ButtonsContainer>
                    </WrapperTypeElementTitle>
                  </FormGroup>
                );
              })}
            <OpenDialog
              openDialog={confirmationPublic}
              setOpenDialog={setConfirmationPublic}
              execute={() => toDeletePublic(toDelete)}
              content={{
                title: "Delete view",
                description: "Do you want to delete view?",
                agree: "delete",
                disagree: "cancel",
              }}
              maxWidth={"400px"}
            />
          </AccordionDetails>

          <AccordionDetails>
            <ViewsTitle style={{ marginBottom: "15px" }}>
              Private views [{privateViews && privateViews?.length}]
            </ViewsTitle>
            {privateViews &&
              privateViews?.map((item, index) => {
                return (
                  <FormGroup key={index}>
                    <WrapperTypeElementTitle>
                      <ButtonsContainer>
                        <button
                          onClick={() => handleToFly(item?.id, false)}
                          className="buttons-views"
                        >
                          <OpenInNewIcon />
                        </button>
                        <div className="icon-title">
                          <p>{item?.description}</p>
                        </div>
                        <button
                          onClick={() => handleToDeletePrivate(item?.id)}
                          className="buttons-views button-delete"
                        >
                          <DeleteIcon />
                        </button>
                      </ButtonsContainer>
                    </WrapperTypeElementTitle>
                  </FormGroup>
                );
              })}
            <OpenDialog
              openDialog={confirmationPrivate}
              setOpenDialog={setConfirmationPrivate}
              execute={() => toDeletePrivate(toDelete)}
              content={{
                title: "Delete view",
                description: "Do you want to delete view?",
                agree: "delete",
                disagree: "cancel",
              }}
              maxWidth={"400px"}
            />
          </AccordionDetails>
        </List>
      )}
    </>
  );
};

export default BookmarksViews;
