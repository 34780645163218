import styled from "styled-components";
import {
  OBJECT_CLUSTER_COLOR,
  ICON_GREEN_COLOR,
  SENSOR_CLUSTER_COLOR,
  EVENT_CLUSTER_COLOR,
  CSV_CLUSTER_COLOR,
  COMPONENT_CLUSTER_COLOR,
  WHITE_COLOR,
} from "utils/const";

export const MarkerElementContainer = styled.button`
  position: relative;
  border: none;
  background: none;
  cursor: ${(props) => (props.drawInMap ? "none" : "pointer")};

  img {
    width: 40px;
    filter: ${(props) => (props.filter ? "none" : "grayscale(100%)")};
    z-index: 2;
  }

  img:hover {
    opacity: ${(props) => (props.drawInMap ? "0" : "1")};
  }

  .selected {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.flyTo ? "block" : "none")};
  }

  .qaqc {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${ICON_GREEN_COLOR};
    border-radius: 50%;
    z-index: -1;
    display: ${(props) => (props.qaqc ? "block" : "none")};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      opacity: 0.6;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }
`;

export const MarkerClusterContainer = styled("div")`
  .cluster-marker-component,
  .cluster-marker-object,
  .cluster-marker-csv,
  .cluster-marker-operation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    color: ${WHITE_COLOR};
    border-radius: 50%;
    cursor: pointer;
  }

  .cluster-marker-csv {
    background: ${CSV_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .cluster-marker-operation {
    background: ${EVENT_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .cluster-marker-component {
    background: ${COMPONENT_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .cluster-marker-object {
    background: ${OBJECT_CLUSTER_COLOR};
    box-shadow: 0 0 8px ${SENSOR_CLUSTER_COLOR};
  }

  .object-marker {
    background: none;
    border: none;
  }

  .object-marker img {
    width: 40px;
  }
`;
