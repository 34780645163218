import { Loader, LoadingContainer } from "./LoadingStyle.jsx";

const LoadingStyle = () => {
  return (
    <LoadingContainer>
      <Loader>
        <img
          className="logo"
          src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png"
          alt="ghg logo"
        />
      </Loader>
      <p>Loading</p>
    </LoadingContainer>
  );
};

export default LoadingStyle;
