const InformationCollect = () => {
  return (
    <>
      <p className="text">
        1.1 User-Provided Information: When using AI Socrates, you may
        voluntarily provide us with personal information, such as your name,
        email address, contact details, and any documentation or content you
        submit for analysis.
      </p>
      <p className="text">
        1.2 Document Analysis: AI Socrates analyzes the provided documents to
        extract relevant information and generate responses. The documents you
        submit may contain personal, proprietary, or confidential information,
        and you acknowledge and agree to the processing of such information by
        AI Socrates.
      </p>
      <p className="text">
        1.3 Usage Data: We may collect usage data, including interaction
        patterns, queries, and other user activities within AI Socrates. This
        information is used for analytical purposes and to improve the
        Solution's performance and user experience.
      </p>
      <p className="text">
        1.4 Cookies and Tracking Technologies: We may use cookies and similar
        tracking technologies to enhance your experience with AI Socrates. These
        technologies collect information about your usage patterns and
        preferences.
      </p>
    </>
  );
};
const UseInformation = () => {
  return (
    <>
      <p className="text">
        2.1 We use the collected information for the following purposes:
      </p>
      <br></br>
      <p className="text">- To provide, operate, and maintain AI Socrates;</p>
      <p className="text">
        - To analyze and process the provided documents for extracting relevant
        information and generating responses;
      </p>
      <p className="text">
        - To personalize and improve your experience with AI Socrates;
      </p>
      <p className="text">
        - To communicate with you and respond to your inquiries or requests;
      </p>
      <p className="text">
        - To send you technical notices, updates, and administrative messages;
      </p>
      <p className="text">
        - To analyze usage patterns and trends and perform data analytics;
      </p>
      <p className="text">
        - To enforce our terms, policies, andlegal obligations;
      </p>
      <p className="text">
        - To protect against fraud, unauthorized access, or other potential
        risks.
      </p>
      <br></br>
      <p className="text">
        2.2 We may use anonymized and aggregated data for statistical, research,
        and analytical purposes.
      </p>
    </>
  );
};
const DataSharing = () => {
  return (
    <>
      <p className="text">
        3.1 We do not sell, trade, or rent your personal information to third
        parties for marketing purposes. However, we may share your information
        in the following circumstances:{" "}
      </p>
      <br></br>
      <p className="text">
        - With service providers who assist us in providing and improving AI
        Socrates (e.g., hosting, analytics, customer support);{" "}
      </p>
      <p className="text">
        - With your consent or at your direction, such as when you authorize the
        integration of third-party services with AI Socrates;{" "}
      </p>
      <p className="text">
        - When required by law or to comply with legal process, enforceable
        governmental request, or applicable regulations;{" "}
      </p>
      <p className="text">
        - To protect our rights, property, or safety, and that of our users and
        the public.{" "}
      </p>
      <br></br>
      <p className="text">
        3.2 We may disclose aggregated and anonymized information that does not
        identify individual users for marketing, advertising, research, or other
        purposes.
      </p>
    </>
  );
};
const DataRetention = () => {
  return (
    <>
      <p className="text">
        4.1 We retain your personal information and the documents you submit for
        as long as necessary to fulfill the purposes outlined in this Policy
        unless a longer retention period is required or permitted by law.{" "}
      </p>
      <p className="text">
        4.2 We will securely delete or anonymize your personal information and
        the documents within a reasonable time after it is no longer needed for
        the purposes stated in this Policy or as required by applicable law.{" "}
      </p>
    </>
  );
};
const DataSecurity = () => {
  return (
    <>
      <p className="text">
        5.1 We implement appropriate technical and organizational measures to
        protect your personal information and the documents you submit against
        unauthorized access, loss, or alteration. However, please note that no
        data transmission or storage system is completely secure, and we cannot
        guarantee the absolute security of your information.{" "}
      </p>
    </>
  );
};
const LinksServices = () => {
  return (
    <>
      <p className="text">
        6.1 AI Socrates may contain links to third-party websites, applications,
        or services. This Policy does not apply to such third-party platforms,
        and we are not responsible for their privacy practices. We encourage you
        to review the privacy policies of those third parties before providing
        any personal information.{" "}
      </p>
    </>
  );
};
const ChildrenPrivacy = () => {
  return (
    <>
      <p className="text">
        7.1 AI Socrates is not intendedfor use by individuals under the age of
        16. We do not knowingly collect personal information from children. If
        we become aware that we have inadvertently collected personal
        information from a child under 16, we will take steps to delete the
        information assoon as possible.{" "}
      </p>
    </>
  );
};
const PrivacyPolicy = () => {
  return (
    <>
      <p className="text">
        8.1 We may update or modify this Policy from time to time. If we make
        any material changes, we will notify you by posting the revised Policy
        within AI Socrates or by other means. Your continued use of AI Socrates
        after the effective date of the revised Policy constitutes your
        acceptance of the changes.{" "}
      </p>
    </>
  );
};
const ContactUs = () => {
  return (
    <>
      <p className="text">
        {" "}
        9.1 If you have any questions or concerns regarding this Policy or our
        privacy practices, please contact us at business@decimetrix.com{" "}
      </p>
    </>
  );
};
const Acknowledgment = () => {
  return (
    <>
      <p className="text">
        {" "}
        By downloading, accessing, or using the Platform, you acknowledge that
        you have read, understood, and agree to be bound by this Agreement. If
        you do not agree with any part of this Agreement, you should not
        download, access, or use the Application.
      </p>
    </>
  );
};

export const dataSocratesPol = {
  title: "Decimetrix®AI Socrates Privacy Policy",
  mainText:
    'This Privacy Policy ("Policy")  describes how Decimetrix,  Inc and Decimetrix  SAS. ("Decimetrix,"  "we," or "us") collects,  uses,  and  protects  the  personal  information  and  data of  users  ("User,"  "you,"  or "your") who access or use the Decimetrix AI Socrates artificial intelligence solution ("AI Socrates" or "Solution"). By  accessing  or  using  the  Solution,  you  consent  to  the  collection,  storage,  processing,  and  use  of  your personal information as described in this Policy.',
  info: [
    {
      subtitle: "Information We Collect",
      text: <InformationCollect />,
    },
    {
      subtitle: "Use of Information",
      text: <UseInformation />,
    },
    {
      subtitle: "Data Sharing and Disclosure",
      text: <DataSharing />,
    },
    {
      subtitle: "Data Retention",
      text: <DataRetention />,
    },
    {
      subtitle: "Data Security",
      text: <DataSecurity />,
    },
    {
      subtitle: "Third-Party Links and Services",
      text: <LinksServices />,
    },
    {
      subtitle: "Children's Privacy",
      text: <ChildrenPrivacy />,
    },
    {
      subtitle: "Changes to this Privacy Policy",
      text: <PrivacyPolicy />,
    },
    {
      subtitle: "Contact Us",
      text: <ContactUs />,
    },
    {
      subtitle: "Acknowledgment",
      text: <Acknowledgment />,
    },
  ],
};
