// Mapbox
import { Marker } from "react-map-gl";
// Style
import { NamePlateMarker, ImgIconOperation } from "./showEventsStyle";

/**
 * Generates a list of event markers based on the given marks array.
 *
 * @param {array} marks - The array of marks representing the events.
 * @param {function} handleClickDynamicEvent - The callback function for handling dynamic events.
 * @param {function} handleClickMarker - The callback function for handling markers.
 * @param {string} markedEvent - The ID of the currently marked event.
 * @return {array} The list of event markers.
 */
export const ShowEventMarkers = (
  marks,
  handleClickDynamicEvent,
  handleClickMarker,
  markedEvent
) => {
  return marks?.map((event, index) => {
    const iconEvent = event?.typeOperation?.urlIcon;
    const icontDynamicEvent = event?.pointTypeEvent?.icon;
    const icon = iconEvent || icontDynamicEvent;
    const handlerFunction = event.mongoId
      ? handleClickDynamicEvent
      : handleClickMarker;
    return (
      <div key={index}>
        {marks && (
          <Marker
            latitude={Number(event.locationOperation.latitude)}
            longitude={Number(event.locationOperation.longitude)}
            style={{
              cursor: "pointer",
            }}
          >
            <NamePlateMarker
              id={event.id}
              onClick={() => handlerFunction(event.id)}
              marked={markedEvent === event.id}
            >
              <ImgIconOperation
                id={event.id}
                src={icon}
                alt="nameplate marker"
                style={{
                  cursor: "pointer",
                }}
              ></ImgIconOperation>
              <span className="selected"></span>
            </NamePlateMarker>
            <p>{event.id}</p>
          </Marker>
        )}
      </div>
    );
  });
};
