import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function LineChart({ labels, quantityData, horizontal }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: PRIMARY_COLOR,
          width: 2,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Quantity",
        data: quantityData,
        borderColor: SECOND_COLOR,
        pointBackgroundColor: PRIMARY_COLOR,
        cubicInterpolationMode: "monotone",
        capBezierPoints: false,
        borderJoinStyle: "round",
        borderCapStyle: "round",
        pointStyle: false,
        pointHoverRadius: 5,
        grid: false,
        pointRadius: 2,
      },
    ],
  };
  return <Line options={options} data={data} />;
}
