import Typography from "@mui/material/Typography";
// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// custom style
import {
  WrapperTypeObjectTitle,
  useStyles,
  CustomizedAccordion,
} from "./AccordionStyles";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  showMarkerByElementWithMedia,
} from "redux/actions/admin";

import {
  showLabels,
  setShowLines,
  setShowTrackings,
} from "redux/actions/digitalTwin";

import {
  PRIMARY_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";

const AccordionSpecialFilters = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let showElementWitheMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );

  let showLabelsElements = useSelector(
    (state) => state.digitalTwinReducer.showLabels
  );

  const showLines = useSelector((state) => state.digitalTwinReducer.showLines);
  const showTrackings = useSelector(
    (state) => state.digitalTwinReducer.showTrackings
  );

  const setShowLinesHandler = (change) => {
    dispatch(setShowLines(change));
  };

  const setShowTrackingsHandler = (change) => {
    dispatch(setShowTrackings(change));
  };

  const handlerClickFilterMedia = () => {
    showElementWitheMedia = !showElementWitheMedia;
    dispatch(showMarkerByElementWithMedia(showElementWitheMedia));
  };

  const handlerClickShowLabel = () => {
    showLabelsElements = !showLabelsElements;
    dispatch(showLabels(showLabelsElements));
  };

  return (
    <>
      <CustomizedAccordion
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={false}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "1.5rem" }}>Special Filters</Typography>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        </div>

        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {!showLabelsElements ? (
              <VisibilityOffIcon
                sx={{
                  color: `${VISIBILITY_OFF_ICON}`,
                  margin: "10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickShowLabel}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  color: `${PRIMARY_COLOR}`,
                  margin: "10px 10px 10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickShowLabel}
              />
            )}
            <p>Etiquetas</p>
          </div>
        </WrapperTypeObjectTitle>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showElementWitheMedia ? (
              <VisibilityOffIcon
                sx={{
                  color: `${VISIBILITY_OFF_ICON}`,
                  margin: "10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickFilterMedia}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  color: `${PRIMARY_COLOR}`,
                  margin: "10px 10px 10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickFilterMedia}
              />
            )}
            <p>Objetos Con Media</p>
          </div>
        </WrapperTypeObjectTitle>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showLines ? (
              <VisibilityIcon
                sx={{
                  color: `${PRIMARY_COLOR}`,
                  margin: "10px 15px",
                  cursor: "pointer",
                }}
                onClick={() => setShowLinesHandler(false)}
              />
            ) : (
              <VisibilityOffIcon
                sx={{
                  color: `${VISIBILITY_OFF_ICON}`,
                  margin: "10px 10px 10px 15px",
                  cursor: "pointer",
                }}
                onClick={() => setShowLinesHandler(true)}
              />
            )}
            <p>Lineas</p>
          </div>
        </WrapperTypeObjectTitle>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showTrackings ? (
              <VisibilityIcon
                sx={{
                  color: `${PRIMARY_COLOR}`,
                  margin: "10px 15px",
                  cursor: "pointer",
                }}
                onClick={() => setShowTrackingsHandler(false)}
              />
            ) : (
              <VisibilityOffIcon
                sx={{
                  color: `${VISIBILITY_OFF_ICON}`,
                  margin: "10px 10px 10px 15px",
                  cursor: "pointer",
                }}
                onClick={() => setShowTrackingsHandler(true)}
              />
            )}
            <p>Rastreos</p>
          </div>
        </WrapperTypeObjectTitle>
      </CustomizedAccordion>
    </>
  );
};

export default AccordionSpecialFilters;
