import { useNavigate } from "react-router-dom";
import { useState } from "react";
import NavBar from "components/NavBar";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { MessageError, MessageSuccess } from "components/Forms/FormStyles";

import {
  LoginContainer,
  LoginTitle,
  LoginForm,
  ArrowForward,
  ArrowRight,
  LoginButton,
} from "./RecoveryElements";

import InputComponent from "components/Forms/Input";

const PasswordConfirmation = () => {
  const [message, setMessage] = useState(null);
  const [password2, setPassword2] = useState({ field: "", valid: null });
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({ field: "", valid: null });
  const [FormValidated, setFormValidated] = useState(null);
  const [hover, setHover] = useState(false);
  const { token } = useParams();

  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };

  const expressions = {
    lastName: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // letters y spaces, can carry accents.
    name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // letters y spaces, can carry accents.
    password: /^.{8,12}$/, // 4 a 12 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const navigate = useNavigate();

  const saveUser = async (body) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/auth/resetPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      await res.json();
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password.valid === "true") {
      const body = {
        password: password.field,
        passResetToken: token,
      };

      const res = await saveUser(body);
      if (res.status === 201) {
        setFormValidated(true);
        setLoading(false);
        setMessage(null);
        navigate("/signin");
      } else {
        setFormValidated(false);
        setLoading(false);
        setMessage("Incorrect user or password");
      }

      setFormValidated(true);

      setPassword({ field: "", valid: null });
    } else {
      setFormValidated(false);
    }
  };
  const onHover = () => {
    setHover(!hover);
  };
  const validatePassword2 = () => {
    if (password.field.length > 0) {
      if (password.field !== password2.field) {
        setPassword2((prevState) => {
          return { ...prevState, valid: "false" };
        });
      } else {
        setPassword2((prevState) => {
          return { ...prevState, valid: "true" };
        });
      }
    }
  };

  const navigateSignin = () => {
    navigate("/signin");
  };

  return (
    <div>
      <NavBar configNavbar={configNavbar} />
      <LoginContainer>
        <LoginForm onSubmit={onSubmit}>
          <LoginTitle>Type the new password</LoginTitle>

          <InputComponent
            state={password}
            changeState={setPassword}
            type="password"
            name="password"
            label="Password"
            placeholder="********"
            legendError="The password must be 8 to 12 characters long."
            regularExpression={expressions.password}
          >
            {" "}
          </InputComponent>

          <InputComponent
            state={password2}
            changeState={setPassword2}
            type="password"
            label="Repeat password"
            name="password2"
            legendError="Both passwords must be the same."
            func={validatePassword2}
          />

          <LoginButton
            type="submit"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Send {hover ? <ArrowForward /> : <ArrowRight />}
          </LoginButton>

          {loading ? (
            <Box sx={{ marginTop: "20px" }}>
              <CircularProgress />
            </Box>
          ) : null}

          <div>
            {FormValidated === false && (
              <MessageError>
                <p>
                  <b>Error:</b> {message}
                </p>
              </MessageError>
            )}
            {FormValidated === true && (
              <MessageSuccess>Password has been changed</MessageSuccess>
            )}
            {FormValidated === true && (
              <LoginButton onClick={navigateSignin}>Go to log in</LoginButton>
            )}
          </div>
        </LoginForm>
      </LoginContainer>
    </div>
  );
};

export default PasswordConfirmation;
