import styled from "styled-components";

export const FormContainer = styled.form`
  min-width: 350px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  border: 1px solid rgba(198, 197, 197, 0.609);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  position: relative;
  background-color: #fff;

  p {
    margin-top: 10px;
    font-size: 1.7rem;
  }
`;
