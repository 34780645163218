import { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
// Components
import Layout from "components/Layout/AdminDecimetrix";
import ConfigTypeElements from "pages/ConfigAdmin/typeElemts";
import ConfigTypeLines from "pages/ConfigAdmin/TypeLine";
import ConfigTypeRegions from "pages/ConfigAdmin/TypeRegions";

// Styles
import {
  TitleAdminConfig,
  ReturnSettingsPage,
  SelectContainerAdminConfig,
  ConfigObjectsContainer,
} from "./configAdminStyles";

const typeObjects = [
  {
    id: 1,
    name: "Points",
  },
  {
    id: 2,
    name: "Lines",
  },
  {
    id: 3,
    name: "Polygons",
  },
];

const Objects = () => {
  const [object, setObjects] = useState({
    id: "1",
    name: "Points",
  });
  const navigate = useNavigate();

  const navigation = (path) => {
    navigate(path);
  };

  const handleLibrary = (e) => {
    setObjects((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: typeObjects.find(
        (object) => object.id === parseInt(e.target.value)
      )?.name,
    }));
  };

  return (
    <Layout>
      <ConfigObjectsContainer>
        <ReturnSettingsPage onClick={() => navigation("/config-admin")}>
          <AiOutlineLeft />
        </ReturnSettingsPage>
        <SelectContainerAdminConfig>
          <p>Type Object: </p>
          <select name="endPoint" id="endPoint" onChange={handleLibrary}>
            {typeObjects?.map((object) => (
              <option key={object.id} value={object.id}>
                {object.name}
              </option>
            ))}
          </select>
        </SelectContainerAdminConfig>
      </ConfigObjectsContainer>
      <TitleAdminConfig>
        Digital Twin Object Configuration - {object.name}
      </TitleAdminConfig>

      {object.id === "1" && <ConfigTypeElements />}
      {object.id === "2" && <ConfigTypeLines />}
      {object.id === "3" && <ConfigTypeRegions />}
    </Layout>
  );
};

export default Objects;
