import axios from "axios";

const saveFlirVideo = async (body) => {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video`,
    body
  );
  const data = await res.data;
  return { data, res };
};

export default saveFlirVideo;
