import { useState } from "react";
import { useSWRConfig } from "swr";
// Style
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
// Icons
import { AiFillCloseCircle } from "react-icons/ai";
import SaveIcon from "@mui/icons-material/Save";
// Components
import LoadingButton from "@mui/lab/LoadingButton";
import { HeadModal } from "components/Dialogs/DialogsStyles";
// Consts
import { PRIMARY_COLOR } from "utils/const";
// Services
import saveObjectFile, { saveCaptureMedia } from "services/saveObjectFile";
import uploadFileToS3 from "services/uploadFileToS3sdk";
import updateObjectPg from "services/updateObjectPg";

// Config
import { config } from "config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const UploadMediaObject = ({
  object,
  photosFile,
  setPhotosFile,
  openModal,
  setOpenModal,
}) => {
  const [typeObjectFileId, setTypeObjectFileId] = useState("1");
  const [loading, setLoading] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this element?",
    disagree: "Cancel",
    agree: "Accept",
  });

  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { mutate } = useSWRConfig();

  const urlData = `${config.URL_BACKEND_PG}api/v1/objects/${object.id}`;

  const setObjectHandler = (e) => {
    setTypeObjectFileId(e.target.value);
  };

  const handleClose = () => setOpenModal(false);

  const savePhotos = async () => {
    let body = {
      lastUpdate: new Date(),
      userUpdateId: userId,
    };
    await saveObject(body);
    setLoading(true);
    Promise.all(
      photosFile.map(async (file) => {
        const urlFile = await uploadFileToS3(file, 8);
        if (
          typeObjectFileId === "1" ||
          typeObjectFileId === "2" ||
          typeObjectFileId === "3"
        ) {
          await saveObjectFile({
            objectId: object.id,
            typeObjectFileId,
            urlFile,
          });
        } else {
          const aliasFieldMedia = object?.fieldsCaptureMedia.find(
            (item) => item.name === typeObjectFileId
          ).alias;

          await saveCaptureMedia({
            pointObjectMgId: object.mongoId,
            media: [
              {
                type: typeObjectFileId,
                alias: aliasFieldMedia,
                url: urlFile,
              },
            ],
          });
          await updateObjectPg({ haveMedia: true }, object.id);
        }
      })
    ).then(() => {
      setLoading(false);
      handleClose();
      setPhotosFile([]);
      mutate(urlData);
    });
  };

  const saveObject = async (body) => {
    try {
      await updateObjectPg(body, object.id);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`
      );
      setContentDialog({ ...contentDialog, agree: "yes" });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        style={{
          position: "relative",
          borderRadius: "10px",
        }}
      >
        <HeadModal>
          <div className="title">
            <img
              src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
              alt=""
              className="neurona"
            />
            <span className="titleHeadModal">Add Picture</span>
          </div>
          <Button onClick={handleClose}>
            <AiFillCloseCircle
              style={{
                color: "#FFF",
                fontSize: 21,
                marginBottom: -4,
              }}
            />
          </Button>
        </HeadModal>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{
            marginTop: "50px",
          }}
        >
          Please select the type of picture to upload:
          <br />
          <div>
            <select
              value={typeObjectFileId}
              onChange={setObjectHandler}
              style={{
                backgroundColor: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: "#FFF",
                padding: "5px 15px",
                margin: "11px 11px 11px 15px",
              }}
            >
              <option value="1">Equipment</option>
              <option value="2">Nameplate</option>
              <option value="3">General</option>
              {object?.fieldsCaptureMedia?.length > 0 &&
                object?.fieldsCaptureMedia.map((item) => (
                  <option key={item.name} value={item.name}>
                    {item.alias}
                  </option>
                ))}
            </select>
          </div>
        </Typography>
        <LoadingButton
          sx={{
            margin: "11px",
          }}
          loadingPosition="start"
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpenModal(false);
          }}
          style={{
            backgroundColor: PRIMARY_COLOR,
            borderColor: PRIMARY_COLOR,
            color: "#FFF",
          }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          color="primary"
          onClick={() => {
            setLoading(true);
            savePhotos();
          }}
          style={{
            backgroundColor: PRIMARY_COLOR,
            borderColor: PRIMARY_COLOR,
            color: "#FFF",
          }}
        >
          Save
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default UploadMediaObject;
