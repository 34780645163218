// React
import { useCallback, useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import { setFlyTo, setRowNum } from "redux/actions/digitalTwin";
// Components
import GenericTable from "components/Tables/GenericTable";
import ObjectDetails from "components/ImageGallery/ObjectDetails/ObjectDetails";
// Dependecies
import moment from "moment-timezone";
// Styles
import { SearchPosition } from "./styles";

const keyValues = [
  "id",
  "flyTo",
  "markPoint",
  "fieldEquipmentName",
  "typeElementId",
  "typeElementName",
  "dateUTC",
  "localDate",
  "userName",
  "fieldBaseline",
  "latitude",
  "longitude",
  "comments",
];

const headersTable = [
  "ID",
  "Fly To",
  "Mark Object",
  "Field Equipment",
  "Element ID",
  "Type of Element",
  "Date UTC-0",
  "Local Date",
  "User",
  "Baseline",
  "Latitude",
  "Longitude",
  "Comments",
];

/**
 * Renders a table of objects based on the provided parameters.
 *
 * @param {Object} pointLibrary - The point library object.
 * @param {function} setRows - A function to set the rows of the table.
 * @param {string} attributte - The attribute to filter the rows by.
 * @param {function} setLabels - A function to set the labels of the table.
 * @param {string} label - The label to filter the rows by.
 * @param {function} setTableVisibility - A function to set the visibility of the table.
 * @param {boolean} tableVisibility - The visibility of the table.
 * @param {function} handleClose - A function to handle the closing of the table.
 * @param {boolean} state - The state of the table.
 * @return {JSX.Element} The rendered table component.
 */
export default function TableObjects({
  pointLibrary,
  setRows: setRowsMain,
  attributte,
  setLabels,
  label,
  setTableVisibility,
  tableVisibility,
  handleClose,
  typeOfTable,
  typeOfLibrary,
  handleDrawerClose,
  handleDrawerOpen,
  state = false,
}) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );
  const map = useSelector((state) => state.digitalTwinReducer.map);
  const flyToRedux = useSelector((state) => state.digitalTwinReducer.flyTo);

  useEffect(() => {
    setRows([]);
  }, [pointLibrary]);

  const handerClickTable = (id) => {
    const object = {
      content: <ObjectDetails objectId={id} />,
      title: "Objects Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
    localStorage.setItem("currentId", id);
  };

  const removeLineBreak = (text) => {
    if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
    if (text && isNaN(text) && typeof text !== "object") {
      return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
    }
    return text;
  };

  const markPoint = useCallback(
    (id, lat, lng) => {
      if (state) {
        const data = {
          id,
          lat,
          lng,
        };
        const dataMarketPoint = JSON.stringify(data);
        localStorage.setItem("dataMarketPoint", dataMarketPoint);
        setTimeout(() => {
          const setData = {
            id: "",
            lat: "",
            lng: "",
          };
          const setDataMarketPoint = JSON.stringify(setData);
          localStorage.setItem("dataMarketPoint", setDataMarketPoint);
        }, 7000);
      } else {
        dispatch(
          setFlyTo({
            ...flyToRedux,
            object: {
              id,
              latitude: parseFloat(lat),
              longitude: parseFloat(lng),
            },
          })
        );
        setTableVisibility(false);
        setTimeout(() => {
          dispatch(
            setFlyTo({
              ...flyToRedux,
              object: {
                id: "",
                latitude: "",
                longitude: "",
              },
            })
          );
        }, 5000);
      }
    },
    [dispatch, flyToRedux, state, setTableVisibility]
  );

  const flyTo = useCallback(
    (e, id) => {
      const { value } = e.target;
      const [lng, lat] = value.split(",");
      if (state) {
        const coordinates = [lng, lat];
        const parseCoordinates = JSON.stringify(coordinates);
        localStorage.setItem("coordinates", parseCoordinates);
        markPoint(id, lat, lng);
      } else {
        map.flyTo({
          center: [parseFloat(lng), parseFloat(lat)],
          duration: 2000,
          zoom: 24,
        });
        markPoint(id, lat, lng);
        setTableVisibility(false);
      }
    },
    [map, markPoint, state, setTableVisibility]
  );

  const createRows = useCallback(
    (data) => {
      const rows = data.map((item) => {
        const convertedTime = moment
          .tz(item.date, "UTC")
          .utcOffset(item.timeZoneOffset || "-05:00")
          .tz(item.timeZone || "America/Bogota");
        // const date = new Date(item.date);
        const { location, id } = item;
        const { latitude, longitude } = location;
        const row = {
          id: removeLineBreak(item.id),
          flyTo: (
            <button
              className="button"
              value={`${longitude},${latitude}`}
              onClick={(e) => flyTo(e, id)}
            >
              Fly
            </button>
          ),
          markPoint: (
            <button
              className="button"
              onClick={() => markPoint(id, latitude, longitude)}
            >
              Flash
            </button>
          ),
          fieldEquipmentName: removeLineBreak(item.fieldEquipmentName),
          typeElementName: removeLineBreak(item.typeElement.name),
          localDate: removeLineBreak(
            convertedTime.format("YYYY-MM-DD HH:mm:ss z")
          ),
          dateUTC: removeLineBreak(item.date),
          userName: removeLineBreak(
            (item.user &&
              item.user.adminCompany &&
              item.user.adminCompany.firstName +
                " " +
                item.user.adminCompany.firstLastName) ||
              (item.user &&
                item.user.operator &&
                item.user.operator.firstName +
                  " " +
                  item.user.operator.firstLastName) ||
              (item.user &&
                item.user.adminDecimetrix &&
                item.user.adminDecimetrix.firstName +
                  " " +
                  item.user.adminDecimetrix.firstLastName)
          ),
          typeElementId: removeLineBreak(item.typeElementId),
          fieldBaseline: removeLineBreak(item.fieldBaseline),
          latitude: removeLineBreak(item.location.latitude),
          longitude: removeLineBreak(item.location.longitude),
          comments: removeLineBreak(item.comments),
        };

        if (item.attributes.length !== 0) {
          item.attributes.forEach((object) => {
            try {
              if (typeof JSON.parse(object.value) === "object") {
                const value = JSON.parse(object.value);
                row[object.name] = value.value;
              } else {
                return (row[object.name] = object.value);
              }
            } catch (error) {
              return (row[object.name] = object.value);
            }
          });
        }

        for (const key in row) {
          if (attributte === "") return row;
          if (!row[key] || typeof row[key] === "object") continue;
          if (label !== "all" && key !== label) continue;

          const value = isNaN(row[key]) ? row[key] : row[key].toString();
          try {
            if (value.toUpperCase().includes(attributte.toUpperCase())) {
              return row;
            }
          } catch (error) {
            console.log(attributte);
          }
        }
        return null;
      });

      const rowsFilter = rows.filter((item) => item !== null);

      return rowsFilter;
    },
    [attributte, label, flyTo, markPoint]
  );

  const updateLabels = useCallback(() => {
    const labels = {};
    for (const key in keyValues) {
      labels[keyValues[key]] = headersTable[key];
    }
    setLabels(labels);
  }, [setLabels]);

  useEffect(() => {
    const headers = [];
    headersTable.forEach((item, index) => {
      const header = {
        label: item,
        key: keyValues[index],
      };
      headers.push(header);
    });
  }, []);

  useEffect(() => {
    if (!pointLibrary) return;
    const newDataObject = dataObjectsPolygon.filter((data) => {
      return data?.typeElement?.pointLibraryId === pointLibrary;
    });
    const rows = createRows(newDataObject);
    setRows(() => [...rows]);
    setRowsMain(() => [...rows]);
    updateLabels();
  }, [dataObjectsPolygon, pointLibrary, createRows, setRowsMain, updateLabels]);

  useEffect(() => {
    dispatch(setRowNum(rows.length));
  }, [rows.length, dispatch]);
  return (
    <div className="body">
      {rows.length > 0 && (
        <SearchPosition>
          <GenericTable
            headers={headersTable}
            rows={rows}
            rowsDynamic={rows}
            typeOfTable={typeOfTable}
            typeOfLibrary={typeOfLibrary}
            keyValues={keyValues}
            handlerClick={handerClickTable}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
            handleClose={handleClose}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            style={{
              small: true,
              maxHeight: state ? 100 : 50,
              width: 55,
            }}
            digitalTwin={true}
            state={state}
          />
        </SearchPosition>
      )}
    </div>
  );
}
