import { useEffect, useMemo, useState } from "react";
import MapDigitalTwin from "components/DigitalTwin/Map";
import { setLocationsCSV } from "redux/actions";
import { setDataObjects, setShowDataObjects } from "redux/actions/admin";
import {
  setDataOperations,
  setDataEvents,
  setDataComponents,
  setShowDataComponents,
  setShowEvents,
} from "redux/actions/digitalTwin";
import { useDispatch, useStore } from "react-redux";
import useSwr from "swr";
import calculateCenter from "helpers/map/calculateCenter";
import Layout from "components/Layout/AdminDecimetrix";

import setDispatchFilterEventByTypeInspection from "helpers/filters/setDispatchFilterEventByTypeInspection";
import setDispatchFilterEventByType from "helpers/filters/setDispatchFilterEventByType";
import setDispatchFilterNewObjectsByType from "helpers/filters/setDipatchFilterObjects";
import setDispatchFilterNewEventsByType from "helpers/filters/setDispatchFilterNewEventsByType";
import setDispatchFilterNewComponentsByType from "helpers/filters/setDispatchFilterComponents";

import { config } from "config.js";
import { PRIMARY_COLOR } from "utils/const";

/**
 * DigitalTwinAdmin component.
 * Fetches and displays data related to the admin's company digital twin.
 */
const DigitalTwinAdmin = () => {
  // Status objects number
  const [currentObjects, setCurrentObjects] = useState(0)
  // Get the admin company ID from local storage
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // State variables
  const [centerLocations, setCenterLocations] = useState({});
  const dispatch = useDispatch();
  const store = useStore();

  // Fetch objects data
  const url = `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`;
  const { data, error } = useSwr(url);
  const objects = useMemo(() => {
    return data && !error ? data : [];
  }, [data, error]);

  // Fetch operations data
  const urlOperations = `${config.URL_BACKEND_PG}api/v1/admin-company/events/${adminCompanyId}`;
  const { data: dataOperations, error: errorOperations } =
    useSwr(urlOperations);
  const operations = useMemo(
    () => (dataOperations && !errorOperations ? dataOperations : []),
    [dataOperations, errorOperations]
  );

  // Fetch anemometers data
  const urlAnemometers = `${config.URL_BACKEND_PG}api/v1/anemometer?adminCompanyId=${adminCompanyId}`;
  const { data: dataAnemometers, error: errorAnemometers } =
    useSwr(urlAnemometers);
  const anemometers =
    dataAnemometers && !errorAnemometers ? dataAnemometers : [];

  // Fetch events data
  const urlEvents = `${config.URL_BACKEND_PG}api/v1/point-events?adminCompanyId=${adminCompanyId}`;
  const { data: dataEvents, error: errorEvents } = useSwr(urlEvents);
  const eventsObjects = useMemo(
    () => (dataEvents && !errorEvents ? dataEvents : null),
    [dataEvents, errorEvents]
  );

  // Fetch component events data
  const urlComponentEvents = `${config.URL_BACKEND_PG}api/v1/point-events/components?adminCompanyId=${adminCompanyId}`;
  const { data: dataComponentEvents, error: errorComponentEvents } =
    useSwr(urlComponentEvents);
  const eventsComponents = useMemo(
    () =>
      dataComponentEvents && !errorComponentEvents ? dataComponentEvents : null,
    [dataComponentEvents, errorComponentEvents]
  );

  // Fetch components data
  const urlComponents = `${config.URL_BACKEND_PG}api/v1/point-components?adminCompanyId=${adminCompanyId}`;
  const { data: dataComponents, error: errorComponents } =
    useSwr(urlComponents);
  const components = useMemo(
    () => (dataComponents && !errorComponents ? dataComponents : []),
    [dataComponents, errorComponents]
  );

  // Fetch events relation data
  const urlEventsRelation = `${config.URL_BACKEND_PG}api/v1/point-events/relation?adminCompanyId=${adminCompanyId}`;
  const { data: dataEventsRelation, error: errorEventsRelation } =
    useSwr(urlEventsRelation);
  const eventsRelation = useMemo(
    () =>
      dataEventsRelation && !errorEventsRelation ? dataEventsRelation : null,
    [dataEventsRelation, errorEventsRelation]
  );

  // Fetch trackings data
  const { data: dataTrackings, error: errorTrackings } = useSwr(
    `${config.URL_BACKEND_MG}routes?adminCompanyId=${adminCompanyId}`
  );
  const trackings = dataTrackings && !errorTrackings ? dataTrackings : [];

  const dataAllEvents = useMemo(() => {
    if (eventsObjects && eventsComponents && eventsRelation)
      return [...eventsObjects, ...eventsComponents, ...eventsRelation];
    return null;
  }, [eventsObjects, eventsComponents, eventsRelation]);

  const {
    showEventsByType,
    showEventsByInspectionType,
    showOperationsByType,
    showDataComponentsLibrary,
  } = store.getState().digitalTwinReducer;

  const { markerByTypeObject } = store.getState().adminReducer;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (objects.length > 0) {
          const center = calculateCenter(objects);
          setCenterLocations({
            latitude: center[0],
            longitude: center[1],
          });

          dispatch(setDataObjects(objects));
          if (markerByTypeObject.length === 0 || objects.length > currentObjects) {
            setDispatchFilterNewObjectsByType(objects, dispatch);
            dispatch(
              setShowDataObjects({ state: true, color: `${PRIMARY_COLOR}` })
            );
          }
        }
        if (operations.length > 0) {
          // Set all data operation
          dispatch(setDataOperations(operations));

          if (
            Object.keys(showEventsByInspectionType).length === 0 &&
            Object.keys(showOperationsByType).length === 0
          ) {
            // Set data to filter by inspection flir type
            setDispatchFilterEventByTypeInspection(operations, dispatch);
            // Set data to filter by event type
            setDispatchFilterEventByType(operations, dispatch);
          }
        }
        if (dataAllEvents) {
          // Set all data events
          dispatch(setDataEvents(dataAllEvents));
          // Set data to filter by new event type
          if (showEventsByType.length === 0) {
            dispatch(setShowEvents({ state: true, color: `${PRIMARY_COLOR}` }));
            setDispatchFilterNewEventsByType(dataAllEvents, dispatch);
          }
        }
        if (components?.length > 0) {
          dispatch(setDataComponents(components));

          if (showDataComponentsLibrary.length === 0) {
            dispatch(
              setShowDataComponents({ state: true, color: `${PRIMARY_COLOR}` })
            );
            setDispatchFilterNewComponentsByType(components, dispatch);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    setCurrentObjects(objects.length)
    fetchData();
    dispatch(setLocationsCSV({}));
  }, [objects, operations, components, dataAllEvents, dispatch]);

  return (
    <Layout>
      <MapDigitalTwin
        centerLocations={centerLocations}
        objects={objects}
        operations={operations}
        anemometers={anemometers}
        trackings={trackings}
        events={dataAllEvents ? dataAllEvents : []}
        components={components?.length ? components : []}
      />
    </Layout>
  );
};

export default DigitalTwinAdmin;
