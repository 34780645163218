import useSupercluster from "use-supercluster";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import MarkerElement from "./MarkerElement";
import MarkerCluster from "./MarkerCluster";

/**
 * Generates a cluster of markers on a map based on the given locations JSON,
 * bounds, view state, set view state, and onSelectPoint function.
 *
 * @param {object} locationsJSON - The JSON array of locations.
 * @param {object} bounds - The bounds of the map.
 * @param {object} viewState - The current view state of the map.
 * @param {function} setViewState - The function to set the view state of the map.
 * @param {function} onSelectPoint - The function to handle when a point is selected.
 * @return {JSX.Element} The cluster of markers.
 */
const CSVCluster = ({
  locationsJSON,
  bounds,
  viewState,
  setViewState,
  onSelectPoint,
}) => {
  const showMarkersByTypeObjectLoad = useSelector(
    (state) => state.adminReducer.markerByTypeObjectLoad
  );

  const showMarkersByTypeElementLoad = useSelector(
    (state) => state.adminReducer.markerByTypeElementLoad
  );

  const objectsFilter = locationsJSON.filter((location) => {
    if (
      showMarkersByTypeObjectLoad[location.libraryId] &&
      showMarkersByTypeElementLoad[location.typeElementId]
    )
      return true;
    return false;
  });

  // locationCSV to geoJSON
  const points = objectsFilter.map((location) => ({
    type: "Feature",
    properties: {
      cluster: false,
      element: {
        id: location.id,
        icon: location.icon,
        location: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
      },
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(location.longitude),
        parseFloat(location.latitude),
      ],
    },
  }));

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const renderMarkerCSV = useCallback((element) => {
    return <MarkerElement element={element} handleClick={() => {}} />;
  }, []);

  const MarkersCluster = useMemo(
    () =>
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const clusterData = {
          id: cluster.id,
          location: {
            latitude,
            longitude,
          },
          pointCount,
          elementsCount: points.length,
          type: "csv",
        };
        if (isCluster) {
          return (
            <MarkerCluster
              cluster={clusterData}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        }
        return renderMarkerCSV(cluster.properties.element);
      }),
    [clusters, renderMarkerCSV, onSelectPoint, points, setViewState, viewState]
  );

  return MarkersCluster;
};

export default CSVCluster;
