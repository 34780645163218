import { Marker } from "react-map-gl";
import { MarkerClusterContainer } from "components/Clusters/ClustersStyle";

/**
 * Renders a marker cluster on the map.
 *
 * @param {object} cluster - The cluster object: { id, location, pointCount, elementsCount, type: "operation" | "component" | "object" | "csv"} containing information about the cluster.
 * @param {function} onSelectPoint - The function to be called when a point in the cluster is selected.
 * @param {function} setViewState - The function to set the view state of the map.
 * @param {object} viewState - The current view state of the map.
 * @return {JSX.Element} The rendered marker cluster component.
 */
const MarkerCluster = ({ cluster, onSelectPoint, setViewState, viewState }) => {
  const { id, location, pointCount, elementsCount, type } = cluster;
  return (
    <Marker
      key={`cluster-${id}`}
      latitude={Number(location.latitude)}
      longitude={Number(location.longitude)}
    >
      <MarkerClusterContainer>
        <div
          className={`cluster-marker-${type}`}
          style={{
            width: `${10 + (pointCount / elementsCount) * 25}px`,
            height: `${10 + (pointCount / elementsCount) * 25}px`,
          }}
          onClick={() => {
            onSelectPoint(
              Number(location.longitude),
              Number(location.latitude)
            );
            setViewState({
              ...viewState,
              latitude: Number(location.latitude),
              longitude: Number(location.longitude),
            });
          }}
        >
          {pointCount}
        </div>
      </MarkerClusterContainer>
    </Marker>
  );
};

export default MarkerCluster;
