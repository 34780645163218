import axios from "axios";
import timeZoneOffset from "services/timeZoneOffset";

export const createEvent = (feature, info, objectId) => {
  const userId = localStorage.getItem("userId");
  const { coordinates } = feature.geometry;
  const [lng, lat] = coordinates;

  return {
    ...info,
    operation: {
      date: new Date(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneOffset: timeZoneOffset(),
      userId: parseInt(userId),
      locationOperation: {
        longitude: lng,
        latitude: lat,
      },
      objectId,
    },
  };
};

const saveEventApiPg = async (typeEvent, event) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/${typeEvent}`,
      event,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default saveEventApiPg;
