import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AddField, Button } from "../configAdminStyles";
import OperationsColumns from "./OperationsElements";
import { ButtonInfo } from "../../../components/ImageGallery/ObjectDetails/ObjectDetailsElements";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import OpenDialog from "components/Dialogs/OpenDialog";

export default function DescriptionOpenDialog({
  field,
  setField,
  setDialog,
  fields,
  update,
}) {
  const [tag, setTag] = useState("");
  const [possible, setPossible] = useState("select");
  const [step, setStep] = useState(0);
  const [errorName, setErrorName] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [typeField, setTypeField] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [expression, setExpression] = useState("");
  const [alias, setAlias] = useState("");

  const handleChangeType = (e) => {
    setTypeField(e.target.value);
    setField((current) => ({
      ...current,
      type: e.target.value,
      options: [],
      possible: [],
      min: 0,
      max: 1,
      operation: "",
    }));
  };
  const handleChangeAlias = (e) => {
    setAlias(e.target.value);
    setField((current) => ({
      ...current,
      alias: e.target.value,
    }));
  };

  const handleChangeName = (e) => {
    setInputValue(e.target.value);
    setField((current) => ({
      ...current,
      name: e.target.value,
    }));
  };

  const handleChangeRequired = (e) => {
    setField((current) => ({
      ...current,
      required: e.target.checked,
    }));
  };

  const handleTag = (e) => {
    setTag(e.target.value);
  };

  const addTag = () => {
    setField((current) => ({
      ...current,
      options: [...current.options, tag],
    }));
    setTag("");
  };

  const handleDeleteTag = (tag) => {
    setField((current) => ({
      ...current,
      options: current.options.filter((option) => option !== tag),
    }));
  };

  const handleMin = (e) => {
    let value = parseInt(e.target.value);
    if (value > field.max) {
      value = field.max - 1;
    }
    setField((current) => ({
      ...current,
      min: value,
    }));
  };

  const handlemax = (e) => {
    let value = parseInt(e.target.value);
    if (value < field.min) {
      value = field.min + 1;
    }
    setField((current) => ({
      ...current,
      max: value,
    }));
  };

  const handleStep = (e) => {
    setStep(e.target.value);
    setField((current) => ({
      ...current,
      options: [e.target.value],
    }));
  };

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    setField((current) => ({
      ...current,
      possible: [...current.possible, value],
    }));
    setPossible("select");
  };

  const handleDeletePosible = (value) => {
    setField((current) => ({
      ...current,
      possible: current.possible.filter((posible) => posible !== value),
    }));
  };

  useEffect(() => {
    setField((current) => ({
      ...current,
      operation: expression,
    }));
  }, [expression, setField]);

  useEffect(() => {
    const expression = /^[a-zA-Z0-9]+$/;
    if (
      !expression.test(inputValue) &&
      (typeField === "number" || typeField === "operation")
    ) {
      setInvalidName(true);
    } else {
      setInvalidName(false);
    }
  }, [inputValue, typeField]);

  useEffect(() => {
    const existField = fields.find((field_) => field_.name === field.name);
    if (
      !update &&
      (field.name === "" ||
        existField ||
        (invalidName && typeField === "number"))
    ) {
      setDialog((current) => {
        const { agree, ...actions } = current.actions;
        return {
          ...current,
          actions: { ...actions },
        };
      });
      if (existField) {
        setErrorName(true);
        return;
      }
      setErrorName(false);
      return;
    }
    setErrorName(false);
    setDialog((current) => ({
      ...current,
      actions: {
        ...current.actions,
        agree: update ? "Update" : "Add",
      },
    }));
  }, [
    field.name,
    setDialog,
    fields,
    update,
    typeField,
    invalidName,
    inputValue,
  ]);
  const messageAttributeName =
    "The Field Attribute name will be used in the Database. No spaces nor special characters are allowed. If want to see a name with special characters or spaces please use the Alias field.";
  const messageDisplayName =
    "This field is the one that the end users will see in the Digital Twin. Special Charachters are allowed.";
  const WarningSpan = ({ content }) => (
    <span
      style={{
        maginBottom: "10px",
        position: "fixed",
        marginTop: "60px",
        width: "100%",
        textAlign: "center",
      }}
    >
      {content}
    </span>
  );

  return (
    <AddField>
      <div className="required">
        <>
          <div className="name" style={{ display: "flex", width: "70%" }}>
            <div>
              Attribute Name:{" "}
              <InfoField
                title={"Atribute Name"}
                message={messageAttributeName}
              />{" "}
            </div>
            <div className="input">
              <input
                className="input-field"
                type="text"
                value={field.name}
                onChange={handleChangeName}
                disabled={update}
              />
              {errorName && <WarningSpan content="This name already exist" />}
              {invalidName && (
                <WarningSpan content="Invaid characters for name field" />
              )}
            </div>
          </div>
          <div className="name">
            <div htmlFor="">
              Display Name:
              <InfoField title={"Display Name"} message={messageDisplayName} />
            </div>
            <div className="input">
              <input
                className="input-field"
                type="text"
                value={field.alias}
                onChange={handleChangeAlias}
              />
            </div>
          </div>
        </>
        <div className="type">
          <label htmlFor="">Attribute Type: </label>
          <select
            className="input-field select"
            name=""
            onChange={handleChangeType}
            value={field.type}
          >
            <option value="string">String</option>
            <option value="number">Number</option>
            <option value="check">Check</option>
            <option value="select">Select</option>
            <option value="date">Date</option>
            <option value="file">File</option>
            <option value="email">Email</option>
            <option value="range">Range</option>
            <option value="url">Url</option>
            <option value="delegate">Delegate</option>
            <option value="currency">Currency</option>
            {fields.filter((elm) => elm.type === "number").length > 0 && (
              <option value="operation">Expression</option>
            )}
            <option value="captureMedia">Capture media</option>
          </select>
        </div>

        <div className="required2" id="input-checkbox">
          <label>Required?</label>
          <input
            className="input-field"
            type="checkbox"
            checked={field.required}
            onChange={handleChangeRequired}
          />
        </div>
      </div>

      {field.type === "file" && (
        <div>
          <div className="label">
            <label htmlFor="">Possible: </label>
            <select value={possible} onChange={handleChangeSelect}>
              <option value="select" disabled>
                Select
              </option>
              <option value="jpg">JPG</option>
              <option value="png">PNG</option>
              <option value="pdf">PDF</option>
              <option value="doc">DOC</option>
              <option value="docx">DOCX</option>
              <option value="xsls">XSLX</option>
              <option value="xls">XLS</option>
              <option value="xlsx">XLSX</option>
              <option value="csv">CSV</option>
              <option value="ppt">PPT</option>
              <option value="dwg">DWG</option>
              <option value="dwf">DWF</option>
              <option value="avi">AVI</option>
              <option value="mp3">MP3</option>
              <option value="dat">DAT</option>
              <option value="seq">SEQ</option>
              <option value="dwt">DWT</option>
              <option value="dxf">DXF</option>
              <option value="rvt">RVT</option>
            </select>
          </div>

          <div className="tags">
            {field.possible.map((option, index) => (
              <div className="tag">
                <span key={index}>{option}</span>
                <IoMdClose
                  onClick={() => handleDeletePosible(option)}
                  style={{ cursor: "pointer" }}
                  color="red"
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {field.type === "currency" && (
        <div>
          <div className="label">
            <label htmlFor="">Possible: </label>
            <select value={possible} onChange={handleChangeSelect}>
              <option value="select" disabled>
                Select
              </option>
              <option value="COP">COP </option>
              <option value="USD">USD </option>
              <option value="MXN">MXN </option>
              <option value="AED">AED </option>
              <option value="BHD">BHD </option>
              <option value="BRL">BRL </option>
              <option value="CAD">CAD </option>
              <option value="CHF">CHF </option>
              <option value="CNY">CNY </option>
              <option value="GBP">GBP </option>
              <option value="GIP">GIP </option>
              <option value="INR">INR </option>
              <option value="JOD">JOD </option>
              <option value="KWD">KWD </option>
              <option value="KYD">KYD </option>
              <option value="MXN">MXN </option>
              <option value="OMR">OMR </option>
              <option value="SAR">SAR </option>
            </select>
          </div>

          <div className="tags">
            {field.possible.map((option, index) => (
              <div className="tag">
                <span key={index}>{option}</span>
                <IoMdClose
                  onClick={() => handleDeletePosible(option)}
                  style={{ cursor: "pointer" }}
                  color="red"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {field.type === "select" && (
        <div>
          <div className="label">
            <label htmlFor="">Options: </label>
            <input type="text" value={tag} onChange={handleTag} />
            <Button onClick={addTag}>Add</Button>
          </div>
          <div className="tags">
            {field.options.map((option, index) => (
              <div className="tag">
                <span key={index}>{option}</span>{" "}
                <IoMdClose
                  onClick={() => handleDeleteTag(option)}
                  style={{ cursor: "pointer" }}
                  color="red"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {field.type === "range" && (
        <div className="label">
          <label htmlFor="">Max:</label>
          <input type="number" onChange={handlemax} value={field.max} />
          <label htmlFor="">Min: </label>
          <input type="number" onChange={handleMin} value={field.min} />
          <label htmlFor="">Step: </label>
          <input type="number" onChange={handleStep} value={step} />
        </div>
      )}

      {field.type === "operation" && (
        <OperationsColumns
          fields={fields}
          setFields={setField}
          setExpression={setExpression}
          update={update}
          field={field}
          setField={setField}
        />
      )}
    </AddField>
  );
}

const InfoField = ({ title, message }) => {
  const [dialog, setDialog] = useState({
    open: false,
    actions: { disagree: "Close" },
  });
  const handleShow = () => {
    setDialog((current) => ({ ...current, open: true }));
  };
  return (
    <ButtonInfo>
      {
        <ContactSupportIcon
          sx={{
            fontSize: "1.8rem",
            color: "#3f51b5",
          }}
          onClick={handleShow}
        />
      }
      <OpenDialog
        openDialog={dialog.open}
        setOpenDialog={setDialog}
        content={{
          title,
          description: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <div>
                <p>{message}</p>
              </div>
            </div>
          ),
          ...dialog.actions,
        }}
      />
    </ButtonInfo>
  );
};
