import { useState } from "react";
import { Button } from "./../FormStyles";
import saveEventApiPg, { createEvent } from "services/saveEventApiPg";
import { useSWRConfig } from "swr";
import { useEffect } from "react";
import findScketch from "services/findScetch";
import updateSkecth from "services/updateScketch";
import { useForm } from "react-hook-form";
import { fieldEquipmentName2 } from "utils/events";
import { Form } from "../SaveObject/DynamicFieldsStyle";
import TextInput from "../GenericInput";

export default function InspectionSniffer({
  feature,
  objectId,
  closeModal,
  callbackUpdate,
  isUpdate,
  id,
  operationId,
}) {
  const [form, setForm] = useState({
    fieldEquipmentName: "",
    comments: "",
  });

  const { mutate } = useSWRConfig();
  const urlMarks =
    `${process.env.REACT_APP_URL_BACKEND}api/v1/operation?` +
    `objectId=${objectId}`;

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlOperations = `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/events/${adminCompanyId}`;
  const urlOperation = `${process.env.REACT_APP_URL_BACKEND}api/v1/operation/${operationId}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      fieldEquipmentName: "",
      comments: "",
    },
  });

  const onSubmit = (data) => {
    const { name, value } = data.target;
    setForm({
      ...form,
      [name === "Comments"
        ? "comments"
        : name === "Field Name"
        ? "fieldEquipmentName"
        : name]: value,
    });
  };

  const createBodyOfEvent = () => {
    const newForm = {};
    Object.keys(form).forEach((key) => {
      if (form[key] !== "") newForm[key] = form[key];
    });
    return newForm;
  };

  const handleMutate = () => {
    mutate(urlMarks);
    mutate(urlOperations);
    mutate(urlOperation);
  };

  const handleUpdate = async () => {
    const body = createBodyOfEvent();
    const { res } = await updateSkecth(body, id);
    if (res?.status !== 200) return;
    callbackUpdate();
    handleMutate();
  };

  const handleSave = async () => {
    if (isUpdate) {
      handleUpdate();
      return;
    }
    const newForm = createBodyOfEvent();
    const event = createEvent(feature, newForm, objectId);
    await saveEventApiPg("inspection-sniffer", event);
    closeModal(false);
    handleMutate();
  };

  useEffect(() => {
    const getData = async () => {
      const data = await findScketch(id);
      setValue("Field Name", data.fieldEquipmentName || "");
      setValue("Comments", data.comments || "");
      setForm((current) => ({
        ...current,
        comments: data.comments || "",
        fieldEquipmentName: data.fieldEquipmentName || "",
      }));
    };
    if (isUpdate) getData();
  }, [id, isUpdate]);

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      {fieldEquipmentName2.map((elm) => {
        return (
          <div onChange={(e) => onSubmit(e)}>
            <TextInput
              key={elm.key}
              field={elm}
              register={register}
              errors={errors}
            />
          </div>
        );
      })}
      <Button type="submit" style={{ padding: 0 }}>
        Save
      </Button>
      {isUpdate && <Button onClick={callbackUpdate}>Cancel</Button>}
    </Form>
  );
}
