import axios from "axios";

const token = localStorage.getItem("token");

const deletePolygonRelations = async (polygonId) => {
  const res = await axios.delete(
    `
    ${process.env.REACT_APP_URL_BACKEND}api/v1/region/${polygonId}
  `,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return data;
};

const deletePolygon = async (polygonId) => {
  const res = await axios.delete(
    `${process.env.REACT_APP_URL_MG_API}region?id=${polygonId}`
  );
  const data = await res.data;
  const response = await deletePolygonRelations(data._id);
  return response;
};

export default deletePolygon;
