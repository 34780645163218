import axios from "axios";
import { config } from "config.js";

/**
 * Retrieves the count of components by object ID.
 *
 * @param {object} params - The parameters for the function.
 * @param {string} params.objectId - The object ID to count components for.
 * @return {Array} The list of components.
 */
const countComponentsByObjectId = async ({ objectId }) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    `${config.URL_BACKEND_PG}api/v1/point-components?count=${objectId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const { components } = await response.data;
  return components;
};

export default countComponentsByObjectId;
