import axios from "axios";
// Config
import { config } from "config.js";

const deleteComponent = async (pointComponentId) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${config.URL_BACKEND_PG}api/v1/point-components/${pointComponentId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteComponent;
