import axios from "axios";

const deleteEventPhoto = async (photoId) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/operation-photo/${photoId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteEventPhoto;
