import { useCallback, useEffect, useState } from "react";
import {
  ContainerButtonCenter,
  FormSetUp,
  ContainerInputs,
} from "./FormStyles";
import InputComponent from "./InputSetUp";
import CustomButton from "components/Buttons/CustomButton";
import { DELETE_COLOR, HOVER_DELETE_COLOR, PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
// import CustomSelect from "components/Buttons/CustomSelect";

export default function Form(props) {
  const {
    form,
    handleChange,
    buttons,
    handleRows,
    renderBeforeButtons,
    renderBeforeTitle,
    title,
    loadingCreate,
    loadingDelete
    // setSelect,
    // select,
  } = props;

  // To change the validate of the form
  const [formValidate, setFormValidate] = useState(true);
  const exception = [
    "id",
    "created_at",
    "createdAt",
    undefined,
    "undefined",
    "adminCompanyId",
    "libraryId",
    "pointLibraryId",
    "lineLibraryId",
    "regionLibraryId",
    "pointLibrary",
    "pointLibraryEventId",
    "point_library_event_id",
    "pointLibraryEvent",
    "updatedAt",
    "fields",
    "mongoId",
    "typeEventId",
    "typeLibraryId",
    "type_library_id",
    "pointLibraryComponentId",
    "point_library_component_id",
    "pointLibraryComponent",
    "PointTypeEventRelation",
    "PointTypeEventRelationWith",
  ];

  // Action the button
  // Callback is a function that will be called when the button is clicked
  // Callback(fieldOfForm, ifFormIsValid, handlingOfRowsAfterExecuting)
  const handleClick = (e, callback) => {
    e.preventDefault();
    callback(form, formValidate, handleRows);
  };

  // Validate the form each that form have a change
  useEffect(() => {
    const validate = Object.values(form).every((field) => {
      if (!field.validate) return true; // In case of id
      return field.validate === "true";
    });
    setFormValidate(validate);
  }, [form]);

  const preButtons = useCallback(() => {
    return renderBeforeButtons?.map((render) => render);
  }, [renderBeforeButtons]);

  return (
    <FormSetUp>
      {renderBeforeTitle?.map((render) => render)}
      <h1>{title}</h1>
      <form>
        <ContainerInputs>
          {Object.keys(form)?.map((key, index) => {
            if (exception.includes(key)) return false;
            // if (form[key].type === "select")
            //   return (
            //     <CustomSelect
            //       options={form[key].options}
            //       label={form[key].label}
            //       setSelect={setSelect}
            //       select={select}
            //     />
            //   );
            return (
              <InputComponent
                key={index}
                field={form[key]}
                onChange={handleChange}
              />
            );
          })}
        </ContainerInputs>
        {preButtons()}
        <ContainerButtonCenter inputSetUp={true}>
          {buttons?.map(
            ({ label, handleClick: handleClickButton, type }, index) => {
              return (
                <CustomButton
                  key={index}
                  type={type}
                  isLoad={type === "create" || type === "update"? loadingCreate: loadingDelete}
                  onClick={(e) => handleClick(e, handleClickButton)}
                  text={label}
                  primaryColor={type === "delete"? DELETE_COLOR: PRIMARY_COLOR}
                  secondaryColor={type === "delete"? HOVER_DELETE_COLOR: SECOND_COLOR}
                />
              );
            }
          )}
        </ContainerButtonCenter>
      </form>
    </FormSetUp>
  );
}
