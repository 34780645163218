import { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
// Components
import Layout from "components/Layout/AdminDecimetrix";
// Library to Objects
import LibraryPointObjects from "pages/ConfigAdmin/LibraryPoint";
import LibraryLine from "pages/ConfigAdmin/LibraryLine";
import LibraryPolygon from "pages/ConfigAdmin/LibraryRegion";
// Library to Events
import LibraryPointEvents from "pages/ConfigAdmin/LibraryPoint/EventLibraries";
// Library to Components
import LibraryComponents from "pages/ConfigAdmin/LibraryPoint/ComponentLibraries";

// Styles
import {
  TitleAdminConfig,
  ReturnSettingsPage,
  SelectContainerAdminConfig,
  ConfigObjectsContainer,
} from "./configAdminStyles";

const typeToLibrary = [
  {
    id: 1,
    name: "Objects",
  },
  {
    id: 2,
    name: "Events",
  },
  {
    id: 3,
    name: "Components"
  }
];

const typeLibrary = [
  {
    id: 1,
    name: "Points",
  },
  {
    id: 2,
    name: "Lines",
  },
  {
    id: 3,
    name: "Polygons",
  },
];

const Libraries = () => {
  const [library, setLibrary] = useState({
    id: "1",
    name: "Points",
  });
  const [libraryTo, setLibraryTo] = useState({
    id: "1",
    name: "Objects",
  });
  const navigate = useNavigate();

  const navigation = (path) => {
    navigate(path);
  };

  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: typeLibrary.find(
        (library) => library.id === parseInt(e.target.value)
      )?.name,
    }));
  };

  const handleLibraryTo = (e) => {
    setLibraryTo((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: typeToLibrary.find(
        (libraryTo) => libraryTo.id === parseInt(e.target.value)
      )?.name,
    }));
  };

  return (
    <Layout>
      <ConfigObjectsContainer>
        <ReturnSettingsPage onClick={() => navigation("/config-admin")}>
          <AiOutlineLeft />
        </ReturnSettingsPage>
        <SelectContainerAdminConfig>
          <p>Library to: </p>
          <select name="endPoint" id="endPoint" onChange={handleLibraryTo}>
            {typeToLibrary?.map((libraryTo) => (
              <option key={libraryTo.id} value={libraryTo.id}>
                {libraryTo.name}
              </option>
            ))}
          </select>
        </SelectContainerAdminConfig>
        <SelectContainerAdminConfig>
          <p>Type library: </p>
          <select name="endPoint" id="endPoint" onChange={handleLibrary}>
            {typeLibrary?.map((library) => (
              <option key={library.id} value={library.id}>
                {library.name}
              </option>
            ))}
          </select>
        </SelectContainerAdminConfig>
      </ConfigObjectsContainer>
      <TitleAdminConfig>
        Digital Twin Libraries Configuration - {libraryTo.name} - {library.name}
      </TitleAdminConfig>

      {library.id === "1" && libraryTo.id === "1" && <LibraryPointObjects />}
      {library.id === "1" && libraryTo.id === "2" && <LibraryPointEvents />}
      {library.id === "1" && libraryTo.id === "3" && <LibraryComponents />}
      {library.id === "2" && libraryTo.id === "1" && <LibraryLine />}
      {library.id === "2" && libraryTo.id === "2" && (
        <h1
          style={{
            textAlign: "center",
          }}
        >
          Coming soon
        </h1>
      )}
      {library.id === "3" && libraryTo.id === "1" && <LibraryPolygon />}
      {library.id === "3" && libraryTo.id === "2" && (
        <h1
          style={{
            textAlign: "center",
          }}
        >
          Coming soon
        </h1>
      )}
    </Layout>
  );
};

export default Libraries;
