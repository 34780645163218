import axios from "axios";

const findEvents = async (objectId) => {
  try {
    const token = localStorage.getItem("token");
    let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/point-events`;
    if (objectId) url = `${url}?id=${objectId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default findEvents;

/**
 * Retrieves events by component ID.
 *
 * @param {Object} pointComponentId - The ID of the point component.
 * @return {Promise<Object>} - The data containing the events.
 */
export const findEventsByComponentId = async ({ pointComponentId }) => {
  try {
    const token = localStorage.getItem("token");
    let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/point-events/components?pointComponentId=${pointComponentId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};
