import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { PRIMARY_COLOR } from "utils/const";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function HorizontalChart({ labels, quantityData, horizontal }) {
  const options = {
    indexAxis: horizontal ? "y" : "x",
    responsive: true,
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Quantity",
        data: quantityData,
        backgroundColor: PRIMARY_COLOR,
        barThickness: 18,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
