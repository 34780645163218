import MainCard from "components/Cards/MainCard";

import { WrapperCards } from "./indexStyles";

const WrapperMainCards = ({ contentCards }) => {
  return (
    <WrapperCards>
      {contentCards.map((contentCard) => (
        <MainCard
          toNavigate={contentCard.toNavigate}
          title={contentCard.title}
          image={contentCard.image}
          description={contentCard.description}
        />
      ))}
    </WrapperCards>
  );
};

export default WrapperMainCards;
