const InformationCollect = () => {
  return (
    <>
      <p className="text">
        1.1 Personal Information: We may collect personal information that you
        voluntarily provide to us when using the Application, such as your name,
        email address, contact information, and other details you choose to
        provide.
      </p>
      <p className="text">
        1.2 Device Information: We may collect certain information about your
        mobile device, including the device model, operating system version,
        unique device identifiers, and mobile network information. This
        information is collected to ensure the compatibility and optimal
        performance of the Application.
      </p>
      <p className="text">
        1.3 Usage Data: We may automatically collect usage data and analytics
        information when you interact with the Application. This data may
        include the features and functionalities you use, the duration of your
        sessions, and other usage patterns.
      </p>
    </>
  );
};
const UseInformation = () => {
  return (
    <>
      <p className="text">
        2.1 We use the collected information for the following purposes:
      </p>
      <br></br>
      <p className="text">
        -To provide, operate, and maintain the Application;
      </p>
      <p className="text">
        -To personalize and improve your experience with the Application;
      </p>
      <p className="text">
        -To communicate with you and respond to your inquiries or requests;
      </p>
      <p className="text">
        -To send you technical notices, updates, and administrative messages;
      </p>
      <p className="text">
        -To analyze usage patterns and trends and perform data analytics;
      </p>
      <p className="text">
        -To enforce our terms, policies, and legal obligations
      </p>
      <p className="text">
        -To protect against fraud, unauthorized access, or other potential
        risks.
      </p>
      <br></br>
      <p className="text">
        2.2 We may use anonymized and aggregated data for statistical, research,
        and analyticalpurposes.
      </p>
    </>
  );
};
const DataSharing = () => {
  return (
    <>
      <p className="text">
        3.1 We do not sell, trade, or rent your personal information to third
        parties for marketing purposes. However, we may share your information
        in the following circumstances:
      </p>
      <br></br>
      <p className="text">
        -With service providers who assist us in providing and improving the
        Application (e.g., hosting, analytics, customer support);
      </p>
      <p className="text">
        -With your consent or at your direction, such as when you authorize the
        integration of third-party services with the Application;
      </p>
      <p className="text">
        -With your employer’s consent, as long as your employer isthe one that
        grants access to you to the platform, such as when the granting agent
        needs to optimize the use of the Application;
      </p>
      <p className="text">
        -When required by lawor to comply with legal process, enforceable
        governmental request, or applicable regulations;
      </p>
      <p className="text">
        -To protect our rights, property, or safety, and that of our users and
        the public.
      </p>
      <br></br>
      <p className="text">
        3.2 We may disclose aggregated and anonymized information that does not
        identify individual users for marketing, advertising, research, or other
        purposes.
      </p>
    </>
  );
};
const DataRetention = () => {
  return (
    <p className="text">
      4.1 We retain your personal information for as long as necessary to
      fulfill the purposes outlined in this Policy unless a longer retention
      period is required or permitted by law.<br></br>4.2 We will securely
      delete or anonymize your personal information within a reasonable time
      after it is no longer needed for the purposes stated in this Policy or as
      required by applicable law.
    </p>
  );
};
const DataSecurity = () => {
  return (
    <p className="text">
      5.1 We implement appropriate technical and organizational measures to
      protect your personal information against unauthorized access, loss, or
      alteration. However, please note that no data transmission or storage
      system is completely secure, and we cannot guarantee the absolute security
      of your information.
    </p>
  );
};
const LinksService = () => {
  return (
    <p className="text">
      6.1 The Application may contain links to third-party websites,
      applications, or services. This Policy does not apply to such third-party
      platforms, and we are not responsible for their privacy practices. We
      encourage you to review the privacy policies of those third parties before
      providing any personal information.
    </p>
  );
};
const ChildrenPrivacy = () => {
  return (
    <p className="text">
      7.1 The Application is not intended for use by individuals under the age
      of 16. We do not knowingly collect personal information from children. If
      we become aware that we have inadvertently collected personal information
      from a child under 16, we will take steps to delete the information as
      soon as possible.
    </p>
  );
};
const ChangesPrivacy = () => {
  return (
    <p className="text">
      8.1 We may update or modify this Policy from time to time. If we make any
      material changes, we will notify you by posting the revised Policy within
      the Application or by other means. Your continued use of the Application
      after the effective date of the revised Policy constitutes your acceptance
      of the changes.
    </p>
  );
};
const Contact = () => {
  return (
    <p className="text">
      9.1 If you have any questions or concerns regarding this Policy or our
      privacy practices, please contact us at business@decimetrix.com
    </p>
  );
};
const Acknowledgment = () => {
  return (
    <p className="text">
      By downloading, accessing, or using the Platform, you acknowledge that you
      have read, understood, and agree to be bound by this Agreement. If you do
      not agree with any part of this Agreement, you should not download,
      access, or use the Application.
    </p>
  );
};

export const dataMobile = {
  title: "Decimetrix® Reality Capture Mobile Solution Privacy Policy",
  mainText:
    'This Privacy Policy ("Policy") describes how Decimetrix, Inc and Decimetrix SAS. ("Decimetrix", "we", or "us") collects, uses, and protects the personal information and data of users ("User", "you", "or", "your") who access or use the Decimetrix Green Reality Capture mobile solution ("Application"). By downloading, accessing, or using the Application, you consent to the collection, storage, processing, and use of your personal information as described in this Policy.',
  info: [
    {
      subtitle: "Information We Collect",
      text: <InformationCollect />,
    },
    {
      subtitle: "Use of Information",
      text: <UseInformation />,
    },
    {
      subtitle: "Data Sharing and Disclosure",
      text: <DataSharing />,
    },
    {
      subtitle: "Data Retention",
      text: <DataRetention />,
    },
    {
      subtitle: "Data Security",
      text: <DataSecurity />,
    },
    {
      subtitle: "Third-Party Links and Services",
      text: <LinksService />,
    },
    {
      subtitle: "Children's Privacy",
      text: <ChildrenPrivacy />,
    },
    {
      subtitle: "Changes to this Privacy Policy",
      text: <ChangesPrivacy />,
    },
    {
      subtitle: "Contact Us",
      text: <Contact />,
    },
    {
      subtitle: "Acknowledgment",
      text: <Acknowledgment />,
    },
  ],
};
