import { useState } from "react";
// Components
import CustomSelect from "components/Buttons/CustomSelect";
import CreateEvent from "./CreateEvent";
import CreateComponent from "./CreateComponent";
// Styles
import { SelectTypeContainer } from "./SelectTypeStyle";

/**
 * Renders a component that allows the user to select a type to create.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - {string} feature: The feature name.
 *   - {string} objectId: The object ID.
 *   - {function} setHaveObjects: A function to update the state of whether objects exist or not.
 *   - {Array} typeEvents: An array of type events.
 *   - {Array} typeComponents: An array of type components.
 * @return {JSX.Element} The rendered component.
 */
const SelectType = ({
  feature,
  objectId,
  setHaveObjects,
  typeEvents,
  typeComponents,
}) => {
  const [select, setSelect] = useState(1);

  return (
    <SelectTypeContainer>
      <CustomSelect
        label={"Select type to create"}
        options={[
          { id: 1, name: "Events" },
          { id: 2, name: "Components" },
        ]}
        setSelect={setSelect}
        select={select}
      />
      {parseInt(select) === 1 && (
        <CreateEvent
          feature={feature}
          objectId={objectId}
          closeModal={setHaveObjects}
          typeEvents={typeEvents}
        />
      )}
      {parseInt(select) === 2 && (
        <CreateComponent
          feature={feature}
          objectId={objectId}
          closeModal={setHaveObjects}
          typeComponents={typeComponents}
        />
      )}
    </SelectTypeContainer>
  );
};

export default SelectType;
