import styled, { css } from "styled-components";
import Paper from "@mui/material/Paper";
import { PRIMARY_COLOR, SECOND_COLOR } from "./../../utils/const";

export const CustomSelect = styled("div")`
  display: flex;
  flex-direction: column;

  select {
    margin-top: 9px;
    padding: 12px 10px;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: rgb(61, 61, 61);
    border: none;
    border-radius: 10px;
  }

  label {
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    color: #5c5757;
  }
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DeleteUserButton = styled.div`
  outline: none;
  border: none;
  padding-left: 15px;
`;

const colors = {
  border: "#15bbfd",
  error: "#f67070",
  success: "#54c25d",
};

export const FormSetUp = styled.form`
  min-width: 300px;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  padding: 21px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    font-weight: 500;
    font-size: 2.5rem;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const ProfileWorkIcon = styled.p``;

export const Wrapperbtn = styled("div")`
  position: relative;
`;

export const AddButton = styled("label")`
  height: 45px;
  line-height: 45px;
  width: 100%;
  background: ${PRIMARY_COLOR};
  color: #fff;
  font-weight: bold;

  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 1px;
  transition: 0.1s ease all;

  &:hover {
    box-shadow: 1px 0px 1px rgba(163, 163, 163, 1);
  }
`;

export const CustomizedAccordion = styled(Paper)`
  margin-top: 0px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #b8b2b2;
  padding: 10px;
  box-shadow: none;
`;

export const FormInput = styled.input`
  margin-left: 10px;
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  color: rgb(61, 61, 61);
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
`;

export const ProfileButton = styled.button`
  height: 45px;
  line-height: 45px;
  width: 45%;
  background: ${PRIMARY_COLOR};
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1.4rem;
  transition: 0.1s ease all;

  &:hover {
    box-shadow: 1px 0px 10px rgba(163, 163, 163, 1);
  }
`;

export const ProfileFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 2%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const ProfileContainer = styled.div`
  padding: 16px 30px 16px 30px;
  height: 100%;
  width: 100%;
  border-radius: 2%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80hv;
  width: 100%;
  background-color: #fff;
`;
export const StyledP = styled.p`
  font-size: 1.6rem;
  color: #5c5757;
  font-weight: 600;
`;

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 10px;
`;

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 5px 20px;
  background-color: #ffffff;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.label`
  display: block;
  font-weight: 600;
  cursor: pointer;
  margin: 10px 0;
  color: #5c5757;
  font-size: 1.6rem;

  ${(props) =>
    props.valid === "false" &&
    css`
      color: ${colors.error};
    `}
`;

export const WrapperInput = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  position: relative;
  z-index: 90;
`;

export const Input = styled.input`
  width: 100%;
  background: #f1f1f1;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  padding: 10px 40px 10px 20px;
  margin: 10px 0 30px;
  transition: 0.3s ease all;
  border: none;
  /* border-bottom: 1px solid #2e2e2e; */
  display: flex;
  font-size: 1.5rem;

  &.little {
    transform: scale(0.5);
    margin-top: 0;
  }

  &:focus {
    border: 3px solid ${colors.border};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }

  ${(props) =>
    props.valid === "true" &&
    css`
      border: 3px solid transparent;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${colors.error} !important;
    `}
`;

export const InputProfile = styled.input`
  width: 100%;
  background: #f1f1f1;
  color: grey;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  padding: 10px 40px 10px 20px;
  pointer-events: none;
  border: none;
  color: #000;
  display: flex;
  margin: 10px 0;
  ${(props) =>
    props.valid === "true" &&
    css`
      border: 3px solid transparent;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${colors.error} !important;
    `}

  ${(props) =>
    props.type === "range" &&
    css`
      pointer-events: visible;
      padding: 10px 0;
    `}
`;

export const LabelFile = styled.label`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  cursor: pointer;

  span {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props.valid === "false" &&
      css`
        color: #f00;
      `}
  }

  img {
    margin-top: 1rem;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #000;
    border-radius: 13px;
    ${(props) =>
      props.valid === "false" &&
      css`
        border-color: #f00;
      `}
  }
`;

export const InputFile = styled.input`
  display: none;
`;

export const LegendError = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
  color: ${colors.error};
  display: none;

  ${(props) =>
    props.valid === "true" &&
    css`
      display: none;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      display: block;
    `}
`;

export const ContainerTerms = styled.div`
  grid-column: span 2;

  input {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`;
export const ContainerButtonRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 100%;
  margin: 0px;

  left: 40px;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;
export const ContainerButtonBack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 100%;

  /* margin-right: 100px; */
  left: 40px;

  @media (max-width: 800px) {
    width: 100%;
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;
export const ContainerButtonBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 95%;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerButtonCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 95%;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;

export const ContainerButtonCenterUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;

export const Button = styled.button`
  height: 45px;
  line-height: 45px;
  width: 100%;
  background: ${PRIMARY_COLOR};
  ${(props) =>
    props.type === "delete" &&
    css`
      background: #960909;
    `}
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.1s ease all;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${SECOND_COLOR};
    ${(props) =>
      props.type === "delete" &&
      css`
        background: #d30a0a;
      `}
  }
`;

export const MessageSuccess = styled.p`
  height: 30px;
  line-height: 45px;
  padding: 0px 15px;
  border-radius: 3px;
  margin: 10px auto;
  color: ${colors.success};
`;

export const MessageError = styled.div`
  height: 30px;
  line-height: 45px;
  color: #f66060;
  padding: 0px 15px;
  border-radius: 3px;
  margin: 10px auto;
  font-size: 1.6rem;
`;

export const ContainerForm = styled.div`
  transition: 0.3s all ease-in-out;
  overflow-y: hidden;
  /* padding: 21px; */
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;

  ${(props) =>
    !props.see &&
    css`
      height: 0;
      pointer-events: none;
      padding: 0;
      animation: upForm 1s;
    `}

  ${(props) =>
    props.see &&
    css`
      pointer-events: all;
      opacity: 1;
      animation: downForm 1s;
    `}

  @keyframes downForm {
    0% {
      height: 0%;
    }

    100% {
      height: 100%;
    }
  }

  @keyframes upForm {
    0% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }
`;
