import { useCallback, useEffect, useState } from "react";
// Services
import findSensors from "services/findSensors";
// Style
import { FormSettings } from "./SensorOptimizationStyle";

const Settings = ({ objects, setSensors, setSettings }) => {
  const [form, setForm] = useState({
    numberSensors: "4",
    radius: "100",
  });

  const findSensorsPosition = useCallback(async () => {
    setSettings(() => {
      if (form.numberSensors === "" || form.radius === "")
        return {
          numberSensors: "0",
          radius: "5",
        };
      return form;
    });
    const { response, data } = await findSensors({
      numberSensors: parseInt(
        form.numberSensors === "" ? 0 : form.numberSensors
      ),
      radius: parseInt(form.radius === "" ? 5 : form.radius),
      data: objects,
    });
    if (response.status === 201) return setSensors(data);
    alert("No se pudo calcular los sensores las optimas ubicaciones");
  }, [form, objects, setSensors, setSettings]);

  useEffect(() => {
    findSensorsPosition();
  }, [findSensorsPosition]);

  const submitForm = async (e) => {
    e.preventDefault();
    findSensorsPosition();
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  return (
    <FormSettings onSubmit={submitForm}>
      <div>
        <label htmlFor="numberSensors">Número de sensores:</label>
        <input
          id="numberSensors"
          type="number"
          value={form.numberSensors}
          onChange={onChange}
        />
      </div>
      <div>
        <label htmlFor="radius">Radio (m):</label>
        <input
          id="radius"
          type="number"
          value={form.radius}
          onChange={onChange}
        />
      </div>
    </FormSettings>
  );
};

export default Settings;
