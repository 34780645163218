import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AiFillCloseCircle } from "react-icons/ai";
import { HeadModal } from "../Forms/FormStyles";
import SignUp from "components/Forms/OperatorRegister";

export default function FormDialog({ open, handleClose }) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <HeadModal>
            <div className="title">
              <img
                src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
                alt=""
                className="neurona"
              />
              <span className="titleHeadModal">Registro de Operarios</span>
            </div>
            <Button onClick={handleClose}>
              <AiFillCloseCircle style={{ color: "#FFF", fontSize: 21 }} />
            </Button>
          </HeadModal>
        </DialogTitle>
        <DialogContent
          style={{
            marginTop: "25px",
          }}
        >
          <SignUp handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
