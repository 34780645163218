// Required modules;
import GenericTable from "components/Tables/GenericTable";
import Form from "components/Forms/Form.jsx";

// Config admin class
import ConfigAdmin from "../../ConfigAdmin.js";

// Hooks
import { useCallback, useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableTypeElementsEvents,
  keyValuesTableTypeElementsEvents,
  initialValuesFormTypeElementEvents,
} from "../../dataConfigAdmin.jsx";

// Styles
import {
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  SeparatorWithText,
  InformationShare,
  IconAdminConfigChangeColum,
  SelectContainerAdminConfig,
} from "../../configAdminStyles.jsx";
import { Label } from "components/Forms/FormStyles.jsx";

import { IoMdAdd } from "react-icons/io";
import { BsChevronCompactUp } from "react-icons/bs";
import { ContainerForm } from "components/Forms/FormStyles";
import useGet from "hooks/useFetch.js";
// Services
import findTypeEventsTypeElements from "services/findTypeEventsTypeElementsPg.js";
import createTypeEventsTypeElements from "services/createTypeEventsTypeElementsPg.js";
import {
  createTypeEventsTypeComponents,
  findTypeEventsTypeComponents,
} from "services/typeEventsTypeComponents.js";
import {
  createTypeEventsTypeEvents,
  findTypeEventsTypeEvents,
} from "services/typeEventsTypeEvents.js";
// Components
import UpdateEventsComponentsHook from "./UpdateEventsComponentsHook.jsx";
import UpdateEventsElementsHook from "./UpdateEventsElementsHook.jsx";
import CheckListsObjectsElements from "./CheckListsObjectsElements.jsx";
import CheckListComponents from "./CheckListComponents.jsx";
import CheckListEvents from "./CheckListEvents.jsx";
import UpdateEventsEventsCallback from "./UpdateEventsEventsHook.jsx";

import Fields from "../Fields.jsx";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <BsChevronCompactUp />
    </IconAdminConfigChangeColum>
  );
};

const findLibrary = (libraries, e) => {
  return libraries.find((library) => library.id === parseInt(e.target.value));
};

export default function EventsElements({ setOpenDialog, setContentDialog }) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [libraries] = useGet("api/v1/point-library-events");
  const [library, setLibrary] = useState({});
  const [pointTypeEventId, setpointTypeEventId] = useState(null);
  const [typeEventsTypeElements, setTypeEventsTypeElements] = useState({});
  const [typeEventsTypeComponents, setTypeEventsTypeComponents] = useState({});
  const [typeEventsTypeEvents, setTypeEventsTypeEvents] = useState({});
  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormTypeElementEvents);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);
  // State button loadingCreate
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [typeElementsGhgRow, setTypeElementsGhgRow] = useState([]);

  // Handle reset all dataTypes (objects, compoents,events to events)
  const handleResetDataTypes = () => {
    setTypeEventsTypeElements(() => {});
    setTypeEventsTypeComponents({});
    setTypeEventsTypeEvents({});
  };

  // Click  on type element ghg into table
  const handleClickTypeElementGhg = (id) => {
    if (id !== pointTypeEventId) {
      setTypeEventsTypeElements(() => {});
      setTypeEventsTypeComponents({});
      setTypeEventsTypeEvents({});
    }
    setpointTypeEventId(() => id);
    configAdmin.handlerClickIntoTable(id);
  };

  // Change in library select
  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: findLibrary(libraries, e).name,
      typeLibraryId: findLibrary(libraries, e)?.typeLibraryId,
    }));
  };

  const create = useCallback(
    async (pointTypeEvent) => {
      // Create relation events to objects
      await Promise.all(
        Object.keys(typeEventsTypeElements).map(async (typeEventId) => {
          await createTypeEventsTypeElements({
            pointTypeEventId: pointTypeEvent.id,
            typeElementId: parseInt(typeEventId),
          });
        })
      );
      // Create relation events to components
      await Promise.all(
        Object.keys(typeEventsTypeComponents).map(async (typeComponentId) => {
          await createTypeEventsTypeComponents({
            pointTypeEventId: pointTypeEvent.id,
            pointTypeComponentId: parseInt(typeComponentId),
          });
        })
      );
      // Create relation events to events
      await Promise.all(
        Object.keys(typeEventsTypeEvents).map(
          async (pointTypeEventRelationId) => {
            await createTypeEventsTypeEvents({
              pointTypeEventId: pointTypeEvent.id,
              pointTypeEventRelationId: parseInt(pointTypeEventRelationId),
            });
          }
        )
      );
      setTypeEventsTypeElements({});
      setTypeEventsTypeComponents({});
      setTypeEventsTypeEvents({});
      setpointTypeEventId(null);
    },
    [typeEventsTypeElements, typeEventsTypeComponents, typeEventsTypeEvents]
  );

  const update = useCallback(
    async (pointTypeEvent) => {
      const updateEventsElements = UpdateEventsElementsHook({
        pointTypeEvent,
        typeEventsTypeElements,
        setTypeEventsTypeElements,
      });
      await updateEventsElements();
      const updateEventsComponents = UpdateEventsComponentsHook({
        pointTypeEvent,
        typeEventsTypeComponents,
        setTypeEventsTypeComponents,
      });
      await updateEventsComponents();
      const updateEventsEvents = UpdateEventsEventsCallback({
        pointTypeEvent,
        typeEventsTypeEvents,
        setTypeEventsTypeEvents,
      });
      await updateEventsEvents();
      setpointTypeEventId(null);
    },
    [
      typeEventsTypeComponents,
      typeEventsTypeElements,
      typeEventsTypeEvents,
      setTypeEventsTypeElements,
      setTypeEventsTypeComponents,
    ]
  );

  const handleChangeCheckObjects = (id) => {
    const element = typeEventsTypeElements?.[id];
    if (element) {
      setTypeEventsTypeElements((current) => {
        const { [id]: value, ...rest } = current;
        return rest;
      });
    } else {
      setTypeEventsTypeElements((current) => ({
        ...current,
        [id]: true,
      }));
    }
  };

  const handleChangeCheckComponents = (id) => {
    const element = typeEventsTypeComponents?.[id];
    if (element) {
      setTypeEventsTypeComponents((current) => {
        const { [id]: value, ...rest } = current;
        return rest;
      });
    } else {
      setTypeEventsTypeComponents((current) => ({
        ...current,
        [id]: true,
      }));
    }
  };

  const handleChangeCheckEvent = (id) => {
    const element = typeEventsTypeEvents?.[id];
    if (element) {
      setTypeEventsTypeEvents((current) => {
        const { [id]: value, ...rest } = current;
        return rest;
      });
    } else {
      setTypeEventsTypeEvents((current) => ({
        ...current,
        [id]: true,
      }));
    }
  };

  // Search relations with type point event id
  useEffect(() => {
    if (!pointTypeEventId) return;
    const getData = async () => {
      // Objects
      const dataEventsElements = await findTypeEventsTypeElements({
        pointTypeEventId,
      });
      dataEventsElements.forEach(({ typeElementId }) => {
        setTypeEventsTypeElements((current) => ({
          ...current,
          [typeElementId]: true,
        }));
      });
      // Components
      const dataEventsComponents = await findTypeEventsTypeComponents({
        pointTypeEventId,
      });
      dataEventsComponents.forEach(({ pointTypeComponentId }) => {
        setTypeEventsTypeComponents((current) => ({
          ...current,
          [pointTypeComponentId]: true,
        }));
      });
      // Events
      const dataEventsRelations = await findTypeEventsTypeEvents({
        pointTypeEventId,
      });
      dataEventsRelations.forEach(({ pointTypeEventRelationId }) => {
        setTypeEventsTypeEvents((current) => ({
          ...current,
          [pointTypeEventRelationId]: true,
        }));
      });
    };
    getData();
  }, [pointTypeEventId]);

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableTypeElementsEvents);
    configAdmin.setKeyValuesTable(keyValuesTableTypeElementsEvents);
    configAdmin.setSetRows(setTypeElementsGhgRow);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/point-type-events`);
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialog, setContentDialog]);

  // Ser callback update
  useEffect(() => {
    configAdmin && configAdmin?.setUpdateCallback(update);
  }, [configAdmin, update]);

  // Set callback create
  useEffect(() => {
    configAdmin && configAdmin?.setCreateCallback(create);
  }, [configAdmin, create]);

  // Set library default
  useEffect(() => {
    if (libraries) {
      setLibrary(() => libraries[0]);
    }
  }, [libraries]);

  // Set library in config admin
  useEffect(() => {
    const setEndpoint = () => {
      configAdmin?.setEndpointFetch(
        `api/v1/point-type-events?libraryId=${parseInt(library?.id)}`
      );
      configAdmin?.setPointLibraryEventId(library?.id);
      configAdmin?.fetchData();
    };

    if (library?.id) setEndpoint();
  }, [configAdmin, library?.id, loadingCreate, loadingDelete]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  return (
    <RowAdminConfig>
      <ColumnAdminConfig>
        <SelectContainerAdminConfig>
          Select library:
          <select name="endPoint" id="endPoint" onChange={handleLibrary}>
            {libraries?.map((library) => (
              <option key={library.id} value={library.id}>
                {library.name}
              </option>
            ))}
          </select>
        </SelectContainerAdminConfig>
        {configAdmin && (
          <GenericTable
            headers={configAdmin.getHeadersTable()}
            rows={typeElementsGhgRow}
            keyValues={configAdmin.getKeyValuesTable()}
            handlerClick={handleClickTypeElementGhg}
            style={{
              width: 100,
            }}
          />
        )}
      </ColumnAdminConfig>
      <ColumnAdminConfig>
        <ContainerForm see={seeForm}>
          <Form
            form={form}
            handleChange={handleChangeForm}
            handleRows={handleClickTypeElementGhg}
            buttons={buttonsForm}
            loadingCreate={loadingCreate}
            loadingDelete={loadingDelete}
            renderBeforeTitle={[
              <ButtonSeeInitalView click={() => configAdmin.toggleForm()} />,
            ]}
            title={"Create a new point event"}
            renderBeforeButtons={[
              library?.typeLibraryId === 1 && (
                <Fields form={form} handleChange={handleChangeForm} />
              ),
              <div style={{ width: "100%" }}>
                <section>
                  <Label>Objects</Label>
                  <CheckListsObjectsElements
                    typeEventsTypeElements={typeEventsTypeElements}
                    handleChangeCheckObjects={handleChangeCheckObjects}
                  />
                </section>
                <section>
                  <Label>Components</Label>
                  <CheckListComponents
                    typeEventsTypeComponents={typeEventsTypeComponents}
                    handleChangeCheckComponents={handleChangeCheckComponents}
                  />
                </section>
                <section>
                  <Label>Events</Label>
                  <CheckListEvents
                    typeEventsTypeEvents={typeEventsTypeEvents}
                    handleChangeCheckEvent={handleChangeCheckEvent}
                  />
                </section>
              </div>,
            ]}
          />
        </ContainerForm>

        {!seeForm && (
          <>
            <CreateNewRegionButton>
              Create a new point event
              <button
                onClick={() => {
                  configAdmin.toggleForm();
                  handleResetDataTypes();
                }}
              >
                <span>
                  <IoMdAdd />
                </span>
                New {library?.name} event
              </button>
            </CreateNewRegionButton>

            <SeparatorWithText>
              <span className="left"></span>
              Or
              <span className="right"></span>
            </SeparatorWithText>

            <InformationShare>
              <div className="head">Edit Existing Digital Twin Point</div>
              <div className="body">
                To Edit Click on Existing Digital Twin Point ID
              </div>
            </InformationShare>
          </>
        )}
      </ColumnAdminConfig>
    </RowAdminConfig>
  );
}
