import Icon2 from "images/dashboardV2.svg";
import Icon3 from "images/user.svg";
import Icon4 from "images/DigitalTwin.svg";
import Icon5 from "images/setup.svg";
import IconChatbot from "images/undraw_chat_bot_re_e2gj.svg";
import IconSensor from "images/undraw_surveillance_re_8tkl.svg";
import IconCalculator from "images/undraw_calculator_re_alsc.svg";

export const infoHub = [
  {
    title: "DASHBOARD",
    navigate: "/dashboard-admin",
    icon: Icon2,
    description: "Report of all the objects collected.",
  },
  {
    title: "DIGITAL TWIN",
    navigate: "/digital-twin-admin",
    icon: Icon4,
    description: "View all objets and events in the map.",
  },
  {
    title: "USERS",
    navigate: "/operators",
    icon: Icon3,
    description: "Add a new user to your team.",
  },
  {
    title: "CONFIGURATION",
    navigate: "/config-admin",
    icon: Icon5,
    description: "Configure app settings, customization.",
  },
  {
    title: "DECIMETRIX® SOCRATES",
    navigate: "/chatbot",
    icon: IconChatbot,
    description: "Interactive chat with Decimetrix®.",
  },
  {
    title: "SENSORS OPTIMIZATION",
    navigate: "/sensor-optimization",
    icon: IconSensor,
    description: "Sensors location optimization.",
  },
  {
    title: "FLARE EFFICIENCY",
    navigate: "/flare-efficiency",
    icon: IconCalculator,
    description: "Flare efficiency estimator.",
  },
];
