import NavBar from "./../../components/NavBar";
import Login from "./SignIn";

const SignIn = () => {
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };

  return (
    <div>
      <NavBar configNavbar={configNavbar} />
      <Login />
    </div>
  );
};

export default SignIn;
