// Components
import { Grid } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { parse, getDay } from "date-fns/esm";

import GroupButtons from "components/Buttons/GroupButtons";
import SmallCard from "components/Cards/SmallCard";
import Layout from "components/Layout/AdminDecimetrix";
import useGet from "hooks/useFetch";
import { HorizontalChart } from "components/Charts/Horizontal";
import BasicMap from "components/Maps/BasicMap";
import GenericTable from "components/Tables/GenericTable";
import {
  headersTableInspection,
  headersTableInventory,
  keyValuesTableInspection,
  keyValuesTableInventory,
  styleChart,
  weekDays,
} from "./data";
import FloatingFiltersDashboard from "components/Float/FloatingFiltersDashboard";
import { Head, TitleChart } from "./styles";
import { LineChart } from "components/Charts/Line";
import { add, format } from "date-fns";

function Dashboard() {
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const [beforeDate, setBeforeDate] = useState("");
  const [afterDate, setAfterDate] = useState("");

  const [objectsByType, setObjectsByType] = useState({});
  const [objectsByUser, setObjectsByUser] = useState({});
  const [users, setUsers] = useState({});
  const [operationsByType, setOperationsByType] = useState({});
  const [infoPointsInMap, setSeeInfoInMap] = useState({});
  const [currentGraph, setCurrentGraph] = useState("Objects");

  const [userInspections, setUserInspections] = useState({});
  const [inspectionsByUser, setInspectionsByUser] = useState({});
  const [inspectionsByType, setInspectionsByType] = useState({});

  const [userLeaks, setUserLeaks] = useState({});
  const [leaksByUser, setLeaksByUser] = useState({});
  const [leaksByObject, setLeaksByObject] = useState({});
  const [CH4GrH, setCH4GrH] = useState(0);
  const [baselineTonCH4, setBaselineTonCH4] = useState(0);
  const [baselineTonCO2eq, setBaselineTonCO2eq] = useState(0);

  const [byTypeChart, setByTypeChart] = useState({});
  const [byUserChart, setByUserChart] = useState({});

  const [rowsTableInventory, setRowsTableInventory] = useState([]);
  const [byDateChartInventory, setByDateChartInventory] = useState({});
  const [objectsGHGByDate, setObjectGHGsByDate] = useState({});

  const [inspectionsByDate, setInspectionsByDate] = useState({});
  const [inspectionByDateObjectId, setInspectionByDateObjectId] = useState({});
  const [byDateChartInspection, setByDateChartInspection] = useState({});
  const [rowsTableInspection, setRowsTableInspection] = useState([]);

  const [obj] = useGet(`api/v1/admin-company/objects/${adminCompanyId}`);
  const [objects, setObjects] = useState([]);

  const [ope] = useGet(`api/v1/admin-company/events/${adminCompanyId}`);
  const [operations, setOperations] = useState([]);

  const getInspectedToDate = useCallback(
    (date) => {
      const inspections = operations.filter(
        (operation) => operation?.typeOperation?.name === "Inspección flir"
      );
      const inspectionsByDate = {};
      inspections.map((inspection) => {
        let { date: dateInspection } = inspection;
        dateInspection = dateInspection.slice(0, 10);
        inspectionsByDate[dateInspection] = [
          ...(inspectionsByDate[dateInspection] || []),
          inspection,
        ];
        return null;
      });

      let totalInspections = 0;
      Object.keys(inspectionsByDate).map((currentDate) => {
        if (currentDate <= date) {
          totalInspections += inspectionsByDate[currentDate].length;
        }
        return null;
      });
      return totalInspections;
    },
    [operations]
  );

  const getWeekDay = (data) => {
    const date = parse(data, "yyyy-MM-dd", new Date());
    const weekDay = getDay(date);
    return weekDays[weekDay];
  };

  // set states when data is fetched
  const setStates = useCallback(() => {
    setObjectsByType({});
    setObjectsByUser({});
    setOperationsByType({});
    setSeeInfoInMap({});
    setUserInspections({});
    setInspectionsByUser({});
    setInspectionsByType({});
    setUserLeaks({});
    setLeaksByUser({});
    setLeaksByObject({});
    setCH4GrH(0);
    setByTypeChart({});
    setByUserChart({});
    setRowsTableInventory([]);
    setByDateChartInventory({});
    setObjectGHGsByDate({});
    setInspectionsByDate({});
    setInspectionByDateObjectId({});
    setRowsTableInspection([]);
    setByDateChartInspection({});
  }, []);

  const sortWithTwoArrays = (array1, array2) => {
    const sortedArray = array1.map((data, index) => ({
      label: data,
      data: array2[index],
    }));
    sortedArray.sort((a, b) => b.data - a.data);
    return sortedArray;
  };

  // Get sum of CH4
  useEffect(() => {
    if (!operationsByType?.Leak || operationsByType?.Leak.length <= 0)
      return null;
    let CH4 = 0;
    operationsByType?.Leak?.map(({ leak }) => {
      const { grH } = leak;
      if (!grH) return null;
      CH4 += grH;
      return null;
    });
    setCH4GrH(() => CH4);
  }, [operationsByType?.Leak]);

  useEffect(() => {
    const baselineTonCH4Value = (CH4GrH * 24 * 365) / 1000000;
    setBaselineTonCH4(baselineTonCH4Value);
  }, [CH4GrH]);

  useEffect(() => {
    const baselineTonCO2eqValue = (28 * (CH4GrH * 24 * 365)) / 1000000;
    setBaselineTonCO2eq(baselineTonCO2eqValue);
  }, [CH4GrH]);

  // Logic to create see with filter
  useEffect(() => {
    setStates();
    const objectsFiltered = obj?.filter((object) => {
      let { date } = object;
      date = date?.slice(0, 10);
      return date >= beforeDate && date <= afterDate;
    });
    setObjects(() => objectsFiltered);

    const operationsFiltered = ope?.filter((operation) => {
      let { date } = operation;
      date = date?.slice(0, 10);
      return date >= beforeDate && date <= afterDate;
    });
    setOperations(() => operationsFiltered);
  }, [obj, beforeDate, afterDate, ope, setStates]);

  // Set inital dates in last month
  useEffect(() => {
    const today = new Date();
    const todayString = today?.toISOString()?.slice(0, 10);
    setAfterDate(() => todayString);

    const lastMonth = new Date(today?.setMonth(today?.getMonth() - 1));
    const lastMonthString = lastMonth?.toISOString()?.slice(0, 10);
    setBeforeDate(() => lastMonthString);
  }, []);

  // Logic to create chart of inspection
  useEffect(() => {
    if (!rowsTableInspection || rowsTableInspection.length <= 0) return null;
    const labels = rowsTableInspection?.map((row) => row?.date);
    const quantityData = rowsTableInspection?.map((row) => row?.totalInspected);

    const labelsData = {};
    labels.forEach((label, index) => {
      labelsData[label] = quantityData[index];
    });

    const dates = labels?.map((date) => add(new Date(date), { days: 1 }));
    dates.sort((a, b) => a - b);
    const startDate = dates[0];
    const endDate = dates[dates.length - 1];

    const allDates = getAllDaysBetweenTwoDates(startDate, endDate);
    const correctData = allDates?.map((date) => {
      const dateStr = format(date, "yyyy-MM-dd");
      return {
        label: dateStr,
        value: labelsData[dateStr] || 0,
      };
    });

    setByDateChartInspection({
      labels: correctData?.map((data) => data?.label),
      quantityData: correctData?.map((data) => data?.value),
    });
  }, [rowsTableInspection]);

  // Logic to create table inspection
  useEffect(() => {
    if (
      !inspectionsByDate ||
      !inspectionByDateObjectId ||
      inspectionByDateObjectId?.length <= 0
    )
      return null;
    Object.keys(inspectionByDateObjectId).map((date) => {
      const inspesctionsOfDay = inspectionByDateObjectId?.[date];
      const objectsInspected = Object.keys(inspesctionsOfDay)?.length;
      if (!objectsInspected || !inspectionsByDate?.[date]) return null;
      const totalInspections = Object.keys(inspectionsByDate?.[date])?.length;

      const row = {
        day: getWeekDay(date),
        date: date,
        equipmentInspected: objectsInspected,
        totalInspected: totalInspections,
        accumulatedInspections: getInspectedToDate(date),
        expectedInspections: "-",
        deviationInspections: "-",
      };

      setRowsTableInspection((prev) => [...prev, row]);

      return null;
    });
  }, [inspectionByDateObjectId, inspectionsByDate, getInspectedToDate]);

  // Logic to have inspections to data and object id
  useEffect(() => {
    if (!inspectionsByDate || inspectionsByDate?.length <= 0) return null;
    Object.keys(inspectionsByDate)?.map((date) => {
      const inspections = inspectionsByDate?.[date];
      inspections?.map((inspection) => {
        const { objectId } = inspection;
        setInspectionByDateObjectId((prev) => ({
          ...prev,
          [date]: {
            ...prev[date],
            [objectId]: [...(prev?.[date]?.[objectId] || []), inspection],
          },
        }));

        return null;
      });
      return null;
    });
  }, [inspectionsByDate]);

  const getAllDaysBetweenTwoDates = (startDate, endDate) => {
    const dates = [];
    const theDate = new Date(startDate);
    while (theDate <= endDate) {
      dates.push(new Date(theDate));
      theDate.setDate(theDate.getDate() + 1);
    }
    return dates;
  };

  // Logic to create chart of inventory
  useEffect(() => {
    if (!rowsTableInventory || rowsTableInventory?.length <= 0) return null;
    const labels = rowsTableInventory?.map((row) => row?.date);
    const quantityData = rowsTableInventory?.map(
      (row) => row?.equipment + row?.components
    );

    const labelsData = {};
    labels.forEach((label, index) => {
      labelsData[label] = quantityData[index];
    });

    const dates = labels?.map((date) => add(new Date(date), { days: 1 }));
    dates.sort((a, b) => a - b);
    const startDate = dates[0];
    const endDate = dates[dates.length - 1];

    const allDates = getAllDaysBetweenTwoDates(startDate, endDate);
    const correctData = allDates?.map((date) => {
      const dateStr = format(date, "yyyy-MM-dd");
      return {
        label: dateStr,
        value: labelsData[dateStr] || 0,
      };
    });

    setByDateChartInventory({
      labels: correctData?.map((data) => data.label),
      quantityData: correctData?.map((data) => data.value),
    });
  }, [rowsTableInventory]);

  // Logic to create table of inventory
  useEffect(() => {
    if (!objectsGHGByDate) return null;
    Object.keys(objectsGHGByDate)?.map((date) => {
      const dateOfDays = objectsGHGByDate?.[date];
      const others = [];
      const valves = dateOfDays?.filter((data) => {
        const isValve = data?.typeElement?.name === "Válvula";
        if (!isValve) others?.push(data);
        return isValve;
      });

      const row = {
        day: getWeekDay(date),
        date,
        equipment: others?.length,
        components: valves?.length,
        dailyPercentage: "-",
        accumulatedPercentage: "-",
        expectedPercentage: "-",
        deviation: "-",
      };

      setRowsTableInventory((prev) => [...prev, row]);

      return null;
    });
  }, [objectsGHGByDate]);

  // Set labels and data to charts
  useEffect(() => {
    if (!objectsByType) return null;
    if (currentGraph === "Objects") {
      const labels = Object.keys(objectsByType);
      const quantityData = Object.values(objectsByType)?.map(
        (typeObjects) => typeObjects?.length
      );
      const sortedTypeChart = sortWithTwoArrays(labels, quantityData);

      setByTypeChart({
        labels: sortedTypeChart.map((sort) => sort.label),
        quantityData: sortedTypeChart.map((sort) => sort.data),
      });

      const labelsUser = Object?.values(users);
      const quantityDataUser = Object.values(objectsByUser)?.map(
        (typeObjects) => typeObjects?.length
      );

      const sortedUserChart = sortWithTwoArrays(labelsUser, quantityDataUser);
      setByUserChart({
        labels: sortedUserChart.map((sort) => sort.label),
        quantityData: sortedUserChart.map((sort) => sort.data),
      });
    }
    if (currentGraph === "Inspections") {
      const labels = Object.keys(inspectionsByType);
      const quantityData = Object.values(inspectionsByType)?.map(
        (typeObjects) => typeObjects?.length
      );
      const sortedTypeChart = sortWithTwoArrays(labels, quantityData);

      setByTypeChart({
        labels: sortedTypeChart.map((sort) => sort.label),
        quantityData: sortedTypeChart.map((sort) => sort.data),
      });

      const labelsUser = Object.values(userInspections);
      const quantityDataUser = Object.values(inspectionsByUser)?.map(
        (typeObjects) => typeObjects?.length
      );
      const sortedUserChart = sortWithTwoArrays(labelsUser, quantityDataUser);
      setByUserChart({
        labels: sortedUserChart.map((sort) => sort.label),
        quantityData: sortedUserChart.map((sort) => sort.data),
      });
    }

    if (currentGraph === "Leaks") {
      const labelsObjec = Object.values(leaksByObject).map((le) => le.label);
      const quantityDataObject = Object.values(leaksByObject)?.map(
        (le) => le.data.length
      );
      const sortedObjectChart = sortWithTwoArrays(
        labelsObjec,
        quantityDataObject
      );
      setByTypeChart({
        labels: sortedObjectChart.map((sort) => sort.label),
        quantityData: sortedObjectChart.map((sort) => sort.data),
      });

      const labelsUser = Object.values(userLeaks);
      const quantityDataUser = Object.values(leaksByUser)?.map(
        (typeObjects) => typeObjects?.length
      );
      const sortedUserChart = sortWithTwoArrays(labelsUser, quantityDataUser);
      setByUserChart({
        labels: sortedUserChart.map((sort) => sort.label),
        quantityData: sortedUserChart.map((sort) => sort.data),
      });
    }
  }, [
    currentGraph,
    objectsByType,
    inspectionsByType,
    objectsByUser,
    inspectionsByUser,
    users,
    userInspections,
    userLeaks,
    leaksByUser,
    leaksByObject,
  ]);

  // To the current points that are see in the map
  useEffect(() => {
    if (currentGraph === "Objects" && objects && objects?.length > 0) {
      setSeeInfoInMap({
        type: "object",
        data: objects,
        keyLocation: "location",
        keySearchImage: "typeElement",
        keyImage: "urlIconColor",
      });
    }
    if (currentGraph === "Inspections") {
      setSeeInfoInMap({
        type: "inspection",
        data: operationsByType["Inspección flir"] || [],
        keyLocation: "locationOperation",
        keySearchImage: "typeOperation",
        keyImage: "urlIcon",
      });
    }
    if (currentGraph === "Leaks") {
      setSeeInfoInMap({
        type: "inspection",
        data: operationsByType["Leak"] || [],
        keyLocation: "locationOperation",
        keySearchImage: "typeOperation",
        keyImage: "urlIcon",
      });
    }
  }, [currentGraph, objects, operationsByType]);

  // Oeganized objects
  useEffect(() => {
    if (!objects || objects?.length <= 0) return;
    objects?.map((object) => {
      const { name: typeObjectName, pointLibraryId } = object?.typeElement;
      const { operator, adminCompany, id } = object?.user;

      const existObjetByType = objectsByType?.[typeObjectName]?.find(
        (obj) => obj?.id === object?.id
      );
      if (!existObjetByType) {
        setObjectsByType((prev) => ({
          ...prev,
          [typeObjectName]: [...(prev?.[typeObjectName] || []), object],
        }));
      }

      const existObjectByUser = objectsByUser?.[id]?.find(
        (obj) => obj?.id === object?.id
      );
      if (!existObjectByUser) {
        setObjectsByUser((prev) => ({
          ...prev,
          [id]: [...(prev?.[id] || []), object],
        }));
      }
      const name = adminCompany ? adminCompany?.firstName : operator?.firstName;
      setUsers((prev) => ({
        ...prev,
        [id]: name,
      }));

      if (pointLibraryId !== 1) return null;

      const { date } = object;
      const dateOnlyDay = date?.slice(0, 10);

      const existObjectByDate = objectsGHGByDate?.[dateOnlyDay]?.find(
        (ob) => ob.id === object?.id
      );

      if (!existObjectByDate) {
        setObjectGHGsByDate((prev) => ({
          ...prev,
          [dateOnlyDay]: [...(prev?.[dateOnlyDay] || []), object],
        }));
      }

      return null;
    });
  }, [objects, objectsByUser, objectsByType, objectsGHGByDate]);

  // Oranized operations
  useEffect(() => {
    if (!operations || operations.length <= 0) return;
    operations.map((operation) => {
      const { name: typeOperationName } = operation?.typeOperation;

      const existOperationByType = operationsByType?.[typeOperationName]?.find(
        (op) => op?.id === operation?.id
      );
      if (!existOperationByType) {
        setOperationsByType((prev) => ({
          ...prev,
          [typeOperationName]: [
            ...(prev?.[typeOperationName] || []),
            operation,
          ],
        }));
      }

      if (typeOperationName === "Leak") {
        const { userId, objectId } = operation;
        const object = obj?.find((ob) => ob?.id === objectId);

        const existLeakByObject = leaksByObject?.[objectId]?.data?.find(
          (le) => le?.id === operation?.id
        );
        if (!existLeakByObject) {
          setLeaksByObject((prev) => ({
            ...prev,
            [objectId]: {
              label: object?.fieldEquipmentName || `Object ${object?.id}`,
              data: [...(prev?.[objectId]?.data || []), operation],
            },
          }));
        }

        const existLeakByUser = leaksByUser?.[userId]?.find(
          (le) => le?.id === operation?.id
        );
        if (!existLeakByUser) {
          setLeaksByUser((prev) => ({
            ...prev,
            [userId]: [...(prev?.[userId] || []), operation],
          }));
        }

        setUserLeaks((prev) => ({
          ...prev,
          [userId]: users?.[userId],
        }));
      }

      if (typeOperationName !== "Inspección flir") return null;

      const { inspectionFlir, userId, date } = operation;
      const currentDate = date?.slice(0, 10);

      const setInspection = inspectionsByDate?.[currentDate]?.find(
        (inspection) => inspection?.id === operation?.id
      );
      if (!setInspection)
        setInspectionsByDate((prev) => ({
          ...prev,
          [currentDate]: [...(prev?.[currentDate] || []), operation],
        }));

      const { type } = inspectionFlir?.type;

      const exitsInspectionByType = inspectionsByType?.[type]?.find(
        (ins) => ins?.id === operation?.id
      );
      if (!exitsInspectionByType) {
        setInspectionsByType((prev) => ({
          ...prev,
          [type]: [...(prev?.[type] || []), operation],
        }));
      }

      const existInspectionByUser = inspectionsByUser?.[userId]?.find(
        (ins) => ins?.id === operation?.id
      );
      if (!existInspectionByUser) {
        setInspectionsByUser((prev) => ({
          ...prev,
          [userId]: [...(prev?.[userId] || []), operation],
        }));
      }

      setUserInspections((prev) => ({
        ...prev,
        [userId]: users?.[userId],
      }));

      return null;
    });
  }, [
    operations,
    users,
    inspectionsByDate,
    operationsByType,
    inspectionsByType,
    inspectionsByUser,
    leaksByUser,
    leaksByObject,
    obj,
  ]);

  return (
    <Layout>
      <FloatingFiltersDashboard
        beforeDate={{
          beforeDate,
          setBeforeDate,
        }}
        afterDate={{
          afterDate,
          setAfterDate,
        }}
      />
      <Grid
        item
        sx={{
          marginTop: "100px",
          padding: "0 20px",
        }}
      >
        <Head>
          <SmallCard title="Objects" content={objects?.length} />
          <SmallCard
            title="Inspections"
            content={`${operationsByType?.["Inspección flir"]?.length || 0}`}
          />
          <SmallCard title="Leaks" content={operationsByType?.Leak?.length} />
          <SmallCard title="Baseline CH4 Gr/H" content={CH4GrH} />
          <SmallCard
            title="Baseline Ton CH4/Year"
            content={baselineTonCH4.toFixed(2)}
          />{" "}
          <SmallCard
            style={{ padding: "1rem" }}
            title="Baseline Ton CO2eq/Year"
            content={baselineTonCO2eq.toFixed(2)}
          />
        </Head>

        <GroupButtons
          infoButtons={["Objects", "Inspections", "Leaks", "Project"]}
          setButton={setCurrentGraph}
        />

        {currentGraph === "Project" ? (
          <>
            <Grid item xs={12} md={12} lg={12} xl={12} sx={styleChart}>
              <TitleChart>Tabla de Inventario</TitleChart>
              <GenericTable
                headers={headersTableInventory}
                rows={[...rowsTableInventory]}
                keyValues={keyValuesTableInventory}
                style={{
                  width: 100,
                }}
                state={true}
              />
              <div
                style={{
                  width: "50%",
                }}
              >
                <LineChart
                  labels={byDateChartInventory.labels}
                  quantityData={byDateChartInventory.quantityData}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12} sx={styleChart}>
              <TitleChart>Tabla de Inspección</TitleChart>

              <GenericTable
                headers={headersTableInspection}
                rows={[...rowsTableInspection]}
                keyValues={keyValuesTableInspection}
                style={{
                  width: 100,
                }}
                state={true}
              />
              <div
                style={{
                  width: "50%",
                }}
              >
                <LineChart
                  labels={byDateChartInspection.labels}
                  quantityData={byDateChartInspection.quantityData}
                />
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid container xs={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} md={12} lg={7} xl={7} sx={styleChart}>
                <TitleChart>
                  {currentGraph} By{" "}
                  {currentGraph === "Leaks" ? "Objects" : "Type"}
                </TitleChart>
                <HorizontalChart
                  labels={byTypeChart.labels}
                  quantityData={byTypeChart.quantityData}
                  horizontal={true}
                />

                <TitleChart>{currentGraph} Collected By User</TitleChart>
                <HorizontalChart
                  labels={byUserChart.labels}
                  quantityData={byUserChart.quantityData}
                  horizontal={false}
                />
              </Grid>
              <BasicMap pointsIntoMap={infoPointsInMap} />
            </Grid>
          </>
        )}
      </Grid>
    </Layout>
  );
}

export default Dashboard;
