import styled from "styled-components";
import { PRIMARY_COLOR } from "../../utils/const";

export const PopUpmap = styled.div`
  .specification {
    padding: 3px 5px;
  }

  .label {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 5px;
    color: ${PRIMARY_COLOR};
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  .actions.row {
    flex-direction: column;
  }

  .actions.little {
    gap: 0.5rem;
  }

  .button {
    outline: none;
    border: none;
    background-color: ${PRIMARY_COLOR};
    color: white;
    padding: 5px 8px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
  }

  .button.little {
    transform: scale(0.9);
  }
`;

export const DrawerMapStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 15px;
`;

export const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .types-base-map {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .type-base-map {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .select-base-map {
        outline: none;
        border: none;
        background-color: ${PRIMARY_COLOR};
        color: white;
        padding: 5px 8px;
        font-size: 1.5rem;
        cursor: pointer;
        border-radius: 5px;
        width: 150px;
      }

      img {
        height: 20rem;
        width: 20rem;
        object-fit: cover;
      }
    }
  }
`;

export const ButtonMapStyle = styled.button`
  width: 175px;
  height: 60px;
  border-radius: 4px;
  border: none;
  /* background-color: (${PRIMARY_COLOR}, 0.5); */
  background-color: rgba(0, 0, 0, 0.08);
  color: black;
  font-size: 2rem;
  /* font-weight: normal; */
  padding: 10px;
  margin: 3px;
  text-align: center;
  display: flex;
  margin-top: 10px;
  align-items: center;

  &:hover {
    background-color: rgba(14, 77, 69, 0.7);
    cursor: pointer;
  }
`;

export const ImgButton = styled.img`
  width: 50px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
`;
