import { Edit } from "@material-ui/icons";
import { validateBBox } from "@turf/turf";
import {
  InputProfile,
  Label,
  WrapperInput,
  LegendError,
  InputFile,
  LabelFile,
} from "./FormStyles";

const InputComponent = ({ field, onChange }) => {
  const {
    value,
    validate,
    type,
    label,
    placeholder,
    name,
    legendError,
    regularExpression,
    min,
    max,
    step,
    validation: validationTest,
  } = field;

  // Validate the field
  const validation = ({ target }) => {
    const { value } = target;

    if (regularExpression) {
      if (regularExpression.test(value)) {
        onChange({ target: { name, validate: "true" } });
      } else {
        onChange({ target: { name, validate: "false" } });
      }
    }

    if (validationTest) {
      validationTest(value);
    }
  };

  // Change the value of the field
  const handleOnchange = (e) => {
    onChange(e);
    validation(e);
  };

  // Type of input image
  if (type === "image") {
    const image =
      (typeof value === "string" &&
        value === "" &&
        "https://static.thenounproject.com/png/1554489-200.png") ||
      (typeof value === "object" && URL.createObjectURL(value)) ||
      (typeof value === "string" && value !== "" && value);
    return (
      <>
        <WrapperInput width="50%">
          <LabelFile valid={validate}>
            <span>
              <Edit />
              {label}
            </span>
            <img src={image} alt="" />
            <InputFile
              type="file"
              id={name}
              onChange={handleOnchange}
              onKeyUp={validation}
              onBlur={validation}
              valid={validate}
              name={name}
              accept="image/*"
            />
          </LabelFile>
        </WrapperInput>
      </>
    );
  }

  if (type === "select") return null;

  return (
    <>
      <WrapperInput>
        <Label valid={validate}>
          {label}
          {(type === "range" || type === "color") && `: ${value}`}
          <InputProfile
            type={type}
            placeholder={placeholder}
            id={name}
            value={value}
            autocomplete="off"
            onChange={handleOnchange}
            onKeyUp={validation}
            onBlur={validation}
            valid={validate}
            name={name}
            min={min}
            max={max}
            step={step}
          />
        </Label>

        <LegendError valid={validateBBox}>{legendError}</LegendError>
      </WrapperInput>
    </>
  );
};

export default InputComponent;
