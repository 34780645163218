// React
import { useCallback } from "react";
import { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import { setFlyTo, setRowNum } from "redux/actions/digitalTwin";
// Components
import GenericTable from "components/Tables/GenericTable";
import OperationDetails from "components/ImageGallery/OperationDetails/OperationDetails";
import EventDetails from "components/ImageGallery/EventDetails/EventDetails";
// Hooks
import useGet from "hooks/useFetch";
// Dependecies
import moment from "moment-timezone";
// Styles
import { SearchPosition } from "./styles";

const baseKeyValues = [
  "id",
  "flyTo",
  "markPoint",
  "typeOfEvent",
  "relationId",
  "relationTypeName",
  "fieldEquipmentName",
  "dateUTC",
  "localDate",
  "user",
  "latitude",
  "longitude",
];

const baseHeadersTable = [
  "ID",
  "Fly To",
  "Mark Event",
  "Type of Event",
  "Relation Id",
  "Relation Type Name",
  "Field Equipment Name",
  "Date UTC-0",
  "Local date",
  "User",
  "Latitude",
  "Longitude",
];

const keyValuesSniffer = ["leak", "ppm", "grH", "ltMin"];
const headersTableSniffer = ["Leak", "PPM", "Gr/H", "Lt/Min"];

const keyValuesFlir = ["leak"];
const headersTableFlir = ["Leak"];

const keyValuesLeak = ["leak", "label", "ppm", "grH", "ltMin"];
const headersTableLeak = ["Leak", "Label", "PPM", "Gr/H", "Lt/Min"];

const keyValuesRepair = ["repair"];
const headersTableRepair = ["Repair"];

const keyValues = [
  keyValuesSniffer,
  keyValuesFlir,
  keyValuesLeak,
  keyValuesRepair,
];

const headersTable = [
  headersTableSniffer,
  headersTableFlir,
  headersTableLeak,
  headersTableRepair,
];

export default function TableEvents({
  typeEvent,
  setRows: setRowsMain,
  attributte,
  setLabels,
  label,
  setTableVisibility,
  tableVisibility,
  typeOfTable,
  typeOfLibrary,
  handleClose,
  handleDrawerClose,
  handleDrawerOpen,
  state = false,
}) {
  const dataEventPolygon = useSelector(
    (state) => state.adminReducer.dataEventPolygon
  );
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const map = useSelector((state) => state.digitalTwinReducer.map);
  const dispatch = useDispatch();
  const [event, setEvent] = useState(null);

  const [events, setEvents] = useState({
    1: "sketch",
    2: "inspectionFlir",
    3: "leak",
    4: "Repair",
  });
  const [rows, setRows] = useState([]);
  const [isDynamicEvent, setIsDynamicEvent] = useState([]);
  const flyToRedux = useSelector((state) => state.digitalTwinReducer.flyTo);
  const [eventLibraries] = useGet("api/v1/point-library-events");

  const [objects] = useGet(`api/v1/admin-company/objects/${adminCompanyId}`);

  const handerClickTable = (operationId, isDynamic) => {
    const data = { operationId, isDynamic };
    const content = isDynamic ? (
      <EventDetails eventId={operationId} />
    ) : (
      <OperationDetails operationId={operationId} />
    );
    const title = isDynamic ? "Event Details" : "Operation Details";
    const operation = {
      content,
      title,
    };
    dispatch(setOperationDetails(operation));
    dispatch(setShowOperationDetails(true));
    localStorage.setItem("typeEvent", JSON.stringify(data));
  };

  const removeLineBreak = (text) => {
    if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
    if (text && isNaN(text) && typeof text !== "object") {
      return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
    }
    return text;
  };

  const markPoint = useCallback(
    (id, lat, lng) => {
      if (state) {
        const data = {
          id,
          lat,
          lng,
        };
        const dataMarketPoint = JSON.stringify(data);
        localStorage.setItem("dataMarketPoint", dataMarketPoint);
        setTimeout(() => {
          const setData = {
            id: "",
            lat: "",
            lng: "",
          };
          const setDataMarketPoint = JSON.stringify(setData);
          localStorage.setItem("dataMarketPoint", setDataMarketPoint);
        }, 7000);
      } else {
        setTableVisibility(false);
        dispatch(
          setFlyTo({
            ...flyToRedux,
            operation: {
              id,
              latitude: parseFloat(lat),
              longitude: parseFloat(lng),
            },
          })
        );
        setTimeout(() => {
          dispatch(
            setFlyTo({
              ...flyToRedux,
              operation: {
                id: "",
                latitude: "",
                longitude: "",
              },
            })
          );
        }, 5000);
      }
    },
    [flyToRedux, dispatch, setTableVisibility]
  );

  const flyTo = useCallback(
    (e, id) => {
      const { value } = e.target;
      const [lng, lat] = value.split(",");
      if (state) {
        const coordinates = [lng, lat];
        const parseCoordinates = JSON.stringify(coordinates);
        localStorage.setItem("coordinates", parseCoordinates);
        markPoint(id, lat, lng);
      } else {
        map.flyTo({
          center: [parseFloat(lng), parseFloat(lat)],
          duration: 2000,
          zoom: 24,
        });
        markPoint(id, lat, lng);
      }
    },
    [map, markPoint]
  );

  const createRows = useCallback(
    (data) => {
      const rows = data?.map((item) => {
        const isDynamic = item?.typeOperationId ? false : true;
        setIsDynamicEvent((prev) => {
          return [
            ...prev,
            {
              id: item.id,
              isDynamic,
            },
          ];
        });
        const convertedTime = moment
          .tz(item.date, "UTC")
          .utcOffset(item.timeZoneOffset || "-05:00")
          .tz(item.timeZone || "America/Bogota");

        const object = objects?.find((object) => {
          return object.id === item?.objectId;
        });
        const IdRelation = (elm) => {
          if (elm?.objectId) return `${elm.objectId} / O`;
          if (elm?.pointComponentId) return `${elm?.pointComponentId} / C`;
          if (elm?.PointEventRelationWith)
            return `${item?.PointEventRelationWith[0]?.id} / E`;
        };
        const typeOfNameRelation = (elm) => {
          if (elm?.object) return elm.object?.typeElement?.name;
          if (elm?.component) return elm.component?.typeComponent?.name;
          if (elm?.event) return elm.event?.typeEvent?.name;
        };
        const { id, locationOperation } = item;
        const { latitude, longitude } = locationOperation;
        const row = {
          id: removeLineBreak(item?.id),
          flyTo: (
            <button
              className="button"
              value={`${longitude},${latitude}`}
              onClick={(e) => flyTo(e, item?.id)}
            >
              Fly
            </button>
          ),
          markPoint: (
            <button
              className="button"
              onClick={() => markPoint(id, latitude, longitude)}
            >
              Flash
            </button>
          ),
          typeOfEvent: removeLineBreak(
            item?.typeOperation?.name || item?.pointTypeEvent?.name
          ),
          relationId: removeLineBreak(IdRelation(item)),
          relationTypeName: removeLineBreak(typeOfNameRelation(item)),
          fieldEquipmentName: removeLineBreak(object?.fieldEquipmentName),
          dateUTC: removeLineBreak(item?.date),
          localDate: removeLineBreak(
            convertedTime.format("YYYY-MM-DD HH:mm:ss z")
          ),
          user: removeLineBreak(
            (item.user &&
              item.user.adminCompany &&
              item.user.adminCompany.firstName +
                " " +
                item.user.adminCompany.firstLastName) ||
              (item.user &&
                item.user.operator &&
                item.user.operator.firstName +
                  " " +
                  item.user.operator.firstLastName) ||
              (item.user &&
                item.user.adminDecimetrix &&
                item.user.adminDecimetrix.firstName +
                  " " +
                  item.user.adminDecimetrix.firstLastName)
          ),
          latitude: removeLineBreak(item?.locationOperation?.latitude),
          longitude: removeLineBreak(item?.locationOperation?.longitude),
        };

        if (item.hasOwnProperty("attributes") && item.attributes.length !== 0) {
          item.attributes.forEach((object) => {
            row[object.name] = object.value;
          });
        }

        if (item[event]) {
          for (const key of keyValues[parseInt(typeEvent) - 1]) {
            let value = item[event][key];
            if (value || typeof value === "boolean") {
              if (typeof value === "boolean") {
                value = value ? "Yes" : "No";
              }
              row[key] = removeLineBreak(value);
            } else {
              row[key] = "-";
            }
          }
        }

        for (const key in row) {
          if (attributte === "") return row;
          if (!row[key] || typeof row[key] === "object") continue;
          if (label !== "all" && key !== label) continue;

          const value = isNaN(row[key]) ? row[key] : row[key].toString();
          try {
            if (value.toUpperCase().includes(attributte.toUpperCase())) {
              return row;
            }
          } catch (error) {
            console.log(error);
          }
        }
        return null;
      });

      const rowsFilter = rows.filter((item) => item !== null);

      return rowsFilter;
    },
    [typeEvent, event, attributte, label, flyTo, objects, markPoint]
  );

  const updateLabels = useCallback(() => {
    const labels = {};
    let currentKeyValues = [...baseKeyValues];
    let currentHeadersTable = [...baseHeadersTable];

    if (keyValues[parseInt(typeEvent) - 1]) {
      currentKeyValues = [
        ...baseKeyValues,
        ...keyValues[parseInt(typeEvent) - 1],
      ];
    }

    if (headersTable[parseInt(typeEvent) - 1]) {
      currentHeadersTable = [
        ...baseHeadersTable,
        ...headersTable[parseInt(typeEvent) - 1],
      ];
    }

    for (const key in currentKeyValues) {
      labels[currentKeyValues[key]] = currentHeadersTable[key];
    }
    setLabels(labels);
  }, [setLabels, typeEvent]);

  useEffect(() => {
    if (!typeEvent) return;
    const newDataObject = dataEventPolygon?.filter((data) => {
      const pointLibraryEventId = data?.pointTypeEvent?.pointLibraryEventId;
      if (pointLibraryEventId)
        return pointLibraryEventId === parseInt(typeEvent) - 4;
      return data?.typeOperationId === parseInt(typeEvent);
    });
    const newRows = createRows(newDataObject);
    setRows(() => [...newRows]);
    setRowsMain(() => [...newRows]);
    updateLabels();
  }, [dataEventPolygon, typeEvent, createRows, setRowsMain, updateLabels]);

  useEffect(() => {
    setEvent(events[typeEvent]);
    setIsDynamicEvent([]);
  }, [typeEvent, events]);

  useEffect(() => {
    if (!eventLibraries || eventLibraries.error) return;
    const newEventLibraries = {};
    for (const { id, name } of eventLibraries) {
      newEventLibraries[id + 4] = name;
    }
    setEvents((current) => ({ ...current, ...newEventLibraries }));
  }, [eventLibraries]);

  useEffect(() => {
    dispatch(setRowNum(rows.length));
  }, [rows.length, dispatch]);
  return (
    <div className="body">
      {rows.length > 0 && (
        <SearchPosition>
          <GenericTable
            headers={[
              ...baseHeadersTable,
              ...(headersTable[parseInt(typeEvent) - 1]
                ? headersTable[parseInt(typeEvent) - 1]
                : []),
            ]}
            rows={rows}
            rowsDynamic={rows}
            isDynamicEvent={isDynamicEvent}
            keyValues={[
              ...baseKeyValues,
              ...(keyValues[parseInt(typeEvent) - 1]
                ? keyValues[parseInt(typeEvent) - 1]
                : []),
            ]}
            handlerClick={handerClickTable}
            style={{
              small: true,
              width: 55,
              maxHeight: state ? 90 : 50,
            }}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
            typeOfTable={typeOfTable}
            typeOfLibrary={typeOfLibrary}
            handleClose={handleClose}
            digitalTwin={true}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            state={state}
          />
        </SearchPosition>
      )}
    </div>
  );
}
