import styled from "styled-components";

export const Container = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  gap: 10px;
  flex: 1;

  .title {
    font-size: 2rem;
    font-weight: 600;
    color: #000;
  }

  .description {
    font-size: 2.2rem;
    color: #373737;
  }
`;
