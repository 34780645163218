import animationDataTwo from "../../lotties/data-collection.json";
import CAMERA from "../../lotties/CAMERA.json";
import Facility from "../../lotties/Facilitie-json.json";
import { PRIMARY_COLOR } from "../../utils/const";

export const homeObjectOne = {
  id: "greenDragon",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  // topLine: "DECIMETRIX<sup>®</sup> DIGITAL TRANSFORMATION",
  headLine: "Green Dragon Carbon Footprint Management",
  description:
    "Decimetrix<sup>®</sup> is software-technology-based start-up that helps energy companies to collect data, detect leaks, quantify, train and manage with one solution their carbon footprint through the development of specialized software that integrates technology, data science, computer vision, simulation and artificial intelligence.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../../images/collection.svg").default,
  alt: "study",
  animation: Facility,
  colorWave: `${PRIMARY_COLOR}`,
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjectTwo = {
  id: "discover",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: "Decimetrix<sup>®</sup> GREEN DRAGON CARBON FOOTPRINT MANAGEMENT",
  headLine: "GHG Systems",
  description:
    "Annually, the energy industry emits 39.5 billion tons of greenhouse gases, currently there is a driving force from investors, consumers and leaders worldwide towards NET-ZERO carbon neutral energy. To reach that goal THERE IS A NEED from the industry to DETECT, QUANTIFY or ESTIMATE their current levels of greenhouse gas emissions and efficiently manage their leak detection, quantification, and repair programs to achieve the GLOBAL GOAL of zero emissions by the year 2050.",  
  buttonLabel: "Get Started",
  imgStart: true,
  img: require("../../images/report.svg").default,
  alt: "study",
  animation: animationDataTwo,
  colorWave: "#f9f9f9",
  dark: false,
  primary: false,
  darkText: false,
};

export const homeObjectThree = {
  id: "value",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  // topLine: "Decimetrix<sup>®</sup> GREEN DRAGON CARBON FOOTPRINT MANAGEMENT",
  headLine: "Our Differencial Value",
  description:
    "We return value from day one creating the Baseline and identifying the equipment with leaks as soon as the detection happens while minimizing the time in the field increasing safety and  delivering Operational Excellence.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../../images/collection-photos.svg").default,
  alt: "study",
  animation: CAMERA,
  colorWave: `${PRIMARY_COLOR}`,
  dark: false,
  primary: false,
  darkText: true,
};
export const homeObjectFour = {
  id: "ProductAndServices",
};