// React
import { useState } from "react";
import { useEffect } from "react";
// Components
import { InfoTitle } from "../ImageDetailsElements";
import DeleteEventButton from "./DeleteEvent";
import { ButtonInfo } from "../ObjectDetails/ObjectDetailsElements";
import OpenDialog from "components/Dialogs/OpenDialog";
// Helpers
import { isUrl } from "helpers/isUrl";
// Dependecies
import { Grid } from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

export default function TableEvent({ event, deleteButton }) {
  delete event?.object;
  const [infoOperation, setInfoOperation] = useState({});

  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 25);
  };

  useEffect(() => {
    const omit = [
      "mongoId",
      "userId",
      "locationOperationId",
      "pointTypeEventId",
      "adminCompanyId",
      "pointTypeEvent",
      "locationOperation",
      "attributes",
      "date",
      "eventFiles",
      "PointEventRelation",
      "PointEventRelationWith",
      "user",
      "eventsCount",
      "fieldsCaptureMedia",
      "media",
    ];

    Object.keys(event).map((key) => {
      if (omit.includes(key)) return null;
      let value = event[key];
      if (!value) {
        return null;
      }
      setInfoOperation((prev) => ({ ...prev, [key]: value }));
      return null;
    });

    setInfoOperation((prev) => ({
      ...prev,
      Date: isoDateToString(event.date),
      Latitude: event.locationOperation.latitude || 0,
      Longitude: event.locationOperation.longitude || 0,
    }));
  }, [event]);

  const reduceAttributes = () => {
    return event?.attributes?.map((item) => item);
  };
  return (
    <InfoTitle>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <section className="main-tables-container">
          <div className="main-currency-table">
            <div className="currency-table--container">
              <table>
                {Object.keys(infoOperation).map((key) => {
                  return (
                    <tr>
                      <td>{key}</td>
                      <td className="table__right">{infoOperation[key]}</td>
                    </tr>
                  );
                })}
                {event &&
                  reduceAttributes().map((item) => {
                    return (
                      <tr>
                        <td style={{ display: "flex", width: "100%" }}>
                          {item?.operation && item?.alias?.length > 0
                            ? item?.alias
                            : item?.name}
                          {item?.operation ? (
                            <OperationInfo content={item} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="table__right">
                          {isUrl(item.value) ? (
                            <a
                              href={item.value}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Go to link
                            </a>
                          ) : (
                            item.value
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </table>
              {deleteButton && <DeleteEventButton pointEventId={event.id} />}
            </div>
          </div>
        </section>
      </Grid>
    </InfoTitle>
  );
}

const OperationInfo = ({ content }) => {
  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      disagree: "Close",
    },
  });

  const handleShow = () => {
    setDialog((current) => ({
      ...current,
      open: true,
    }));
  };
  return (
    <ButtonInfo>
      {
        <ContactSupportIcon
          sx={{
            fontSize: "1.8rem",
            color: "#3f51b5",
          }}
          onClick={handleShow}
        />
      }
      <OpenDialog
        openDialog={dialog?.open}
        setOpenDialog={setDialog}
        execute={dialog.execute}
        content={{
          title: "Operation detail",
          description: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <div>
                <span>
                  The result of this field is obtained from the following
                  expression:
                </span>
              </div>
              <div>
                <math-field
                  read-only
                  className="math-field"
                  id={content?.name}
                  style={{
                    display: "inline-block",
                    minWidth: "200px",
                    width: "100%",
                    minHeight: "60px",
                    fontSize: "2.2rem",
                    border: "none",
                  }}
                  value={content?.operation}
                />
              </div>
            </div>
          ),
          ...dialog.actions,
        }}
      />
    </ButtonInfo>
  );
};
