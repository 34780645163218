export const headersTableInventory = [
  "DÍA",
  "FECHA",
  "EQUIPOS",
  "COMPONENTES",
  "PORCENTAJE DIARIO (%)",
  "PORCENTAJE ACUMULADO (%)",
  "PORCENTAJE ESPERADO (%)",
  "DESVIACIÓN (+)",
];

export const keyValuesTableInventory = [
  "day",
  "date",
  "equipment",
  "components",
  "dailyPercentage",
  "accumulatedPercentage",
  "expectedPercentage",
  "deviation",
];

export const headersTableInspection = [
  "DÍA",
  "FECHA",
  "EQUIPOS INSPECCIONADOS",
  "TOTAL INSPECCIONADOS",
  "INSPECCIONES ACUMULADAS",
  "INSPECCIONES ESPERADAS",
  "DESVIACIÓN (+)",
];

export const keyValuesTableInspection = [
  "day",
  "date",
  "equipmentInspected",
  "totalInspected",
  "accumulatedInspections",
  "expectedInspections",
  "deviationInspections",
];

export const weekDays = [
  "DOMINGO",
  "LUNES",
  "MARTES",
  "MIÉRCOLES",
  "JUEVES",
  "VIERNES",
  "SÁBADO",
];

export const styleChart = {
  padding: "10px",
  borderRadius: "10px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  marginBottom: "20px",
};
