// Required modules;
import GenericTable from "components/Tables/GenericTable";
import Form from "components/Forms/Form.jsx";
import Fields from "./Fields.jsx";

// Config admin class
import ConfigAdmin from "../ConfigAdmin.js";

// Hooks
import { useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableTypeElementsGhg,
  keyValuesTableTypeElementsGhg,
  initialValuesFormTypeElementGhg,
} from "../dataConfigAdmin";

// Styles
import {
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  SeparatorWithText,
  InformationShare,
  IconAdminConfigChangeColum,
  SelectContainerAdminConfig,
} from "../configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { BsChevronCompactUp } from "react-icons/bs";
import { ContainerForm } from "components/Forms/FormStyles";

import useGet from "hooks/useFetch.js";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <BsChevronCompactUp />
    </IconAdminConfigChangeColum>
  );
};

const findLibrary = (libraries, e) => {
  return libraries.find((library) => library.id === parseInt(e.target.value));
};

export default function ObjectElements({ setOpenDialog, setContentDialog }) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [libraries] = useGet("api/v1/point-libraries");
  const [library, setLibrary] = useState({});

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormTypeElementGhg);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [typeElementsGhgRow, setTypeElementsGhgRow] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // Click  on type element ghg into table
  const handleClickTypeElementGhg = (id) => {
    configAdmin.handlerClickIntoTable(id);
  };

  // Change in library select
  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: findLibrary(libraries, e)?.name,
      typeLibraryId: findLibrary(libraries, e)?.typeLibraryId,
    }));
  };

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableTypeElementsGhg);
    configAdmin.setKeyValuesTable(keyValuesTableTypeElementsGhg);
    configAdmin.setSetRows(setTypeElementsGhgRow);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/type-elements`);
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialog, setContentDialog]);

  // Set library default
  useEffect(() => {
    if (libraries) {
      setLibrary(() => libraries[0]);
    }
  }, [libraries]);

  // Set library in config admin
  useEffect(() => {
    const setEndpoint = () => {
      configAdmin?.setEndpointFetch(
        `api/v1/type-elements?libraryId=${parseInt(library?.id)}`
      );
      configAdmin?.setLibraryId(library?.id);
      configAdmin?.fetchData();
    };
    if (library?.id) setEndpoint();
  }, [configAdmin, library?.id]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  return (
    <RowAdminConfig>
      <ColumnAdminConfig>
        <SelectContainerAdminConfig>
          Select library:
          <select name="endPoint" id="endPoint" onChange={handleLibrary}>
            {libraries?.map((library) => (
              <option key={library.id} value={library.id}>
                {library.name}
              </option>
            ))}
          </select>
        </SelectContainerAdminConfig>
        {configAdmin && (
          <GenericTable
            headers={configAdmin.getHeadersTable()}
            rows={typeElementsGhgRow}
            keyValues={configAdmin.getKeyValuesTable()}
            handlerClick={handleClickTypeElementGhg}
            style={{
              width: 100,
            }}
            state={true}
          />
        )}
      </ColumnAdminConfig>
      <ColumnAdminConfig>
        <ContainerForm see={seeForm}>
          <Form
            form={form}
            handleChange={handleChangeForm}
            handleRows={handleClickTypeElementGhg}
            buttons={buttonsForm}
            loadingCreate={loadingCreate}
            loadingDelete={loadingDelete}
            renderBeforeTitle={[
              <ButtonSeeInitalView click={() => configAdmin.toggleForm()} />,
            ]}
            title={"Create a new Digital Twin Point"}
            renderBeforeButtons={[
              library?.typeLibraryId === 1 && ( // Parametrized object
                <Fields form={form} handleChange={handleChangeForm} />
              ),
            ]}
          />
        </ContainerForm>

        {!seeForm && (
          <>
            <CreateNewRegionButton>
              Create a new Digital Twin Point
              <button onClick={() => configAdmin.toggleForm()}>
                <span>
                  <IoMdAdd />
                </span>
                New Type Element {library?.name}
              </button>
            </CreateNewRegionButton>

            <SeparatorWithText>
              <span className="left"></span>
              Or
              <span className="right"></span>
            </SeparatorWithText>

            <InformationShare>
              <div className="head">Edit Existing Digital Twin Point</div>
              <div className="body">
                To Edit Click on Existing Digital Twin Point ID
              </div>
            </InformationShare>
          </>
        )}
      </ColumnAdminConfig>
    </RowAdminConfig>
  );
}
