import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ButtonOpenOperation, GeneralButton } from "./listEventTableStyle";
import OperationDetails from "./OperationDetails/OperationDetails";
import { useDispatch } from "react-redux";
import { setOperationDetails } from "../../redux/actions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Event Id",
  },
  {
    id: "mark",
    numeric: false,
    disablePadding: false,
    label: "Mark Event",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Event Type",
  },
  {
    id: "flirType",
    numeric: false,
    disablePadding: false,
    label: "Flir Type",
  },
  {
    id: "ppm",
    numeric: false,
    disablePadding: false,
    label: "ppm",
  },
  {
    id: "grH",
    numeric: false,
    disablePadding: false,
    label: "gr/h",
  },
  {
    id: "ltMin",
    numeric: false,
    disablePadding: false,
    label: "lt/min",
  },
  {
    id: "leak",
    numeric: false,
    disablePadding: false,
    label: "Leak captured",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: false,
    label: "Comments",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <b>
              <TableSortLabel
                sx={{
                  "&.MuiTableSortLabel-root": {
                    ml: 2,
                  },
                  "& .MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  objectId,
  setSeeEventsTable,
  setMarkedEvent,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/operation?` +
          `objectId=${objectId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const res = await result.data;
      const events = createRows(res);

      setRows(events);
      if (events.length > 0) setSeeEventsTable(true);
    };
    fetchData();
  }, [objectId, setSeeEventsTable, token]);

  const createRows = (events) => {
    return events.map((event) => {
      if (event.leak !== null) {
        const ppm = event.leak.ppm;
        const grH = event.leak.grH;
        const ltMin = event.leak.ltMin;
        let operation = {
          id: event.id,
          type: "Leak",
          flirType: "-",
          date: event.date,
          ppm: ppm !== null ? ppm : "-",
          grH: grH !== null ? grH : "-",
          ltMin: ltMin !== null ? ltMin : "-",
          leak: event.leak.leak,
          comments: event.leak.comments,
        };
        return operation;
      }
      if (event.Reparation !== null) {
        let operation = {
          id: event.id,
          type: "Repair",
          flirType: "-",
          date: event.date,
          ppm: "-",
          grH: "-",
          ltMin: "-",
          leak: false,
          comments: event.Reparation.comments,
        };
        return operation;
      }
      if (event.inspectionFlir !== null) {
        const { flirVideos } = event.inspectionFlir;

        const { flirVideoProcessed } = flirVideos[0];
        let ppm = null;
        let grh = null;
        let ltMin = null;
        flirVideoProcessed.forEach((item) => {
          if (item.typeId === 1) ppm = item.value;
          if (item.typeId === 2) grh = item.value;
          if (item.typeId === 3) ltMin = item.value;
        });

        let operation = {
          id: event.id,
          type: "Flir",
          flirType: event.inspectionFlir.type.type,
          date: event.date,
          ppm: ppm !== null ? ppm : "-",
          grH: grh !== null ? grh : "-",
          ltMin: ltMin !== null ? ltMin : "-",
          leak: event.inspectionFlir.leak,
          comments: event.inspectionFlir.comments,
        };
        return operation;
      }
      if (event.inspectionSniffer !== null) {
        const ppm = event.inspectionSniffer.ppm;
        const grH = event.inspectionSniffer.grH;
        const ltMin = event.inspectionSniffer.ltMin;
        let operation = {
          id: event.id,
          type: "Inspection",
          flirType: "-",
          date: event.date,
          ppm: ppm !== 0 ? ppm : "-",
          grH: grH !== 0 ? grH : "-",
          ltMin: ltMin !== 0 ? ltMin : "-",
          leak: event.inspectionSniffer.leak,
          comments: event.inspectionSniffer.comments,
        };
        return operation;
      }
      return null;
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleOpenOperation = (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} fromObject={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
  };

  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 15);
  };

  const handleMark = (id) => {
    setMarkedEvent(id);
    setTimeout(() => {
      setMarkedEvent("");
    }, 5000);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              "& th": {
                color: "#000000",
                backgroundColor: "#9bc4cb",
              },
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#F7F4F3" : null,
                      }}
                    >
                      <TableCell id={labelId} align="center">
                        <ButtonOpenOperation
                          onClick={() => handleOpenOperation(row.id)}
                        >
                          {row.id}
                        </ButtonOpenOperation>
                        <OpenInNewIcon
                          onClick={() => handleOpenOperation(row.id)}
                          sx={{
                            ml: 1,
                            verticalAlign: "inherit",
                            pt: 0.3,
                            cursor: "pointer",
                            color: "#0E4D45",
                          }}
                        ></OpenInNewIcon>
                      </TableCell>
                      <TableCell align="center">
                        <GeneralButton onClick={() => handleMark(row.id)}>
                          Flash
                        </GeneralButton>
                      </TableCell>
                      <TableCell align="center">
                        {row.type === "Inspection" ? <p>Sketch</p> : row.type}
                      </TableCell>
                      <TableCell align="center">{row.flirType}</TableCell>
                      <TableCell align="center">{row.ppm}</TableCell>
                      <TableCell align="center">{row.grH}</TableCell>
                      <TableCell align="center">{row.ltMin}</TableCell>
                      <TableCell align="center">
                        {row.leak ? "yes" : "no"}
                      </TableCell>
                      <TableCell align="center">
                        {isoDateToString(row.date)}
                      </TableCell>
                      <TableCell align="center">{row.comments}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 8, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
