import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .input {
    width: 100%;
    border: 1px soli;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  .buttons-views{
    border: none;
  }
  .button-delete:hover{
    color: red;
  }

`;

export const ViewsTitle = styled.h2`
  margin-bottom: "10px";
  font-weight: "bold";
  color: "#525151";
`;
