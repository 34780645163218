import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import { useSWRConfig } from "swr";
import {
  GalleryContainer,
  GalleryContainerNoThumbnails,
  CustomImageGalleyItem,
} from "./PhotoGalleryStyle";
import { SECOND_COLOR } from "utils/const";
import saveRotationImage from "helpers/photoGallery/saveRotationImage";
import deleteImages from "helpers/photoGallery/deleteImages";

const PhotoGalley = ({ photos, ownId, state, mongoId, type, uploadImages }) => {
  const [files, setFiles] = useState([]);
  const [iconSave, setIconSave] = useState({
    show: false,
    photoId: null,
  });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState({
    type: "",
    index: 0,
  });

  const items = photos
    .map((photo) => {
      return {
        id: photo?.id || photo._id,
        original: photo?.url || photo.urlPhoto || photo,
        thumbnail: photo?.url || photo.urlPhoto || photo,
        rotation: photo?.rotation,
        type: photo?.operationId
          ? "operation"
          : photo?.pointEventId
          ? "pointEventPg"
          : photo?.pointComponentId
          ? "pointComponentPg"
          : photo?._id && type === "component"
          ? "pointComponentMg"
          : photo?._id && type === "event"
          ? "pointEventMg"
          : photo?._id && type === "object"
          ? "pointObjectMg"
          : "object",
      };
    })
    ?.sort((a, b) => a.id - b.id);

  const { mutate } = useSWRConfig();

  useEffect(() => {
    setFiles(items);
  }, [photos]);

  const handlerRotation = async (photo) => {
    setFiles((currentFiles) => {
      return currentFiles.map((currentFile) => {
        if (currentFile.id === photo.id)
          return {
            ...currentFile,
            rotation: currentFile.rotation + 90,
          };
        return currentFile;
      });
    });
    setIconSave({ show: true, photoId: photo.id });
  };

  const handleSaveRotation = async (item) => {
    saveRotationImage(
      files,
      uploadImages,
      setCurrentIndex,
      photos,
      item,
      mongoId
    );
    setIconSave({ show: false, photoId: item.id });
  };

  const handleOpenDialogDelete = (item) => {
    setOpenDialogDelete(!openDialogDelete);
    setCurrentPhoto(item);
  };

  const handleDelete = async () => {
    setFiles((files) => files.filter((item) => item?.id !== currentPhoto?.id));
    deleteImages(currentPhoto, uploadImages, mongoId, ownId, mutate);
    handleOpenDialogDelete();
  };

  const _imageGalleryRenderer = (item) => {
    return (
      <CustomImageGalleyItem>
        {
          <img
            className="image-gallery-image"
            src={item.original}
            style={{
              transform: `rotate(${
                files.filter((rotation) => rotation.id === item.id)[0]
                  ?.rotation || 0
              }deg)`,
            }}
            alt=""
          ></img>
        }
        <div className="wrapper-buttons">
          <button className="button" onClick={() => handlerRotation(item)}>
            <RotateRightIcon
              sx={{
                width: "40px",
                height: "40px",
                color: "#ffffff",
              }}
            />
          </button>
          {iconSave.photoId === item.id && iconSave.show ? (
            <button className="button" onClick={() => handleSaveRotation(item)}>
              <CheckCircleIcon
                sx={{
                  width: "40px",
                  height: "40px",
                  color: `${SECOND_COLOR}`,
                }}
              />
            </button>
          ) : (
            <></>
          )}
          <button
            className="button"
            onClick={() => handleOpenDialogDelete(item)}
          >
            <DeleteIcon
              sx={{
                width: "40px",
                height: "40px",
                color: "#e64b4b",
              }}
            />
          </button>
        </div>
      </CustomImageGalleyItem>
    );
  };

  const _thumbnailGalleryRender = (item) => {
    return (
      <img
        className="image-gallery-thumbnail"
        src={item.original}
        style={{
          width: "100px",
          height: "100px",
          objectFit: "cover",
          transform: `rotate(${
            files.filter((file) => file.id === item.id)[0]?.rotation || 0
          }deg)`,
        }}
        alt=""
      ></img>
    );
  };

  return (
    <>
      {state === false && photos.length === files.length ? (
        <GalleryContainerNoThumbnails>
          <ImageGallery
            className="image-gallery-description"
            items={items}
            showBullets={true}
            showPlayButton={false}
            showThumbnails={state}
            renderThumbInner={_thumbnailGalleryRender}
            renderItem={_imageGalleryRenderer}
          />
        </GalleryContainerNoThumbnails>
      ) : (
        files.length > 0 &&
        photos.length === files.length && (
          <GalleryContainer>
            {currentIndex.type === "" || currentIndex.type === "object" ? (
              <ImageGallery
                className="image-gallery-description"
                items={items}
                showBullets={true}
                startIndex={currentIndex.index}
                showPlayButton={false}
                renderItem={_imageGalleryRenderer}
                showThumbnails={state}
                renderThumbInner={_thumbnailGalleryRender}
                thumbnailPosition={"left"}
                showIndex={true}
              />
            ) : (
              <ImageGallery
                className="image-gallery-description"
                items={items}
                showBullets={true}
                startIndex={currentIndex.index}
                showPlayButton={false}
                renderItem={_imageGalleryRenderer}
                showThumbnails={state}
                renderThumbInner={_thumbnailGalleryRender}
                thumbnailPosition={"left"}
                showIndex={true}
              />
            )}
          </GalleryContainer>
        )
      )}
      <OpenDialog
        setOpenDialog={setOpenDialogDelete}
        openDialog={openDialogDelete}
        execute={handleDelete}
        content={{
          title: "Delete file",
          description: "Are you sure you want to delete this file ?",
          agree: "Delete",
          disagree: "Cancel",
        }}
      />
    </>
  );
};

export default PhotoGalley;
