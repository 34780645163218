import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Noto Sans', sans-serif;
    }

html {
  font-size: 62.5%;
}

p {
    font-size:1.4rem;
}

main {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    padding: 40px;
    font-family: 'Montserrat', sans-serif;
    }

body {
    background: #ffffff;
    font-family: 'Montserrat', sans-serif;
}
.ML__keyboard{
    --keyboard-zindex: 3000;
    --keycap-max-width: 200px;
    --keyboard-toolbar-font-size: 2.5rem;
    --keycap-gap: 5px;
    --keycap-border: 1px solid;
}
`;
