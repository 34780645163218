import styled from "styled-components";
import { PRIMARY_COLOR, RED_COLOR, WHITE_COLOR } from "utils/const";

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;

  .required {
    margin: 0 3px;
    color: ${RED_COLOR};
  }

  .label {
    margin-bottom: 5px;
  }

  .select {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};;
  }
`;
