const deleteDuplicateComponents = (components) => {
  return components
    .filter(
      (object, index, self) =>
        index ===
        self.findIndex(
          (t) => t.pointTypeComponentId === object.pointTypeComponentId
        )
    )
    .map((item) => item.pointTypeComponent);
};

export default deleteDuplicateComponents;
