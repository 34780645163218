const saveObject = async (body, endPoint) => {
  try {
    let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/objects`;
    if (endPoint)
      url = `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${endPoint}`;
    const token = localStorage.getItem("token");
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default saveObject;
