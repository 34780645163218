import useSwr from "swr";
import { config } from "config.js";

/**
 * Fetches point events by ID.
 *
 * @param {string} id - The ID of the point events.
 * @return {Object} - An object containing the fetched data and any error that occurred.
 */
const FetchPointEventsById = (id) => {
  const { data, error } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-events/all?id=${id}`
  );
  return { data, error };
};

export default FetchPointEventsById;
