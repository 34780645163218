import axios from "axios";

const updateInspectionFlir = async (body, id) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/inspection-flir/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default updateInspectionFlir;
