import {
  setShowEventsByLibrary,
  showEventsByType,
} from "redux/actions/digitalTwin";
import { PRIMARY_COLOR } from "utils/const";
import findPointLibraryEvents from "services/findPointLibraryEvents";
import deleteDuplicateEvents from "./deleteDuplicateEvents";

const setDispatchFilterNewEventsByType = async (events, dispatch) => {
  const typeEvents = await deleteDuplicateEvents(events);
  const pointLibraryEvents = await findPointLibraryEvents();

  const dataFilterEventsLibrary = [];

  await typeEvents.forEach((event) => {
    pointLibraryEvents.forEach((libraryEvent) => {
      if (event.pointLibraryEventId === libraryEvent.id) {
        const eventLibrary = {
          libraryId: event.pointLibraryEventId,
          name: libraryEvent.name,
          state: true,
          visible: false,
          color: `${PRIMARY_COLOR}`,
        };
        dataFilterEventsLibrary.push(eventLibrary);
      }
    });
  });

  await dispatch(
    setShowEventsByLibrary(
      dataFilterEventsLibrary.length !== 0 ? dataFilterEventsLibrary : []
    )
  );
  const dataFilterEventsByType = [];
  await typeEvents.forEach((typeEvent) => {
    const eventType = {
      id: typeEvent.id,
      name: typeEvent.name,
      libraryId: typeEvent.pointLibraryEventId,
      state: true,
      visible: false,
      icon: typeEvent.icon,
    };
    dataFilterEventsByType.push(eventType);
  });

  await dispatch(
    showEventsByType(
      dataFilterEventsByType.length !== 0 ? dataFilterEventsByType : []
    )
  );
};

export default setDispatchFilterNewEventsByType;
