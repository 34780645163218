import { useEffect, useMemo, useState } from "react";
// Style
import { GeneralButton } from "components/ImageGallery/listEventTableStyle.jsx";
// Config
import { config } from "config.js";
// Hooks
import useSwr from "swr";

function useCreateEventsObjets({ objectId }) {
  const [rows, setRows] = useState("");
  const [seePointEventsTable, setSeePointEventsTable] = useState(false);
  const [markedEvent, setMarkedEvent] = useState("");

  const urlDynamicEvents = `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${objectId}`;
  const { data: dataDynamic, error: errorDataDynamics } =
    useSwr(urlDynamicEvents);

  const dataDynamicEvents = useMemo(() => {
    return dataDynamic && !errorDataDynamics ? dataDynamic : [];
  }, [dataDynamic, errorDataDynamics]);

  const handleMark = (id) => {
    setMarkedEvent(id);
    setTimeout(() => {
      setMarkedEvent("");
    }, 5000);
  };

  useEffect(() => {
    const rows = dataDynamicEvents.map((item) => {
      return {
        id: item.id,
        name: item.pointTypeEvent.name,
        date: item.date,
        longitude: item.locationOperation.longitude,
        latitude: item.locationOperation.latitude,
        createdAt: item.createdAt,
        markPoint: (
          <GeneralButton onClick={() => handleMark(item.id)}>
            Flash
          </GeneralButton>
        ),
      };
    });
    if (rows.length > 0) setSeePointEventsTable(true);
    setRows(rows);
  }, [dataDynamicEvents]);

  return {
    eventRows: rows,
    dataDynamicEvents,
    seePointEventsTable,
    markedEvent,
    setMarkedEvent,
  };
}

export default useCreateEventsObjets;
