import styled from "styled-components";

export const HubCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  margin: 0 20px;
  min-width: 295px;
  padding: 30px;
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media (max-width: 350px) {
    width: 200px;
  }

  .card-title {
    font-size: 1.6rem;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .card-image {
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
  }

  .card-descrition {
    font-size: 1.5rem;
    text-align: center;
  }
`;
