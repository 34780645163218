import styled from "styled-components";
import { WHITE_COLOR, PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

export const Title = styled("div")`
  font-size: 1.4rem;
  background-color: ${WHITE_COLOR};
  margin: 20px 0;
`;

export const ButtonStyle = styled("div")`
  background-color: ${WHITE_COLOR};
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  button {
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: ${SECOND_COLOR};
    }
  }
`;
