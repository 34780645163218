// Styles
import { ConfigAdminContainer } from "../configAdminStyles";

import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import { useState } from "react";
import ObjectElements from "./ObjectElements";

export default function TypeElementsGhg() {
  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  return (
    <div>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={executeDialog}
        content={contentDialog}
      />
      <ConfigAdminContainer>
        <ObjectElements
          setOpenDialog={setOpenDialog}
          setContentDialog={setContentDialog}
        />
      </ConfigAdminContainer>
    </div>
  );
}
