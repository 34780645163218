import styled from "styled-components";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { OBJECT_CLUSTER_COLOR, ICON_GREEN_COLOR } from "utils/const";
import neuronDecimetrix from "images/neuron_mag.png";

export const NamePlateMarker = styled.button`
  position: relative;
  border: none;
  background: none;
  cursor: ${(props) => (props.drawInMap ? "none" : "pointer")};

  img {
    width: 40px;
    filter: ${(props) => (props.filter ? "none" : "grayscale(100%)")};
    z-index: 2;
  }

  img:hover {
    opacity: ${(props) => (props.drawInMap ? "0" : "1")};
  }

  .selected {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${OBJECT_CLUSTER_COLOR};
    border-radius: 50%;
    z-index: -1;
    animation: pulse 1s infinite;
    display: ${(props) => (props.flyTo ? "block" : "none")};
  }

  .qaqc {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    height: 40px;
    background: ${ICON_GREEN_COLOR};
    border-radius: 50%;
    z-index: -1;
    display: ${(props) => (props.qaqc ? "block" : "none")};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      opacity: 0.6;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 0.6rem;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #0e4d45;
    color: white;
  }
`;

export const ButtonFilter = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 19.3rem;

  right: 10px;
  z-index: 10;
  background-color: #f9f9f9;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 5px;
`;

export const NueronTools = styled("div")`
  position: absolute;
  top: ${(props) => props.top}px;
  right: 30px;
  z-index: 100;
  width: 50px;
  height: 50px;
  background-image: url(${neuronDecimetrix});
  background-size: cover;
  display: ${(props) => (props.display ? "block" : "none")};
`;

export const ButtonInformation = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 399px;
  right: 9px;
  z-index: 8;
  background-color: #f9f9f9;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px 4px;

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: #000;
      color: #fff;
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;
export const ButtonExtend = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 440px;
  right: 9px;
  z-index: 8;
  background-color: #f9f9f9;
  color: black;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px 4px;

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-70%, 10px);
      padding: 0.5rem;
      background-color: #000;
      color: #fff;
      text-align: center;
      z-index: 999;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;

export const CustomizeToggleButtonGroup = styled(ToggleButtonGroup)`
  @media (max-width: 480px) {
    padding: 1%;
    margin-right: 2%;
  }
`;
