import countEventsByObjectId from "services/countEventsByObjectId";
import { useEffect, useState } from "react";
const useCountEventsToOneObject = ({ objectId, setShowDeleteButton }) => {
  const [amountEvents, setAmountEvents] = useState();

  useEffect(() => {
    countEventsByObjectId({ objectId })
      .then((response) => {
        setAmountEvents(response);
        if (
          response.inspectionSniffer !== 0 ||
          response.inspectionFlir !== 0 ||
          response.leaks !== 0 ||
          response.reperations !== 0 ||
          response.dynamics?.length !== 0
        ) {
          setShowDeleteButton(false);
        }
      })
      .catch((err) => console.error(err));
  }, [objectId, setShowDeleteButton]);

  return { amountEvents };
};

export default useCountEventsToOneObject;
