import useGet from "hooks/useFetch";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, customStyles, customStylesLibraries } from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import TableObjects from "./TableObjects";
import TableEvents from "./TableEvents";
import { writeFile, utils } from "xlsx";
import GenericSplitButton from "components/Buttons/GenericSplitButton";
import { AiOutlineClose } from "react-icons/ai";
import {
  setStateObjectFilterTable,
  setStateEventFilterTable,
  setTableDetailsForward,
  showEventsByInspectionType,
  showEventsByType,
  showOperationsByType,
  setFilterComponent,
  setShowDataComponentsByType,
  setShowDataComponentsLibrary,
  setShowDataComponents,
  setShowEvents,
  setShowEventsByLibrary,
} from "redux/actions/digitalTwin";
import Select from "react-select";
import {
  setComponentsFilterTable,
  setDataEventFilterTable,
  setObjectsFilterTable,
  setShowDataObjects,
  showMarkerByElementWithMedia,
  showMarkerseByTypeElement,
  showMarkerseByTypeObject,
} from "redux/actions/admin";
import TableComponents from "./TableComponents";
import { PRIMARY_COLOR } from "utils/const";
const events = {
  1: "sketch",
  2: "inspectionFlir",
  3: "leak",
  4: "Repair",
};
const options = [
  "Export to",
  "Full xslx",
  "Full csv",
  "Filtered xlsx",
  "Filtered csv",
];

function setFiltersEventsToTrue(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      obj[prop] = true;
    }
  }
  return obj;
}

function setFilterTrue(component) {
  const components = component.map((elm) => {
    return { ...elm, color: PRIMARY_COLOR, state: true };
  });
  return components;
}

export default function PolygonTable({
  setSeePolygonTable,
  state = false,
  handleDrawerClose,
  handleDrawerOpen,
}) {
  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );
  const dataEventPolygon = useSelector(
    (state) => state.adminReducer.dataEventPolygon
  );
  const dataComponentPolygon = useSelector(
    (state) => state.adminReducer.dataComponentPolygon
  );

  const [rows, setRows] = useState([]);
  const [tableVisibility, setTableVisibility] = useState(true);
  const [attributte, setAttribute] = useState("");
  const [label, setLabel] = useState("all");
  const [labels, setLabels] = useState({});
  const [pointLibraries] = useGet("api/v1/point-libraries");
  const [eventLibraries] = useGet("api/v1/point-library-events");
  const [componentsLibraries] = useGet("api/v1/point-library-components");

  const [pointLibrary, setPointLibrary] = useState(null);
  const [pointComponent, setPointComponent] = useState(null);
  const [currentNameObjects, setCurrentNameObjects] = useState(null);
  const [currentNameEvents, setCurrentNameEvents] = useState(null);
  const [currentNameComponents, setCurrentNameComponents] = useState(null);
  const [typeEvent, setTypeEvent] = useState("1");
  const [typeOfTable, setTypeOfTable] = useState("objects");

  const dispatch = useDispatch();

  const zIndexTable = useSelector(
    (state) => state.digitalTwinReducer.setTableDetailsForward
  );
  const rowNumber = useSelector((state) => state.digitalTwinReducer.rowNum);
  const allFilterTableRows = useSelector(
    (state) => state.digitalTwinReducer.filterTableRows
  );
  const allFullTableRows = useSelector(
    (state) => state.digitalTwinReducer.fullTableRows
  );

  const handleTypeEvent = (typeEvent) => {
    setCurrentNameEvents(typeEvent.label);
    setTypeEvent(typeEvent.value);
    setLabel(() => "all");
    setTableVisibility(true);
  };

  const handlePointLibrary = (pointLibrary) => {
    setPointLibrary(parseInt(pointLibrary.value));
    setCurrentNameObjects(pointLibrary.label);
    setTableVisibility(true);
  };

  const handlePointComponent = (pointComponent) => {
    setPointComponent(parseInt(pointComponent.value));
    setCurrentNameComponents(pointComponent.label);
    setTableVisibility(true);
  };

  // const handleAttribute = (e) => {
  //   setAttribute(e.target.value);
  // };

  // const handleLabel = (e) => {
  //   setLabel(e.target.value);
  // };

  const handleClickTable = () => {
    dispatch(setTableDetailsForward(1000));
  };

  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getFileName = () => {
    const currentLibrary = pointLibraries?.find(
      (point) => parseInt(point?.id) === parseInt(pointLibrary)
    );
    const event = events[typeEvent];
    const name = typeOfTable === "objects" ? currentLibrary.name : event;
    return `${typeOfTable}-${name}-${getCurrentDate()}`;
  };

  const downloadTable = (extension) => {
    if (extension !== "Export to") {
      const rowsWithoutFlyTo =
        extension === "Full xslx" || extension === "Full csv"
          ? allFullTableRows.map((row) => {
              const { flyTo, markPoint, ...rest } = row.original;
              return rest;
            })
          : rows.map((row) => {
              const { flyTo, markPoint, ...rest } = row;
              return rest;
            });
      const worksheet = utils.json_to_sheet([...rowsWithoutFlyTo]);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Data");
      writeFile(
        workbook,
        `Decimetrix-${getFileName()}.${
          extension === "Full xslx" || extension === "Filtered xlsx"
            ? "xlsx"
            : extension === "Filtered csv" || extension === "Full csv"
            ? "csv"
            : extension
        }`,
        { compression: true }
      );
    }
  };

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );
  const showOperationByType = useSelector(
    (state) => state.digitalTwinReducer.showOperationsByType
  );
  const showAllEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );
  const showEventsByLibrary = useSelector(
    (state) => state.digitalTwinReducer.showEventsByLibrary
  );

  const showMarkersByLibrary = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );
  const showByElementWithMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );

  const showDataComponentsByType = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsByType
  );
  const showDataComponentsLibrary = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsLibrary
  );

  const showDataComponents = useSelector(
    (state) => state.digitalTwinReducer.showDataComponents
  );

  const stateButtonTable = useSelector(
    (state) => state.digitalTwinReducer.stateFilterButtonTable
  );

  const [eventByInspectionType, setEventByInspectionType] = useState([]);
  const [operationByType, setOperationByType] = useState([]);
  const [eventByType, setEventByType] = useState([]);
  const [eventsByLibrary, setAllEventsByLibrary] = useState([]);

  const [markersByLibrary, setMarkersByLibrary] = useState([]);
  const [markersByTypeElement, setMarkersByTypeElement] = useState([]);
  const [markerByElementWithMedia, setMarkerByElementWithMedia] = useState();

  const [dataComponentsByType, setDataComponentsByType] = useState([]);
  const [dataComponentsLibrary, setDataComponentsLibrary] = useState([]);
  const [dataComponents, setDataComponents] = useState([]);

  useEffect(() => {
    setEventByInspectionType(showEventByInspectionType);
    setOperationByType(showOperationByType);
    setEventByType(showAllEventsByType);
    setAllEventsByLibrary(showEventsByLibrary);

    setMarkersByLibrary(showMarkersByLibrary);
    setMarkersByTypeElement(showMarkersByTypeElement);
    setMarkerByElementWithMedia(showByElementWithMedia);

    setDataComponentsByType(showDataComponentsByType);
    setDataComponentsLibrary(showDataComponentsLibrary);
    setDataComponents(showDataComponents);
  }, []);

  const handleSetViewMap = () => {
    if (typeOfTable === "objects") {
      dispatch(setObjectsFilterTable([]));
      const env1 = setFiltersEventsToTrue(eventByInspectionType);
      const env2 = setFiltersEventsToTrue(operationByType);
      const env3 = setFilterTrue(eventByType);
      const env4 = setFilterTrue(eventsByLibrary);
      const componentsByType = setFilterTrue(dataComponentsByType);
      const componentsByLibrary = setFilterTrue(dataComponentsLibrary);
      dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(setStateObjectFilterTable(false));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
    }

    if (typeOfTable === "events") {
      dispatch(setDataEventFilterTable([]));
      const objects = setFilterTrue(markersByLibrary);
      const obj = setFilterTrue(markersByTypeElement);
      const componentsByType = setFilterTrue(dataComponentsByType);
      const componentsByLibrary = setFilterTrue(dataComponentsLibrary);
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(showMarkerByElementWithMedia(false));
      dispatch(setStateEventFilterTable(false));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
    }
    if (typeOfTable === "components") {
      dispatch(setComponentsFilterTable([]));
      const env1 = setFiltersEventsToTrue(eventByInspectionType);
      const env2 = setFiltersEventsToTrue(operationByType);
      const env3 = setFilterTrue(eventByType);
      const env4 = setFilterTrue(eventsByLibrary);
      const objects = setFilterTrue(markersByLibrary);
      const obj = setFilterTrue(markersByTypeElement);
      dispatch(setShowDataComponents({ ...dataComponents, state: true }));
      dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(setFilterComponent(true));
    }
  };
  const handleTypeOfTable = (typeOfTable) => {
    setTypeOfTable(typeOfTable.value);
    if (stateButtonTable) {
      handleSetViewMap();
    }
  };

  const handleClose = () => {
    handleDrawerOpen();
    setSeePolygonTable(false);
    if (stateButtonTable) {
      handleSetViewMap();
    }
  };

  let defaultValues = useMemo(() => {
    return [
      { value: 1, label: "Sketch" },
      { value: 2, label: "Inspection Flir" },
      { value: 3, label: "Leak" },
      { value: 4, label: "Repair" },
    ];
  }, []);

  useEffect(() => {
    const setLibraries = () => {
      eventLibraries?.forEach((eveLib) => {
        defaultValues.push({
          value: eveLib.id + 4,
          label: eveLib.name,
        });
      });
      setCurrentNameObjects(pointLibraries[0]?.name || null);
      setCurrentNameEvents(defaultValues[0]?.label);
      setCurrentNameComponents(componentsLibraries[0]?.name);
    };
    if (pointLibraries && eventLibraries && componentsLibraries) setLibraries();
  }, [eventLibraries, pointLibraries, defaultValues, componentsLibraries]);

  useEffect(() => {
    if (!pointLibrary && pointLibraries && !pointLibraries?.error) {
      setPointLibrary(parseInt(pointLibraries[0]?.id));
    }
  }, [pointLibraries, pointLibrary]);

  useEffect(() => {
    if (!typeEvent) {
      setTypeEvent(() => "1");
    }
  }, [typeEvent]);

  useEffect(() => {
    if (!pointComponent && componentsLibraries && !componentsLibraries?.error) {
      setPointComponent(parseInt(componentsLibraries[0]?.id));
    }
  }, [pointComponent, componentsLibraries]);

  useEffect(() => {
    if (typeOfTable === "objects") setTypeEvent(() => null);
    if (typeOfTable === "events") setPointLibrary(() => null);
    if (typeOfTable === "components") setPointComponent(() => null);
    setLabel(() => "all");
    setTableVisibility(true);
  }, [typeOfTable]);

  useEffect(() => {
    setRows(allFilterTableRows);
  }, [allFilterTableRows]);

  if (
    dataObjectsPolygon.length <= 0 &&
    dataEventPolygon.length <= 0 &&
    dataComponentPolygon.length <= 0
  )
    return <></>;

  return (
    <Container
      zIndex={zIndexTable}
      onClick={handleClickTable}
      height={state ? 100 : null}
    >
      <div className="head">
        <div className="head-right">
          {/* <h1 className="title">Region Details:</h1> */}
        </div>

        {/* <div className="head-right">
          <label htmlFor="search" className="title">
            Search:
          </label>
          <input
            id="search"
            type="text"
            className="search"
            placeholder={
              label === "dateUTC"
                ? "YYYY-MM-DD"
                : label === "localDate"
                ? "YYYY-MM-DD"
                : "Attribute"
            }
            value={attributte}
            onChange={handleAttribute}
          />
          <select
            name="type-table"
            className="select"
            onChange={handleLabel}
            value={label}
          >
            <option value="all">All</option>
            {Object.keys(labels).map((label, index) => {
              return (
                <option value={label} key={index}>
                  {labels[label]}
                </option>
              );
            })}
          </select>
        </div> */}

        <div className="head-right">
          <p className="title total">
            Total: <span className="bold">{rowNumber}</span>
          </p>

          <Select
            onChange={handleTypeOfTable}
            options={[
              { value: "objects", label: "Objects" },
              { value: "components", label: "Components" },
              { value: "events", label: "Events" },
            ]}
            value={typeOfTable}
            placeholder={
              typeOfTable.charAt(0).toUpperCase() + typeOfTable.slice(1) + "..."
            }
            styles={customStylesLibraries}
            menuPlacement="auto"
          />

          {typeOfTable === "objects" && (
            <Select
              onChange={handlePointLibrary}
              options={pointLibraries?.map((library) => {
                return {
                  value: library.id,
                  label: library.name,
                };
              })}
              value={pointLibrary}
              placeholder={currentNameObjects}
              styles={customStyles}
              menuPlacement="auto"
            />
          )}

          {typeOfTable === "events" && (
            <Select
              onChange={handleTypeEvent}
              options={defaultValues}
              value={typeEvent}
              placeholder={currentNameEvents}
              styles={customStyles}
              menuPlacement="auto"
            />
          )}

          {typeOfTable === "components" && (
            <Select
              onChange={handlePointComponent}
              options={componentsLibraries?.map((library) => {
                return {
                  value: library.id,
                  label: library.name,
                };
              })}
              value={pointComponent}
              placeholder={currentNameComponents}
              styles={customStyles}
              menuPlacement="auto"
            />
          )}

          <div className="button-container">
            <GenericSplitButton options={options} handleClick={downloadTable} />
          </div>

          {state ? (
            <></>
          ) : (
            <>
              <span
                className="close"
                onClick={() => setTableVisibility((current) => !current)}
              >
                {tableVisibility ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </span>
              <span className="close" onClick={handleClose}>
                <AiOutlineClose />
              </span>
            </>
          )}
        </div>
      </div>
      {typeOfTable === "objects" && (
        <TableObjects
          pointLibrary={pointLibrary}
          setRows={setRows}
          attributte={attributte}
          setLabels={setLabels}
          label={label}
          setTableVisibility={setTableVisibility}
          handleClose={handleClose}
          tableVisibility={tableVisibility}
          state={state}
          typeOfTable={typeOfTable}
          typeOfLibrary={currentNameObjects}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />
      )}

      {typeOfTable === "events" && (
        <TableEvents
          typeEvent={typeEvent}
          setRows={setRows}
          attributte={attributte}
          setLabels={setLabels}
          label={label}
          setTableVisibility={setTableVisibility}
          handleClose={handleClose}
          tableVisibility={tableVisibility}
          state={state}
          typeOfTable={typeOfTable}
          typeOfLibrary={currentNameEvents}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />
      )}

      {typeOfTable === "components" && (
        <TableComponents
          pointLibrary={pointComponent}
          setRows={setRows}
          attributte={attributte}
          setLabels={setLabels}
          label={label}
          setTableVisibility={setTableVisibility}
          handleClose={handleClose}
          tableVisibility={tableVisibility}
          state={state}
          typeOfTable={typeOfTable}
          typeOfLibrary={currentNameComponents}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />
      )}
    </Container>
  );
}
