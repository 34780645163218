import styled from "styled-components";

export const Img = styled.img`
  width: 200px;
  height: auto;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const StyledP = styled.p`
  @media (max-width: 600px) {
    display: none;
  }
`;
