// React
import { useEffect, useState } from "react";

// Components
import TextInput from "../GenericInput";
import SelectInput from "components/Forms/SelectInput";
import CustomButton from "components/Buttons/CustomButton";
// Services
import savePointEventApiPg, {
  createPointEvent,
  savePointEvenRelation,
} from "services/savePointEvent";
import uploadFileToS3 from "services/uploadFileToS3sdk";
// Hooks
import operationsExp from "hooks/useOperations.jsx";
// Dependencies
import { useForm } from "react-hook-form";
import useSwr from "swr";
import { useSWRConfig } from "swr";

// Config
import { config } from "config.js";
// styles
import { Currency, StepSection } from "./DynamicEventStyles";
import { Form } from "../SaveObject/DynamicFieldsStyle";
import { ContentButton } from "../../Buttons/CustomButtonStyle";

export default function DynamicEvent({
  feature,
  objectId = null,
  closeModal,
  pointTypeEventId,
  pointComponentId = null,
  pointEventId = null,
}) {
  const [form, setForm] = useState({});
  const [file, setFile] = useState();
  const [keyFieldFile, setKeyFieldFile] = useState();
  const [loading, setLoading] = useState(false);

  const { mutate } = useSWRConfig();

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // Find type events to type elements
  const urlFieldTypeEvents = `${config.URL_BACKEND_PG}api/v1/point-type-events?id=${pointTypeEventId}`;
  const { data: dataFieldsTypeEvents } = useSwr(urlFieldTypeEvents);
  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`
  );
  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setForm({});
    dataFieldsTypeEvents &&
      dataFieldsTypeEvents[0]?.fields?.forEach((field) => {
        setForm((current) => ({
          ...current,
          [field.name]: "",
        }));
      });
  }, [dataFieldsTypeEvents]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    if (type === "file") {
      setKeyFieldFile(name);
      const file = e.target.files[0];
      setFile(file);
    }

    setForm({ ...form, [name]: value });
  };

  const handleUploadFile = async () => {
    if (file) return await uploadFileToS3(file, 8);
    return null;
  };

  const createBodyOfEvent = async () => {
    const newForm = {};
    Object.keys(form).forEach((key) => {
      const value = form[key];
      if (value !== "") newForm[key] = value;
    });
    const urlFile = await handleUploadFile();
    if (urlFile) newForm[keyFieldFile] = urlFile;
    return newForm;
  };

  const handleSave = async () => {
    setLoading(true);
    let elmt = "";
    const numericFields = dataFieldsTypeEvents[0]?.fields
      ?.map((field) => {
        if (field?.alias?.length >= 0) {
          elmt = document.getElementById(field.alias);
        } else {
          elmt = document.getElementById(field.name);
        }
        if (elmt) {
          field.value = elmt.value;
          return field;
        }
        return null;
      })
      .filter((it) => it !== null);

    const expFields = dataFieldsTypeEvents[0]?.fields?.filter(
      (field) => field.type === "operation"
    );
    const solvedExp = operationsExp(expFields, numericFields);
    const newForm = await createBodyOfEvent();
    const event = createPointEvent(
      feature,
      newForm,
      objectId,
      pointComponentId,
      pointTypeEventId
    );

    if (solvedExp.length > 0)
      solvedExp.forEach((fld) => {
        JSON.stringify(fld);
        event.attributes.push({
          name: fld.name,
          value: `${fld.value}`,
          operation: fld.operation,
          alias: fld.alias,
        });
      });
    const { data: newPointEvent } = await savePointEventApiPg(event);

    // Update all point events
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-events?adminCompanyId=${adminCompanyId}`
    );
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-events/components?adminCompanyId=${adminCompanyId}`
    );
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-events/relation?adminCompanyId=${adminCompanyId}`
    );
    if (objectId) {
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${objectId}`
      );
    }
    if (pointComponentId) {
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/components?pointComponentId=${pointComponentId}`
      );
    }
    if (pointEventId) {
      await savePointEvenRelation(pointEventId, newPointEvent.id);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/all?id=${pointEventId}`
      );
    }
    setLoading(false);
    closeModal(false);
  };

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      {dataFieldsTypeEvents &&
        Object.keys(form).length > 0 &&
        dataFieldsTypeEvents[0]?.fields?.map((field, index) => {
          if (field.type === "select") {
            if (form[field.name] === "") {
              form[field.name] = field.options[0];
            }
            return (
              <div key={index} onChange={(e) => handleChange(e)}>
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: field.options,
                    required: field.required,
                    type: field.type,
                  }}
                  register={register}
                />
              </div>
            );
          }
          if (field.type === "check") {
            return (
              <div onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    required: field.required,
                    type: field.type,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "file") {
            return (
              <div onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    required: field.required,
                    type: field.type,
                    possible: field.possible,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "range") {
            if (form[field.name] === "") {
              form[field.name] = field.min.toString();
            }
            const step =
              field.options.length !== 0 ? parseInt(field.options[0]) : 1;
            return (
              <div onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    required: field.required,
                    type: field.type,
                    step: step,
                    max: field.max,
                    min: field.min,
                  }}
                  register={register}
                  errors={errors}
                />
                <StepSection>
                  <b>Step: {step}</b>
                  <b>{form[field.name]}</b>
                </StepSection>{" "}
              </div>
            );
          }
          if (field.type === "currency") {
            if (!form.Currency) {
              setForm({ ...form, Currency: field.possible[0] });
            }
            return (
              <Currency onChange={(e) => handleChange(e)}>
                <TextInput
                  key={`${index + 1}`}
                  field={{
                    name: field.name,
                    required: field.required,
                    type: field.type,
                  }}
                  register={register}
                  errors={errors}
                />
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    possible: field.possible,
                    required: field.required,
                    type: field.type,
                  }}
                  register={register}
                />
              </Currency>
            );
          }
          if (field.type === "delegate") {
            if (form[field.name] === "" && operatorsAdmin && !errorOperators) {
              form[
                field.name
              ] = `${allOperators.operators[0].firstName} ${allOperators.operators[0].firstLastName}`;
            }
            return (
              <div onChange={(e) => handleChange(e)}>
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: allOperators.operators?.map((operator) => {
                      return `${operator?.firstName} ${operator?.firstLastName}`;
                    }),
                    required: field.required,
                    type: field.type,
                  }}
                  register={register}
                />
              </div>
            );
          }
          if (field.type === "operation") {
            return (
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />
            );
          }
          if (field.type === "captureMedia") return null;
          return (
            <div onChange={(e) => handleChange(e)}>
              <TextInput
                key={index}
                field={{
                  name: field.alias ? field.alias : field.name,
                  required: field.required,
                  type: field.type,
                  possible: field.possible,
                }}
                register={register}
                errors={errors}
              />
            </div>
          );
        })}
      <ContentButton style={{ margin: "-10px" }}>
        <CustomButton type={"submit"} isLoad={loading} text={"Save"} />
      </ContentButton>
    </Form>
  );
}
