import {
  CustomizedAccordion,
  FilterTabStyles,
  ContentFilterItem,
  useStyles,
  ContentFilterItemLabel,
} from "./AccordionStyles";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { addDays, addMonths, format, parse } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setFilterObjectsByData } from "redux/actions";
import DatePicker from "./DatePicker";
import { RED_COLOR } from "utils/const";
import dayjs from "dayjs";
import CustomButton from "components/Buttons/CustomButton";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function FilterByDate() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );
  const objectDates = useSelector((state) => state.adminReducer.dataObjects);

  const today = format(new Date(), "yyyy-MM-dd");
  const [localEndDate, setLocalEndDate] = useState("");
  const [localStartDate, setLocalStartDate] = useState(startDate);

  const handleChangeStartDate = (date) => {
    setLocalStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setLocalEndDate(date);
  };

  const handleRestartDates = () => {
    objectDates.sort(
      (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
    );
    setLocalEndDate(today);
    setLocalStartDate(() =>
      dayjs(objectDates?.at(0)?.date).format("YYYY-MM-DD")
    );
  };

  useEffect(() => {
    if (endDate && localEndDate === "") {
      const endDateParsed = parse(endDate, "yyyy-MM-dd", new Date());
      const currentEndDate = addDays(endDateParsed, -1);
      setLocalEndDate(() => format(currentEndDate, "yyyy-MM-dd"));
    } else if (localEndDate === "") {
      setLocalEndDate(() => today);
    }
  }, [endDate, localEndDate, today]);

  useEffect(() => {
    if (!localStartDate) {
      const parseLocalToday = parse(today, "yyyy-MM-dd", new Date());
      const currentStartDate = addMonths(parseLocalToday, -1);
      setLocalStartDate(() => format(currentStartDate, "yyyy-MM-dd"));
    }
  }, [today, localStartDate]);

  useEffect(() => {
    if (localEndDate === "") return;
    const globalEndData = addDays(
      parse(localEndDate, "yyyy-MM-dd", new Date()),
      1
    );
    dispatch(
      setFilterObjectsByData({
        endDate: format(globalEndData, "yyyy-MM-dd"),
        startDate: localStartDate,
      })
    );
  }, [localEndDate, localStartDate, dispatch]);

  return (
    <CustomizedAccordion
      classes={{
        content: classes.content,
        expanded: classes.expanded,
      }}
      defaultExpanded={true}
    >
      <ContentFilterItem>
        <Typography>Filter For Date</Typography>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
      </ContentFilterItem>
      <AccordionSummary>
        <FilterTabStyles>
          <ContentFilterItem className="date">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ContentFilterItemLabel>
                Start Date:
                <DatePicker
                  startdate={localStartDate}
                  enddate={localEndDate}
                  value={localStartDate}
                  changefunction={handleChangeStartDate}
                />
              </ContentFilterItemLabel>

              <ContentFilterItemLabel>
                End Date:
                <DatePicker
                  startdate={localStartDate}
                  enddate={localEndDate}
                  value={localEndDate}
                  changefunction={handleChangeEndDate}
                />
              </ContentFilterItemLabel>
            </LocalizationProvider>

            <ContentFilterItemLabel>
              <CustomButton
                primaryColor={RED_COLOR}
                text={"Show all"}
                onClick={handleRestartDates}
              />
            </ContentFilterItemLabel>
          </ContentFilterItem>
        </FilterTabStyles>
      </AccordionSummary>
    </CustomizedAccordion>
  );
}
