// Required modules
import NavBar from "components/Admin/NavBar/NavBar";
import GenericTable from "components/Tables/GenericTable";
import Form from "components/Forms/Form.jsx";

// Config admin class
import ConfigAdmin from "./ConfigAdmin.js";

// Hooks
import { useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableTypeLine,
  keyValuesTableTypeLine,
  initialValuesFormTypeLine,
} from "./dataConfigAdmin";

// Styles
import {
  ConfigAdminContainer,
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  SeparatorWithText,
  InformationShare,
  IconAdminConfigChangeColum,
  SelectContainerAdminConfig,
  LineContainerAdminConfig,
} from "./configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { BsChevronCompactUp } from "react-icons/bs";
import { ContainerForm } from "components/Forms/FormStyles";
import useGet from "hooks/useFetch.js";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <BsChevronCompactUp />
    </IconAdminConfigChangeColum>
  );
};

// To show current line aparence
const Line = (props) => {
  const { color, width, separator } = props;

  return (
    <LineContainerAdminConfig separator={separator} width={width} color={color}>
      <span className="text">Preview:</span>
      <div className="container">
        <span className="circle"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="circle"></span>
      </div>
    </LineContainerAdminConfig>
  );
};

export default function TypeLine() {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [libraries] = useGet("api/v1/line-libraries");
  const [library, setLibrary] = useState({});

  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormTypeLine);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [typeLineRow, setTypeLineRow] = useState([]);

  // Click  on type line into table
  const handleClickTypeLine = (id) => {
    configAdmin.handlerClickIntoTable(id);
  };

  // Change in library select
  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: libraries.find((library) => library.id === parseInt(e.target.value))
        ?.name,
    }));
  };

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableTypeLine);
    configAdmin.setKeyValuesTable(keyValuesTableTypeLine);
    configAdmin.setSetRows(setTypeLineRow);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/type-lines`);
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, []);

  // Set library default
  useEffect(() => {
    if (libraries) {
      setLibrary(() => libraries[0]);
    }
  }, [libraries]);

  // Set library in config admin
  useEffect(() => {
    configAdmin?.setEndpointFetch(
      `api/v1/type-lines/library/${parseInt(library?.id)}`
    );
    configAdmin?.setLibraryId(library?.id);
    configAdmin?.fetchData();
  }, [configAdmin, library?.id]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  return (
    <div>
      <NavBar linkLogo={"admin"} />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={executeDialog}
        content={contentDialog}
      />
      <ConfigAdminContainer>
        <RowAdminConfig>
          <ColumnAdminConfig>
            <SelectContainerAdminConfig>
              Existing Digital Twin Line(s)
              <select name="endPoint" id="endPoint" onChange={handleLibrary}>
                {libraries?.map((library) => (
                  <option key={library.id} value={library.id}>
                    Librería {library.name}
                  </option>
                ))}
              </select>
            </SelectContainerAdminConfig>
            {configAdmin && (
              <GenericTable
                headers={configAdmin.getHeadersTable()}
                rows={typeLineRow}
                keyValues={configAdmin.getKeyValuesTable()}
                handlerClick={handleClickTypeLine}
                style={{
                  width: 100,
                }}
                state={true}
              />
            )}
          </ColumnAdminConfig>
          <ColumnAdminConfig>
            <ContainerForm see={seeForm}>
              <Form
                form={form}
                handleChange={handleChangeForm}
                handleRows={handleClickTypeLine}
                buttons={buttonsForm}
                renderBeforeTitle={[
                  <ButtonSeeInitalView
                    click={() => configAdmin.toggleForm()}
                  />,
                ]}
                renderBeforeButtons={[
                  <Line
                    color={form?.color?.value}
                    width={form?.dasharrayWidth?.value}
                    separator={form?.dasharrayPixels?.value}
                  />,
                ]}
                title={"Create a new Digital Twin Line"}
              />
            </ContainerForm>

            {!seeForm && (
              <>
                <CreateNewRegionButton>
                  Create a new Digital Twin Line
                  <button onClick={() => configAdmin.toggleForm()}>
                    <span>
                      <IoMdAdd />
                    </span>
                    New Type Line {library?.name}
                  </button>
                </CreateNewRegionButton>

                <SeparatorWithText>
                  <span className="left"></span>
                  Or
                  <span className="right"></span>
                </SeparatorWithText>

                <InformationShare>
                  <div className="head">Edit Existing Digital Twin Line</div>
                  <div className="body">
                    To Edit Click on Existing Digital Twin Line ID
                  </div>
                </InformationShare>
              </>
            )}
          </ColumnAdminConfig>
        </RowAdminConfig>
      </ConfigAdminContainer>
    </div>
  );
}
