import useSwr from "swr";

export const useFetchObjectLibraries = () => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/point-libraries`
  );
  return { data, error };
};

export const useFetchEventLibraries = () => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/point-library-events`
  );
  return { data, error };
};

export const useFetchComponentsLibraries = () => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/point-library-components`
  );
  return { data, error };
};