// Components
import Layout from "components/Layout/AdminDecimetrix";
import Chatbot from "components/Chatbot";
// Style
import { Container } from "./ChatbotStyle";

const Index = () => {
  return (
    <Layout>
      <Container>
        <section className="logo-decimetrix">
          <img
            src="https://greendragon-production.s3.us-east-2.amazonaws.com/default/deciemtrix_socrates.png"
            alt="Decimetrix Socrates, the chatbot avatar for Decimetrix Admin"
          />
        </section>
        <Chatbot />
      </Container>
    </Layout>
  );
};

export default Index;
