import deleteObjectFile from "services/deleteObjectFile";
import {
  deleteCaptureMedia,
  deleteCaptureMediaPg,
} from "services/uploadFilePointEvent";
import { config } from "config.js";
import deleteEventPhoto from "services/deleteEventPhoto";

export default async function deleteImages(
  currentPhoto,
  uploadImages,
  mongoId,
  ownId,
  mutate
) {
  if (currentPhoto.type === "object") {
    await deleteObjectFile(currentPhoto?.id);
    const urlData = `${config.URL_BACKEND_PG}api/v1/objects/${ownId}`;
    mutate(urlData);
  }
  if (currentPhoto.type === "operation") {
    await deleteEventPhoto(currentPhoto?.id);
    const urlData = `${config.URL_BACKEND_PG}api/v1/operation/${ownId}`;
    mutate(urlData);
  }
  if (currentPhoto.type === "pointEventPg") {
    await deleteCaptureMediaPg(`event-files/${currentPhoto?.id}`);
    uploadImages();
  }
  if (currentPhoto.type === "pointEventMg") {
    await deleteCaptureMedia({
      endPoint: "point-event/event-file/",
      pointTypeMgId: mongoId,
      mediaId: currentPhoto?.id,
    });
    uploadImages();
  }
  if (currentPhoto.type === "pointComponentPg") {
    await deleteCaptureMediaPg(`component-files/${currentPhoto?.id}`);
    uploadImages();
  }
  if (currentPhoto.type === "pointComponentMg") {
    await deleteCaptureMedia({
      endPoint: "point-components/component-file/",
      pointTypeMgId: mongoId,
      mediaId: currentPhoto?.id,
    });
    uploadImages();
  }
  if (currentPhoto.type === "pointObjectMg") {
    await deleteCaptureMedia({
      endPoint: "point-object/object-file/",
      pointTypeMgId: mongoId,
      mediaId: currentPhoto?.id,
    });
    uploadImages();
  }
}
