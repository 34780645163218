const deleteDuplicateOperations = (events) => {
  return events
    .filter(
      (object, index, self) =>
        index ===
        self.findIndex((t) => t.typeOperationId === object.typeOperationId)
    )
    .map((item) => item.typeOperation);
};

export default deleteDuplicateOperations;
