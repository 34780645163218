import * as React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Popup, Marker, NavigationControl } from "react-map-gl";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import * as turf from "@turf/turf";

import { useState, useEffect } from "react";
import { MapCard, CustomSelect, CustomDiv } from "./mapCollectionStyles";
import { NamePlateMarker } from "../ButtonElements";

import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MapIcon from "@mui/icons-material/Map";
import Divider from "@mui/material/Divider";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import fogStyle from "utils/fogStyle";
import { useDispatch } from "react-redux";

import Lottie from "react-lottie";
import airplane from "lotties/airplane.json";

import ObjectDialog from "components/Dialogs/OperationDialog";
import ObjectDetails from "components/ImageGallery/ObjectDetails/ObjectDetails";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";

const MapCollectionComponent = ({ date }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [objects, setObjects] = useState();
  const [dataPopup, setDataPopup] = useState();
  const [error, setError] = useState();
  const [baseMap, setBaseMap] = useState("streets-v11");
  const [centerLocations, setCenterLocations] = useState({});
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/date/${date}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
        const res = await response.json();

        setObjects(res);
        calculateCenter(res);
        setCenterLocations({
          latitude: res[0].location.latitude,
          longitude: res[0].location.longitude,
        });
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [date, token]);

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const iteratorLocation = () => {
    return objects.map((object, index) => {
      const execute = async (event) => {
        setShowPopup(true);
        setDataPopup(object);
      };
      let urlIcon = null;
      if (object.haveMedia) {
        if (object.typeObjectId === 1) {
          urlIcon = object.typeObjectGhg.typeElementGhg.url_icon_color;
        }
        if (object.typeObjectId === 2) {
          urlIcon = object.typeObjectAgile.typeElementAgile.urlIconColor;
        }
        if (object.typeObjectId === 3) {
          urlIcon = object.typeObjectWell.typeElementWell.url_icon_color;
        }
        if (object.typeObjectId === 4) {
          urlIcon = object.typeObjectFacility.typeElementFacility.urlIconColor;
        }
      } else {
        if (object.typeObjectId === 1) {
          urlIcon = object.typeObjectGhg.typeElementGhg.url_icon_gray;
        }
        if (object.typeObjectId === 2) {
          urlIcon = object.typeObjectAgile.typeElementAgile.urlIconGray;
        }
        if (object.typeObjectId === 3) {
          urlIcon = object.typeObjectWell.typeElementWell.url_icon_gray;
        }
        if (object.typeObjectId === 4) {
          urlIcon = object.typeObjectFacility.typeElementFacility.urlIconGray;
        }
      }
      return (
        <Marker
          key={index}
          latitude={Number(object.location.latitude)}
          longitude={Number(object.location.longitude)}
        >
          {/* <button id={object.id}  className="nameplate-marker"> */}
          <NamePlateMarker id={object.id} onClick={execute}>
            <img
              id={object.id}
              // src={object.typeElement.icon}
              src={urlIcon}
              alt="nameplate marker"
            ></img>
          </NamePlateMarker>
        </Marker>
      );
    });
  };

  const changeBaseMap = (event) => {
    setBaseMap(event.target.value);
  };

  const calculateCenter = (allObjects) => {
    const locations = [];
    allObjects.map((object, index) => {
      const location = [
        Number(object.location.latitude),
        Number(object.location.longitude),
      ];
      locations.push(location);
      return false;
    });
    var features = turf.points(locations);
    const center = turf.center(features);
    return center.geometry.coordinates;
  };

  const isEmptyObject = (object) => {
    return Object.keys(object).length === 0 && object.constructor === Object;
  };

  const dispatch = useDispatch();

  const showDetailsOject = (event) => {
    const object = {
      content: <ObjectDetails objectId={event.target.id} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: airplane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2, textAlign: "center" }}>
      <Grid container>
        {error ? (
          <div></div>
        ) : objects && objects.length > 0 ? (
          <Grid item xs={12}>
            <MapCard>
              <CustomSelect>
                <select id="customer" name="customer" onChange={changeBaseMap}>
                  <option defaultValue value="streets-v11">
                    Streets
                  </option>
                  <option value="satellite-v9">Satellite</option>
                  <option value="light-v10">Light</option>
                  <option value="dark-v10">Dark</option>
                </select>
              </CustomSelect>
              {!isEmptyObject(centerLocations) ? (
                <Map
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                  initialViewState={{
                    latitude: centerLocations.latitude,
                    longitude: centerLocations.longitude,
                    width: "100%",
                    height: "70vh",
                    zoom: 16,
                  }}
                  style={{ width: "100%", height: "80vh", margin: "auto" }}
                  mapStyle={`mapbox://styles/mapbox/${baseMap}`}
                  projection={"globe"}
                  fog={fogStyle}
                >
                  <NavigationControl style={navControlStyle} />
                  {iteratorLocation()}

                  {showPopup && objects ? (
                    <Popup
                      longitude={dataPopup.location.longitude}
                      latitude={dataPopup.location.latitude}
                      anchor="left"
                      closeButton={true}
                      closeOnClick={false}
                      offsetTop={-30}
                      onOpen={() => {
                        setShowPopup(true);
                      }}
                      onClose={() => {
                        setShowPopup(false);
                      }}
                    >
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                          position: "relative",
                          overflow: "auto",
                          maxHeight: 450,
                          "& ul": { padding: 0 },
                        }}
                        subheader={<li />}
                      >
                        {dataPopup.objectPhotos.length > 0 ? (
                          <CardMedia
                            id={dataPopup.id}
                            component="img"
                            // height="140"
                            image={dataPopup.objectPhotos[0].url}
                            alt="green iguana"
                            onClick={showDetailsOject}
                            sx={{
                              cursor: "pointer",
                            }}
                          />
                        ) : null}
                        <CardContent>
                          <Typography
                            sx={{ fontSize: "1.4rem" }}
                            gutterBottom
                            variant="h5"
                            component="div"
                          >
                            {dataPopup?.typeObject?.name}
                          </Typography>

                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <DateRangeIcon
                                alt="Remy Sharp"
                                fontSize="large"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary="Date:"
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {dataPopup.date.substring(0, 10)}
                                  </Typography>
                                  {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />

                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <MapIcon alt="Remy Sharp" fontSize="large" />
                            </ListItemAvatar>
                            <ListItemText
                              primary="Coordinates:"
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {dataPopup.location.latitude},{" "}
                                    {dataPopup.location.longitude}
                                  </Typography>
                                  {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <ArtTrackIcon alt="Remy Sharp" fontSize="large" />
                            </ListItemAvatar>
                            <ListItemText
                              primary="Nameplate State:"
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {dataPopup.isProcessed
                                      ? "Processed"
                                      : "Unprocessed"}
                                  </Typography>
                                  {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </CardContent>
                      </List>
                    </Popup>
                  ) : null}
                </Map>
              ) : null}
            </MapCard>
          </Grid>
        ) : (
          <CustomDiv>
            <Lottie options={defaultOptions} height={500} width={500} />
          </CustomDiv>
        )}
      </Grid>
      <ObjectDialog
        style={{
          margin: 0,
          padding: 0,
          width: "70%",
          height: "100%",
          maxWidth: "none",
        }}
      />
    </Container>
  );
};

export default MapCollectionComponent;
