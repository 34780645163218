import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";

import { Container } from "./styles";

export default function FloatingFiltersDashboard({
  beforeDate: beforeDataC,
  afterDate: afterDateC,
}) {
  const { beforeDate, setBeforeDate } = beforeDataC;
  const { afterDate, setAfterDate } = afterDateC;

  const [showFilters, setShowFilters] = useState(false);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleDates = (e) => {
    const { name, value } = e.target;
    if (name === "after") setAfterDate(value);
    if (name === "before") setBeforeDate(value);
  };

  return (
    <>
      {!showFilters && (
        <Container background={false}>
          <span className="buttonFilter" onClick={handleShowFilters}>
            <FiFilter />
          </span>
        </Container>
      )}

      {showFilters && (
        <Container background={true}>
          <div className="close">
            <AiOutlineCloseCircle
              onClick={handleShowFilters}
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "2rem",
              }}
            />
          </div>
          <div className="dates">
            <label>Filtra Por Fechas</label>
            <input
              type="date"
              name="before"
              onChange={handleDates}
              value={beforeDate}
            />
            <p>To</p>
            <input
              type="date"
              name="after"
              onChange={handleDates}
              value={afterDate}
            />
          </div>
        </Container>
      )}
    </>
  );
}
