import Container from "@mui/material/Container";
import { ItemList } from "./UserRoutesElements";
import FetchTypeElements from "hooks/fetchTypeElements";
import { useStyles, CustomizedAccordion } from "./MapDrawerStyle";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ListObjects = () => {
  const { data: typeElements, error: errorTypeElements } = FetchTypeElements();

  const classes = useStyles();

  const listTypeElements = typeElements?.map(
    (typeElement) => typeElement.pointLibraryId
  );
  const pointLibrariesIdsUnic = [...new Set(listTypeElements)];

  const listNameElements = typeElements?.map(
    (typeElement) => typeElement.pointLibrary.name
  );
  const pointLibrariesNamesUnic = [...new Set(listNameElements)];
  return (
    <Container>
      {!errorTypeElements && typeElements !== undefined && (
        <div>
          <>
            {pointLibrariesIdsUnic.map((libraryId, index) => (
              <CustomizedAccordion
                classes={{
                  content: classes.content,
                  expanded: classes.expanded,
                }}
                defaultExpanded={false}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    Librería {pointLibrariesNamesUnic[index]}
                  </Typography>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  ></AccordionSummary>
                </div>

                {typeElements
                  .filter(
                    (typeElement) => typeElement.pointLibraryId === libraryId
                  )
                  .map((typeElementId) => (
                    <ItemList>
                      <img
                        src={typeElementId.urlIconColor}
                        width="40px"
                        alt="ico"
                      />{" "}
                      <p>{typeElementId.name}</p>
                    </ItemList>
                  ))}
              </CustomizedAccordion>
            ))}
          </>
        </div>
      )}
    </Container>
  );
};

export default ListObjects;
