import React, { useRef } from "react";
import {
  HubContainer,
  HubWrapper,
  HubCard,
  HubIcon,
  HubH2,
  HubP,
  CardContainer,
  ArrowContainer,
  LeftArrow,
  RightArrow,
} from "./HubStyles";
import { infoHub } from "./infoHub";
import { useNavigate } from "react-router-dom";

function AvailableModules({ hideCards }) {
  const navigate = useNavigate();
  const cardContainerRef = useRef(null);

  const handleLeftArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: -350, behavior: "smooth" });
  };

  const handleRightArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: 350, behavior: "smooth" });
  };

  return (
    <HubContainer>
      <CardContainer>
        {!hideCards && (
          <>
            <ArrowContainer onClick={handleLeftArrowClick}>
              <LeftArrow sx={{ fontSize: "80px" }} />
            </ArrowContainer>
            <HubWrapper ref={cardContainerRef}>
              {infoHub.map((item, index) => (
                <HubCard key={index} onClick={() => navigate(item.navigate)}>
                  <HubH2>{item.title}</HubH2>
                  <HubIcon src={item.icon} id="1" />
                  <HubP>{item.description}</HubP>
                </HubCard>
              ))}
            </HubWrapper>
            <ArrowContainer onClick={handleRightArrowClick}>
              <RightArrow sx={{ fontSize: "80px" }} />
            </ArrowContainer>
          </>
        )}
      </CardContainer>
    </HubContainer>
  );
}

export default AvailableModules;
