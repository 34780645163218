import { useControl } from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

/**
 * Renders a DrawControl component that allows the user to draw and manipulate features on a map.
 *
 * @param {function} handleCreateEvent - The function to be called when a feature is created.
 * @return {null} Returns null.
 */
export default function DrawControl({ handleCreateEvent }) {
  useControl(
    ({ map }) => {
      const controlGl = new MapboxDraw({
        controls: {
          point: true,
          polygon: false,
          line_string: false,
          combine_features: false,
          uncombine_features: false,
        },
      });
      map.on("load", () => {
        map.addControl(controlGl);
      });
      map.on("draw.create", (e) => {
        const { features } = e;
        const lenFeatures = features.length;
        const lastFeature = features[lenFeatures - 1];
        handleCreateEvent(lastFeature);
      });
    },
    {
      position: "top-left",
    }
  );

  return null;
}
