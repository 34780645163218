import axios from "axios";

/**
 * Updates an object in the database.
 *
 * @param {Object} body - The updated object data.
 * @param {string} id - The ID of the object to update.
 * @return {Promise<Object>} An object containing the updated data and the response.
 */
const updateObjectPg = async (body, id) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default updateObjectPg;
