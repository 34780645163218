import deleteDuplicateObjects from "./deleteDuplicateObjetcs";
import findPointLibraries from "services/findPointLibrary";
import { PRIMARY_COLOR } from "utils/const";
import { showMarkerseByTypeElement, showMarkerseByTypeObject } from "redux/actions/admin";

const setDispatchFilterNewObjectsByType = async (objects, dispatch) => {
  const typeObjetcs = await deleteDuplicateObjects(objects);

  const pointLibraryObjects = await findPointLibraries();

  const dataFilterObjectssLibrary = [];

  await typeObjetcs.forEach((object) => {
    pointLibraryObjects.data.forEach((libraryObject) => {
      if (object.pointLibraryId === libraryObject.id) {
        const objectLibrary = {
          libraryId: object.pointLibraryId,
          name: libraryObject.name,
          state: true,
          visible: false,
          color: `${PRIMARY_COLOR}`,
        };
        dataFilterObjectssLibrary.push(objectLibrary);
      }
    });
  });

  await dispatch(
    showMarkerseByTypeObject(
      dataFilterObjectssLibrary.length !== 0 ? dataFilterObjectssLibrary : []
    )
  );

  const dataFilterObjectsByType = [];
  await typeObjetcs.forEach((object) => {
    const componentType = {
      id: object.id,
      name: object.name,
      libraryId: object.pointLibraryId,
      state: true,
      visible: false,
      icon: object.urlIconColor
    };
    dataFilterObjectsByType.push(componentType);
  });

  await dispatch(
    showMarkerseByTypeElement(
      dataFilterObjectsByType.length !== 0 ? dataFilterObjectsByType : []
    )
  );
};

export default setDispatchFilterNewObjectsByType;
