import axios from "axios";

const findTypeInspectionFlir = async (id) => {
  const token = localStorage.getItem("token");
  let url = `${process.env.REACT_APP_URL_BACKEND}api/v1/type-inspections-flir`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default findTypeInspectionFlir;
