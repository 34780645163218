import { Grid } from "@mui/material";
import { useRef } from "react";
import { useCallback, useState } from "react";
import { Map, Marker } from "react-map-gl";
import useSupercluster from "use-supercluster";
import { MarkerContainer } from "./styles";
import fogStyle from "utils/fogStyle";

export default function BasicMap({ pointsIntoMap }) {
  const [viewState, setViewState] = useState({
    latitude: 4.81500179,
    longitude: -75.725484,
    width: "100%",
    height: "100%",
    zoom: 5,
  });
  const { keyLocation, data, keySearchImage, keyImage } = pointsIntoMap;

  const mapRef = useRef();

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const points = data?.map((item) => ({
    type: "Feature",
    properties: {
      cluster: false,
      object: item,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(item[keyLocation].longitude),
        parseFloat(item[keyLocation].latitude),
      ],
    },
  }));

  const { clusters } = useSupercluster({
    points: points || [],
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={5}
      xl={5}
      sx={{
        minHeight: "500px",
        padding: "10px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        marginBottom: "20px",
        borderRadius: "10px",
      }}
    >
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        style={{ width: "100%", height: "100%", margin: "auto" }}
        mapStyle={`mapbox://styles/mapbox/streets-v11`}
        attributionControl={false}
        ref={mapRef}
        initialViewState={{
          latitude: viewState.latitude,
          longitude: viewState.longitude,
          zoom: viewState.zoom,
        }}
        onMove={useCallback((e) => {
          setViewState(e.viewState);
        }, [])}
        projection={"globe"}
        fog={fogStyle}
      >
        {clusters?.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${index}`}
                longitude={longitude}
                latitude={latitude}
              >
                <MarkerContainer>
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / points?.length) * 20}px`,
                      height: `${10 + (pointCount / points?.length) * 20}px`,
                    }}
                  >
                    {pointCount}
                  </div>
                </MarkerContainer>
              </Marker>
            );
          }

          const type = cluster.properties.object[keySearchImage];
          if (!type) return null;
          const image = type[keyImage];
          if (!image) return null;

          return (
            <Marker
              key={`marker-${index}`}
              longitude={longitude}
              latitude={latitude}
            >
              <div className="marker">
                <img src={image} alt="marker" width={30} height={30} />
              </div>
            </Marker>
          );
        })}
      </Map>
    </Grid>
  );
}
