//React
import * as React from "react";

//style
import LayersIcon from "@mui/icons-material/Layers";
import { ButtonBaseMap, ContainerStyle } from "./BaseMapButtonStyle";

export default function BaseMapButton({setHaveObjects, setContentDialog, setBaseMap, position}) {
  const ContentBaseMap = (changeBaseMap) => ({
    title: "Base Map",
    description: (
      <ContainerStyle>
        <h3 className="title">Select Base Map Type</h3>
        <div className="types-base-map">
          <div className="type-base-map">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3tZaAJFEXlkxY3W3cMkbEfU95DFoJTipPww&usqp=CAU"
              alt="Streets"
            />
            <span className="description">Streets</span>
            <button
              className="select-base-map"
              value="streets-v12"
              onClick={changeBaseMap}
            >
              View
            </button>
          </div>
          <div className="type-base-map">
            <img
              src="https://greendragon-production.s3.us-east-2.amazonaws.com/default/outdoors.jpg"
              alt="Outdoors"
            />
            <span className="description">Outdoors</span>
            <button
              className="select-base-map"
              value="outdoors-v12"
              onClick={changeBaseMap}
            >
              View
            </button>
          </div>
          <div className="type-base-map">
            <img
              src="https://greendragon-production.s3.us-east-2.amazonaws.com/default/satellite-streets.jpg"
              alt="Satellite Streets"
            />
            <span className="description">Satellite Streets</span>
            <button
              className="select-base-map"
              value="satellite-streets-v12"
              onClick={changeBaseMap}
            >
              View
            </button>
          </div>
          <div className="type-base-map">
            <img
              src="https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_SATELLITE.png"
              alt="Satellite"
            />
            <span className="description">Satellite</span>
            <button
              className="select-base-map"
              value="satellite-v9"
              onClick={changeBaseMap}
            >
              View
            </button>
          </div>
          <div className="type-base-map">
            <img
              src="https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_LIGHT_V2.png"
              alt="Light"
            />
            <span className="description">Light</span>
            <button
              className="select-base-map"
              value="light-v11"
              onClick={changeBaseMap}
            >
              View
            </button>
          </div>
          <div className="type-base-map">
            <img
              src="https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_DARK_V2.png"
              alt="Dark"
            />
            <span className="description">Dark</span>
            <button
              className="select-base-map"
              value="dark-v11"
              onClick={changeBaseMap}
            >
              View
            </button>
          </div>
        </div>
      </ContainerStyle>
    ),
  });

  const changeBaseMap = (event) => {
    setBaseMap(event.target.value);
    setHaveObjects(false);
  };

  const handleBaseMap = () => {
    setHaveObjects(true);
    setContentDialog(ContentBaseMap(changeBaseMap));
  };

  return (
    <ButtonBaseMap onClick={handleBaseMap} top={position}>
      <LayersIcon
        style={{
          color: "#282828",
          fontSize: "1.6rem",
        }}
      />
      <div className="container-tooltip">
        <span className="tooltip">Change Base Map</span>
      </div>
    </ButtonBaseMap>
  );
}
