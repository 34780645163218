import { useSelector } from "react-redux";
import { Marker } from "react-map-gl";
import { MarkerElementContainer } from "components/Clusters/ClustersStyle";
import { useState, useEffect } from "react";

/**
 * Render a MarkerElement with the given props.
 *
 * @param {Object} element - The element object containing id, icon and location.
 * @param {function} handleClick - The function to handle click events on the element.
 * @param {Object} flyTo - The object representing the element to fly to.
 * @return {JSX.Element} The rendered MarkerElement.
 */
const MarkerElement = ({ element, handleClick }) => {
  // los datos label, haveMedia, QAQC son opcionales y solamente son usados en los marker de objetos
  const { icon, id, location, label, haveMedia, QAQC } = element;
  // States
  const [dataFlyTo, setDataFlyTo] = useState({});
  /** 
    flyTo es de la forma:
        {
          operation: {
            id: '',
            latitude: '',
            longitude: ''
          }
          object: {
            id: '',
            latitude: '',
            longitude: ''
          }
        }
    es necesario obtener el id que no sea vacio, esto depende de a que elemento se le hace flyto en la tabla, 
    el id que no sea un string vacio se guarda en @const {number} elementId
  */
  const flyTo = useSelector((state) => state.digitalTwinReducer.flyTo);
  let elementId = null;
  for (const key in flyTo) {
    const id = flyTo[key].id;
    if (typeof id === "number" || (typeof id === "string" && id !== "")) {
      elementId = id;
      break;
    }
  }

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === "dataMarketPoint") {
        const res = JSON.parse(localStorage.getItem("dataMarketPoint"));
        setDataFlyTo(res);
      }
    });
    if (elementId !== null) {
      setDataFlyTo({ id: elementId });
    } else {
      setDataFlyTo({ id: null });
    }
  }, [elementId]);

  let showLabelsElements = useSelector(
    (state) => state.digitalTwinReducer.showLabels
  );
  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );

  return (
    <>
      <Marker
        latitude={Number(location.latitude)}
        longitude={Number(location.longitude)}
      >
        <MarkerElementContainer
          filter={haveMedia === undefined ? "none" : haveMedia}
          onClick={() => handleClick(id)}
          flyTo={id === dataFlyTo.id}
          drawInMap={drawAnyThing}
          qaqc={QAQC}
        >
          <img src={icon} alt="nameplate marker"></img>
          <span className="qaqc"></span>
          <span className="selected"></span>
        </MarkerElementContainer>
      </Marker>
      {showLabelsElements && (
        <Marker
          style={{
            marginTop: "25px",
          }}
          latitude={Number(location.latitude)}
          longitude={Number(location.longitude)}
        >
          <p>{label}</p>
        </Marker>
      )}
    </>
  );
};

export default MarkerElement;
