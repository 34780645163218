import Reparation from "components/Forms/Events/Reparation";
import InspectionSniffer from "components/Forms/Events/InspectionSniffer";
import Leak from "components/Forms/Events/Leak";
import InspectionFlir from "components/Forms/Events/InspectionFlir";
import DynamicEvent from "components/Forms/Events/DynamicEvent";
import { useState } from "react";
import { events, eventsIcons } from "utils/events";
import SelectInput from "components/Forms/SelectInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FeatureDetails } from "components/Forms/SaveObject/indexStyle";
import { WraperTitleCardMarker } from "components/ImageGallery/ImageDetailsElements";
import { useFetchEventLibraries } from "hooks/fetchLibraries";

export default function CreateEvent({
  feature,
  objectId,
  closeModal,
  typeEvents,
}) {
  const [type, setType] = useState("Repair");
  const [currentEventType, setCurrentEventType] = useState("Repair");
  const [currentLibraryType, setCurrentLibraryType] = useState("Generic Events");

  const [field2, setField2] = useState({
    name: "Library type",
    options: ["Generic Events"],
    id: 0,
  });
  const [field, setField] = useState({
    name: "Type event",
    options: ["Repair", "Sketch", "Leak", "Inspection Flir"],
    icons: [],
    id: 0,
  }); 

  const { data: typeEventLibraries, errorTypeEventLibraries } =
    useFetchEventLibraries();

  useEffect(() => {
    const librarieIds = typeEvents.map(
      (event) => event.pointTypeEvent.pointLibraryEventId
    );
    var typeIcons = typeEvents.map((elm) => {
     const library = !errorTypeEventLibraries &&
      typeEventLibraries?.find((library) => {
        return elm.pointTypeEvent.pointLibraryEventId === library.id
      })
      return {
        name: elm.pointTypeEvent.name,
        icon: elm.pointTypeEvent.icon,
        library: library?.name
      };
    });
    !errorTypeEventLibraries &&
      typeEventLibraries?.map((library) => {
        if (librarieIds.includes(library.id) && field2.options.length === 1) {
          setField2((prevField2) => ({
            ...prevField2,
            options: [...prevField2.options, library.name],
          }));
        }
      });
    const allTypesIcons = typeIcons.concat(eventsIcons);
    setField((prevField) => ({
      ...prevField,
      icons: allTypesIcons,
    }));
  }, [typeEventLibraries]);

  const { register, handleSubmit } = useForm();

  const handleSelectTypeEvent = (data) => {
    setCurrentLibraryType(data["Library type"]);
    if (data["Library type"] === "Generic Events") {
      setType(data["Type event"]);
      setCurrentEventType(data["Type event"]);
    } else {
      typeEvents.forEach((event) => {
        if (event.pointTypeEvent.name === data["Type event"]) {
          setType(event.pointTypeEvent.id);
          setCurrentEventType(data["Type event"]);
        }
      });
    }
  };

  const handleSelectLibrary = (data) => {
    setCurrentLibraryType(data["Library type"]);
    const options = Object.values(events).map((event) => event);
    if (data["Library type"] === "Generic Events") {
      setField((prevField) => ({
        ...prevField,
        options: options,
      }));
      setType("Repair");
      setCurrentEventType("Repair");
    } else {
      const currentLibrary = typeEventLibraries.find(
        (event) => event.name === data["Library type"]
      );
      const currentLibraryOptions = [];
      const currentLibraryIds = [];
      typeEvents.forEach((elm) => {
        if (elm.pointTypeEvent.pointLibraryEventId === currentLibrary.id) {
          currentLibraryOptions.push(elm.pointTypeEvent.name);
          currentLibraryIds.push(elm.pointTypeEvent.id);
        }
      });
      setField({ ...field, options: currentLibraryOptions });
      setType(currentLibraryIds[0]);
      setCurrentEventType(currentLibraryOptions[0]);
    }
  };

  const renderForm = () => {
    if (type === "Repair") {
      return (
        <Reparation
          closeModal={closeModal}
          feature={feature}
          objectId={objectId}
        />
      );
    }
    if (type === "Sketch") {
      return (
        <InspectionSniffer
          closeModal={closeModal}
          feature={feature}
          objectId={objectId}
        />
      );
    }
    if (type === "Leak") {
      return (
        <Leak closeModal={closeModal} feature={feature} objectId={objectId} />
      );
    }
    if (type === "Inspection Flir") {
      return (
        <InspectionFlir
          closeModal={closeModal}
          feature={feature}
          objectId={objectId}
        />
      );
    } else {
      return (
        <DynamicEvent
          closeModal={closeModal}
          objectId={objectId}
          feature={feature}
          pointTypeEventId={type}
        />
      );
    }
  };

  return (
    <FeatureDetails>
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {field?.icons.map((event) => {
            return (
              event.name === currentEventType && event.library === currentLibraryType && (
                <img className="dynamicImg" src={event.icon} alt="Decimetrix" />
              )
            );
          })}
          <h2>Events</h2>
        </div>
      </WraperTitleCardMarker>
      <div onChange={handleSubmit(handleSelectLibrary)}>
        <SelectInput field={field2} register={register} />
      </div>
      <div onChange={handleSubmit(handleSelectTypeEvent)}>
        <SelectInput field={field} register={register} />
      </div>
      {renderForm()}
    </FeatureDetails>
  );
}
