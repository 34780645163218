import { Source, Layer } from "react-map-gl";

const ShowLinesJoin = ({ features }) => {
  return (
    <Source
      type="geojson"
      data={{ type: "FeatureCollection", features: features }}
    >
      <Layer
        id={"lines-data"}
        type="line"
        paint={{
          "line-color": ["get", "color"],
          "line-width": 3,
          "line-dasharray": [3, 1],
        }}
      />
    </Source>
  );
};

export default ShowLinesJoin;
