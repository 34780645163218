import { React, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import List from "@mui/material/List";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import createNewView from "services/createBookmarkView.js";
import BookmarksViews from "./BookmarksViews";

import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import OpenDialog from "components/Dialogs/OpenDialog";
import { config } from "config.js";
import { Form } from "./AccordionBookmarksViewsStyle";
import TextInput from "components/Forms/GenericInput";
import CustomButton from "components/Buttons/CustomButton";

const fieldInputs = [
  {
    name: "Name of the View",
    type: "string",
    required: true,
  },
  {
    name: "Public",
    type: "checkbox",
    required: false,
  },
];

function AccordionBookmarksViews(props) {
  const { mutate } = useSWRConfig();
  const map = useSelector((state) => state.digitalTwinReducer.map);
  const userid = parseInt(localStorage.getItem("userId"));
  const role = localStorage.getItem("role");
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const getCoordinates = useCallback(() => {
    const newcenter = map.getMap().getCenter();
    const newbounds = map.getMap().getBounds().toArray().flat();
    if (map === null || newcenter === undefined || newbounds === undefined) {
      return {
        center: [0, 0],
        bounds: [
          [0, 0],
          [0, 0],
        ],
      };
    }
    /*  [0,1,2,3] → [0, 1] = SW  [2, 3] = NE*/
    return {
      center: [newcenter?.lng, newcenter?.lat],
      bounds: [
        [newbounds[0], newbounds[1]],
        [newbounds[2], newbounds[3]],
      ],
    };
  }, [map]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const coordinates = getCoordinates();
    const newView = {
      userid,
      description: data["Name of the View"],
      visibility: data?.Public || false,
      coordinates
    };
    await createNewView(newView);
    setDisplayConfirmation(true);
    mutate(`${config.URL_BACKEND_PG}api/v1/views`);
    cleanField();
  };

  const cleanField = () => {
    const input = document.getElementById("Name of the View");
    if (input) input.value = "";
  };

  return (
    <div>
      <List
        sx={{
          height: "100%",
          position: "bottom",
        }}
        subheader={<li />}
      >
        <ToggleButtonGroup
          size="medium"
          exclusive
          fullWidth={true}
          aria-label="text view"
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            {fieldInputs.map((field) => {
              if (
                field.type === "checkbox" &&
                role === "Administrador de compañía"
              )
                return (
                  <TextInput
                    field={field}
                    register={register}
                    errors={errors}
                  />
                );
              if (field.type === "string")
                return (
                  <TextInput
                    field={field}
                    register={register}
                    errors={errors}
                  />
                );
              return null;
            })}

            <CustomButton text={"Add view"} type={"submit"} />
          </Form>
        </ToggleButtonGroup>
        <BookmarksViews />
      </List>
      <OpenDialog
        openDialog={displayConfirmation}
        setOpenDialog={setDisplayConfirmation}
        execute={() => setDisplayConfirmation(false)}
        content={{
          title: "Add view",
          description:
            role === "Administrador de compañía"
              ? "Public view added"
              : "view added",
          agree: "Ok",
        }}
        maxWidth={"400px"}
      />
    </div>
  );
}

export default AccordionBookmarksViews;
