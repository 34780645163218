import styled from "styled-components";

export const CardDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  text-align: center;
  padding: 20px 0;

  h1 {
    margin-bottom: 10px;
  }
`;
