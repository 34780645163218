import axios from 'axios';

async function createNewView(newView){
  try {
    const token = localStorage.getItem("token");
    const url = `${process.env.REACT_APP_URL_BACKEND}api/v1/views`;
    const result = await axios.post(url, newView, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.error("🚩🚩Error on Create new View: ", error);
    return {error};
  }
}

export default createNewView;