import Profile from "../../components/Profile/Profile";
import NavBar from "../../components/Admin/NavBar/NavBar";
import { ProfileStyle } from "./profileStyle";

const Admin = () => {
  const userId = localStorage.getItem("userId");
  return (
    <>
      <NavBar linkLogo={"admin"} />
      <ProfileStyle>
        <h1 className="title-profile">Perfil de Usuario</h1>
        <Profile userId={userId} />
      </ProfileStyle>
    </>
  );
};

export default Admin;
