import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import { useEffect, useState } from "react";
import useGet from "hooks/useFetch.js";

const AccordionRenderTypesObjects = ({
  library,
  typeEventsTypeElements,
  handleChangeCheckObjects,
}) => {
  const { name, id } = library;
  const [types] = useGet(`api/v1/type-elements?libraryId=${parseInt(id)}`);
  const [check, setCheck] = useState(false);

  const handleChangeCheckLibrary = () => {
    const thisCheck = check;
    if (thisCheck) {
      types.forEach((type) => {
        const element = typeEventsTypeElements?.[type?.id];
        if (element) {
          handleChangeCheckObjects(type.id);
        }
      });
      return;
    }
    types.forEach((type) => {
      const element = typeEventsTypeElements?.[type?.id];
      if (!element) {
        handleChangeCheckObjects(type.id);
      }
    });
  };

  useEffect(() => {
    if (!types || types.error) return;
    types.every((type) => {
      const element = typeEventsTypeElements?.[type?.id];
      if (element) return setCheck(true);
      return setCheck(false);
    });
  }, [types, typeEventsTypeElements]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <FormControlLabel
            control={
              <Checkbox checked={check} onChange={handleChangeCheckLibrary} />
            }
            label={name}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          {types &&
            !types.error &&
            types.length > 0 &&
            types.map((type, key) => {
              const element = typeEventsTypeElements?.[type?.id];
              let check = false;
              if (element) check = true;
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={check}
                      onChange={() => handleChangeCheckObjects(type.id)}
                    />
                  }
                  label={type.name}
                />
              );
            })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionRenderTypesObjects;
