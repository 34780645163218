import { useEffect, useState } from "react";
import countComponentsByObjectId from "services/countComponentsByObjectId";

const useCountComponentsToOneObject = ({ objectId, setShowDeleteButton }) => {
  const [amountComponents, setAmountComponents] = useState();

  useEffect(() => {
    countComponentsByObjectId({ objectId })
      .then((response) => {
        setAmountComponents(response);
        if (response.length > 0) {
          setShowDeleteButton(false);
        }
      })
      .catch((err) => console.error(err));
  }, [objectId, setShowDeleteButton]);

  return { amountComponents };
};

export default useCountComponentsToOneObject;