// Required modules
import NavBar from "components/Admin/NavBar/NavBar";
import GenericTable from "components/Tables/GenericTable";
import Form from "components/Forms/Form.jsx";

// Config admin class
import ConfigAdmin from "./ConfigAdmin.js";

// Hooks
import { useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableTypeRegions,
  keyValuesTableTypeRegions,
  initialValuesFormRegionType,
} from "./dataConfigAdmin";

// Styles
import {
  ColumnAdminConfig,
  ConfigAdminContainer,
  CreateNewRegionButton,
  IconAdminConfigChangeColum,
  InformationShare,
  Pentagono,
  PentagonoContainer,
  RowAdminConfig,
  SelectContainerAdminConfig,
  SeparatorWithText,
} from "./configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { BsChevronCompactUp } from "react-icons/bs";
import { BsHexagon } from "react-icons/bs";
import { ContainerForm } from "components/Forms/FormStyles";
import useGet from "hooks/useFetch.js";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <BsChevronCompactUp />
    </IconAdminConfigChangeColum>
  );
};

// To show current polygon aparence
const Region = (props) => {
  const { background, border, opacity, width } = props;

  return (
    <PentagonoContainer>
      <span className="text">Preview: </span>
      <div className="container">
        <Pentagono background={border} width={parseInt(width)}>
          <Pentagono small={true} />
          <Pentagono
            small={true}
            into={true}
            background={background}
            opacity={opacity}
          />
        </Pentagono>
      </div>
    </PentagonoContainer>
  );
};

export default function TypeRegions() {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [libraries] = useGet("api/v1/region-libraries");
  const [library, setLibrary] = useState({});

  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormRegionType);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [typeRegionsRow, setTypeRegionsRow] = useState([]);

  // Click  on type region into table
  const handleClickTypeRegion = (id) => {
    configAdmin.handlerClickIntoTable(id);
  };

  // Change in library select
  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: libraries.find((library) => library.id === parseInt(e.target.value))
        ?.name,
    }));
  };

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableTypeRegions);
    configAdmin.setKeyValuesTable(keyValuesTableTypeRegions);
    configAdmin.setSetRows(setTypeRegionsRow);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin.setEndpoint("api/v1/type-region");
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, []);

  // Set library default
  useEffect(() => {
    if (libraries) {
      setLibrary(() => libraries[0]);
    }
  }, [libraries]);

  // Set library in config admin
  useEffect(() => {
    configAdmin?.setEndpointFetch(
      `api/v1/type-region/library/${parseInt(library?.id)}`
    );
    configAdmin?.setLibraryId(library?.id);
    configAdmin?.fetchData();
  }, [configAdmin, library?.id]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  return (
    <div>
      <NavBar linkLogo={"admin"} />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={executeDialog}
        content={contentDialog}
      />
      <ConfigAdminContainer>
        <RowAdminConfig>
          <ColumnAdminConfig>
            <SelectContainerAdminConfig>
              Existing Digital Twin Line(s)
              <select name="endPoint" id="endPoint" onChange={handleLibrary}>
                {libraries?.map((library) => (
                  <option key={library.id} value={library.id}>
                    Librería {library.name}
                  </option>
                ))}
              </select>
            </SelectContainerAdminConfig>
            {configAdmin?.getHeadersTable && configAdmin?.getKeyValuesTable && (
              <GenericTable
                headers={configAdmin.getHeadersTable()}
                rows={typeRegionsRow}
                keyValues={configAdmin.getKeyValuesTable()}
                handlerClick={handleClickTypeRegion}
                style={{
                  width: 100,
                }}
                state={true}
              />
            )}
          </ColumnAdminConfig>
          <ColumnAdminConfig>
            <ContainerForm see={seeForm}>
              <Form
                form={form}
                handleChange={handleChangeForm}
                handleRows={handleClickTypeRegion}
                buttons={buttonsForm}
                title={"Create a new Digital Twin Region"}
                renderBeforeTitle={[
                  <ButtonSeeInitalView
                    click={() => configAdmin.toggleForm()}
                  />,
                ]}
                renderBeforeButtons={[
                  <Region
                    background={form?.background?.value}
                    border={form?.border?.value}
                    opacity={form?.opacity?.value}
                    width={form?.width?.value}
                  />,
                ]}
              />
            </ContainerForm>

            {!seeForm && (
              <>
                <CreateNewRegionButton>
                  <BsHexagon
                    style={{
                      marginBottom: "13px",
                    }}
                  />
                  Create a new Digital Twin Region
                  <button onClick={() => configAdmin.toggleForm()}>
                    <span>
                      <IoMdAdd />
                    </span>
                    Add Region to Library
                  </button>
                </CreateNewRegionButton>

                <SeparatorWithText>
                  <span className="left"></span>
                  Or
                  <span className="right"></span>
                </SeparatorWithText>

                <InformationShare>
                  <div className="head">Edit Existing Digital Twin Region</div>
                  <div className="body">
                    To Edit Click on Existing Digital Twin Region ID
                  </div>
                </InformationShare>
              </>
            )}
          </ColumnAdminConfig>
        </RowAdminConfig>
      </ConfigAdminContainer>
    </div>
  );
}
