import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AvailableModules from "./AvailableModules";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import { styled } from "@mui/material/styles";

const AdminTabs = () => {
  const StyledTabs = styled(Tabs)({
    display: "flex",
    marginLeft: "115px",
    "@media (max-width: 768px)": {
      marginLeft: "50px",
    },
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <StyledTabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: `${SECOND_COLOR}`,
            height: "6px",
            borderRadius: "6px",
          },
        }}
      >
        <Tab
          label="Available Modules"
          style={{
            color: `${PRIMARY_COLOR}`,
          }}
          sx={{
            fontSize: "20px",
            "@media (max-width: 768px)": {
              fontSize: "17px",
            },
          }}
        />
        <Tab
          label="Access"
          sx={{
            fontSize: "20px",
            "@media (max-width: 768px)": {
              fontSize: "17px",
            },
          }}
          disabled
        />
        <Tab
          label="Get More"
          sx={{
            fontSize: "20px",
            "@media (max-width: 768px)": {
              fontSize: "17px",
            },
          }}
          disabled
        />
      </StyledTabs>

      {value === 0 && <AvailableModules />}
      {value === 1 && <AvailableModules hideCards={true} />}
      {value === 2 && <AvailableModules hideCards={true} />}
    </div>
  );
};

export default AdminTabs;
