import axios from "axios";

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const chatResponse = async (promptContext, prompt) => {
  const res = await axios.post(
    `https://decimetrixia.openai.azure.com/openai/deployments/ChatSocrates/chat/completions?api-version=2023-03-15-preview`,
    {
      messages: [
        { role: "user", content: promptContext.prompt + prompt + " " },
      ],
      max_tokens: 1024,
      temperature: 0.1,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 0.95,
      stop: null,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": OPENAI_API_KEY,
      },
    }
  );
  const data = await res.data;
  return data.choices[0].message.content.trim();
};

export default chatResponse;
