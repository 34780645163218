import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";

// custom style
import { WrapperTypeObjectTitle } from "../../AccordionStyles";

//redux
import {
  setShowDataComponents,
  setShowDataComponentsByType,
  setShowDataComponentsLibrary,
  setFilterComponent,
} from "redux/actions/digitalTwin";
import { useDispatch, useSelector } from "react-redux";

//helpers
import deleteDuplicatePointsLibrary from "helpers/filters/deleteDuplicatePointsLibrary";
import getLibrariesTrue from "helpers/filters/getLibrariesTrue";

//utils
import {
  PRIMARY_COLOR,
  SET_VISIBILITY_ICON,
  VISIBILITY_OFF_ICON,
} from "utils/const";


const FilterComponents = () => {
  const dispatch = useDispatch();

  const showDataComponentsByType = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsByType
  );

  const showDataComponentsLibrary = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsLibrary
  );

  const showDataComponents = useSelector(
    (state) => state.digitalTwinReducer.showDataComponents
  );

  const handlerClickFilterComponents = (state, color) => {
    showDataComponentsByType.map((elm) => {
      elm.state = state;
    });

    const dataLibraryComponent = showDataComponentsLibrary.map((elm) => ({
      ...elm,
      state: state,
      color: color,
    }));

    dispatch(setShowDataComponentsByType(showDataComponentsByType));
    dispatch(setShowDataComponentsLibrary(dataLibraryComponent));
    dispatch(setShowDataComponents({ state: state, color: color }));
    dispatch(setFilterComponent(true));
  };

  const handlerClickFilterComponentsByLibrary = (state, libraryId, color) => {
    const dataLibraryComponent = showDataComponentsLibrary.map((elm) => {
      if (elm.libraryId === libraryId) {
        return { ...elm, state: state, color: color };
      } else {
        return elm;
      }
    });

    showDataComponentsByType.map((elm) => {
      if (elm.libraryId === libraryId) {
        elm.state = state;
      }
    });

    const componentLibraryOff = dataLibraryComponent.filter((elm) => {
      return elm.state === true;
    });

    if (showDataComponents.state === false) {
      dispatch(
        setShowDataComponents({ state: state, color: `${SET_VISIBILITY_ICON}` })
      );
    }
    if (componentLibraryOff.length === 0) {
      dispatch(
        setShowDataComponents({ state: false, color: `${VISIBILITY_OFF_ICON}` })
      );
    }
    if (componentLibraryOff.length > 0) {
      dispatch(
        setShowDataComponents({ state: true, color: `${SET_VISIBILITY_ICON}` })
      );
    }
    if (componentLibraryOff.length === dataLibraryComponent.length) {
      dispatch(
        setShowDataComponents({ state: true, color: `${PRIMARY_COLOR}` })
      );
    }
    dispatch(setShowDataComponentsByType(showDataComponentsByType));
    dispatch(setShowDataComponentsLibrary(dataLibraryComponent));
    dispatch(setFilterComponent(true));
  };

  const handlerClickFilterComponentsByType = (typeId, libraryId, state) => {
    const currentLibrary = showDataComponentsLibrary.filter((elm) => {
      return elm.libraryId === libraryId;
    });
    showDataComponentsByType.map((elm) => {
      if (elm.libraryId === libraryId && typeId === elm.id) {
        elm.state = state;
      }
    });
    dispatch(setShowDataComponentsByType(showDataComponentsByType));
    const currentComponentsType = showDataComponentsByType.filter((elm) => {
      return elm.libraryId === libraryId;
    });

    const currentComponentsActivate = currentComponentsType.filter((elm) => {
      return elm.state === true;
    });

    if (showDataComponents.state === false) {
      dispatch(
        setShowDataComponents({ state: state, color: `${SET_VISIBILITY_ICON}` })
      );
    }
    if (currentComponentsActivate.length === 0) {
      const dataLibraryComponent = showDataComponentsLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: false, color: `${VISIBILITY_OFF_ICON}` };
        } else {
          return elm;
        }
      });

      if (getLibrariesTrue(showDataComponentsLibrary)) {
        dispatch(
          setShowDataComponents({
            state: true,
            color: `${SET_VISIBILITY_ICON}`,
          })
        );
      }
      dispatch(setShowDataComponentsLibrary(dataLibraryComponent));
      if (getLibrariesTrue(showDataComponentsLibrary) === false) {
        dispatch(
          setShowDataComponents({
            state: false,
            color: `${VISIBILITY_OFF_ICON}`,
          })
        );
      }
    }
    if (currentComponentsActivate.length > 0) {
      const dataLibraryComponent = showDataComponentsLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: true, color: `${SET_VISIBILITY_ICON}` };
        } else {
          return elm;
        }
      });
      dispatch(setShowDataComponentsLibrary(dataLibraryComponent));
      dispatch(
        setShowDataComponents({ state: true, color: `${SET_VISIBILITY_ICON}` })
      );
      if (
        getLibrariesTrue(showDataComponentsLibrary) === false &&
        currentLibrary.length === 1
      ) {
        dispatch(
          setShowDataComponents({ state: true, color: `${PRIMARY_COLOR}` })
        );
      }
    }
    if (currentComponentsActivate.length === currentLibrary.length) {
      const dataLibraryComponent = showDataComponentsLibrary.map((elm) => {
        if (elm.libraryId === libraryId) {
          return { ...elm, state: true, color: `${PRIMARY_COLOR}` };
        } else {
          return elm;
        }
      });
      dispatch(setShowDataComponentsLibrary(dataLibraryComponent));
    }
    if (
      currentComponentsActivate.length === currentLibrary.length &&
      getLibrariesTrue(showDataComponentsLibrary)
    ) {
      dispatch(
        setShowDataComponents({ state: true, color: `${PRIMARY_COLOR}` })
      );
    }
    dispatch(setFilterComponent(true));
  };

  const currentLibrarys = deleteDuplicatePointsLibrary(
    showDataComponentsLibrary.length > 0 ? showDataComponentsLibrary : []
  );
  const currentLibrarysSort = currentLibrarys.sort((a, b) => a.name.localeCompare(b.name));

  const showDataComponentsByTypeSort = showDataComponentsByType.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Accordion
      sx={{
        paddingLeft: "0px",
        border: "none",
        boxShadow: "none",
      }}
      defaultExpanded={false}
    >
      <WrapperTypeObjectTitle>
        <div className="icon-title">
          {showDataComponents.state ? (
            <VisibilityIcon
              sx={{
                color: showDataComponents.color,
                margin: "10px 10px 10px 15px",
                cursor: "pointer",
              }}
              onClick={() =>
                handlerClickFilterComponents(false, `${VISIBILITY_OFF_ICON}`)
              }
            />
          ) : (
            <VisibilityOffIcon
              sx={{
                color: `${VISIBILITY_OFF_ICON}`,
                margin: "10px 15px",
                cursor: "pointer",
              }}
              onClick={() =>
                handlerClickFilterComponents(true, `${PRIMARY_COLOR}`)
              }
            />
          )}
          <p>Components</p>
        </div>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
      </WrapperTypeObjectTitle>
      {currentLibrarys.length !== 0 &&
        currentLibrarysSort.map((elm) => {
          return (
            <AccordionDetails>
              <Accordion
                sx={{
                  paddingLeft: "0px",
                  border: "none",
                  boxShadow: "none",
                }}
                defaultExpanded={false}
              >
                <WrapperTypeObjectTitle>
                  <div className="icon-title">
                    {elm.state ? (
                      <VisibilityIcon
                        sx={{
                          color: elm.color,
                          margin: "10px 10px 10px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handlerClickFilterComponentsByLibrary(
                            false,
                            elm.libraryId,
                            `${VISIBILITY_OFF_ICON}`
                          )
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{
                          color: elm.color,
                          margin: "10px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handlerClickFilterComponentsByLibrary(
                            true,
                            elm.libraryId,
                            `${PRIMARY_COLOR}`
                          )
                        }
                      />
                    )}
                    <p>{elm.name}</p>
                  </div>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  ></AccordionSummary>
                </WrapperTypeObjectTitle>
                <AccordionDetails>
                  {showDataComponentsByTypeSort.map((elm2) => {
                    return (
                      <WrapperTypeObjectTitle>
                        {elm.libraryId === elm2.libraryId ? (
                          <>
                            <div className="icon-title">
                              {elm2.state ? (
                                <VisibilityIcon
                                  sx={{
                                    color: `${PRIMARY_COLOR}`,
                                    margin: "10px 10px 10px 15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlerClickFilterComponentsByType(
                                      elm2.id,
                                      elm2.libraryId,
                                      false,
                                      `${VISIBILITY_OFF_ICON}`
                                    )
                                  }
                                />
                              ) : (
                                <VisibilityOffIcon
                                  sx={{
                                    color: `${VISIBILITY_OFF_ICON}`,
                                    margin: "10px 15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlerClickFilterComponentsByType(
                                      elm2.id,
                                      elm2.libraryId,
                                      true,
                                      `${PRIMARY_COLOR}`
                                    )
                                  }
                                />
                              )}
                              <p>{elm2.name}</p>
                            </div>
                            <div>
                              <img
                                src={elm2.icon}
                                width="30px"
                                height="30px"
                                alt="ico"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </WrapperTypeObjectTitle>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          );
        })}
    </Accordion>
  );
};

export default FilterComponents;
