// import { createStore, compose, applyMiddleware } from "redux";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import rootReducers from "./reducers/rootReducers";
// import { logActions } from "./middlewares";
// import createTransform from "redux-persist/es/createTransform";
// import lzString from "lz-string";

// export const compressionData = createTransform(
//   (inboundState, key) => {
//     const { dataObjectsPolygon, dataEventPolygon, setFeaturesDetailsDT, objectsFilterTable} =
//       inboundState;

//     const compressedDataObjectsPolygon = lzString.compressToUTF16(
//       JSON.stringify(dataObjectsPolygon)
//     );
//     const compressedDataEventPolygon = lzString.compressToUTF16(
//       JSON.stringify(dataEventPolygon)
//     );
//     const compressedDataObjectsFilterTable = lzString.compressToUTF16(
//       JSON.stringify(objectsFilterTable)
//     );

//     return {
//       compressedDataObjectsPolygon,
//       compressedDataEventPolygon,
//       setFeaturesDetailsDT,
//       compressedDataObjectsFilterTable
//     };
//   },
//   (outboundState, key) => {
//     const {
//       compressedDataObjectsPolygon,
//       compressedDataEventPolygon,
//       compressedDataObjectsFilterTable,
//       setFeaturesDetailsDT,
//     } = outboundState;

//     const decompressedDataObjectsPolygon = lzString.decompressFromUTF16(
//       compressedDataObjectsPolygon
//     );
//     const decompressedDataEventPolygon = lzString.decompressFromUTF16(
//       compressedDataEventPolygon
//     );
//     const decompressedDataObjectsFilterTable = lzString.decompressFromUTF16(
//       compressedDataObjectsFilterTable
//     );

//     const dataObjectsPolygon = JSON.parse(decompressedDataObjectsPolygon);
//     const dataEventPolygon = JSON.parse(decompressedDataEventPolygon);
//     const dataObjectsFilterTable = JSON.parse(decompressedDataObjectsFilterTable);

//     return {
//       dataObjectsPolygon,
//       dataEventPolygon,
//       setFeaturesDetailsDT,
//       dataObjectsFilterTable
//     };
//   }
// );

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["adminReducer"],
//   transforms: [compressionData],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducers);

// const commposeAlt = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const composeEnhancers = commposeAlt(applyMiddleware(logActions));

// const preloadedState = {};

// const store = createStore(persistedReducer, preloadedState, composeEnhancers);
// const persistor = persistStore(store);

// export { store, persistor };

import { createStore, compose, applyMiddleware } from "redux";

import rootReducers from "./reducers/rootReducers";

import { logActions } from "./middlewares";

const commposeAlt = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers = commposeAlt(applyMiddleware(logActions));

const store = createStore(rootReducers, composeEnhancers);

export default store;
