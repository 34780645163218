import axios from "axios";
import { config } from "config.js";

/**
 * Creates a new type events type events.
 *
 * @param {object} body - The request body for creating a new type events type events.
 *                        Example body:
 *                          {
 *                            "pointTypeEventId": 9,
 *                            "pointTypeEventRelationId": 25
 *                          }
 * @return {Promise<object>} - A promise that resolves to the created type events type events.
 */
export const createTypeEventsTypeEvents = async (body) => {
  const token = localStorage.getItem("token");
  const result = await axios.post(
    `${config.URL_BACKEND_PG}api/v1/type-events-type-events`,
    body,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await result.data;
  return data;
};

export const findTypeEventsTypeEvents = async (queries) => {
  const { pointTypeEventRelationId, pointTypeEventId } = queries;
  const token = localStorage.getItem("token");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  let url = `${config.URL_BACKEND_PG}api/v1/type-events-type-events?`;
  if (adminCompanyId) {
    url = `${config.URL_BACKEND_PG}api/v1/type-events-type-events?adminCompanyId=${adminCompanyId}`;
  }
  let existQuery = false;

  if (pointTypeEventRelationId) {
    url = url + `&pointTypeEventRelationId=${pointTypeEventRelationId}`;
    existQuery = true;
  }

  if (pointTypeEventId) {
    if (existQuery) url = url + `&pointTypeEventId=${pointTypeEventId}`;
    else url = url + `&pointTypeEventId=${pointTypeEventId}`;
  }

  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  const data = await result.data;
  return data;
};

/**
 * Deletes a type event type component with the given ID.
 *
 * @param {number} id - The ID of the type event type component to be deleted.
 * @return {Promise<Object>} Returns a promise that resolves with the deleted data object.
 */
export const deleteTypeEventsTypeEvents = async (id) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_PG}api/v1/type-events-type-events/${id}`;
  const result = await axios.delete(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};
