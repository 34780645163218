import useSwr from "swr";
import { config } from "config.js";

/**
 * Fetches a point type event by its ID.
 *
 * @param {number} id - The ID of the point type event.
 * @return {Object} An object containing the fetched data and any error that occurred.
 */
const useFetchPointTypeEvent = ({id, libraryId}) => {
  let url = `${config.URL_BACKEND_PG}api/v1/point-type-events`;
  if(id){
    url = `${url}?id=${id}`;
  }
  if (libraryId) {
    url = `${url}?libraryId=${libraryId}`;
  }
  const { data, error } = useSwr(url);
  return { data, error };
};

export default useFetchPointTypeEvent;
