import Layout from "components/Layout/AdminDecimetrix";
import WrapperMainCards from "components/Cards/WrapperMainCards";

import { HubContainer } from "./indexStyles";

import contentCards from "./contentCardsHub";

const ConfigAdmin = () => {
  return (
    <Layout>
      <HubContainer>
        <h1 className="title">
          Decimetrix<sup style={{ fontSize: 25 }}>®</sup> Green Dragon <br />{" "}
          Digital Twin Configurations Tools
        </h1>
        <WrapperMainCards contentCards={contentCards} />
      </HubContainer>
    </Layout>
  );
};

export default ConfigAdmin;
