import { PRIMARY_COLOR } from "utils/const";
import styled from "styled-components";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Accordion from "@mui/material/Accordion";

export const ContentFilterItemLabel = styled("label")`
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .input-start {
    margin-bottom: 10px;
  }
`;

export const ContentFilterItem = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-around;

  .start {
    margin-right: 21px;
  }
`;

export const FilterTabStyles = styled("div")`
  width: 100%;

  div {
    display: flex;
    align-items: center;
    align-content: center;
  }
  p {
    font-size: 2.4rem;
  }

  .date {
    width: 100%;
    padding: 0 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CustomizedAccordion = styled(Accordion)`
  margin-top: 0px;
  background-color: #f9f9f9;
  border: "none";
  padding: 10px;
  box-shadow: none;
`;

export const useStyles = makeStyles(() => ({
  detailRoot: {
    padding: "0 16px",
  },
  expanded: {},
  root: {
    minHeight: 8 * 6,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    margin: "0px 0",
    "&$expanded": {
      margin: "0px 0",
      marginBottom: 0,
    },
  },
}));

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }
`;

export const WrapperTypeObjectTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0 0 10px 0px; */

  .icon-title {
    display: flex;
    align-items: center;
  }
  p {
    font-size: 1.4rem;
  }
`;
export const WrapperTypeElementTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  .icon-title {
    display: flex;
    align-items: center;
  }
`;
export const FormInput = styled.input`
  margin-left: 1px;
  margin-right: 10px;
  width: 180%;
  border: none;
  background-color: #f9f9f9;
  color: #000008;
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid #31a636;
`;
export const Input = styled.input`
  margin-left: 1px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 5px;
  width: 100%;
  height: 28px;
  border-radius: 3px;
  border: none;
  background-color: #f9f9f9;
  color: #1c1c1c;
  font-size: 1.4rem;
  /* cursor: line; */
  border: 1px solid #31a636;
`;
export const FormTextarea = styled.textarea`
  margin-left: 1px;
  padding-left: 5px;
  margin-right: 10px;
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  color: #1c1c1c;
  font-size: 1.4rem;
  /* cursor: line; */
  border: 1px solid #31a636;
`;

export const FormLabel = styled.label`
  margin-left: 1px;
  margin-right: 10px;
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  color: #1c1c1c;
  font-size: 1.4rem; ;
`;
export const WrapperButtonsIcons = styled("div")`
  position: right;
  display: flex;
  margin-right: 1px;

  align-items: right;
  justify-content: space-between;

  margin-left: 1px;
  width: 40%;
`;

export const ButtonCard = styled.button`
  width: 100%;
  height: 37px;
  border-radius: 10px;
  border: none;
  background-color: ${PRIMARY_COLOR};
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  align-items: center;

  &:hover {
    background-color: #1c1c1c;
    cursor: pointer;
  }
`;

export const SelectRegion = styled.select`
  margin: 5px 0;
  width: 100%;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 5px;
  padding: 5px;
  background-color: #ffffff;
  font-size: 1.6rem;
`;

export const SummaryFeature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0px 0;
  margin-top: 50px;
  h3 {
    display: flex;
    padding: 1%;

    font-size: 1.6rem;
  }

  p {
    margin: 4px 0px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1.6rem;
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    font-size: 1.5rem;
  }
  textarea {
    height: 60px;
  }
`;

export const LabelForm = styled.b`
  font-size: 1.6rem;
  margin: 4px 0px 5px 5px;
  cursor: pointer;
`;

export const InputForm = styled.p`
  /* margin: 10px 0px 5px 5px; */
  font-size: 1.6rem;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 5px;
  height: 30px;
  width: 100%;
`;
