import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { setSectionCollection } from "redux/actions";

const ViewSection = () => {
  const dispatch = useDispatch();

  const section = useSelector(
    (state) => state.collectionReducer.sectionCollection
  );

  const handleAlignment = (event, nextSection) => {
    dispatch(setSectionCollection(nextSection));
  };

  return (
    <ToggleButtonGroup
      value={section[0].toUpperCase() + section.slice(1)}
      exclusive
      onChange={handleAlignment}
      aria-label="text view"
    >
      <ToggleButton value="gallery">
        <p>Gallery</p>
      </ToggleButton>
      <ToggleButton value="map">
        <p>Map</p>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ViewSection;
