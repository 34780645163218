// Required modules
import styled, { css } from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "utils/const";

export const AddField = styled.div`
  min-width: 400px;
  width: 100%;
  font-size: 2rem;

  .input-field {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    padding-left: 20px;
  }

  .required {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 5%;
    padding-top: 30px;
    height: auto;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
    .name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;

      .input {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;

        span {
          font-size: 1rem;
          color: red;
        }
      }
    }
    .type {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 35%;
    }
  }
  .select {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }

  .required2 {
    display: flex;
    flex-direction: column;
    width: 15%;
  }

  #input-checkbox > input[type="checkbox"] {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    appearance: none;
    outline: none;
    cursor: pointer;
  }

  #input-checkbox > input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #input-checkbox > input[type="checkbox"]:checked {
    background-color: ${SECOND_COLOR};
    border-color: ${SECOND_COLOR};
  }

  #input-checkbox > input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAfZJREFUaEPtmF8rBUEYxp9DSVKSpCQpSW4kJUlJkpKkhE/h1p2P4M61LyAkSZIkSSeSJJIkSZIkJUkSvXrUXpw/s/POnj1bM7fnzLu/3/PO7EybQsJHKuH88AJxd9B3wHdAmYBfQsoA1dN9B9QRKgv4DigDzDZ9AkAVgIV89YuxA+MAloC/a84MgLlcEsUmMAZgGUApoS8ATAE4zyZRTAKjTL6MsJeEP0tCB0YIX07YK8KfJmEPDBO+grDXhD/JBy+/x72EhrjmKwl7Q/hjE/i4BQaZvLwuZdwS/sgUPk6BAcJXE/YOwCSAwzDwcQn0E76GsPeET4eFDyMwC2AFgLyXNaOP8LUs8kD4A9uiJpt4GsA8DxM5VGwlerlh6wj7SPh9W3iTDsi6XAw8QE5EG4keJl/PWk+E39PAmwjIf7QS3YRvIOwza+5q4U0FNBJdhG8k7Avhd1zAhxGwkejkmm8i7Cvht13BhxUII9HB5JsJ+wZA7vhbLuFtBEwk2gnfQth3wm+6hrcVyCVRQvhWwn4QfiMKeI1ANgkRaCPsJ+HXo4LXCmSS+Gf9IvxalPAuBDJJfBN+NWp4VwJBiR/Cy72pIMPkLmQKIie2CMgXhYINlwIFgw4+yAvEEnvgob4DvgPKBPwSUgaonu47oI5QWcB3QBmgevovuadeMWdblWkAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    filter: invert(100%);
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid ${SECOND_COLOR};
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const Button = styled.button`
  background: ${PRIMARY_COLOR};
  color: #fff;
  padding: 8px 13px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin: 13px 0;
  transition: 0.3s all ease-in-out;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 21px;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 1px 5px 1px;
  border-radius: 5px;
  margin: 13px 0;

  .fields {
    display: flex;
    justify-content: center;
    row-gap: 1rem;
    column-gap: 2rem;
    align-items: center;
    padding-bottom: 2rem;
    flex-wrap: wrap;

    .field {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      box-shadow: rgba(149, 157, 165, 0.5) 0px 1px 5px 1px;
      border-radius: 5px;
      padding: 1rem;
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;

      .delete {
        position: absolute;
        top: -5px;
        right: -5px;
        cursor: pointer;
      }

      .update {
        position: absolute;
        top: -5px;
        right: 12px;
        cursor: pointer;
      }

      span {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
  }

  .handling {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 5px 0;

    button {
      display: flex;
      background: ${PRIMARY_COLOR};
      color: #fff;
      padding: 8px 13px;
      outline: none;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      margin: 13px 0;
      transition: 0.3s all ease-in-out;
    }

    button:hover {
      background: ${SECOND_COLOR};
    }

    .label {
      font-size: 1.6rem;
      font-weight: 600;
      color: #5c5757;
    }
  }
`;

export const ConfigAdminContainer = styled.div`
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  width: 100%;
  padding: 5px 21px 0 21px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  ${(props) =>
    props.neuron &&
    css`
      background-image: url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png");
      background-repeat: no-repeat;
      background-size: 25%;
      background-position: center;
    `}
`;

export const ConfigObjectsContainer = styled.div`
  display: flex;
  margin-top: 90px;
  width: 100%;
  padding: 5px 21px 0 21px;
  align-items: center;
  justify-content: right;
  position: relative;
`;

export const TitleAdminConfig = styled.h1`
  font-size: 3rem;
  /* text-transform: capitalize; */
  margin: 20px 0;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4rem;
`;

export const ItemsAdminConfig = styled.div`
  display: flex;
  justify-content: center;
`;

export const ItemAdminCofig = styled.div`
  flex: 1;
  box-shadow: rgba(79, 79, 79, 0.2) 0px 0px 5px 0px;
  padding: 13px 21px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
  margin: 13px;
  transition: all 0.3s ease-in-out;

  h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: 400;
  }

  .bottom {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const RowAdminConfig = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  transition: 0.3s all ease-in-out;

  ${(props) =>
    props.boxShadow &&
    css`
      box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
      border-radius: 5px;
      padding: 0 10.5px;
    `}

  &.down {
    animation-name: down;
    animation-duration: 0.3s;
  }

  &.up {
    animation-name: up;
    animation-duration: 0.3s;
  }

  @keyframes down {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes up {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
    }
  }
`;

export const ColumnAdminConfig = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 21px;

  ${(props) =>
    props.large &&
    css`
      padding: 0;
    `}
`;

export const CreateNewRegionButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 21px;
  font-size: 2rem;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  padding: 21px;
  border-radius: 5px;

  button {
    width: 100%;
    display: flex;
    background: ${PRIMARY_COLOR};
    color: #fff;
    padding: 8px 13px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 13px 0;
    transition: 0.3s all ease-in-out;
  }

  button:hover {
    background: ${SECOND_COLOR};
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    font-size: 17px;
  }
`;

export const PentagonoContainer = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .text {
    display: block;
    font-weight: 600;
    cursor: pointer;
    color: #5c5757;
    font-size: 1.6rem;
  }
`;

export const Pentagono = styled.div`
  width: ${(props) => props.width * 2 + 150}px;
  height: ${(props) => props.width * 2 + 150}px;
  background: ${(props) =>
    props.background ||
    'url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png"), #FFF'};
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  opacity: ${(props) => props.opacity || "1"};
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  ${(props) =>
    props.small &&
    css`
      position: relative;
      width: 140px;
      height: 140px;
      z-index: 11;
    `}

  ${(props) =>
    props.into &&
    css`
      position: absolute;
      width: 140px;
      height: 140px;
      z-index: 12;
    `}
`;

export const TitleColumn = styled.h2`
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  margin: 15px 0;
`;

export const ButtonAdminConfig = styled.button`
  background: ${PRIMARY_COLOR};
  margin: 13px 0;
  outline: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 8px 13px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:hover {
    background: ${SECOND_COLOR};
  }

  ${(props) =>
    props.large &&
    css`
      width: 100%;
    `}
`;

export const IconAdminConfigChangeColum = styled.span`
  font-size: 44px;
  cursor: pointer;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
`;

export const SeparatorWithText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin: 13px 0;

  .left {
    flex: 1;
    background: ${SECOND_COLOR};
    margin-right: 1rem;
    height: 2px;
  }

  .right {
    flex: 1;
    background: ${SECOND_COLOR};
    height: 2px;
    margin-left: 1rem;
  }
`;

export const InformationShare = styled.div`
  background-image: url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png");
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  padding: 21px;
  border-radius: 5px;
  /* border: 1px dashed ${SECOND_COLOR}; */
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;

  .body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${SECOND_COLOR};
  }
`;

export const ReturnSettingsPage = styled.div`
  position: absolute;
  top: 5px;
  left: 42px;
  width: 27.5px;
  height: 27.5px;
  cursor: pointer;
  font-size: 2rem;
  color: #fff;
  background: ${PRIMARY_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  transition: 0.3s all ease-in-out;

  &:hover {
    transform: scale(1.05);
    background: ${SECOND_COLOR};
  }
`;

export const SelectType = styled.div`
  position: absolute;
  top: 33px;
  left: 80px;
`;

export const SelectContainerAdminConfig = styled.div`
  font-size: 2rem;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 21px;

  p {
    font-size: 2rem;
  }

  select {
    background: ${PRIMARY_COLOR};
    color: #fff;
    padding: 8px 13px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    margin: 0 10px;
  }
`;

export const LineContainerAdminConfig = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .container {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .text {
    display: block;
    font-weight: 600;
    cursor: pointer;
    color: #5c5757;
    font-size: 1.6rem;
  }

  .line {
    width: 50px;
    height: ${(props) => props.width * 2}px;
    background: ${(props) => props.color};
  }

  .separator {
    width: ${(props) => props.separator * 2}px;
  }

  .circle {
    width: ${(props) => props.width * 8}px;
    height: ${(props) => props.width * 8}px;
    background: ${(props) => props.color};
    border-radius: 50%;
  }
`;
