/* eslint-disable no-loop-func */
import { ComputeEngine } from "@cortex-js/compute-engine";
import "mathlive";

const ce = new ComputeEngine();

export default function operationsExp(arrExp, arrNum) {
  let expresiones = arrExp.length > 0 ? arrExp : [];
  const numericos = arrNum.length > 0 ? arrNum : [];
  const unsolved = [];
  const result = [];

  let signal = false;
  let expr = "";
  let newName = "";
  let tmp = 0;
  let occurrencies = 0;

  while (!signal) {
    //recorrer el arreglo de las expresiones las veces del while hasta que se resuelvan todas
    expresiones.forEach((fld, inx) => {
      expr = fld.operation;
      //verifica si la expresion es valida → no malformada
      if (!ce.parse(expr).isValid) {
        fld.value = "Math Error";
        unsolved.push(fld);
        expresiones = expresiones.filter((it) => it.name !== fld.name);
        return;
      }
      //validar si la expresion se puede resolver sin otros campos → e^(-i.PI) = -1
      const firstTry = ce.parse(expr).N().numericValue;
      if (firstTry !== null) {
        fld.value = `${parseFloat(firstTry).toFixed(4)}`;
        numericos.push(fld);
        result.push(fld);
        expresiones = expresiones.filter((it) => it.name !== fld.name);
        return;
      }
      //validar si una expresion tiene campos definidos dentro sino se va a unsolved
      //la expresion entre expresiones es viable
      let semaforo = { name: fld.name, status: true };
      [...expresiones, ...numericos].forEach((item) => {
        newName = item.name.replace(/\s+/g, "");
        if (expr.includes(newName)) {
          semaforo = { name: fld.name, status: false };
        }
      });

      // si la expresion no contiene campos numericos o de expresiones (definidos)
      // no tiene campos con qué operarse y no se resuelve sin campos

      if (semaforo.status) {
        fld.value = "Math Error";
        unsolved.push(fld);
        expresiones = expresiones.filter((it) => it.name !== fld.name);
        return;
      }
      //reemplazar los campos numericos en las expresiones (tambien contiene exp resueltas)
      numericos.forEach((numerico) => {
        newName = numerico.name.replace(/\s+/g, "").trim();
        occurrencies = Array.from(expr.matchAll(`${newName}`)).length;
        if (occurrencies === 0) return;
        expr = expr.replace(
          new RegExp(`\\b${newName}\\b`, "g"),
          parseFloat(numerico.value)
        );
      });

      tmp = ce.parse(expr).N().numericValue;
      // la evaluacion de la expresion debe ser un numero o booleano para incluirse en los numericos
      if (tmp !== null) {
        fld.value = `${tmp}`;
        numericos.push(fld);
        result.push(fld);
        expresiones = expresiones.filter((it) => it.name !== fld.name);
        return;
      }

      // como la operacion aun no se resuelve porque puede tener campos sin resolver
      // verificamos si tiene o no esos campos

      if (tmp === null) {
        let noResoluble = true;
        [...expresiones, ...numericos].forEach((item) => {
          newName = item.name.replace(/\s+/g, "");
          if (expr.includes(newName)) noResoluble = false;
        });

        // como la expresion es no resoluble entonces se ingresa en el arreglo de unsolved

        if (noResoluble) {
          fld.value = "Math Error";
          unsolved.push(fld);
          expresiones = expresiones.filter((it) => it.name !== fld.name);
          return;
        }
      }
    });
    if (expresiones.length === 0) signal = true;
  }
  return [...result, ...unsolved];
}
