import styled from "styled-components";

export const ProfileStyle = styled.div`
  .profile-title {
    font-size: 3rem;
  }

  .profile-details-item {
    font-size: 1.5rem;
  }
`;
