import { Container, ContainerLoad } from "./styles";
import { BsTable } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getPointsPolygon } from "services/sendPolygonGeometry";
import {
  setDataObjectsPolygon,
  setDataEventPolygon,
} from "redux/actions/admin";
import LoadingStyle from "components/Lodings/Loading";
import { useState } from "react";

const coordinates = [
  [-373.929210164948, 85.051129],
  [-377.6997060058268, -84.41022077070609],
  [-19.973913102456663, -83.93182739311646],
  [-17.145855400279686, 84.9166581475817],
  [-373.929210164948, 85.051129],
];

export default function FloatingTable({ setSeePolygonTable }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const objects = useSelector(
    (state) => state.digitalTwinReducer.filteredObjects
  );
  const operations = useSelector(
    (state) => state.digitalTwinReducer.filteredOperations
  );

  const numberOpenTables = localStorage.getItem("tablesOpen");

  const handleSeeTable = async () => {
    setLoading(true);
    const { features } = getPointsPolygon(coordinates, objects);
    const objectsIntoPolygon = features.map(
      (feature) => feature.properties.object
    );
    dispatch(setDataObjectsPolygon(objectsIntoPolygon));

    const { features: featuresOperations } = getPointsPolygon(
      coordinates,
      operations
    );
    const operationsIntoPolygon = featuresOperations.map(
      (feature) => feature.properties.operation
    );
    dispatch(setDataEventPolygon(operationsIntoPolygon));
    setSeePolygonTable(true);
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <ContainerLoad>
          <LoadingStyle />
        </ContainerLoad>
      )}
      <Container>
        {numberOpenTables === null || numberOpenTables === "0" ? (
          <></>
        ) : (
          <p className="dynamic-num">{`${numberOpenTables}`}</p>
        )}
        <span className="icon-table" onClick={handleSeeTable}>
          <BsTable />
        </span>
        <div className="container-tooltip">
          <span className="tooltip">See All Element Of Map</span>
        </div>
      </Container>
    </>
  );
}
