import { Container } from "./showDataStyles";

const Terms = ({ data }) => {
  const { title, mainText, info } = data;
  return (
    <Container>
      <h1 className="title">{title}</h1>
      <p className="text">{mainText}</p>
      <ol className="list-number">
        {info.map((item) => {
          return (
            <li>
              <h3 class="subtitle">{item.subtitle}</h3>
              <p className="text">{item.text}</p>
            </li>
          );
        })}
      </ol>
      <p className="text last-update">Last updated: July 1st 2023.</p>
    </Container>
  );
};

export default Terms;
