import styled from "styled-components";

export const CustomInput = styled.input`
  width: 50%;
`;

export const LoadCsv = styled("div")`
  width: 100vw;
  display: flex;
  align-items: center;

  input {
    position: absolute;
  }
`;
