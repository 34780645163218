import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const PopUpContainer = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .title {
    font-size: 1.8;
    font-weight: 600;
    text-transform: uppercase;
  }

  .row {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1.5rem;
      font-weight: 600;
    }

    span {
      font-size: 1.5rem;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .button-actions {
    background: ${PRIMARY_COLOR};
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 1.5rem;
  }
`;
