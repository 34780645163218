import * as turf from "@turf/turf";

const calculateCenter = (objects) => {
  const locations = objects.map((object) => {
    const location = [
      Number(object.location.latitude),
      Number(object.location.longitude),
    ];
    return location;
  });
  var features = turf.points(locations);
  const center = turf.center(features);
  return center.geometry.coordinates;
};

export default calculateCenter;
