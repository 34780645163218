import findTypeEventsTypeElements from "services/findTypeEventsTypeElementsPg";
import createTypeEventsTypeElements from "services/createTypeEventsTypeElementsPg";
import deleteTypeEventsTypeElements from "services/deleteTypeEventsTypeElementsPg";

const UpdateEventsElementsCallback = ({
  pointTypeEvent,
  typeEventsTypeElements,
  setTypeEventsTypeElements,
}) => {
  const update = async () => {
    const { id } = pointTypeEvent;
    const lastEventsTypes = await findTypeEventsTypeElements({
      pointTypeEventId: id,
    });
    const toDelete = [];
    const toCreate = [];

    lastEventsTypes.forEach(({ typeElementId }) => {
      if (!typeEventsTypeElements[typeElementId]) {
        const ids = lastEventsTypes
          .filter((last) => last.typeElementId === typeElementId)
          .map((last) => last.id);
        ids.filter((item, index) => ids.indexOf(item) === index);
        toDelete.push(...ids);
      }
    });

    if (typeEventsTypeElements) {
      Object.keys(typeEventsTypeElements).forEach((typeElementId) => {
        if (
          !lastEventsTypes.find(
            (e) => parseInt(e.typeElementId) === parseInt(typeElementId)
          )
        )
          toCreate.push(typeElementId);
      });
    }

    await Promise.all(
      toCreate?.map(async (typeEventId) => {
        await createTypeEventsTypeElements({
          pointTypeEventId: pointTypeEvent.id,
          typeElementId: parseInt(typeEventId),
        });
      })
    );

    await Promise.all(
      toDelete?.map(async (id) => {
        await deleteTypeEventsTypeElements(id);
      })
    );

    setTypeEventsTypeElements({});
  };

  return update;
};

export default UpdateEventsElementsCallback;
