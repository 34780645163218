// React
import { useEffect, useMemo, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  openSelectColumns,
  getTableRowsFilter,
  getTableRowsFull,
  setRowNum,
  showEventsByInspectionType,
  showOperationsByType,
  showEventsByType,
  // setFilteredOperations,
  setStateObjectFilterTable,
  setStateEventFilterTable,
  setShowDataComponentsByType,
  setShowDataComponentsLibrary,
  setShowDataComponents,
  setFilterComponent,
  setStateComponentFilterTable,
  setShowEventsByLibrary,
  setShowEvents,
  setStateFilterButtonTable,
  // setObjectsFilterTable,
} from "redux/actions/digitalTwin";
import {
  setComponentsFilterTable,
  setDataEventFilterTable,
  // setDataEventPolygon,
  setObjectsFilterTable,
  setShowDataObjects,
  showMarkerByElementWithMedia,
  showMarkerseByTypeElement,
  showMarkerseByTypeObject,
} from "redux/actions/admin";
// Helpers
import { isUrl, nameUrl } from "helpers/isUrl";
// Dependencies
import * as turf from "@turf/turf";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
// Styles
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import TollIcon from "@mui/icons-material/Toll";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
// import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {
  ButtonInfo,
  Change,
  ContentTable,
  Divider,
  SearchTable,
} from "./GenericTableStyle";
import Select from "react-select";

import {
  COMPONENT_CLUSTER_COLOR,
  EVENT_CLUSTER_COLOR,
  INFORMATION_COLOR,
  OBJECT_CLUSTER_COLOR,
  PRIMARY_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";

// import { setDataObjectsPolygon, setObjectsFilterTable } from "redux/actions/admin";
function setFiltersEventsToFalse(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      obj[prop] = false;
    }
  }
  return obj;
}
function setFiltersEventsToTrue(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      obj[prop] = true;
    }
  }
  return obj;
}

function setFiltersTrue(component) {
  const components = component.map((elm) => {
    return { ...elm, color: PRIMARY_COLOR, state: true };
  });
  return components;
}

function setFiltersFalse(component) {
  const components = component.map((elm) => {
    return { ...elm, color: VISIBILITY_OFF_ICON, state: false };
  });
  return components;
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

function Filter({ column, table, setValue }) {
  const [changeRange, setChangeRange] = useState(false);
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  var columnFilterValue = column.getFilterValue();
  if (setValue) {
    columnFilterValue = "";
  }

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column, firstValue]
  );
  const dateValue =
    sortedUniqueValues[0]?.length === 23 || sortedUniqueValues[0]?.length === 24
      ? sortedUniqueValues.map((elm) => {
          return elm.substring(0, 10);
        })
      : [];
  const sortedUniqueDateValues = [...new Set(dateValue)];

  return typeof firstValue === "number" ? (
    <div>
      {changeRange === false ? (
        <div>
          <Change onClick={() => setChangeRange(!changeRange)}>
            <FiberSmartRecordIcon sx={{ mb: "-5px", mr: "5px" }} />
            <div className="container-tooltip">
              <span className="tooltip">Range Mode</span>
            </div>
          </Change>
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) => column.setFilterValue((old) => [value, value])}
            placeholder={`Num... (${column.getFacetedUniqueValues().size})`}
            className="tableCell2"
          />
        </div>
      ) : (
        <div>
          <div>
            <Change onClick={() => setChangeRange(!changeRange)}>
              <TollIcon sx={{ mb: "-5px", mr: "5px" }} />
              <div className="container-tooltip">
                <span className="tooltip">Unic Value</span>
              </div>
            </Change>
          </div>

          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[0] ?? ""}
            onChange={(value) =>
              column.setFilterValue((old) => [value, old?.[1]])
            }
            placeholder={`Min ${
              column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ""
            }`}
            className="tableCell2"
          />
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) =>
              column.setFilterValue((old) => [old?.[0], value])
            }
            placeholder={`Max ${
              column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ""
            }`}
            className="tableCell2"
          />
        </div>
      )}

      <div className="h-1" />
    </div>
  ) : typeof firstValue === "string" ? (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueDateValues.length !== 0
          ? sortedUniqueDateValues
              .slice(0, 5000)
              .map((value) => <option value={value} key={value} />)
          : sortedUniqueValues
              .slice(0, 5000)
              .map((value) => <option value={value} key={value} />)}
      </datalist>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="tableCell"
        list={column.id + "list"}
      />
    </>
  ) : (
    <></>
  );
}

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

const defaultColumns = (headers, keyValues, rows) => {
  const allValues = [];
  rows?.map((elm) => {
    for (let key in elm) {
      if (keyValues.includes(key) === false) {
        allValues.push(key);
      }
    }
  });
  const uniqueArray = [...new Set(allValues)];
  const allHeaders = headers.concat(uniqueArray);
  const allKeyValues = keyValues.concat(uniqueArray);
  const columnsFormat = [
    {
      header: null,
      footer: (props) => props.column.id,
      id: ` `,
      columns: allHeaders.map((clum, index) => {
        return {
          accessorKey: `${allKeyValues[index]}`,
          cell: (info) => info.getValue(),
          header: () => <span>{clum}</span>,
          footer: (props) => props.column.id,
        };
      }),
    },
  ];
  return columnsFormat;
};

/**
 * Renders a customized table with given headers, rows, and keyValues. The table
 * can handle click and delete events, with the option to add dynamic values.
 *
 * @param {Object} headers - An array of table headers.
 * @param {Object} rows - An array of table rows.
 * @param {Object} keyValues - An array of key values to map to row data.
 * @param {Function} handlerClick - A function to handle row click events.
 * @param {Function} handlerDelete - A function to handle row delete events.
 * @param {Array} isDynamicEvent - An array of objects containing dynamic values.
 * @return {JSX.Element} A customized table component.
 */
const GenericTable = ({
  headers,
  rows,
  keyValues,
  typeOfTable,
  handlerClick,
  rowsDynamic,
  handlerDelete,
  style,
  tableVisibility = true,
  setTableVisibility,
  isDynamicEvent,
  typeOfLibrary,
  handleClose,
  digitalTwin,
  handleDrawerClose,
  handleDrawerOpen,
  state,
  customHeaderWidth,
  customTableWidth,
}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(() => [...rows]);
  const [columns, setColumns] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [useFilter, setUseFilter] = useState(false);
  const [columnResizeMode] = useState("onChange");
  const [filterValue, setFilterValue] = useState(false);
  const [tablesOpen, setTablesOpen] = useState(0);
  const [idFilters, setIdFilters] = useState([]);
  const [buttonFilters, setButtonFilters] = useState(true);
  const [messageInfoMap, setMessageInfoMap] = useState(false);
  const [messageInfoFilter, setMessageInfoFilter] = useState(false);

  const optionDownload = useSelector(
    (state) => state.digitalTwinReducer.optionDownload
  );

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );
  const showOperationByType = useSelector(
    (state) => state.digitalTwinReducer.showOperationsByType
  );
  const showAllEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );
  const showEventsByLibrary = useSelector(
    (state) => state.digitalTwinReducer.showEventsByLibrary
  );

  const showMarkersByLibrary = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );

  const showDataComponentsByType = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsByType
  );
  const showDataComponentsLibrary = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsLibrary
  );

  const [eventByInspectionType, setEventByInspectionType] = useState([]);
  const [operationByType, setOperationByType] = useState([]);
  const [allEventByType, setAllEventsByType] = useState([]);
  const [eventsByLibrary, setAllEventsByLibrary] = useState([]);

  const [markersByLibrary, setMarkersByLibrary] = useState([]);
  const [markersByTypeElement, setMarkersByTypeElement] = useState([]);

  const [dataComponentsByType, setDataComponentsByType] = useState([]);
  const [dataComponentsLibrary, setDataComponentsLibrary] = useState([]);

  useEffect(() => {
    setEventByInspectionType(showEventByInspectionType);
    setOperationByType(showOperationByType);
    setAllEventsByType(showAllEventsByType);
    setAllEventsByLibrary(showEventsByLibrary);

    setMarkersByLibrary(showMarkersByLibrary);
    setMarkersByTypeElement(showMarkersByTypeElement);

    setDataComponentsByType(showDataComponentsByType);
    setDataComponentsLibrary(showDataComponentsLibrary);
  }, []);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    const numTables = localStorage.getItem("tablesOpen");
    if (numTables === null) {
      localStorage.setItem("tablesOpen", 0);
    }
  }, []);

  useEffect(() => {
    setData([]);
    const res = defaultColumns(headers, keyValues, rowsDynamic);
    setData(rows);
    setColumns(res);
    dispatch(setRowNum(table.getPrePaginationRowModel().rows.length));
    const numTables = localStorage.getItem("tablesOpen");
    if (numTables > 0) {
      setTablesOpen(JSON.parse(numTables));
    }
  }, [rows, headers, dispatch, table, keyValues, columnFilters]);

  useEffect(() => {
    dispatch(setRowNum(table.getPrePaginationRowModel().rows.length));
  }, [rows, table, dispatch, isDynamicEvent]);

  useEffect(() => {
    const allRowsById = table.getRowModel().rowsById;
    const allRows = [];
    for (let key in allRowsById) {
      allRows.push(allRowsById[key]);
    }
    dispatch(getTableRowsFull(allRows));
  }, [dispatch, table, optionDownload]);

  useEffect(() => {
    const allRowsById = table.getRowModel().rowsById;
    const allRows = [];
    for (let key in allRowsById) {
      allRows.push(allRowsById[key]);
    }
    const filterData = [];
    allRows.forEach((elm) => {
      const currentColumnVisible = elm.getVisibleCells();
      let currentData = {};
      currentColumnVisible.forEach((elm2) => {
        currentData[elm2.column.id] = elm2.getValue();
        if (currentColumnVisible.length === Object.keys(currentData).length) {
          filterData.push(currentData);
        }
      });
    });
    dispatch(getTableRowsFilter(filterData));
  }, [dispatch, table, optionDownload]);

  let dynamicValues = {};
  isDynamicEvent?.map((value) => (dynamicValues[value.id] = value.isDynamic));

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const options = table?.getAllLeafColumns().map((column) => ({
    value: column.id,
    label: column.id,
    column: column,
  }));

  const CheckboxSelect = () => {
    const onpenSelect = useSelector(
      (state) => state.digitalTwinReducer.openSelectColumns
    );
    const handleOpenClose = (bolean) => {
      dispatch(openSelectColumns(bolean));
    };

    return (
      <div style={{ width: "200px", fontSize: "15px" }}>
        <Select
          menuPlacement="auto"
          options={[{ value: "Toggle All", label: "Toggle All" }, ...options]}
          isMulti
          closeMenuOnSelect={false}
          menuIsOpen={onpenSelect}
          onMenuClose={() => handleOpenClose(false)}
          onMenuOpen={() => handleOpenClose(true)}
          placeholder="Columns Visibility ..."
          components={{
            Option: ({ label, data }) => (
              <div>
                <div>
                  <input
                    style={{
                      marginLeft: "5px",
                    }}
                    type="checkbox"
                    checked={
                      data.value === "Toggle All"
                        ? table?.getIsAllColumnsVisible()
                        : data?.column.getIsVisible()
                    }
                    onChange={
                      data.value === "Toggle All"
                        ? table?.getToggleAllColumnsVisibilityHandler()
                        : data?.column.getToggleVisibilityHandler()
                    }
                  />{" "}
                  {capitalizeFirstLetter(label)}
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  };

  const handlerSetValue = () => {
    handleSetViewMap();
    setFilterValue(true);
    setTimeout(() => {
      setFilterValue(false);
    }, 1000);
  };

  // const handleOpenWindow = () => {
  //   const width = window.innerWidth;
  //   const height = window.innerHeight / 2;
  //   localStorage.setItem("tablesOpen", tablesOpen + 1);
  //   handleClose();
  //   window.open(
  //     "/new-window-table",
  //     "_blank",
  //     `width=${width},height=${height}`
  //   );
  // };

  const removeAccents = (str) => {
    const accentsMap = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      ñ: "n",
    };
    return str.replace(/[áéíóúñ]/gi, (match) => accentsMap[match]);
  };

  const handleGlobalFilterChange = (e) => {
    const value = e.target.value;
    const filteredData = rows.filter((row) => {
      return Object.values(row).some(
        (cellValue) =>
          removeAccents(String(cellValue).toLowerCase()) === value.toLowerCase()
      );
    });

    setData(filteredData);
  };

  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  const filterOperations = useSelector(
    (state) => state.digitalTwinReducer.filteredOperations
  );

  const dataComponentsPolygon = useSelector(
    (state) => state.adminReducer.dataComponentPolygon
  );

  const map = useSelector((state) => state.digitalTwinReducer.map);

  useEffect(() => {
    const objectsFilters = table.getRowModel().rowsById;
    const allIds = [];
    for (let key in objectsFilters) {
      allIds.push(objectsFilters[key].original.id);
    }
    setIdFilters(allIds);
  }, [
    table.getRowModel().rowsById,
    dataObjectsPolygon,
    filterOperations,
    dataComponentsPolygon,
  ]);

  const handleExtendView = (array) => {
    const features =
      array.length !== 0
        ? array.map((obj) => {
            if (obj.hasOwnProperty("type")) {
              return obj.geometry.coordinates;
            } else {
              if (obj.hasOwnProperty("locationComponent")) {
                return Array.of(
                  obj.locationComponent.longitude,
                  obj.locationComponent.latitude
                );
              } else {
                return Array.of(obj.location.longitude, obj.location.latitude);
              }
            }
          })
        : [];
    const featuresCollection = turf.points(features);
    const envelopeFeatures = turf.envelope(featuresCollection);

    map?.getMap().fitBounds(envelopeFeatures.bbox, {
      padding: 100,
      duration: 500,
    });
  };

  const handleViewMap = () => {
    setTableVisibility(false);
    setButtonFilters(false);
    dispatch(setStateFilterButtonTable(true));
    if (typeOfTable === "objects") {
      const env1 = setFiltersEventsToFalse(eventByInspectionType);
      const env2 = setFiltersEventsToFalse(operationByType);
      const env3 = setFiltersFalse(allEventByType);
      const env4 = setFiltersFalse(eventsByLibrary);
      const componentsByType = setFiltersFalse(dataComponentsByType);
      const componentsByLibrary = setFiltersFalse(dataComponentsLibrary);
      const currentDataTable = [];
      dataObjectsPolygon.forEach((elm) => {
        idFilters.filter((elm2) => {
          if (elm.id === elm2) {
            currentDataTable.push(elm);
          }
        });
      });
      dispatch(
        setShowEvents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(setObjectsFilterTable(currentDataTable));
      dispatch(setStateObjectFilterTable(true));
      handleExtendView(currentDataTable);
      handleDrawerClose();
    }
    if (typeOfTable === "events") {
      const currentDataEventsTable = [];
      const currentDataOperationTable = [];
      const objects = setFiltersFalse(markersByLibrary);
      const obj = setFiltersFalse(markersByTypeElement);
      const componentsByType = setFiltersFalse(dataComponentsByType);
      const componentsByLibrary = setFiltersFalse(dataComponentsLibrary);
      filterOperations.forEach((elm) => {
        idFilters.forEach((elm2) => {
          if (elm.properties.event.id === elm2) {
            if (
              elm.properties.event.typeOperation?.name === "Inspección flir" ||
              elm.properties.event.typeOperation?.name === "Leak" ||
              elm.properties.event.typeOperation?.name === "Sketch" ||
              elm.properties.event.typeOperation?.name === "Reparación"
            ) {
              return currentDataOperationTable.push(elm);
            } else if (elm.properties.event.hasOwnProperty("pointTypeEvent")) {
              return currentDataEventsTable.push(elm);
            }
          }
        });
      });
      const currentDataTable =
        typeOfLibrary === "Inspection Flir" ||
        typeOfLibrary === "Leak" ||
        typeOfLibrary === "Sketch" ||
        typeOfLibrary === "Repair"
          ? currentDataOperationTable
          : currentDataEventsTable;
      dispatch(setDataEventFilterTable(currentDataTable));
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(
        setShowDataObjects({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(showMarkerByElementWithMedia(true));
      handleExtendView(currentDataTable);
      dispatch(setStateEventFilterTable(true));
      handleDrawerClose();
    }
    if (typeOfTable === "components") {
      const env1 = setFiltersEventsToFalse(eventByInspectionType);
      const env2 = setFiltersEventsToFalse(operationByType);
      const env3 = setFiltersFalse(allEventByType);
      const env4 = setFiltersFalse(eventsByLibrary);
      const objects = setFiltersFalse(markersByLibrary);
      const obj = setFiltersFalse(markersByTypeElement);

      const currentDataTable = [];
      dataComponentsPolygon.forEach((elm) => {
        idFilters.filter((elm2) => {
          if (elm.id === elm2) {
            currentDataTable.push(elm);
          }
        });
      });
      dispatch(
        setShowEvents({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(
        setShowDataObjects({ color: `${VISIBILITY_OFF_ICON}`, state: false })
      );
      dispatch(setComponentsFilterTable(currentDataTable));
      dispatch(setFilterComponent(true));
      dispatch(setStateComponentFilterTable(true));
      handleExtendView(currentDataTable);
      handleDrawerClose();
    }
  };
  const handleSetViewMap = () => {
    setButtonFilters(true);
    setTableVisibility(true);
    dispatch(setStateFilterButtonTable(false));
    if (typeOfTable === "objects") {
      dispatch(setObjectsFilterTable([]));
      const env1 = setFiltersEventsToTrue(eventByInspectionType);
      const env2 = setFiltersEventsToTrue(operationByType);
      const env3 = setFiltersTrue(allEventByType);
      const env4 = setFiltersTrue(eventsByLibrary);
      const componentsByType = setFiltersTrue(dataComponentsByType);
      const componentsByLibrary = setFiltersTrue(dataComponentsLibrary);
      dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
      handleExtendView(dataObjectsPolygon);
      dispatch(setStateObjectFilterTable(false));
      handleDrawerOpen();
    }

    if (typeOfTable === "events") {
      dispatch(setDataEventFilterTable([]));
      const objects = setFiltersTrue(markersByLibrary);
      const obj = setFiltersTrue(markersByTypeElement);
      const componentsByType = setFiltersTrue(dataComponentsByType);
      const componentsByLibrary = setFiltersTrue(dataComponentsLibrary);
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(setShowDataComponentsByType(componentsByType));
      dispatch(setShowDataComponentsLibrary(componentsByLibrary));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
      dispatch(showMarkerByElementWithMedia(false));
      handleExtendView(filterOperations);
      dispatch(setStateEventFilterTable(false));
      handleDrawerOpen();
    }

    if (typeOfTable === "components") {
      dispatch(setComponentsFilterTable([]));
      const env1 = setFiltersEventsToTrue(eventByInspectionType);
      const env2 = setFiltersEventsToTrue(operationByType);
      const env3 = setFiltersTrue(allEventByType);
      const env4 = setFiltersTrue(eventsByLibrary);
      const objects = setFiltersTrue(markersByLibrary);
      const obj = setFiltersTrue(markersByTypeElement);
      dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(showEventsByInspectionType(env1));
      dispatch(showOperationsByType(env2));
      dispatch(showEventsByType(env3));
      dispatch(setShowEventsByLibrary(env4));
      dispatch(showMarkerseByTypeObject(objects));
      dispatch(showMarkerseByTypeElement(obj));
      dispatch(setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true }));
      dispatch(
        setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
      );
      dispatch(setFilterComponent(true));
      dispatch(setStateComponentFilterTable(false));
      handleExtendView(dataComponentsPolygon);
      handleDrawerOpen();
    }
  };

  useEffect(() => {
    if (!buttonFilters) {
      handleSetViewMap();
    }
  }, [typeOfLibrary]);

  const relationType = (relationId) => {
    const res = relationId;
    if (res[res.length - 1] === "O" && res[res.length - 3] === "/") return true;
    if (res[res.length - 1] === "C" && res[res.length - 3] === "/") return true;
    if (res[res.length - 1] === "E" && res[res.length - 3] === "/") return true;
  };
  const relationTypeColor = (relationId) => {
    const res = relationId;
    if (res[res.length - 1] === "O" && res[res.length - 3] === "/")
      return OBJECT_CLUSTER_COLOR;
    if (res[res.length - 1] === "C" && res[res.length - 3] === "/")
      return COMPONENT_CLUSTER_COLOR;
    if (res[res.length - 1] === "E" && res[res.length - 3] === "/")
      return EVENT_CLUSTER_COLOR;
  };

  return (
    <div style={{ marginTop: "0px" }}>
      <SearchTable width={style?.width} margin={state ? 0 : 20}>
        <div className="space">
          {/* {state ? (
            <></>
          ) : (
            <button onClick={() => handleOpenWindow()}>
              <OpenInNewRoundedIcon />
              <div className="container-tooltip">
                <span className="tooltipOpenWindow">Open in new window</span>
              </div>
            </button>
          )} */}
        </div>
        <div className="space">
          {/* <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="input"
            placeholder="Search all columns..."
          /> */}
          <input
            type="text"
            onChange={handleGlobalFilterChange}
            className="input"
            placeholder="Search all columns..."
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}
        >
          <CheckboxSelect />
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}
        >
          {useFilter || !buttonFilters ? (
            <>
              <button onClick={() => handlerSetValue()}>
                <RestartAltIcon sx={{ fontSize: "3rem" }} />
                <div className="container-tooltip">
                  <span className="tooltip">Reset Filters</span>
                </div>
              </button>
            </>
          ) : (
            <></>
          )}
          {useFilter ? (
            <button onClick={() => setUseFilter(!useFilter)}>
              <FilterAltOffIcon sx={{ fontSize: "3rem" }} />
            </button>
          ) : (
            <button onClick={() => setUseFilter(!useFilter)}>
              <FilterAltIcon sx={{ fontSize: "3rem" }} />
            </button>
          )}
          <ButtonInfo onClick={() => setMessageInfoFilter(!messageInfoFilter)}>
            {messageInfoFilter ? (
              <></>
            ) : (
              <ContactSupportIcon
                sx={{
                  fontSize: "1.6rem",
                  color: `${INFORMATION_COLOR}`,
                  ml: "-6px",
                  mb: "15px",
                }}
              />
            )}
            {messageInfoFilter ? (
              <div className="container-tooltip">
                <span className="tooltip">
                  <button
                    className="button"
                    onClick={() => setMessageInfoFilter(!messageInfoFilter)}
                  >
                    X
                  </button>
                  Decimetrix view filters tools
                </span>
              </div>
            ) : (
              <></>
            )}
          </ButtonInfo>
          {state === false ? (
            <>
              <div className="space">
                {buttonFilters ? (
                  <button onClick={() => handleViewMap()}>
                    <PublicIcon sx={{ fontSize: "3rem" }} />
                  </button>
                ) : (
                  <button onClick={() => handleSetViewMap()}>
                    <PublicOffIcon sx={{ fontSize: "3rem" }} />
                  </button>
                )}
              </div>
              <ButtonInfo onClick={() => setMessageInfoMap(!messageInfoMap)}>
                {messageInfoMap ? (
                  <></>
                ) : (
                  <ContactSupportIcon
                    sx={{
                      fontSize: "1.6rem",
                      color: `${INFORMATION_COLOR}`,
                      ml: "-7px",
                      mb: "15px",
                    }}
                  />
                )}
                {messageInfoMap ? (
                  <div className="container-tooltip">
                    <span className="tooltip">
                      <button
                        className="button"
                        onClick={() => setMessageInfoMap(!messageInfoMap)}
                      >
                        X
                      </button>
                      Note: This filter overrides the filters in the left panel
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </ButtonInfo>
            </>
          ) : (
            <></>
          )}
        </div>
      </SearchTable>
      {tableVisibility ? (
        <>
          <Divider />
          <ContentTable maxHeight={style?.maxHeight}>
            <table
              {...{
                style: {
                  width: customTableWidth || table.getCenterTotalSize()
                },
              }}
            >
              <div style={{ width: "100%" }}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const customWidth = customHeaderWidth || header.getSize() + 8;
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{
                              width: customWidth,
                            }}
                          >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <ExpandLessIcon />,
                                  desc: <ExpandMoreIcon />,
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                              {header.column.getCanFilter() && useFilter ? (
                                <div style={{ marginTop: "5px" }}>
                                  <Filter
                                    column={header.column}
                                    table={table}
                                    setValue={filterValue}
                                  />
                                </div>
                              ) : null}
                            </>
                          )}

                          <div
                            {...{
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `resizer ${
                                header.column.getIsResizing()
                                  ? "isResizing"
                                  : ""
                              }`,
                              style: {
                                transform:
                                  columnResizeMode === "onEnd" &&
                                  header.column.getIsResizing()
                                    ? `translateX(${
                                        table.getState().columnSizingInfo
                                          .deltaOffset
                                      }px)`
                                    : "",
                              },
                            }}
                          />
                        </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      style={{
                        backgroundColor:
                          index % 2 !== 0 ? "#eeeeee" : "#f9f9f9",
                      }}
                    >
                      {row.getVisibleCells().map((cell, index) =>
                        index === 0 ? (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                Width: cell.column.getSize() + 8,
                                cursor: "pointer",
                              },
                            }}
                            onClick={() =>
                              handlerClick(
                                row.original.id,
                                dynamicValues[row.original.id]
                              )
                            }
                          >
                            {flexRender(cell.getValue(), cell.getContext())}
                          </td>
                        ) : (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                Width: cell.column.getSize() + 8,
                              },
                            }}
                          >
                            {flexRender(cell.getValue(), cell.getContext()) ===
                            null ? (
                              <HorizontalRuleIcon />
                            ) : relationType(
                                flexRender(cell.getValue(), cell.getContext())
                              ) ? (
                              <p
                                style={{
                                  color: `${relationTypeColor(
                                    flexRender(
                                      cell.getValue(),
                                      cell.getContext()
                                    )
                                  )}`,
                                }}
                              >
                                {flexRender(cell.getValue(), cell.getContext())}
                              </p>
                            ) : isUrl(
                                flexRender(cell.getValue(), cell.getContext())
                              ) ? (
                              <a
                                href={flexRender(
                                  cell.getValue(),
                                  cell.getContext()
                                )}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {nameUrl(
                                  flexRender(cell.getValue(), cell.getContext())
                                )}
                              </a>
                            ) : (
                              flexRender(cell.getValue(), cell.getContext())
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
            <div className="h-2" />
            <div style={{ display: "flex" }}>
              <div>{table.getPrePaginationRowModel().rows.length} Rows|</div>
              <span className="flex items-center gap-1">
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount()}
                </strong>
                | Go to page:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    table.setPageIndex(page);
                  }}
                  className="border p-1 rounded w-16"
                />
              </span>

              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(
                    Number(e.target.value === "All" ? 1000000 : e.target.value)
                  );
                }}
              >
                {[5, 10, 15, 20, "All"].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <div className="flex items-center gap-2">
                <div style={{}}>
                  <Change
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <FirstPageIcon />
                  </Change>
                  <Change
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <NavigateBeforeIcon />
                  </Change>
                  <Change
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <NavigateNextIcon />
                  </Change>
                  <Change
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    <LastPageIcon />
                  </Change>
                </div>
              </div>
            </div>
          </ContentTable>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

GenericTable.defaultProps = {
  handlerDelete: () => {},
  handlerClick: () => {},
};

export default GenericTable;
