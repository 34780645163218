const getLibrariesTrue = (library) => {
  let counter = 0;
  library.forEach((elm) => {
    if (elm.state === true) {
      counter++;
    }
  });
  if (counter === library.length) return true;
  return false;
};

export default getLibrariesTrue;
