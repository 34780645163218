import { combineReducers } from "redux";
import locationCSVReducer from "./locationCSV";
import userReducer from "./user";
import digitalTwinReducer from "./digitalTwin";
import collectionReducer from "./collections";
import adminReducer from "./admin";

export default combineReducers({
  locationCSVReducer,
  userReducer,
  digitalTwinReducer,
  collectionReducer,
  adminReducer,
});
