import OpenDialog from "components/Dialogs/OpenDialog";
import { useEffect, useMemo, useState } from "react";
import { IoPencil, IoTrash } from "react-icons/io5";
import { FieldsContainer } from "../configAdminStyles";
import DescriptionOpenDialog from "./DescriptionOpenDialog";
import { TYPE_FIELDS } from "utils/constStrings";

export default function Fields({ handleChange, form, typeFields }) {
  const fieldsForm = useMemo(
    () => form?.fields?.value || [],
    [form?.fields?.value]
  );

  const [field, setField] = useState({
    name: "",
    type: "string",
    required: true,
    options: [],
    possible: [],
    min: 0,
    max: 1,
    operation: "",
    alias: "",
  });

  const [fields, setFields] = useState([...fieldsForm]);

  const setOpen = (open) => {
    if (!open) {
      setField({
        name: "",
        type: "string",
        required: true,
        options: [],
        possible: [],
        min: 0,
        max: 1,
        operation: "",
        alias: "",
      });
    }
    setDialog((current) => ({
      ...current,
      open,
      execute: handleExecute,
      update: false,
    }));
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setDialog((current) => ({
      ...current,
      open: true,
    }));
  };

  const handleExecute = () => {
    if (dialog.update) {
      handleUpdateExecute();
      return;
    }
    const newField = { ...field };
    handleChange({
      target: {
        name: "fields",
        value: [...fields, { ...newField }],
      },
    });
    setDialog((current) => ({
      ...current,
      open: false,
    }));
    setOpen(false);
  };

  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      disagree: "Cancel",
    },
    execute: handleExecute,
    update: false,
  });

  const handleUpdateExecute = () => {
    handleChange({
      target: {
        name: "fields",
        value: fields?.map((f) => {
          if (f.name === field.name) {
            return field;
          }
          return f;
        }),
      },
    });
    setFields(
      fields?.map((f) => {
        if (f.name === field.name) {
          return field;
        }
        return f;
      })
    );
    setField({
      name: "",
      type: "string",
      required: true,
      options: [],
      possible: [],
      min: 0,
      max: 1,
      operation: "",
      alias: "",
    });
    setDialog((current) => ({
      ...current,
      open: false,
      execute: handleExecute,
      update: false,
    }));
    setOpen(false);
  };

  const handleDeleteField = (name) => {
    handleChange({
      target: {
        name: "fields",
        value: fields.filter((field) => field.name !== name),
      },
    });
    setFields(fields.filter((field) => field.name !== name));
  };

  const handleUpdateField = (name) => {
    setField(fields.find((field) => field.name === name));
    setDialog((current) => ({
      ...current,
      open: true,
      execute: handleUpdateExecute,
      actions: {
        agree: "Update",
        disagree: "Cancel",
      },
      update: true,
    }));
  };

  useEffect(() => {
    setFields([...fieldsForm]);
    setField({
      name: "",
      type: "string",
      required: true,
      options: [],
      possible: [],
      min: 0,
      max: 1,
      operation: "",
      alias: "",
    });
  }, [fieldsForm]);

  return (
    <FieldsContainer>
      <OpenDialog
        openDialog={dialog?.open}
        setOpenDialog={setOpen}
        content={{
          title: "Add Field",
          description: (
            <DescriptionOpenDialog
              field={field}
              setField={setField}
              setDialog={setDialog}
              fields={fields}
              update={dialog.update}
            />
          ),
          ...dialog.actions,
        }}
        execute={handleExecute}
      />

      <div className="handling">
        <h2 className="label">Fields</h2>
        {TYPE_FIELDS.typeToLibrary === typeFields && (
          <button onClick={handleAddField}>Add Field</button>
        )}
      </div>

      <div className="fields">
        {fields?.map((field, index) => (
          <div key={index} className="field">
            {TYPE_FIELDS.typeToLibrary === typeFields && (
              <div>
                <span
                  className="delete"
                  onClick={() => handleDeleteField(field.name)}
                >
                  <IoTrash style={{ cursor: "pointer" }} color="red" />
                </span>
                <span
                  className="update"
                  onClick={() => handleUpdateField(field.name)}
                >
                  <IoPencil style={{ cursor: "pointer" }} color="blue" />
                </span>
              </div>
            )}
            <p>
              <span>Display Name:</span>{" "}
              {field.alias ? field.alias : field.name}
            </p>
            <p>
              <span>Name:</span> {field.name}
            </p>
            <p>
              <span>Type:</span> {field.type}
            </p>
            <p>
              <span>Required:</span> {field.required ? "Yes" : "No"}
            </p>
          </div>
        ))}
      </div>
    </FieldsContainer>
  );
}
