import axios from "axios";

const deleteNewEvent = async (pointEventId) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/point-events/${pointEventId}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteNewEvent;
