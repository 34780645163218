import { Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { InfoTitle } from "../ImageDetailsElements";
import DeleteEventButton from "./DeleteEvent";
import CustomizedButton from "components/Buttons/CustomButton";

export default function TableOperation({
  ppmValue,
  grhValue,
  ltMinValue,
  operation,
  distance,
  videos,
  setStatus,
}) {
  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 25);
  };
  const [infoOperation, setInfoOperation] = useState({});

  const camelCaseToNormal = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleEdit = () => {
    setStatus("edit");
  };

  useEffect(() => {
    const { typeOperationId } = operation;
    const typeOperations = {
      1: "inspectionSniffer",
      2: "inspectionFlir",
      3: "leak",
      4: "Reparation",
    };
    const omit = ["id", "operationId", "typeId"];
    const abrebiations = {
      ppm: "ppm / meter",
      grH: "gram / hour",
      ltMin: "litre / minute",
    };
    const dates = ["date", "createdAt", "updatedAt"];
    const typeOperation = typeOperations[typeOperationId];
    const currentOperation = operation[typeOperation];
    Object.keys(currentOperation).map((key) => {
      if (omit.includes(key)) return null;
      let value = currentOperation[key];
      if (typeof value === "boolean") {
        value = value ? "Yes" : "No";
      }
      if (!value) {
        return null;
      }
      if (dates.includes(key)) value = isoDateToString(value);
      let keyNormal;
      if (abrebiations[key]) keyNormal = abrebiations[key];
      else keyNormal = camelCaseToNormal(key);
      setInfoOperation((prev) => ({ ...prev, [keyNormal]: value }));
      return null;
    });
    setInfoOperation((prev) => ({
      ...prev,
      Date: isoDateToString(operation.date),
      "Event Id": operation.id,
      Latitude: parseFloat(operation.locationOperation.latitude || 0).toFixed(
        5
      ),
      Longitude: parseFloat(operation.locationOperation.longitude || 0).toFixed(
        5
      ),
      Videos: videos.length,
      "Created By":
        operation.user.operator !== null
          ? operation.user.operator.firstName +
            " " +
            operation.user.operator.firstLastName
          : operation.user.adminCompany !== null
          ? operation.user.adminCompany.firstName +
            " " +
            operation.user.adminCompany.firstLastName
          : null,
    }));
    if (typeOperationId === 2) {
      setInfoOperation((prev) => ({
        ...prev,
        Type: operation?.inspectionFlir?.type.type,
      }));
    }
    if (distance) {
      setInfoOperation((prev) => ({
        ...prev,
        "Distance (m)": distance,
      }));
    }
  }, [operation, distance, videos]);

  return (
    <InfoTitle>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <section className="main-tables-container">
          <div className="main-currency-table">
            <div className="currency-table--container">
              <table>
                <tr>
                  <td className="table__top-left">Leak per regulation</td>
                  <td
                    style={
                      ppmValue >= 500 ||
                      grhValue >= 14487.85 ||
                      ltMinValue >= 336.77
                        ? { backgroundColor: "#ed3939" }
                        : {}
                    }
                    className="table__top-right table__right"
                  >
                    {ppmValue >= 500 ||
                    grhValue >= 14487.85 ||
                    ltMinValue >= 336.77
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <td className="table__top-left">Leak captured</td>
                  <td
                    style={
                      operation.inspectionFlir?.leak
                        ? { backgroundColor: "#ed3939" }
                        : {}
                    }
                    className="table__top-right table__right"
                  >
                    {operation.inspectionFlir?.leak ? "Yes" : "No"}
                  </td>
                </tr>

                {Object.keys(infoOperation).map((key) => {
                  return (
                    <tr>
                      <td>{key}</td>
                      <td className="table__right">{infoOperation[key]}</td>
                    </tr>
                  );
                })}
              </table>
              <DeleteEventButton eventId={operation.id} />
              <CustomizedButton text={"Edit event"} onClick={handleEdit} />
            </div>
          </div>
        </section>
      </Grid>
    </InfoTitle>
  );
}
