import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
// Mapbox
import Map, {
  Marker,
  NavigationControl,
  AttributionControl,
  Source,
  Layer,
} from "react-map-gl";
import DraweControl from "./DrawControl";
// Style
import "mapbox-gl/dist/mapbox-gl.css";
import Container from "@mui/material/Container";
import { ImgIconOperation, MapCard } from "./MapViewElements";
import { NamePlateMarker } from "./MapViewElements";
import OperationDetails from "../ImageGallery/OperationDetails/OperationDetails";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails } from "redux/actions";
// Consts
import fogStyle from "utils/fogStyle";
import { EVENT_CLUSTER_COLOR, COMPONENT_CLUSTER_COLOR } from "utils/const";
// Component
import EventDetails from "components/ImageGallery/EventDetails/EventDetails";
import ObjectDetails from "components/ImageGallery/ObjectDetails/ObjectDetails";
import SelectType from "./SelectType";
import OpenDialog from "components/Dialogs/OpenDialog";
import ComponentDetails from "components/ImageGallery/ComponentDetails/Index.jsx";
import BaseMapButton from "components/Buttons/BaseMapButton";
// Hooks
import FetchComponentsByObjectId from "hooks/fetchComponentsByObjectId";
import useSwr from "swr";
// import useLineString from "hooks/maps/useLineString";
// Config
import { config } from "config.js";
// Helpers
import { createLineString } from "helpers/createLineString";
import { ShowEventMarkers } from "helpers/ShowEventsMarker";
import ShowLinesJoin from "helpers/showLines";
// Services
import { findEventsByComponentId } from "services/findEvents";

const MapView = ({
  id,
  eventId,
  typeOperation,
  objectId,
  location,
  urlIcon,
  typeObjectId,
  markedEvent,
  otherMarkers,
  typeElementId,
}) => {
  const [marks, setMarks] = useState([]);
  const [operation, setOperation] = useState([]);
  const [anemometer, setAnemometer] = useState([]);
  const [eventsComponents, setEventsComponents] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const [baseMap, setBaseMap] = useState("streets-v11");

  const [haveObjects, setHaveObjects] = useState(false);
  const [feature, setFeatures] = useState({});
  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const [dataLineStrings, setDataLineStrings] = useState();

  const [componentsObject, setComponentsObject] = useState([]);

  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );
  // const { marks, componentsObject, dataLineStrings, operation } = useLineString(
  //   { objectId, location }
  // );

  // old events (operations)
  const urlMarks = `${config.URL_BACKEND_PG}api/v1/operation?objectId=${objectId}`;
  const { data: dataMarks } = useSwr(urlMarks);

  // Block to obtain the information of the dynamic events
  const urlDynamicEvents = `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${objectId}`;
  const { data: dataDynamic, error: errorDataDynamics } =
    useSwr(urlDynamicEvents);

  // Block to obtain the information of the dynamic components
  const { data: dataComponents } = FetchComponentsByObjectId(objectId);

  const eventsComponentes = useCallback(async () => {
    if (eventId !== undefined) return;
    const allEvents = await Promise.all(
      dataComponents?.map(async (component) => {
        const events = await findEventsByComponentId({
          pointComponentId: component.id,
        });
        const eventsComponentsLocation = events.map((event) => {
          return {
            ...event,
            componentLocation: {
              latitude: component.locationComponent.latitude,
              longitude: component.locationComponent.longitude,
            },
          };
        });
        return eventsComponentsLocation;
      })
    );
    const flattenedArray = allEvents.flat();
    setEventsComponents(flattenedArray);
  }, [dataComponents, eventId]);

  const dataDynamicEvents = useMemo(() => {
    return dataDynamic && !errorDataDynamics ? dataDynamic : [];
  }, [dataDynamic, errorDataDynamics]);

  // Find type events to type elements
  const urlTypeEvents = `${config.URL_BACKEND_PG}api/v1/type-events-type-elements?typeElementId=${typeElementId}&adminCompanyId=${adminCompanyId}`;
  const { data: dataTypeEvents } = useSwr(urlTypeEvents);
  // Find type components to type events
  const urlTypeComponents = `${config.URL_BACKEND_PG}api/v1/type-components-type-elements?typeElementId=${typeElementId}&adminCompanyId=${adminCompanyId}`;
  const { data: dataTypeComponents } = useSwr(urlTypeComponents);

  // Merge old events with dynamic events
  useEffect(() => {
    if (dataMarks) {
      let allMarks = [...dataMarks];
      if (dataDynamicEvents?.length)
        allMarks = [...dataMarks, ...dataDynamicEvents];
      setMarks([...allMarks]);
      setOperation([...allMarks]);
    }
    if (dataComponents?.length) {
      setComponentsObject(dataComponents);
      eventsComponentes();
    }
  }, [dataMarks, dataDynamicEvents, dataComponents, eventsComponentes]);

  useEffect(() => {
    if (typeOperation !== undefined) {
      typeOperation.name === "Inspección flir" &&
        axios
          .get(
            `${config.URL_BACKEND_PG}api/v1/inspection-flir/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;
            setAnemometer(res.operationAnemometer);
          })
          .catch((err) => {
            console.log(err);
            setAnemometer([]);
          });

      typeOperation.name === "LEAK" &&
        axios
          .get(`${config.URL_BACKEND_PG}api/v1/leak/anemometer/${eventId}`, {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          })
          .then((response) => {
            const res = response.data;

            setAnemometer(res.operationAnemometer);
          });
      typeOperation.name === "INSPECTION SNIFFER" &&
        axios
          .get(
            `${config.URL_BACKEND_PG}api/v1/inspection-sniffer/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;
            setAnemometer(res.operationAnemometer);
          });
      typeOperation.name === "REPERATION" &&
        axios
          .get(
            `${config.URL_BACKEND_PG}api/v1/reparation/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;

            setAnemometer(res.operationAnemometer);
          });
    }
  }, [objectId, eventId, typeOperation, token]);

  // When click in some operation chnage view in the modal
  const handleClickMarker = (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} fromObject={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
  };

  const handleClickDynamicEvent = (eventId) => {
    const event = {
      content: <EventDetails eventId={eventId} fromObject={true}  />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(event));
  };

  const HandleClickMarkerObject = (objectId) => {
    const object = {
      content: <ObjectDetails objectId={objectId}  />,
      title: "Objects Details",
    };
    if (!drawAnyThing) {
      dispatch(setOperationDetails(object));
    }
  };

  const handleClickComponent = (componentId) => {
    const component = {
      content: <ComponentDetails componentId={componentId} fromObject={true}  />,
      title: "Component Details",
    };
    dispatch(setOperationDetails(component));
  };

  useEffect(() => {
    const typeOperations = [
      ["inspection-sniffer", "inspectionSniffer"],
      ["inspection-flir", "inspectionFlir"],
      ["leak", "leak"],
      ["reparation", "Reparation"],
    ];

    const getSniffers = async (objectOperation) => {
      const { typeOperationId } = objectOperation;
      const [path, type] = typeOperations[typeOperationId - 1];
      const { id } = objectOperation[type];
      const { data } = await axios.get(
        `${config.URL_BACKEND_PG}api/v1/${path}/anemometer/${id}`
      );

      const { operationAnemometer } = data;
      setAnemometer((currentAnemometers) => [
        ...currentAnemometers,
        ...operationAnemometer,
      ]);
    };

    marks?.map((object) => {
      if (!object?.operations) return null;
      object?.operations?.map((objectOperation) =>
        getSniffers(objectOperation)
      );
      return null;
    });
  }, [marks]);

  const showComponentsMarkers = () => {
    return componentsObject?.map((component, index) => {
      const icon = component?.pointTypeComponent?.icon;
      return (
        <div
          key={`${component?.pointTypeComponent?.name}-${component.id}-${index}`}
        >
          {componentsObject && (
            <Marker
              latitude={Number(component.locationComponent.latitude)}
              longitude={Number(component.locationComponent.longitude)}
              style={{
                cursor: "pointer",
              }}
            >
              <NamePlateMarker
                id={component.id}
                onClick={() => handleClickComponent(component.id)}
                marked={markedEvent === component.id}
              >
                <ImgIconOperation
                  id={component.id}
                  src={icon}
                  alt="component marker"
                  style={{
                    cursor: "pointer",
                  }}
                ></ImgIconOperation>
                <span className="selected"></span>
              </NamePlateMarker>
              <p>{component.id}</p>
            </Marker>
          )}
        </div>
      );
    });
  };

  // get all dataLineStrings of the Object GHG
  useEffect(() => {
    // Lines to events
    const fetchData = async () => {
      try {
        const data = [];
        marks?.map(async (event) => {
          if (id !== undefined) return;
          const objectLocation = [location.longitude, location.latitude];

          const locationOperation = [
            event.locationOperation.longitude,
            event.locationOperation.latitude,
          ];
          const geojsonLineString = createLineString(
            [objectLocation, locationOperation],
            EVENT_CLUSTER_COLOR
          );
          data.push(geojsonLineString);
        });
        setDataLineStrings(data);
      } catch (error) {
        console.error("error:", error);
      }
    };
    marks && fetchData();

    // Lines to components
    const linesToComponents = () => {
      try {
        if (eventId === undefined) {
          const data = [];
          componentsObject?.map(async (component) => {
            const objectLocation = [location.longitude, location.latitude];

            const locationComponent = [
              component.locationComponent.longitude,
              component.locationComponent.latitude,
            ];
            const geojsonLineString = createLineString(
              [objectLocation, locationComponent],
              COMPONENT_CLUSTER_COLOR
            );
            data.push(geojsonLineString);
          });
          setDataLineStrings((prev) => [...prev, ...data]);
        }
      } catch (error) {
        console.error("error:", error);
      }
    };
    componentsObject && linesToComponents();

    //Lines to events of components
    const linesToEventsComponents = () => {
      try {
        if (eventId === undefined) {
          const data = [];
          eventsComponents?.map(async (eventComponent) => {
            const componentLocation = [
              eventComponent.componentLocation.longitude,
              eventComponent.componentLocation.latitude,
            ];

            const eventLocation = [
              eventComponent.locationOperation.longitude,
              eventComponent.locationOperation.latitude,
            ];

            const geojsonLineString = createLineString(
              [componentLocation, eventLocation],
              EVENT_CLUSTER_COLOR
            );
            data.push(geojsonLineString);
          });
          setDataLineStrings((prev) => [...prev, ...data]);
        }
      } catch (error) {
        console.error("error:", error);
      }
    };
    eventsComponents && linesToEventsComponents();
  }, [
    id,
    eventId,
    location.longitude,
    location.latitude,
    componentsObject,
    marks,
    eventsComponents,
  ]);

  // show event anemometer markers
  const showAnemometeresOfEvent = () => {
    return anemometer?.map((object, index) => {
      return (
        <div key={index}>
          {object.anemometer !== null && (
            <Marker
              latitude={Number(object?.anemometer?.locationAnemometer.latitude)}
              longitude={Number(
                object?.anemometer?.locationAnemometer.longitude
              )}
            >
              <NamePlateMarker id={anemometer.id}>
                <img
                  id={anemometer.id}
                  src={
                    "https://icons-greendragon.s3.us-east-2.amazonaws.com/type_elements_events/anemometerIconMap.png"
                  }
                  alt="nameplate marker"
                ></img>
              </NamePlateMarker>
            </Marker>
          )}
        </div>
      );
    });
  };

  const showOthersMarkers = () => {
    return otherMarkers?.map((marker, index) => {
      const firstLocation = [location.longitude, location.latitude];
      const secondLocation = [marker.longitude, marker.latitude];
      const lineString = createLineString([firstLocation, secondLocation]);

      return (
        <>
          <div key={index}>
            <Source type="geojson" data={lineString} key={index}>
              <Layer
                key={index}
                id={index}
                type="line"
                paint={{
                  "line-color": EVENT_CLUSTER_COLOR,
                  "line-width": 3,
                  "line-dasharray": [2, 2],
                }}
              />
            </Source>
          </div>
          <Marker
            latitude={Number(marker.latitude)}
            longitude={Number(marker.longitude)}
            onClick={() => HandleClickMarkerObject(marker.id)}
          >
            <NamePlateMarker>
              <img
                src={marker.urlIcon}
                alt="nameplate marker"
                style={{
                  cursor: "pointer",
                }}
              ></img>
            </NamePlateMarker>
          </Marker>
        </>
      );
    });
  };

  useEffect(() => {
    if (haveObjects) return;
    setFeatures({});
  }, [haveObjects]);

  useEffect(() => {
    if (!feature.geometry) return;
    setContentDialog({
      title: "Assign Event or Component",
      description: (
        <SelectType
          feature={feature}
          objectId={objectId}
          setHaveObjects={setHaveObjects}
          typeEvents={dataTypeEvents}
          typeComponents={dataTypeComponents}
        />
      ),
    });
    setHaveObjects(true);
  }, [feature, objectId, dataTypeEvents, dataTypeComponents]);

  return (
    <Container
      maxWidth="l"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        disagree={() => {}}
        content={contentDialog}
        maxWidth={contentDialog.title === "Base Map" ? null : "500px"}
      />
      <MapCard>
        <Map
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            latitude: location.latitude,
            longitude: location.longitude,
            zoom: 20,
          }}
          style={{ width: "100%", height: 434, margin: "auto" }}
          mapStyle={`mapbox://styles/mapbox/${baseMap}`}
          attributionControl={false}
          projection={"globe"}
          fog={fogStyle}
        >
          {objectId && !otherMarkers && (
            <DraweControl handleCreateEvent={setFeatures} />
          )}
          {/* Button layer map */}
          <BaseMapButton
            setHaveObjects={setHaveObjects}
            setContentDialog={setContentDialog}
            setBaseMap={setBaseMap}
            position={eventId ? 104 : 200}
          />
          <Marker
            latitude={Number(location.latitude)}
            longitude={Number(location.longitude)}
          >
            <NamePlateMarker>
              <img src={urlIcon} alt="nameplate marker"></img>
            </NamePlateMarker>
          </Marker>
          {otherMarkers && showOthersMarkers()}
          <AttributionControl customAttribution="© Decimetrix® 2023" />
          <NavigationControl style={navControlStyle} />
          {typeObjectId &&
            marks?.length > 0 &&
            operation?.length > 0 &&
            ShowEventMarkers(
              marks,
              handleClickDynamicEvent,
              handleClickMarker,
              markedEvent
            )}
          {eventsComponents.length > 0 &&
            ShowEventMarkers(
              eventsComponents,
              handleClickDynamicEvent,
              handleClickMarker,
              markedEvent
            )}
          {dataLineStrings && <ShowLinesJoin features={dataLineStrings} />}
          {anemometer.length > 0 ? showAnemometeresOfEvent() : null}
          {componentsObject.length > 0 &&
            typeObjectId &&
            showComponentsMarkers()}
        </Map>
      </MapCard>
    </Container>
  );
};

export default MapView;
