import styled from "styled-components";

export const FieldDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #f9f9f9;
  text-align: center;
  padding: 20px 0;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;

export const FieldDataWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 30px 66px;
  padding: 0 50px;


  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
  }
`;

export const FieldDataCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;


  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const FieldDataIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const FieldDataH1 = styled.h1`
  font-size: 2.5rem;
  color: #31A636;
  margin-bottom: 64px;

  @media (max-width: 600px) {
    font-size: 2rem;
    text-align: center;
  }
`;

export const FieldDataH2 = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;



`;

export const FieldDataP = styled.p`
  font-size: 1.4rem;
  text-align: center;
`;

export const ViewModeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;