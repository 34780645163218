import { SelectContainer } from "./CustomSelectStyle";

/**
 * Renders a custom select component.
 *
 * @param {Object} options - An array of options: [{id:1, name: "option 1"}] for the select component.
 * @param {string} label - The label for the select component.
 * @param {function} setSelect - A function to set the selected value.
 * @param {string} select - The currently selected value.
 * @return {JSX.Element} The rendered custom select component.
 */
const CustomSelect = ({ options, label, setSelect, select }) => {
  const setOptionsHandler = (e) => {
    setSelect(e.target.value);
  };
  return (
    <SelectContainer>
      <label className="label" htmlFor="select-option">
        {label}
      </label>
      <select
        className="select"
        name="select-option"
        value={select}
        onChange={setOptionsHandler}
      >
        {options.map((option) => (
          <option value={option.id}>{option.name}</option>
        ))}
      </select>
    </SelectContainer>
  );
};

export default CustomSelect;
