import styled from "styled-components";

export const MainContainer = styled.div`
  position: relative;

  .settings {
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
    justify-content: right;
    top: 100px;
  }
`;

export const FormSettings = styled.form`
  display: flex;
  margin: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;

  div,
  label {
    margin: 0 10px;
  }
  input {
    background-color: #282828;
    color: #fff;
    padding: 4px;
  }
`;
