import styled from "styled-components";
import {
  PRIMARY_COLOR,
  OBJECT_CLUSTER_COLOR,
  EVENT_CLUSTER_COLOR,
  COMPONENT_CLUSTER_COLOR,
} from "utils/const";

export const Container = styled.div`
  position: absolute;
  bottom: 6rem;
  right: 1rem;
  z-index: 7;
  background: #f1f1f1;
  border-radius: 1rem;

  .icon-settings {
    font-size: 2.5rem;
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .container-tooltip {
    position: relative;

    .tooltip {
      display: none;
      position: absolute;
      top: 100%;
      transform: translate(-85%, 20px);
      padding: 0.5rem;
      background-color: #000;
      color: #fff;
      text-align: center;
      font-size: 1.5rem;
    }
  }

  &:hover .tooltip {
    display: block;
  }
`;

export const Panel = styled.div`
  padding: 1rem;

  .head {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    border-bottom: 1px solid ${PRIMARY_COLOR};
  }

  .head-left {
    cursor: pointer;
    color: red;
  }

  .body {
    margin-top: 1rem;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
  }

  .body-items {
    font-size: 1.5rem;
    color: #000;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .object-clusters-title {
    display: flex;
    align-items: center;
  }

  .color-cluster-object,
  .color-cluster-event,
  .color-cluster-component {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 10px;
    background-color: ${OBJECT_CLUSTER_COLOR};
  }

  .color-cluster-event {
    background-color: ${EVENT_CLUSTER_COLOR};
  }

  .color-cluster-component {
    background-color: ${COMPONENT_CLUSTER_COLOR};
  }

  .body-item {
    flex: 1;

    input {
      width: 40%;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid ${PRIMARY_COLOR};
      text-align: center;
    }

    label {
      display: flex;
      gap: 0.2rem;
      font-weight: 500;
    }
  }
`;
