import CircularProgress from "@mui/material/CircularProgress";
import { PRIMARY_COLOR } from "utils/const";
import { LoadingContainer } from "./LoadingStyle";

export default function CircularIndeterminate() {
  return (
    <LoadingContainer>
      <CircularProgress
        style={{
          width: "150px",
          height: "150px",
          color: PRIMARY_COLOR,
        }}
      />
      <p>Loading</p>
    </LoadingContainer>
  );
}
