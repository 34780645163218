import styled from "styled-components";

export const ManageUserButtons = styled.div`
  display: flex;
  outline: none;
  border: none;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  /* height: auto; */
  text-align: left;

  .main-currency-table {
    width: 100%;
    min-width: 235px;
    /* max-width: 500px; */
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .main-currency-table .currency-table--title {
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.3rem;
    text-align: center;
  }
  .currency-table--container {
    width: 90%;
    min-width: 230px;
    /* max-width: 1200px; */
    /* height: 200px; */
    margin: 0 auto;
  }
  .currency-table--container table {
    width: 100%;
    height: 100%;
  }

  .currency-table--container table td {
    width: 50%;
    height: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
  }
  .currency-table--container .table__top-left {
    border-radius: 5px 0 0 0;
  }
  .currency-table--container .table__top-right {
    border-radius: 0 5px 0 0;
  }
  .currency-table--container .table__bottom-right {
    border-radius: 0 0 5px 0;
  }
  .currency-table--container .table__bottom-left {
    border-radius: 0 0 0 5px;
  }
  .currency-table--container .table__right {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
    word-break: break-all;
  }
  .currency-table--container table tr:nth-child(2n + 1) {
    background-color: #f7f4f3;
  }
  .currency-table--container table tr:nth-child(2n) {
    background-color: #fff;
  }
`;
