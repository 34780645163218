import {
  ContainerOperations,
  CustomizedChevronLeftIcon,
} from "components/ImageGallery/EventDetails/EventDetailsElements";
import { WraperTitleCardMarker, InfoTitle } from "../ImageDetailsElements";
import OpenDialog from "../../Dialogs/OpenDialog";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import useSwr from "swr";
import { useSWRConfig } from "swr";

import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Components
import ObjectDetails from "../ObjectDetails/ObjectDetails";
import TableComponent from "./TableComponent";
import ComponentMap from "components/Maps/ComponentMap";
import PhotoGallery from "../PhotoGalley/PhotoGallery";
import AddPicture from "./AddPicture/AddPicture";
// Services
import findObjects from "services/findObjects";
import { LibToolTip } from "../ObjectDetails/ObjectDetailsElements";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
// Config
import { config } from "config.js";

const ComponentDetails = ({ componentId, fromObject }) => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const [errorMessage, setErrorMessage] = useState(false);

  const [objectGHG, setObjectGHG] = useState(false);

  const urlData = `${process.env.REACT_APP_URL_BACKEND}api/v1/point-components?id=${componentId}`;
  const { data: component, error: errorComponent } = useSwr(urlData);

  const urlDataCountEvents = `${process.env.REACT_APP_URL_BACKEND}api/v1/point-components?countEvents=${componentId}`;
  const { data: countEvents, error: errorCountEvents } =
    useSwr(urlDataCountEvents);

  // Get object the current component
  useEffect(() => {
    const getObject = async () => {
      const objectId = component[0]?.objectId;
      const data = await findObjects(objectId);
      setObjectGHG(data);
    };
    component && getObject();
  }, [component]);

  const returnToObject = () => {
    const object = {
      content: <ObjectDetails objectId={objectGHG.id} />,
      title: "Object Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };

  const uploadImages = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/point-components?id=${componentId}`);
  };

  return (
    <>
      {component && !errorComponent && (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            position: "relative",
          }}
        >
          {fromObject && (
            <IconButton
              onClick={returnToObject}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          <OpenDialog
            openDialog={errorMessage}
            setOpenDialog={setErrorMessage}
            content={{
              title: "Conexion lost",
              description: "Please check your internet conexion and try again.",
              disagree: "Continue",
            }}
          />
          <ContainerOperations>
            {/* --COMPONENT DETAILS-- */}
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="info-event">
                <WraperTitleCardMarker>
                  <h1>
                    COMPONENT TYPE
                    <br />
                    <span> {component[0].pointTypeComponent?.name} </span>
                  </h1>
                  <img
                    width="30px"
                    height="100px"
                    alt="ico"
                    src={component[0].pointTypeComponent?.icon}
                  ></img>
                </WraperTitleCardMarker>
                <InfoTitle>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <section className="main-tables-container">
                      <TableComponent
                        component={component[0]}
                        deleteButton={
                          countEvents?.eventCount.length > 0 ? false : true
                        }
                      />
                      <div className="main-currency-table">
                        {countEvents?.eventCount.length > 0 && (
                          <p className="currency-table--title">Events</p>
                        )}
                        <div className="currency-table--container">
                          <table>
                            {!errorCountEvents &&
                              countEvents &&
                              countEvents?.eventCount.map((it) => {
                                return (
                                  <tr>
                                    <td className="table__bottom-left">
                                      <LibToolTip>
                                        {it.typeName}
                                        <span className="tooltiptrigger">
                                          <LocalLibraryIcon
                                            sx={{
                                              fontSize: "1.8rem",
                                              color: "#3f51b5",
                                            }}
                                          />
                                        </span>
                                        <span className="tooltip">
                                          Library {it.libName}
                                        </span>
                                      </LibToolTip>
                                    </td>
                                    <td className="table__bottom-right table__right">
                                      {it.quantity}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </div>
                    </section>
                  </Grid>
                </InfoTitle>
              </div>
            </Grid>

            {/* --COMPONENT GALLERY-- */}

            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div className="photo-container-image">
                <h1 className="component-title">
                  <span>GALLERY</span>
                </h1>
                <div
                  className="gallery"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 24px 0px 20px",
                  }}
                >
                  {component[0].componentFiles.length > 0 ||
                  component[0]?.media?.length > 0 ? (
                    <>
                      <p style={{ fontSize: "1.6rem" }}>
                        <b>Total Pictures: </b>{" "}
                        {component[0].componentFiles.length}
                      </p>
                      <br />
                      <PhotoGallery
                        photos={[
                          ...component[0]?.componentFiles,
                          ...component[0]?.media,
                        ]}
                        ownId={component[0]?.id}
                        mongoId={component[0]?.mongoId}
                        type={"component"}
                        uploadImages={uploadImages}
                      />
                      <AddPicture pointComponent={component[0]} />
                    </>
                  ) : (
                    <AddPicture pointComponent={component[0]} />
                  )}
                </div>
              </div>
            </Grid>

            {/* -- MAP -- */}
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="content-map">
                <h1 className="component-title">
                  <span>LOCATION</span>
                </h1>
                {component && <ComponentMap component={component[0]} />}
              </div>
            </Grid>
          </ContainerOperations>
        </Box>
      )}
    </>
  );
};

export default ComponentDetails;
