import styled from "styled-components";
import { PRIMARY_COLOR } from "utils/const";

export const Container = styled.div`
  position: absolute;
  bottom: 14rem;
  right: 1rem;
  z-index: 7;
  background: #f1f1f1;
  border-radius: 1rem;

  .icon-table {
    font-size: 2.5rem;
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
`;
