import { TableContainer } from "./TableStyle";

const Table = ({ title, data }) => {
  const dataTable = Object.entries(data).map((item) => ({
    key: item[0],
    value: item[1],
  }));

  const tableContent = () => {
    const data = [...dataTable];
    data.shift();
    data.pop();
    return data;
  };

  return (
    <>
      <TableContainer>
        <div className="main-currency-table">
          <p className="currency-table--title">{title}</p>
          <div className="currency-table--container">
            <table>
              <tr>
                <td className="table__top-left">{dataTable[0].key}</td>
                <td className="table__top-right table__right">
                  {dataTable[0].value}
                </td>
              </tr>
              {tableContent().map((item) => (
                <tr>
                  <td>{item.key}</td>
                  <td className="table__right">{item.value}</td>
                </tr>
              ))}
              <tr>
                <td className="table__bottom-left">{dataTable.at(-1).key}</td>
                <td className="table__bottom-right table__right">
                  {dataTable.at(-1).value}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </TableContainer>
    </>
  );
};

export default Table;
