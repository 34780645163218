import { Input, Label, WrapperInput, LegendError } from "./InputStyle";

const InputComponent = ({
  state,
  changeState,
  type,
  label,
  placeholder,
  name,
  legendError,
  regularExpression,
  func,
}) => {
  const onChange = (e) => {
    changeState({ ...state, field: e.target.value });
  };

  const validation = () => {
    if (regularExpression) {
      if (regularExpression.test(state.field)) {
        changeState({ ...state, valid: "true" });
      } else {
        changeState({ ...state, valid: "false" });
      }
    }

    if (func) {
      func();
    }
  };

  if (state.field !== "" && !state.valid) validation();

  return (
    <>
      <WrapperInput>
        <div>
          <Label htmlFor={name} valid={state.valid}>
            {label}
          </Label>
          <Input
            type={type}
            placeholder={placeholder}
            id={name}
            onChange={onChange}
            onKeyUp={validation}
            onBlur={validation}
            valid={state.valid}
            value={state.field}
          />
        </div>

        <LegendError valid={state.valid}>{legendError}</LegendError>
      </WrapperInput>
    </>
  );
};

export default InputComponent;
