import axios from "axios";

const deleteFlirVideoProcessed = async (id) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video-processed/${id}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteFlirVideoProcessed;
