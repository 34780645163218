import { WHITE_COLOR, WHITE_SECOND_COLOR, SPACE_COLOR } from "utils/const";

const fogStyle = {
  color: WHITE_COLOR, // Lower atmosphere
  "high-color": WHITE_SECOND_COLOR, // Upper atmosphere
  "horizon-blend": 0, // Atmosphere thickness (default 0.2 at low zooms)
  "space-color": SPACE_COLOR, // Background color
  "star-intensity": 0.1, // Background star brightness (default 0.35 at low zoooms )
};

export default fogStyle;
