import useSwr from "swr";

const FetchUsersAdminCompany = (adminCompanyId) => {
  const { data, error } = useSwr(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/operators/${adminCompanyId}`
  );
  return { data, error };
};

export default FetchUsersAdminCompany;
