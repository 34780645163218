const deleteDuplicateEvents = (events) => {
  return events
    .filter(
      (object, index, self) =>
        index ===
        self.findIndex((t) => t.pointTypeEventId === object.pointTypeEventId)
    )
    .map((item) => item.pointTypeEvent);
};

export default deleteDuplicateEvents;
